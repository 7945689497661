import { components } from "react-select";

// see https://itecnote.com/tecnote/reactjs-react-select-show-different-text-label-for-drop-down-and-control/ for 
export const SingleValue = props => (
  <components.SingleValue {...props}>
    {props.data.chipLabel}
  </components.SingleValue>
);

export const customFilterForSearchIndex = (option, inputValue) => {
  const reg = new RegExp(`^.*${inputValue}.*$`, "i");
  return reg.test(option.data.searchIndex);
};