import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import { constructErrorMessage } from "../../helpers/utils";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { contactService } from "../../services";

const AddAdditionalContactAttribute = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      attributeName: "",
      displayName: "",
      description: "",
    },
    validationSchema: Yup.object({
      attributeName: Yup.string().required("Please enter the attribute name").matches(/^[a-zA-Z]+\w*$/, 'The attribute name should be alphanumeric and start with a word character').max(20, 'Please use no more than 20 characters'),
    }),
    onSubmit: async (values) => {
      try {
        setIsCreating(true);
        setErrorMessage('');
        let response = await contactService.createAdditionalContactAttribute(currentProjectId, values.attributeName, values.displayName, values.description);

        if (!response?.createdAdditionalContactAttribute?.additionalContactAttributeId) {
          throw new Error("attribute was not created");
        }
        validation.resetForm();
        history.push(`/projects/${currentProjectId}/directory/contacts/attributes/${response?.createdAdditionalContactAttribute?.additionalContactAttributeId}`, { redirectStatus: `create-attr-succeeded` });

      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsCreating(false);
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Custom Contacts Attributes"
            titleUrl={`/projects/${currentProjectId}/directory/contacts/attributes`}
            breadcrumbItem="New Attribute" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {!isCreating && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  <>
                    <Form
                      className="form-group add-contact-attr-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row className="mb-3">
                        <Label className="form-label">Attribute name</Label>
                        <InputGroup>
                          <Input
                            name="attributeName"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.attributeName || ""}
                            invalid={
                              validation.touched.attributeName && validation.errors.attributeName ? true : false
                            }
                          />
                          {validation.touched.attributeName && validation.errors.attributeName ? (
                            <FormFeedback type="invalid">{validation.errors.attributeName}</FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Row>
                      <Row className="mb-3">
                        <Label className="form-label">Display name</Label>
                        <InputGroup>
                          <Input
                            name="displayName"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.displayName || ""}
                            invalid={
                              validation.touched.displayName && validation.errors.displayName ? true : false
                            }
                          />
                          {validation.touched.displayName && validation.errors.displayName ? (
                            <FormFeedback type="invalid">{validation.errors.displayName}</FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Row>
                      <Row className="mb-3">
                        <Label className="form-label">Description</Label>
                        <InputGroup>
                          <Input
                            name="description"
                            type="text"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={
                              validation.touched.description && validation.errors.description ? true : false
                            }
                          />
                          {validation.touched.description && validation.errors.description ? (
                            <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Row>

                      <Row className="mb-3">
                        <div className="d-flex flex-wrap gap-3 mt-3">
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md"
                          >
                            {isCreating && <>
                              <Spinner size="sm me-1" color="light" />
                            </>}
                            {!isCreating && <>
                              Create
                            </>}
                          </Button>
                        </div>
                      </Row>
                    </Form>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddAdditionalContactAttribute.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AddAdditionalContactAttribute)));