/* CONTACTS */
export const GET_CONTACTS_FOR_PROJECT_REQUEST = "GET_CONTACTS_FOR_PROJECT_REQUEST";
export const GET_CONTACTS_FOR_PROJECT_SUCCESS = "GET_CONTACTS_FOR_PROJECT_SUCCESS";

export const SET_CONTACTS_LIMIT = "SET_CONTACTS_LIMIT";

export const GET_CONTACT_DETAILS_REQUEST = "GET_CONTACT_DETAILS_REQUEST";
export const GET_CONTACT_DETAILS_NO_LOADING_REQUEST = "GET_CONTACT_DETAILS_NO_LOADING_REQUEST";
export const GET_CONTACT_DETAILS_SUCCESS = "GET_CONTACT_DETAILS_SUCCESS";

export const UPDATE_CONTACT_DETAILS_REQUEST = "UPDATE_CONTACT_DETAILS_REQUEST";
export const UPDATE_CONTACT_DETAILS_SUCCESS = "UPDATE_CONTACT_DETAILS_SUCCESS";

export const DELETE_CONTACTS_REQUEST = "DELETE_CONTACTS_REQUEST";
export const DELETE_CONTACTS_SUCCESS = "DELETE_CONTACTS_SUCCESS";

export const GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST = "GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST";
export const GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST = "GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST";
export const GET_CONTACT_GROUPS_FOR_PROJECT_SUCCESS = "GET_CONTACT_GROUPS_FOR_PROJECT_SUCCESS";

export const SET_CONTACTS_GROUP_LIMIT = "SET_CONTACTS_GROUP_LIMIT";

export const GET_CONTACT_GROUP_DETAILS_REQUEST = "GET_CONTACT_GROUP_DETAILS_REQUEST";
export const GET_CONTACT_GROUP_DETAILS_SUCCESS = "GET_CONTACT_GROUP_DETAILS_SUCCESS";

export const UPDATE_CONTACT_GROUP_DETAILS_REQUEST = "UPDATE_CONTACT_GROUP_DETAILS_REQUEST";
export const UPDATE_CONTACT_GROUP_DETAILS_SUCCESS = "UPDATE_CONTACT_GROUP_DETAILS_SUCCESS";

export const DELETE_CONTACT_GROUP_REQUEST = "DELETE_CONTACT_GROUP_REQUEST";
export const DELETE_CONTACT_GROUP_SUCCESS = "DELETE_CONTACT_GROUP_SUCCESS";

export const DELETE_CONTACT_GROUPS_REQUEST = "DELETE_CONTACT_GROUPS_REQUEST";
export const DELETE_CONTACT_GROUPS_SUCCESS = "DELETE_CONTACT_GROUPS_SUCCESS";

export const GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST = "GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST";
export const GET_CONTACT_ATTRIBUTE_DETAILS_SUCCESS = "GET_CONTACT_ATTRIBUTE_DETAILS_SUCCESS";

export const UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST = "UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST";
export const UPDATE_CONTACT_ATTRIBUTE_DETAILS_SUCCESS = "UPDATE_CONTACT_ATTRIBUTE_DETAILS_SUCCESS";

export const DELETE_CONTACT_ATTRIBUTE_REQUEST = "DELETE_CONTACT_ATTRIBUTE_REQUEST";
export const DELETE_CONTACT_ATTRIBUTE_SUCCESS = "DELETE_CONTACT_ATTRIBUTE_SUCCESS";

export const GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST = "GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST";
export const GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_SUCCESS = "GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_SUCCESS";

export const SET_CONTACT_IMPORT_TASKS_LIMIT = "SET_CONTACT_IMPORT_TASKS_LIMIT";

export const CONTACT_API_ERROR = "CONTACT_API_ERROR";
