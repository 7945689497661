import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import {
  Row, Col, Table, Button, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import AddContactsDialog from './AddContactsDialog';
import ContactPanel from './ContactPanel';

import { constructErrorMessage, textOrEmpty } from '../../helpers/utils';

import { contactService } from "../../services";


const GroupContactsCard = (props) => {

  const currentProjectId = props?.currentProjectId;
  const currentContactGroupId = props?.currentContactGroupId;

  const [groupContactsList, setGroupContactsList] = useState([]);
  const [groupContactsLimit, setGroupContactsLimit] = useState(20);
  const [groupContactsOffset, setGroupContactsOffset] = useState(0);
  const [groupContactsTotalCount, setGroupContactsTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [btnActionOpen, setBtnActionOpen] = useState(false);
  const [contactSelectedIds, setContactSelectedIds] = useState(new Map());
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const [isAddContactsDialogOpen, setIsAddContactsDialogOpen] = useState(false);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);
  const [panelContactId, setPanelContactId] = useState('');

  // this is done for compatibility (copy/paste) with other code
  let pageSize = groupContactsLimit ?? 20;
  let contactsOffset = groupContactsOffset ?? 0;
  let contactsTotalCount = groupContactsTotalCount ?? 0;

  const loadContacts = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      setSuccessMessage('');
      setGroupContactsList([]);
      let l = await contactService.fetchContactsByContactGroupId(currentContactGroupId, currentProjectId, groupContactsLimit, groupContactsOffset);

      if (!l?.contacts) {
        throw new Error('empty contacts');
      }
      setGroupContactsList(l?.contacts);
      setGroupContactsLimit(l?.limit ?? 20);
      setGroupContactsOffset(l?.offset ?? 0);
      setGroupContactsTotalCount(l?.totalCount ?? 0);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, [currentProjectId, currentContactGroupId, groupContactsLimit, groupContactsOffset]);

  const dataRefresh = useCallback(() => {
    if (currentProjectId && currentContactGroupId) {
      // if (groupContactsOffset !== 0) {
      //  setGroupContactsOffset(0);
      ///// loadContacts() will be called automatically because it's a callback with dependent values
      //} else {
      loadContacts();
      //}
    }
  }, [loadContacts, currentProjectId, currentContactGroupId]);

  useEffect(() => {
    if (currentProjectId && currentContactGroupId) {
      loadContacts();
    }
  }, [loadContacts, currentProjectId, currentContactGroupId]);

  const onAddContacts = async (selectedIds /* array of IDs */) => {
    try {
      setSuccessMessage("");
      setIsLoading(true);
      await contactService.addContactsToContactGroup(currentProjectId, currentContactGroupId, selectedIds);
      setIsLoading(true);
      setErrorMessage("");
      setSuccessMessage("Successfully added selected contacts");
      loadContacts();
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  };

  const onNextPageClick = () => {
    if (contactsOffset + pageSize < contactsTotalCount) {
      setGroupContactsOffset(contactsOffset + pageSize);
      // loadContacts() will be called automatically because it's a callback with dependent values
    }
  }

  const onPreviousPageClick = () => {
    if (contactsOffset !== 0) {
      setGroupContactsOffset(contactsOffset - pageSize);
      // loadContacts() will be called automatically because it's a callback with dependent values
    }
  }

  const onDeleteContactsClick = () => {
    let selectedIds = [...contactSelectedIds.keys()];

    setConfirmDialogText(`Are you sure you want to removed selected contact${selectedIds.length > 1 ? 's' : ''} from this group?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      setIsLoading(true);
      contactService.deleteContactsFromContactGroup(currentProjectId, currentContactGroupId, selectedIds)
        .then(() => {
          loadContacts();
          setSuccessMessage(`Contact${selectedIds.length > 1 ? 's were' : ' was'} successfully removed`);
        })
        .catch(err => {
          console.log(err);
          setErrorMessage(constructErrorMessage(err));
        })
        .finally(() => setIsLoading(false));
    });

    setIsConfirmDialogOpen(true);
  }

  const handleContactSelectedIdsChange = (e) => {
    let contactId = e?.target?.value;

    if (!contactId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (contactSelectedIds.has(contactId)) {
      let m = contactSelectedIds;
      m.delete(contactId);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    } else {
      let m = contactSelectedIds;
      m.set(contactId, true);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }
  }

  const showDialog = () => {
    setIsAddContactsDialogOpen(true);
  }

  const hideDialog = (needsRefresh) => {
    setIsAddContactsDialogOpen(false);
    if (needsRefresh) {
      loadContacts();
    }

  }

  const rowCheckboxIsChecked = (contactId) => {
    return contactSelectedIds.has(contactId);
  }

  const onContactUpdated = useCallback(() => {
    setIsContactPanelOpen(false);
    setSuccessMessage("Contact was successfully updated");
    setTimeout(() => { // clearing the message to avoid showing it multiple times
      setSuccessMessage('');
    }, 1000);
  }, []);

  const onContactDeleted = useCallback(() => {
    setIsContactPanelOpen(false);
    setSuccessMessage("Contact was successfully deleted");
    setTimeout(() => { // clearing the message to avoid showing it multiple times
      setSuccessMessage('');
    }, 1000);
  }, []);

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (isLoading === undefined || isLoading === true) {
      return undefined;
    }

    return Array.isArray(groupContactsList) && groupContactsList.length > 0;
  }, [isLoading, groupContactsList]);

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
          {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
          <div className="actions clearfix">
            <ul>
              <li>
                <Dropdown
                  isOpen={btnActionOpen}
                  toggle={() => setBtnActionOpen(!btnActionOpen)}
                >
                  <DropdownToggle
                    tag="button"
                    className="btn btn-secondary"
                  >
                    Actions <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem disabled={!contactSelectedIds || [...contactSelectedIds.keys()].length === 0} onClick={() => onDeleteContactsClick()}>Remove selected contacts</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
              <li>
                <Button
                  type="button"
                  color="primary"
                  className="w-md"
                  onClick={() => showDialog()}
                >
                  + Add contacts
                </Button>
              </li>
              <li>
                <button
                  className="btn refresh without-outline"
                  type="button"
                  title="Refresh"
                  onClick={dataRefresh}
                >
                  <i className='mdi mdi-reload h3' />
                </button>
              </li>
            </ul>
          </div>
          <GlobalProgressBar isLoading={isLoading} />
          {isDataAvailable() === false && <EmptyBlock>
            <div className="text-center">
              <p className="h3">No Contacts</p>
              <p>This group is empty.</p>
              {/* commented the buttons for now. we probably don't need it. <p>
                <Button
                  type="button"
                  color="primary"
                  className="w-md"
                  onClick={() => showDialog()}
                >
                  + Add contacts
                </Button>
              </p>*/ }
            </div>
          </EmptyBlock>}
          {isDataAvailable() && <Table className="table table-striped table-responsive">
            <thead>
              <tr>
                <th></th>
                <th>Phone or ID</th>
                <th>First name</th>
                <th>Last name</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {groupContactsList.map((c, i) => <tr key={i} className={rowCheckboxIsChecked(c.contactId) ? "align-middle table-info" : "align-middle"}>
                <td style={{ width: "2rem" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={c.contactId}
                    onChange={handleContactSelectedIdsChange}
                    id={`chk-contact-${i}`}
                    defaultChecked={rowCheckboxIsChecked(c.contactId)}
                  />
                </td>
                <td>
                  <Link
                    to={`/projects/${currentProjectId}/directory/contacts/${c.contactId}`}
                    onClick={(e) => { setIsContactPanelOpen(true); setPanelContactId(c.contactId); return e.preventDefault(); }}
                    title="Go to contact details">
                    {c.phoneNumber ?? c.contactId}
                  </Link>
                </td>
                <td>
                  {textOrEmpty(c.firstName)}
                </td>
                <td>
                  {textOrEmpty(c.lastName)}
                </td>
                <td>
                  {textOrEmpty(c.email)}
                </td>
              </tr>)
              }
            </tbody>
          </Table>
          }
        </Col>
      </Row>
      <Row>
        <Col className="col-12">
          {!isLoading &&
            <div className="actions clearfix">
              <ul className='d-flex align-items-center justify-content-end'>
                <li>
                  {/* we need Number() because without it it uses "String" */}
                  <select
                    className="form-control cursor-pointer"
                    onChange={(e) => { setGroupContactsLimit(Number(e?.target?.value)); setGroupContactsOffset(0); }}
                    value={pageSize}
                  >
                    <option value="10">10 rows per page</option>
                    <option value="20">20 rows per page</option>
                    <option value="50">50 rows per page</option>
                    <option value="100">100 rows per page</option>
                    <option value="250">250 rows per page</option>
                  </select>
                </li>
                <li
                  className={
                    groupContactsOffset === 0 ? "previous disabled" : "previous"
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={onPreviousPageClick}
                  >
                    <i className="fa fa-chevron-left" />
                  </Link>
                </li>
                <li
                  className={
                    (groupContactsOffset + pageSize >= contactsTotalCount) ? "next disabled" : "next"
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={onNextPageClick}
                  >
                    <i className="fa fa-chevron-right" />
                  </Link>
                </li>
              </ul>
            </div>
          }
        </Col>
      </Row>
      <AddContactsDialog
        currentProjectId={currentProjectId}
        currentContactGroupId={currentContactGroupId}
        isOpen={isAddContactsDialogOpen}
        onAddContacts={onAddContacts}
        closeDialog={hideDialog}
      />
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
      <ContactPanel
        isOpen={isContactPanelOpen}
        toggle={() => setIsContactPanelOpen(!isContactPanelOpen)}
        currentProjectId={currentProjectId}
        currentContactId={panelContactId}
        onUpdateCallback={onContactUpdated}
        onDeleteCallback={onContactDeleted}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(GroupContactsCard));