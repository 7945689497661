import {
  GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST,
  GET_PAYMENTS_HISTORY_FOR_PROJECT_SUCCESS,
  SET_PAYMENTS_HISTORY_LIMIT,
  GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST,
  GET_PAYMENT_METHODS_FOR_PROJECT_SUCCESS,
  GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST,
  GET_SETUP_INTENT_FOR_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_SUCCESS,
  TOP_UP_BALANCE_REQUEST,
  TOP_UP_BALANCE_SUCCESS,
  STRIPE_API_ERROR,
  BILLING_API_ERROR
} from "./actionTypes";

export const getPaymentsHistoryForProject = (projectId, limit, offset) => {
  return {
    type: GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST,
    payload: {projectId, limit, offset}
  }
};

export const getPaymentsHistoryForProjectSuccess = ({paymentsHistory: paymentsHistoryList, offset: paymentsHistoryOffset, limit: paymentsHistoryLimit, totalCount: paymentsHistoryTotalCount}) => {
  return {
    type: GET_PAYMENTS_HISTORY_FOR_PROJECT_SUCCESS,
    payload: {paymentsHistoryList, paymentsHistoryOffset, paymentsHistoryLimit, paymentsHistoryTotalCount}
  }
};

export const setPaymentsHistoryLimit = limit => {
  return {
    type: SET_PAYMENTS_HISTORY_LIMIT,
    payload: limit,
  }
}

export const getPaymentMethodsForProject = projectId => {
  return {
    type: GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST,
    payload: projectId
  }
};

export const getPaymentMethodsForProjectSuccess = ({paymentMethods: paymentMethodsList, totalCount: paymentMethodsTotalCount}) => {
  return {
    type: GET_PAYMENT_METHODS_FOR_PROJECT_SUCCESS,
    payload: {paymentMethodsList, paymentMethodsTotalCount}
  }
};

export const getSetupIntentForPaymentMethod = projectId => {
  return {
    type: GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST,
    payload: projectId
  }
};

export const getSetupIntentForPaymentMethodSuccess = secret => {
  return {
    type: GET_SETUP_INTENT_FOR_PAYMENT_METHOD_SUCCESS,
    payload: secret
  }
};

export const topUpBalance = (projectId, amount, paymentMethodId, callback) => {
  return {
    type: TOP_UP_BALANCE_REQUEST,
    payload: {projectId, amount, paymentMethodId, callback}
  }
};

export const deletePaymentMethod = (projectId, paymentMethodId, callback) => {
  return {
    type: DELETE_PAYMENT_METHOD_REQUEST,
    payload: {projectId, paymentMethodId, callback}
  }
};

export const deletePaymentMethodSuccess = () => {
  return {
    type: DELETE_PAYMENT_METHOD_SUCCESS,
  }
};

export const topUpBalanceSuccess = () => {
  return {
    type: TOP_UP_BALANCE_SUCCESS,
  }
};

export const stripeApiError = error => {
  return {
    type: STRIPE_API_ERROR,
    payload: error,
  }
}

export const billingApiError = error => {
  return {
    type: BILLING_API_ERROR,
    payload: error,
  }
}