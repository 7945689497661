import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Card, Label, Input, Button, FormFeedback } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { emptyFunc, formatBytes } from '../../helpers/utils';

import './tcr.scss';

const AddCampaignStep4 = (props) => {

  let onSampleMessage1Change = props?.onSampleMessage1Change ?? emptyFunc;
  let onSampleMessage2Change = props?.onSampleMessage2Change ?? emptyFunc;
  let onSampleMessage3Change = props?.onSampleMessage3Change ?? emptyFunc;
  let onSampleMessage4Change = props?.onSampleMessage4Change ?? emptyFunc;
  let onSampleMessage5Change = props?.onSampleMessage5Change ?? emptyFunc;
  let onSampleMediaFilesChange = props?.onSampleMediaFilesChange ?? emptyFunc;

  const { validation } = props;
  const validationRef = useRef(validation);

  const [errorMessage] = useState(''); // to show a error message
  const [isLoading] = useState(false);

  const [sampleMessage1, setSampleMessage1] = useState('');
  const [sampleMessage2, setSampleMessage2] = useState('');
  const [sampleMessage3, setSampleMessage3] = useState('');
  const [sampleMessage4, setSampleMessage4] = useState('');
  const [sampleMessage5, setSampleMessage5] = useState('');
  const [sampleMessageCount, setSampleMessageCount] = useState(1);

  const [attachedFilesMap, setAttachedFilesMap] = useState(new Map());

  const addMoreSampleMessages = () => {
    if (sampleMessageCount >= 5) {
      return; // only 5 samples allowed
    }

    setSampleMessageCount(sampleMessageCount + 1);
  }

  function handleAttachedFiles(files) {

    let mapCopy = new Map(attachedFilesMap); // this will create a new map

    Array.from(files).forEach(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })

      mapCopy.set(file.name, file); // Adding file to the map or override if the file with the same name exists. This will prevent duplicates.
    });

    setAttachedFilesMap(mapCopy); // setting changed map to the state variable
  }

  function removeAttachedFile(fileName) {
    if (attachedFilesMap.has(fileName)) {
      let m = new Map(attachedFilesMap);
      m.delete(fileName);
      setAttachedFilesMap(m);
    }
  }

  useEffect(() => {
    onSampleMessage1Change(sampleMessage1);
    validationRef.current.setFieldValue('sampleMessage1', sampleMessage1);
  }, [onSampleMessage1Change, sampleMessage1]);

  useEffect(() => {
    onSampleMessage2Change(sampleMessage2);
    validationRef.current.setFieldValue('sampleMessage2', sampleMessage2);
  }, [onSampleMessage2Change, sampleMessage2]);

  useEffect(() => {
    onSampleMessage3Change(sampleMessage3);
    validationRef.current.setFieldValue('sampleMessage3', sampleMessage3);
  }, [onSampleMessage3Change, sampleMessage3]);

  useEffect(() => {
    onSampleMessage4Change(sampleMessage4);
    validationRef.current.setFieldValue('sampleMessage4', sampleMessage4);
  }, [onSampleMessage4Change, sampleMessage4]);

  useEffect(() => {
    onSampleMessage5Change(sampleMessage5);
    validationRef.current.setFieldValue('sampleMessage5', sampleMessage5);
  }, [onSampleMessage5Change, sampleMessage5]);

  useEffect(() => {
    if (attachedFilesMap) {
      onSampleMediaFilesChange([...attachedFilesMap.values()]);
    }
  }, [onSampleMediaFilesChange, attachedFilesMap])

  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={isLoading} />
      {errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
      <Row className="mb-3">
        <Col className="col-6">
          <Label>Sample message 1</Label>
          <Input
            type="textarea"
            name="sampleMessage1"
            onChange={e => setSampleMessage1(e?.target?.value)}
            value={sampleMessage1}
            required={sampleMessageCount > 1}
            minLength="20"
            maxLength="1024"
            rows="3"
            placeholder="Please provide a sample message for this campaign. Min length is 20. Max length is 1024."
          />
          {validation.touched.sampleMessage1 && validation.errors.sampleMessage1 ? (
            <>
              <span className="is-invalid"></span>
              <FormFeedback type="invalid">{validation.errors.sampleMessage1}</FormFeedback>
            </>
          ) : null}
        </Col>
        <Col className="col-6 align-self-center">
          {sampleMessageCount === 1 && <Button color="secondary" onClick={addMoreSampleMessages}>+ Add more message samples</Button>}
        </Col>
      </Row>
      {sampleMessageCount > 1 && <Row className="mb-3">
        <Col className="col-6">
          <Label>Sample message 2</Label>
          <Input
            type="textarea"
            name="sampleMessage2"
            onChange={e => setSampleMessage2(e?.target?.value)}
            value={sampleMessage2}
            maxLength="1024"
            rows="3"
            placeholder="Please provide a sample message for this campaign. Min length is 20. Max length is 1024."
          />
          {validation.touched.sampleMessage2 && validation.errors.sampleMessage2 ? (
            <>
              <span className="is-invalid"></span>
              <FormFeedback type="invalid">{validation.errors.sampleMessage2}</FormFeedback>
            </>
          ) : null}
        </Col>
        <Col className="col-6 align-self-center">
          {sampleMessageCount === 2 && <Button color="secondary" onClick={addMoreSampleMessages}>+ Add more message samples</Button>}
        </Col>
      </Row>}
      {sampleMessageCount > 2 && <Row className="mb-3">
        <Col className="col-6">
          <Label>Sample message 3</Label>
          <Input
            type="textarea"
            name="sampleMessage3"
            onChange={e => setSampleMessage3(e?.target?.value)}
            value={sampleMessage3}
            maxLength="1024"
            rows="3"
            placeholder="Please provide a sample message for this campaign. Min length is 20. Max length is 1024."
          />
          {validation.touched.sampleMessage3 && validation.errors.sampleMessage3 ? (
            <>
              <span className="is-invalid"></span>
              <FormFeedback type="invalid">{validation.errors.sampleMessage3}</FormFeedback>
            </>
          ) : null}
        </Col>
        <Col className="col-6 align-self-center">
          {sampleMessageCount === 3 && <Button color="secondary" onClick={addMoreSampleMessages}>+ Add more message samples</Button>}
        </Col>
      </Row>}
      {sampleMessageCount > 3 && <Row className="mb-3">
        <Col className="col-6">
          <Label>Sample message 4</Label>
          <Input
            type="textarea"
            name="sampleMessage4"
            onChange={e => setSampleMessage4(e?.target?.value)}
            value={sampleMessage4}
            maxLength="1024"
            rows="3"
            placeholder="Please provide a sample message for this campaign. Min length is 20. Max length is 1024."
          />
          {validation.touched.sampleMessage4 && validation.errors.sampleMessage4 ? (
            <>
              <span className="is-invalid"></span>
              <FormFeedback type="invalid">{validation.errors.sampleMessage4}</FormFeedback>
            </>
          ) : null}
        </Col>
        <Col className="col-6 align-self-center">
          {sampleMessageCount === 4 && <Button color="secondary" onClick={addMoreSampleMessages}>+ Add more message samples</Button>}
        </Col>
      </Row>}
      {sampleMessageCount > 4 && <Row className="mb-3">
        <Col className="col-6">
          <Label>Sample message 5</Label>
          <Input
            type="textarea"
            name="sampleMessage5"
            onChange={e => setSampleMessage5(e?.target?.value)}
            value={sampleMessage5}
            maxLength="1024"
            rows="3"
            placeholder="Please provide a sample message for this campaign. Min length is 20. Max length is 1024."
          />
          {validation.touched.sampleMessage5 && validation.errors.sampleMessage5 ? (
            <>
              <span className="is-invalid"></span>
              <FormFeedback type="invalid">{validation.errors.sampleMessage5}</FormFeedback>
            </>
          ) : null}
        </Col>
      </Row>}
      <Row className="mb-3 mt-5">
        <Col className="col-16">
          <label htmlFor="formFileSm" className="form-label text-primary attach-files-label">
            <i className="mdi mdi-attachment me-1" />
            Add multimedia samples
          </label>
          <input
            className="form-control form-control-sm d-none"
            id="formFileSm"
            type="file"
            multiple
            onChange={(e) => handleAttachedFiles(e.target.files)}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col className="col-6">

          <div className="dropzone-previews mt-3" id="file-previews">
            {[...attachedFilesMap.values()].map((f, i) => {
              return (
                <Card
                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  key={i + "-file"}
                >
                  <Row>
                    <Col className="align-items-center">
                      <span
                        className="text-muted font-weight-bold"
                      >
                        {f.name}
                      </span>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                    </Col>
                    <Col className="d-flex flex-row-reverse align-items-center">
                      <div>
                        <i className="uil uil-trash-alt h5 cursor-pointer" title='Remove this attachment' onClick={() => removeAttachedFile(f.name)}></i>
                      </div>
                    </Col>
                  </Row>
                </Card>
              )
            })}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

AddCampaignStep4.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddCampaignStep4)));