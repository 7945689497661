export const getMessageTypeAndDirectionString = (m) => {

  if (!m) {
    return '';
  }

  if (m.messageType === 'mms' && m.direction === 'outbound') {
    return <><i className="uil uil-arrow-up"/>Outbound MMS</>;
  }
  if (m.messageType === 'sms' && m.direction === 'outbound') {
    return <><i className="uil uil-arrow-up"/>Outbound SMS</>;
  }
  if (m.messageType === 'mms' && m.direction === 'inbound') {
    return <><i className="uil uil-arrow-down"/>Inbound MMS</>;
  }
  if (m.messageType === 'sms' && m.direction === 'inbound') {
    return <><i className="uil uil-arrow-down"/>Inbound SMS</>;
  }

  return '';
}

export const getMessageBadgeCssClassName = (m) => {

  if (!m) {
    return 'bg-dark';
  }

  if (m.status === 'delivered' || m.status === 'received') {
    return 'bg-success';
  }

  if (m.status === 'partially_delivered') {
    return 'bg-soft-success';
  }

  if (m.status === 'sending') {
    return 'bg-info';
  }

  if (m.status === 'sent') {
    return 'bg-info';
  }

  if (m.status === 'failed' || m.status === 'undelivered') {
    return 'bg-danger';
  }

  if ( m.status === 'partially_failed' || m.status === 'partially_undelivered') {
    return 'bg-soft-danger';
  }

  return 'bg-soft-dark';
}

export const getMessageErrorCodeDescription = (code) => {

  if (!code) {
    return '';
  }

  if (code === 'telephone_number_unsubscribed') {
    return 'This telephone number unsubscribed';
  }


  if (code === 'insufficient_funds') {
    return 'Insufficient funds';
  }

  return '';
}
