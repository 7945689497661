import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, GOOGLE_SIGN_IN_WITH_POPUP } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history, redirectUrl } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      })
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    //history.push("/dashboard");
    console.log(`redirectUrl: ${redirectUrl}`);
    if (redirectUrl) {
      window.location = redirectUrl;
    } else {
      window.location = '/';
    }
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history, redirectUrl } }) {
  try {
    localStorage.removeItem("authUser");
    var response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }

    let { pathname } = window.location;
    if (pathname === '/login') { // we are already on login page. No need to redirect. This can happen because Authmiddleware.js reacts on the empty users and redirects.
      return;
    }

    if (redirectUrl) {
      window.location = `/login?r=${redirectUrl}`;
    } else {
      window.location = '/login';
    }

  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type,
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)

      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    //history.push("/dashboard")
    window.location = '/';
  } catch (error) {
    yield put(apiError(error))
  }
}

function* googleSignInWithPopup({ payload: { history, type, redirectUrl } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.signInWithPopup
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      throw new Error('unknown provider');
    }

    if (redirectUrl) {
      window.location = redirectUrl;
    } else {
      window.location = '/';
    }
  } catch (error) {
    console.log(error);
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeLatest(GOOGLE_SIGN_IN_WITH_POPUP, googleSignInWithPopup)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
