import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userResetPassword } from "../../store/actions"

// import images
import loginLogo from "../../assets/images/logo-1.png";

const SetPasswordPage = props => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  // Retrieve the "mode" query parameter
  const oobCode = new URLSearchParams(window?.location?.search).get(
    'oobCode'
  );

  const { resetError, resetSuccessMsg } = useSelector(state => ({
    resetError: state.ResetPassword.resetError,
    resetSuccessMsg: state.ResetPassword.resetSuccessMsg,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: '',
      passwordRepeat: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Please Enter Your Password").min(6, "Password should be at least 6 characters long"),
      passwordRepeat: Yup.string()
        .max(3000, "Password is too long")
        .test(
          "passwordsAreEqual",
          "Passwords are not equal",
          (value, context) => value === context.parent.newPassword,
        ),
    }),
    onSubmit: (values) => {
      dispatch(userResetPassword(oobCode, values.newPassword));
    }
  });

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={5}>
              <div>

                <a href="/" className="mb-5 d-block auth-logo">
                  <img src={loginLogo} alt="" height="44" className="logo logo-dark" />
                </a>
                <Card>

                  <CardBody className="p-4">

                    <div className="text-center mt-2">
                      <h5 className="text-primary">Set New Password</h5>
                    </div>
                    <div className="p-2 mt-4">
                      {resetError ? (
                        <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          Something went wrong. Please make sure your link is not expired.
                        </Alert>
                      ) : null}
                      {resetSuccessMsg ? (
                        <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          Successfully set a new password. Let's <Link to={`/login${search}`}>log in</Link>.
                        </Alert>
                      ) : null}
                      {!resetSuccessMsg && !resetError &&
                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="newPassword"
                              className="form-control"
                              placeholder="Enter new password"
                              type="password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.newPassword || ""}
                              invalid={
                                validation.touched.newPassword && validation.errors.newPassword ? true : false
                              }
                            />
                            {validation.touched.newPassword && validation.errors.newPassword ? (
                              <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Repeat password</Label>
                            <Input
                              name="passwordRepeat"
                              type="password"
                              placeholder="Enter password again"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.passwordRepeat || ""}
                              invalid={
                                validation.touched.passwordRepeat && validation.errors.passwordRepeat ? true : false
                              }
                            />
                            {validation.touched.passwordRepeat && validation.errors.passwordRepeat ? (
                              <FormFeedback type="invalid">{validation.errors.passwordRepeat}</FormFeedback>
                            ) : null}
                          </div>
                          <Row className="mb-0">
                            <Col xs={12} className="text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Submit
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      }
                    </div>

                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} Built by Corelink Telecom</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SetPasswordPage);