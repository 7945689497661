import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table, Badge, UncontrolledTooltip } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage } from '../../helpers/utils';

import { getMessageTypeAndDirectionString, getMessageBadgeCssClassName } from './utils';

import './messages.scss';

import {
  getMessageGroupsForProject,
  setMessageGroupsLimit
} from "../../store/actions";

const MessageGroups = () => {

  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const messageGroupsList = useSelector((state) => state.Message.messageGroupsList);
  const messageGroupsLimit = useSelector((state) => state.Message.messageGroupsLimit);
  const messageGroupsOffset = useSelector((state) => state.Message.messageGroupsOffset);
  const isLoading = useSelector((state) => state.Message.loading);
  const apiError = useSelector((state) => state.Message.error);

  const pageSize = messageGroupsLimit || 20;

  const { direction } = useParams();

  const loadMessages = useCallback((projectId, dirction /* misspelled on purpose */, limit, offset) => {
    dispatch(getMessageGroupsForProject(projectId, dirction, limit, offset));
  }, [dispatch]);

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      loadMessages(currentProjectId, direction, pageSize, 0);
    }
  }, [loadMessages, currentProjectId, direction, pageSize]);

  const dataRefresh = useCallback(() => {
    if (currentProjectId) {
      loadMessages(currentProjectId, direction, messageGroupsLimit, messageGroupsOffset);
    }
  }, [loadMessages, currentProjectId, direction, messageGroupsLimit, messageGroupsOffset]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  const onNextPageClick = () => {
    if (messageGroupsList && messageGroupsList?.length >= pageSize) {
      dispatch(getMessageGroupsForProject(currentProjectId, direction, pageSize, messageGroupsOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (messageGroupsOffset !== 0) {
      dispatch(getMessageGroupsForProject(currentProjectId, direction, pageSize, messageGroupsOffset - pageSize));
    }
  }

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (isLoading === undefined || isLoading === true) {
      return undefined;
    }

    return Array.isArray(messageGroupsList) && messageGroupsList.length > 0;
  }, [isLoading, messageGroupsList]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs title="SMS/MMS" breadcrumbItem={direction === "inbound" ? "Inbound Messages" : (direction === "outbound" ? "Outbound Messages" : "All Messages")} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
                      <div className="actions clearfix">
                        <ul>
                          {isDataAvailable() && <li>
                            <Link
                              to={`/projects/${currentProjectId}/compose`}
                              className="btn btn-success"
                              role="button"
                            >
                              + Send message
                            </Link>
                          </li>}
                          <li>
                            <button
                              className="btn refresh without-outline"
                              type="button"
                              title="Refresh"
                              onClick={dataRefresh}
                            >
                              <i className='mdi mdi-reload h3' />
                            </button>
                          </li>
                        </ul>
                      </div>
                      <GlobalProgressBar isLoading={isLoading} />
                      {isDataAvailable() === false && <EmptyBlock>
                        <div className="text-center">
                          <p className="h3">No Messages</p>
                          <p>Your messages history will appear here</p>
                          <p>
                            <Link
                              to={`/projects/${currentProjectId}/compose`}
                              className="btn btn-success"
                              role="button"
                            >
                              + Send message
                            </Link>
                          </p>
                        </div>
                      </EmptyBlock>}
                      {isDataAvailable() &&
                        <Table className="table-striped table-responsive">
                          <thead>
                            <tr>
                              <th>Message</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Status</th>
                              <th>Created date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {messageGroupsList.map((m, i) => <tr key={i} className="align-middle">
                              <td>
                                <span>{getMessageTypeAndDirectionString(m)}</span><br />
                                {(m?.status !== 'created' && m?.status !== 'receiving' && m?.status !== 'queued' && m?.status !== 'queuing') &&
                                  <Link to={`/projects/${currentProjectId}/messages/groups/${m.messageGroupId}`} title="Go to message details" className="small">
                                    {m.messageGroupId}
                                  </Link>}
                                {(m?.status === 'created' || m?.status === 'receiving' || m?.status === 'queued' || m?.status === 'queuing') &&
                                  <span>
                                    {m.messageGroupId}
                                  </span>}
                              </td>
                              <td>
                                {/* m?.fromTelephoneNumberId && <Link to={`/projects/${currentProjectId}/numbers/${m?.fromTelephoneNumberId}`} title="Go to telephone number details">
                                    {m?.fromTelephoneNumber}
                                  </Link>}
                                  {!m?.fromTelephoneNumberId && <>
                                    {m?.fromTelephoneNumber}
                                  </> */}
                                {m?.fromTelephoneNumber}
                              </td>
                              <td>
                                {m?.totalCount === 1 && m?.toTelephoneNumbers?.length === 1 && <>
                                  {m?.toTelephoneNumbers[0]}{/* toTelephoneNumbers.length should be always 1 for totalCount == 1 */}
                                </>}
                                {(m?.totalCount > 1 || (m?.totalCount === 1 && m?.toTelephoneNumbers?.length !== 1)) && <>
                                  <Badge className="bg-soft-info rounded-pill" id={`recipients-badge-${m.messageGroupId}`}>
                                    <Link to={`/projects/${currentProjectId}/messages/groups/${m.messageGroupId}`}>
                                      {m?.totalCount} {m?.totalCount > 1 ? "recipients" : (m?.totalCount === 1) ? "recipient" : ""}
                                    </Link>
                                  </Badge>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target={`recipients-badge-${m.messageGroupId}`}
                                  >
                                    Open the details form to get more info
                                  </UncontrolledTooltip>
                                </>}
                                {!m?.totalCount && <>
                                  -
                                </>}
                              </td>
                              <td>
                                <span className='text-uppercase'><Badge className={getMessageBadgeCssClassName(m) + " rounded-pill"}>
                                  {m?.status ? m?.status.replaceAll("_", " ") : ""}
                                </Badge></span>
                              </td>
                              <td>{!m.createdTimestampMs ? '' : new Date(m.createdTimestampMs).toLocaleString()}</td>
                            </tr>)
                            }
                          </tbody>
                        </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {isLoading === false &&
                        <div className="actions clearfix">
                          <ul className='d-flex align-items-center justify-content-end'>
                            <li>
                              {/* we need Number() because without it it uses "String" */}
                              <select
                                className="form-control cursor-pointer"
                                onChange={(e) => dispatch(setMessageGroupsLimit(Number(e?.target?.value)))}
                                value={pageSize}
                              >
                                <option value="10">10 rows per page</option>
                                <option value="20">20 rows per page</option>
                                <option value="50">50 rows per page</option>
                                <option value="100">100 rows per page</option>
                                <option value="250">250 rows per page</option>
                              </select>
                            </li>
                            <li
                              className={
                                messageGroupsOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                <i className="fa fa-chevron-left" />
                              </Link>
                            </li>
                            <li
                              className={
                                (!messageGroupsList || messageGroupsList?.length === 0 || messageGroupsList?.length < pageSize) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                <i className="fa fa-chevron-right" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

MessageGroups.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    message: state.Message,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(MessageGroups)));