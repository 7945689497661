import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { contactService } from "../../services";
import { constructErrorMessage } from '../../helpers/utils';

const AddContactGroup = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter the group title"),
    }),
    onSubmit: async (values) => {
      try {
        setIsCreating(true);
        setErrorMessage('');
        let response = await contactService.createContactGroup(currentProjectId, values.title, values.description);

        if (!response?.createdContactGroup?.contactGroupId) {
          throw new Error("contacts group was not created");
        }
        validation.resetForm();
        history.push(`/projects/${currentProjectId}/directory/contacts/groups/${response?.createdContactGroup?.contactGroupId}`, { redirectStatus: `create-contacts-group-succeeded` });

      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsCreating(false);
      }
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Contacts Groups"
            titleUrl={`/projects/${currentProjectId}/directory/contacts/groups`}
            breadcrumbItem="New Contacts Group" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {!isCreating && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  <>
                    <Form
                      className="form-group add-contact-group-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <Row className="mb-3">
                        <Col className="col-8">
                          <Label className="form-label">Group title</Label>
                          <InputGroup>
                            <Input
                              name="title"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.title || ""}
                              invalid={
                                validation.touched.title && validation.errors.title ? true : false
                              }
                            />
                            {validation.touched.title && validation.errors.title ? (
                              <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col className="col-8">
                          <Label className="form-label">Description</Label>
                          <InputGroup>
                            <Input
                              name="description"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ""}
                              invalid={
                                validation.touched.description && validation.errors.description ? true : false
                              }
                            />
                            {validation.touched.description && validation.errors.description ? (
                              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="col-8">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              {isCreating && <>
                                <Spinner size="sm me-1" color="light" />
                              </>}
                              {!isCreating && <>
                                Create
                              </>}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddContactGroup.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AddContactGroup)));