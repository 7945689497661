import { Badge } from "reactstrap";

export const constructContactImportTaskStatus = (status) => {
  switch (status) {
    case "completed":
      return <Badge className="bg-success rounded-pill">
        Completed
      </Badge>;
    case "failed":
      return <Badge className="bg-danger rounded-pill">
        Failed
      </Badge>;
    case "pending":
      return <Badge className="bg-info rounded-pill">
        Pending
      </Badge>;
    default:
      return <Badge className="bg-info rounded-pill">
        Processing
      </Badge>;
  }
}