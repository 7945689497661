import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col, Label , Alert} from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

import { SingleValue, customFilterForSearchIndex } from '../../helpers/react_select_helpers';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import './tcr.scss';

import { tcrService } from "../../services";

import { constructErrorMessage, emptyFunc } from "../../helpers/utils";
import { isSpecialCampaignType, isSubCaseAvailableForUseCase } from "./utils";

import {
  getBrandsForProject
} from "../../store/actions";

const AddCampaignStep1 = (props) => {

  let onBrandIdChange = props?.onBrandIdChange ?? emptyFunc;
  let onBrandUseCaseChange = props?.onBrandUseCaseChange ?? emptyFunc;
  let onBrandSubUseCaseListChange = props?.onBrandSubUseCaseListChange ?? emptyFunc;
  let onMinSubUseCasesChange = props?.onMinSubUseCasesChange ?? emptyFunc;
  let onMaxSubUseCasesChange = props?.onMaxSubUseCasesChange ?? emptyFunc;

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const brandsList = useSelector((state) => state.Tcr.brandsList);
  const [brandUseCasesList, setBrandUseCasesList] = useState([]); // to save use cases for a selected brand
  const [subUseCases, setSubUseCases] = useState({}); // to save sub use cases 
  const [allPossibleUseCases, setAllPossibleUseCases] = useState({}); // to save all possible cases for TCR. We use it to grab some info like description 
  const tcrLoading = useSelector((state) => state.Tcr.loading);
  const apiError = useSelector((state) => state.Tcr.error);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message
  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [selectedUseCase, setSelectedUseCase] = useState('');
  const [selectedSubUseCases, setSelectedSubUseCases] = useState(new Map()); // we maintain map to represent a Set (and easy manipulate)
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getBrandsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  const getSelectedBrandUseCase = useCallback(() => brandUseCasesList.find((uc) => uc.usecase === selectedUseCase), [brandUseCasesList, selectedUseCase]);

  const clearUseCaseState = useCallback(() => {
    // clear state
    setSelectedUseCase('');
    setSelectedSubUseCases(new Map());
  }, []);

  // these are "triggers" to execute an update funcs passed in props
  useEffect(() => {
    onBrandIdChange(selectedBrandId);
    clearUseCaseState();
  }, [onBrandIdChange, selectedBrandId, clearUseCaseState]);

  useEffect(() => {
    onBrandUseCaseChange(selectedUseCase);
    setSelectedSubUseCases(new Map());
    let bu = getSelectedBrandUseCase();
    onMinSubUseCasesChange(bu?.minSubUsecases ?? 0);
    onMaxSubUseCasesChange(bu?.maxSubUsecases ?? 0);
  }, [onBrandUseCaseChange, onMinSubUseCasesChange, onMaxSubUseCasesChange, selectedUseCase, getSelectedBrandUseCase]);

  useEffect(() => {
    onBrandSubUseCaseListChange(Array.from(selectedSubUseCases.keys()));
  }, [onBrandSubUseCaseListChange, selectedSubUseCases]);

  // end "triggers"


  // filterSubUseCases filters useCases (as a map) end returns an map of use cases that can be used as a sub case. The key for the mpa is a useCase name.
  const filterSubUseCases = (allCasesObj) => {
    if (!allCasesObj) {
      return {};
    }

    let result = {}
    Object.keys(allCasesObj).forEach((key) => {
      if (allCasesObj[key].validSubUsecase === true) {
        result[key] = allCasesObj[key]
      }
    });

    return result;
  }


  // qualifyBrand fetches data to qualify brand (=loads use cases for it) and fetches all possible use cases to grab some info from them (like description)
  const qualifyBrand = useCallback(async (projectId, brandId) => {
    if (!brandId) {
      return;
    }
    try {
      setIsLoading(true);
      setErrorMessage('');

      // loading qualifyBrand() and allUseCasesResult() in parallel. see https://stackoverflow.com/questions/35612428/call-async-await-functions-in-parallel
      let [qualifyBrandResult, allUseCasesResult] = await Promise.all([
        tcrService.qualifyBrand(projectId, brandId),
        tcrService.fetchUseCases(projectId)
      ]);

      if (!qualifyBrandResult?.useCases) {
        throw new Error("no use cases available for the selected brand");
      }

      if (!allUseCasesResult?.useCases) {
        throw new Error("cannot load use cases info");
      }

      setBrandUseCasesList(qualifyBrandResult?.useCases);
      setAllPossibleUseCases(allUseCasesResult?.useCases);
      setSubUseCases(filterSubUseCases(allUseCasesResult?.useCases));
      clearUseCaseState();
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, [clearUseCaseState]);


  // loading use cases and qualify brand
  useEffect(() => {
    if (selectedBrandId) {
      qualifyBrand(currentProjectId, selectedBrandId);
    }
  }, [qualifyBrand, currentProjectId, selectedBrandId]);


  // BRAND SELECT
  const getBrandsOptions = () => {
    let options = [];

    brandsList.forEach((b) => {
      options.push({ label: b.companyName, value: b.brandId });
    });

    return options;
  }

  const brandsOptionGroup = [
    {
      // label: group label
      options: getBrandsOptions()
    }
  ];
  // END BRAND SELECT


  // use case select
  const getUseCaseInfo = useCallback((ucName) => {

    let result = {};

    Object.keys(allPossibleUseCases).forEach((key) => {
      if (key === ucName) {
        result = allPossibleUseCases[key];
      }
    });

    return result;
  }, [allPossibleUseCases]);

  const getUseCaseDescription = useCallback((ucName) => {
    let info = getUseCaseInfo(ucName);
    return info?.description;
  }, [getUseCaseInfo]);

  const getUseCaseDisplayName = useCallback((ucName) => {
    let info = getUseCaseInfo(ucName);
    return info?.displayName;
  }, [getUseCaseInfo]);

  const getUseCasesOptionGroup = useCallback((requiresReviewFilter) => {
    let options = [];
    let useCases = brandUseCasesList;
    if (Array.isArray(useCases)) {
      useCases.forEach((val) => {
        let reviewRequired = isSpecialCampaignType(val);
        // filtering by whether we need to return standard use cases (= no review) or special use cases (= needs review)

        if ((requiresReviewFilter && reviewRequired) || (!requiresReviewFilter && !reviewRequired)) {
          options.push({
            "label": <>
              <div className="d-flex justify-content-between" data-toggle="tooltip" data-placement="top" title={getUseCaseDescription(val.usecase)}>
                <div>{getUseCaseDisplayName(val.usecase)}</div>
                <div className='text-info'>${val.monthlyFee}/mo</div>
              </div>
              <div>
                <span>
                  <small className="text-muted">{getUseCaseDescription(val.usecase)}</small>
                </span>
              </div>
            </>,
            "value": val?.usecase,
            "searchIndex": `${val?.usecase} ${getUseCaseDescription(val.usecase)}`,
            "chipLabel": <>{/* this allows different labels for dropdown vs selected item (dropdown item is too wide) */}
              <div className="d-flex justify-content-between" data-toggle="tooltip" data-placement="top" title={getUseCaseDescription(val.usecase)}>
                <div>{getUseCaseDisplayName(val.usecase)}</div>
                <div className='text-primary'>${val.monthlyFee}/mo</div>
              </div>
            </>
          });
        }
      })
    }

    return options;
  }, [brandUseCasesList, getUseCaseDescription, getUseCaseDisplayName]);

  const useCasesOptionGroup = [
    {
      label: "Standard Campaign Type",
      options: getUseCasesOptionGroup(false),
    },
    {
      label: "Special Campaign Type",
      options: getUseCasesOptionGroup(true),
    }
  ];
  // end use case select

  // sub use cases checkbox handlers
  const subUseCaseCheckboxIsChecked = (uc) => {
    return selectedSubUseCases.has(uc);
  }

  const handleSelectedSubUseCasesChange = (e) => {
    let uc = e?.target?.value;

    if (!uc) {
      return;
    }

    // toggle by adding/removing keys from map
    if (selectedSubUseCases.has(uc)) {
      let m = selectedSubUseCases;
      m.delete(uc);
      setSelectedSubUseCases(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    } else {

      let m = selectedSubUseCases;
      m.set(uc, true);
      setSelectedSubUseCases(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }
  }
  // end sub use cases checkbox handlers

  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={tcrLoading || isLoading} />
      {!(isLoading || tcrLoading) && (errorMessage || apiError) ? <CustomAlert color="danger" role="alert">{errorMessage ?? constructErrorMessage(apiError)}</CustomAlert> : null}
      {isLoading === false && (!Array.isArray(brandsList) || brandsList.length === 0) && <Alert color="warning">
                    <i className="uil uil-exclamation-triangle text-warning"></i>{" "}You have no 10DLC brands registered yet.{" "}
                    Let's create it  <Link to={`/projects/${currentProjectId}/tcr/brands/add`} className="alert-link">
                      here
                    </Link>.
                  </Alert>}
      {tcrLoading === false && Array.isArray(brandsList) && brandsList.length > 0 &&
        <>
          <Row className="mb-3">
            <Col className="col-6">

              <div>
                <Label className="form-label">Brand</Label>
                {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                <Select
                  name="brandId"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  onChange={option => setSelectedBrandId(option?.value)}
                  isClearable
                  options={brandsOptionGroup}
                  maxMenuHeight={300}
                />
              </div>
            </Col>
          </Row>
          {!isLoading && Array.isArray(brandUseCasesList) && brandUseCasesList.length > 0 && <>
            <Row className="mb-3">
              <Col className="col-6">
                <div>
                  <Label className="form-label">Use case</Label>
                  {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}

                  {/* to preserve selected style (width): https://stackoverflow.com/questions/46571811/react-select-auto-size-width */}
                  <Select
                    name="usecase"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), singleValue: base => ({ ...base, width: "max-content", minWidth: "100%", height: "max-content", paddingRight: "10px" }) }}
                    onChange={option => setSelectedUseCase(option?.value)}
                    components={{ SingleValue }}
                    filterOption={customFilterForSearchIndex}
                    isClearable
                    options={useCasesOptionGroup}
                    maxMenuHeight={300}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-6">
                <h5 className="mb-3">
                  <small className="text-muted fw-normal">
                    {getUseCaseDescription(selectedUseCase)}
                  </small>
                </h5>
              </Col>
            </Row>
          </>
          }
          {!isLoading && Array.isArray(brandUseCasesList) && subUseCases && selectedUseCase && isSubCaseAvailableForUseCase(getSelectedBrandUseCase()) && <Row className="mb-3">
            <Col className="col-12">
              <Label className="form-label">Sub use cases</Label>
              <i><small className="text-muted fw-normal">  please select from {getSelectedBrandUseCase().minSubUsecases} to {getSelectedBrandUseCase().maxSubUsecases} use cases</small></i>
              <div className="vstack gap-2">
                {/* see https://stackoverflow.com/questions/39965579/how-to-loop-an-object-in-react */
                  subUseCases && Object.keys(subUseCases).map(key => <label key={key} className="form-check-label form-check" data-toggle="tooltip" data-placement="top" title={subUseCases[key]?.description}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subUseCases"
                      value={key}
                      onChange={handleSelectedSubUseCasesChange}
                      id={`chk-sub-usecase-${key}`}
                      defaultChecked={subUseCaseCheckboxIsChecked(key)}
                      disabled={!selectedSubUseCases.has(key) && selectedSubUseCases.size >= getSelectedBrandUseCase().maxSubUsecases}
                    />
                    {subUseCases[key]?.displayName}
                  </label>)
                }
              </div>
            </Col>
          </Row>
          }
        </>
      }
    </React.Fragment>
  )
}

AddCampaignStep1.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddCampaignStep1)));