import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody} from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';

import ContactEditComponent from './ContactEditComponent';

import { clearHistoryState, constructContactName } from '../../helpers/utils';

const ContactDetails = () => {

  const location = useLocation();
  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [contactDetails, setContactDetails] = useState({});
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  // getting contactId fromURL
  const { contactId: currentContactId } = useParams();

  useEffect(() => {

    if (redirectStatus === 'create-contact-succeeded') {
      setSuccessMessage("Contact was successfully created");
    }

    if (redirectStatus === 'update-contact-succeeded') {
      setSuccessMessage("Contact was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);
  }, [redirectStatus, history]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Contacts"
            pageTitle="Contact Details"
            titleUrl={`/projects/${currentProjectId}/directory/contacts`}
            breadcrumbItem={constructContactName(contactDetails, contactDetails?.phoneNumber)} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  <ContactEditComponent
                    currentProjectId={currentProjectId}
                    currentContactId={currentContactId}
                    onContactDetailsLoadedCallback={useCallback((c) => setContactDetails(c), [])}
                    onUpdateCallback={() => history.push(`/projects/${currentProjectId}/directory/contacts/${currentContactId}`, { redirectStatus: `update-contact-succeeded` })}
                    onDeleteCallback={() => {
                      history.push(`/projects/${currentProjectId}/directory/contacts`, { redirectStatus: `delete-contact-succeeded` });
                    }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContactDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ContactDetails)));