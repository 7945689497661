const API_URL = process?.env?.REACT_APP_API_URL || 'http://localhost:20001/dash';

module.exports = {
  APP_VERSION: process?.env?.REACT_APP_VERSION || (window.location.hostname === 'localhost' ? 'local' : ''), // setting version from env variable or to "local" if localhost. empty otherwise.
  API_URL: API_URL,
  AUTH_TOKEN_EXPIRATION_HOURS: process?.env?.REACT_APP_AUTH_TOKEN_EXPIRATION_HOURS || 3,
  MESSAGE_DETAILS_REFRESH_SEC: process?.env?.REACT_APP_MESSAGE_DETAILS_REFRESH_SEC|| 10,
  TELEPHONE_NUMBERS_REFRESH_SEC: process?.env?.REACT_APP_TELEPHONE_NUMBERS_REFRESH_SEC|| 10,
  DEFAULT_SPINNER_DELAY: 500,
  DEFAULT_SPINNER_MIN_DURATION: 0,
  DEFAULT_GLOBAL_PROGRESS_BAR_DELAY: 0,
  DEFAULT_GLOBAL_PROGRESS_BAR_MIN_DURATION: 500,
  google: {
    API_KEY: "",
    CLIENT_ID: "789916937707-qs590a3uq2klda8k4ifhmp5aechdp060.apps.googleusercontent.com",
    SECRET: "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",
  },
  facebook: {
    APP_ID: "",
  },
}