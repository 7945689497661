import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Card, CardTitle, CardBody, Button } from 'reactstrap';
import { withRouter, Redirect, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { clearHistoryState, formatAmountWithCurrency } from '../../helpers/utils';

//i18n
import { withTranslation } from 'react-i18next';

const ProjectBalanceCard = () => {

  const history = useHistory();
  const location = useLocation();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const projectBalanceAmount = useSelector((state) => state.Project.projectDetails?.project?.balanceAmount);
  const projectBalanceUnit = useSelector((state) => state.Project.projectDetails?.project?.balanceUnit);
  const projectBalanceCurrency = useSelector((state) => state.Project.projectDetails?.project?.balanceCurrency);
  const projectDetailsLoading = useSelector((state) => state.Project.loading);

  const [redirectToTopUpBalance, setRedirectToTopUpBalance] = useState(false); // a flag to redirect to TopUpBalance form
  const [billingSuccessMessage, setBillingSuccessMessage] = useState(''); // to show a success message when we added a payment method

    // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    if (redirectStatus === 'top-up-balance-succeeded') {
      setBillingSuccessMessage("Successfully topped up the balance.");
    };

    if (!redirectStatus) {
      setBillingSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);
  }, [redirectStatus, history]);

  const onTopUpBalanceClick = () => {
    //dispatch(bApiError('')); // clear the error message first
    setRedirectToTopUpBalance(true); // show the add payment method form
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Current Amount</CardTitle>
          {billingSuccessMessage ? <CustomAlert color="success" role="alert">{billingSuccessMessage}</CustomAlert> : null}
          <GlobalProgressBar isLoading={projectDetailsLoading} />
          {redirectToTopUpBalance && <Redirect push to={`/projects/${currentProjectId}/billing/balance/top-up`} />}
          {!projectDetailsLoading &&
            <div>
              <h1 className={(!projectBalanceAmount || projectBalanceAmount < 0) ? 'text-danger' : ''}>{formatAmountWithCurrency(projectBalanceAmount, projectBalanceUnit, projectBalanceCurrency)}</h1>
              <div className="d-flex flex-wrap gap-3 mt-3">
                <Button
                  type="submit"
                  color="success"
                  className="w-md"
                  onClick={onTopUpBalanceClick}
                >
                  Top up balance
                </Button>
              </div>
            </div>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ProjectBalanceCard.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ProjectBalanceCard)));