// @flow
import {
  GET_PROFILE_DETAILS_REQUEST,
  GET_PROFILE_DETAILS_SUCCESS,
  UPDATE_PROFILE_DETAILS_REQUEST,
  UPDATE_PROFILE_DETAILS_SUCCESS,
  PROFILE_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
  updating: false,
  profileDetails: undefined
};

const Profile = (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        profileDetails: undefined, // this is important
        error: ''
      };

    case GET_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        profileDetails: action.payload,
      }

    case UPDATE_PROFILE_DETAILS_REQUEST:
      return {
        ...state,
        updating: true,
        error: ''
      };

    case UPDATE_PROFILE_DETAILS_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case PROFILE_API_ERROR:
      return {
        ...state,
        loading: false,
        updating: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Profile;
