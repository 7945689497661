import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Redirect, withRouter, useParams } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Label, Button, Table, Badge } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, isNullOrUndefined, formatAmountWithCurrency } from '../../helpers/utils';

import { getMessageTypeAndDirectionString, getMessageBadgeCssClassName } from './utils';

import {
  getMessageDetails
} from "../../store/actions";

const MessageDetails = () => {

  const dispatch = useDispatch();

  // getting tokenId fromURL
  const { messageId: currentMessageId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const messageDetails = useSelector((state) => state.Message?.messageDetails);
  const isLoading = useSelector((state) => state.Message.loading);
  const messageAPIError = useSelector((state) => state.Message.error);
  const [redirectToMessagesList, setRedirectToMessagesList] = useState(false); // a flag to redirect back

  useEffect(() => {
    if (currentProjectId && currentMessageId) {
      dispatch(getMessageDetails(currentProjectId, currentMessageId)); // we don't have to call this, it creates a double HTTP request. but in the same time we cannot rely on the "magic" with projectDetails fetch after every page navigation. so let's just keep it here.
    }
  }, [dispatch, currentProjectId, currentMessageId]);

  const onBackClick = () => {
    setRedirectToMessagesList(true);
  }

  return (
    <React.Fragment>
      {redirectToMessagesList &&
        <Redirect push to={`/projects/${currentProjectId}/messages`} />
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="SMS/MMS"
            titleUrl={`/projects/${currentProjectId}/messages`}
            breadcrumbItem="Message Details"
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && messageAPIError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(messageAPIError)}</CustomAlert> : null}
                  {!isLoading &&
                    <>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Message ID</Label>
                          <p>{messageDetails?.messageId}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Status</Label>
                          <p>
                            <span className='text-uppercase'><Badge className={getMessageBadgeCssClassName(messageDetails)}>
                              {messageDetails?.status ? messageDetails?.status.replaceAll("_", " ") : ""}
                            </Badge></span>
                          </p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Message Type</Label>
                          <p>{getMessageTypeAndDirectionString(messageDetails)}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">From</Label>
                          <p>{messageDetails?.from}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">To</Label>
                          <p>{messageDetails?.to?.join(', ')}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Segments</Label>
                          <p>{messageDetails?.segments}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Sent Date</Label>
                          <p>{!messageDetails?.sentTimestampMs ? '' : new Date(messageDetails?.sentTimestampMs).toLocaleString()}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Cost</Label>
                          <p>
                            {!isNullOrUndefined(messageDetails?.costAmount) && messageDetails?.costAmount >= 0 &&
                              <span>{formatAmountWithCurrency(messageDetails?.costAmount, messageDetails?.costUnit, messageDetails?.costCurrency)}</span>
                            }

                            {isNullOrUndefined(messageDetails?.costAmount) &&
                              <span>N/A</span>
                            }
                          </p>
                        </Col>
                      </Row>
                      {messageDetails?.body &&
                        <Row>
                          <Col md={12}>
                            <Label className="form-label">Body</Label>
                            <p>{messageDetails?.body}</p>
                          </Col>
                        </Row>
                      }
                      <Row>
                        <Col md={12}>
                          <div><br /><br /></div>
                          <Label className="form-label">Media</Label>
                          <Table className="table table-striped table-responsive">
                            <thead>
                              <tr>
                                <th>Url</th>
                                <th>Content-Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(!messageDetails?.media || !Array.isArray(messageDetails?.media) || messageDetails?.media.length === 0) && <tr><td colSpan={2} className="text-center">No media</td></tr>}
                              {
                                messageDetails?.media && Array.isArray(messageDetails?.media) && messageDetails?.media.length > 0 && messageDetails?.media.map((md, i) => <tr key={i} className="align-middle">
                                  <td>
                                    <a href={md?.url} target="_blank" rel="noreferrer">{md?.url}</a>
                                  </td>
                                  <td>
                                    {md?.contentType}
                                  </td>
                                </tr>)
                              }
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </>
                  }
                  <Row className="mb-3">
                    <div className="d-flex flex-wrap gap-3 mt-3">
                      <Button
                        color="link"
                        className="waves-effect"
                        style={{ padding: "0" }}
                        onClick={onBackClick}
                      >
                        &lt;&lt; Go To All Messages
                      </Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

MessageDetails.propTypes = {
  t: PropTypes.any,
  message: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    message: state.Message,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(MessageDetails)));