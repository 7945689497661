import { call, put, takeLatest, takeEvery, all, fork } from "redux-saga/effects";

// Login Redux States
import { GET_AUTH_USER_PROJECTS_REQUEST, GET_AUTH_USERS_FOR_PROJECT_REQUEST, GET_INVITE_DETAILS_REQUEST, ACCEPT_INVITE_REQUEST, DELETE_USER_FROM_PROJECT_REQUEST } from "./actionTypes";
import { getAuthUserProjectsSuccess, getAuthUsersForProjectSuccess, getInviteDetailsSuccess, authUserApiError, acceptInviteSuccess, deleteUserFromProjectSuccess } from "./actions";

//Include Both Helper File with needed methods
import { authUserService } from "../../services";

function* getProjects() {
  try {
    const response = yield call(
      authUserService.fetchProjects
    )
    yield put(getAuthUserProjectsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(authUserApiError(error));
  }
}

function* getUsers({ payload: projectId }) {
  try {
    const response = yield call(
      authUserService.fetchUsersByProjectId,
      projectId
    )
    yield put(getAuthUsersForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(authUserApiError(error));
  }
}

function* getInviteDetails({ payload: inviteId }) {
  try {
    const response = yield call(
      authUserService.fetchInviteDetails,
      inviteId
    )
    yield put(getInviteDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(authUserApiError(error));
  }
}

function* acceptInvite({ payload: {inviteId, callback} }) {
  try {
    const response = yield call(
      authUserService.acceptInvite,
      inviteId
    )
    yield put(acceptInviteSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(authUserApiError(error));
  }
}

function* deleteUserFromProject({ payload: { projectId, userId, callback } }) {
  try {
    const response = yield call(
      authUserService.deleteUserFromProject,
      projectId,
      userId
    )
    yield put(deleteUserFromProjectSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(authUserApiError(error));
  }
}

function* watchGetProjectsRequests() {
  yield takeLatest(GET_AUTH_USER_PROJECTS_REQUEST, getProjects)
}

function* watchGetUsersRequests() {
  yield takeEvery(GET_AUTH_USERS_FOR_PROJECT_REQUEST, getUsers)
}

function* watchGetInviteDetailsRequests() {
  yield takeEvery(GET_INVITE_DETAILS_REQUEST, getInviteDetails)
}

function* watchAcceptInviteRequests() {
  yield takeEvery(ACCEPT_INVITE_REQUEST, acceptInvite)
}

function* watchDeleteUserFromProjectRequests() {
  yield takeEvery(DELETE_USER_FROM_PROJECT_REQUEST, deleteUserFromProject)
}

function* authUserSaga() {
  yield all([
    fork(watchGetProjectsRequests),
    fork(watchGetUsersRequests),
    fork(watchGetInviteDetailsRequests),
    fork(watchAcceptInviteRequests),
    fork(watchDeleteUserFromProjectRequests),
  ]);
}

export default authUserSaga;
