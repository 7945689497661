import { get, put } from "../helpers/api_helper";
const _ = require('lodash');

class ProfileService {

  fetchProfileDetails() {
    return new Promise((resolve, reject) => {
      get(`/profile`)
        .then(response => {
          const profile = _.get(response, 'profile', {});
          resolve(profile);
        }).catch(error => {
          reject(error);
        })
    });

  }


  updateProfile(fullName, phone, companyName) {
    return new Promise((resolve, reject) => {
      put(
        `/profile`,
        {
          fullName,
          phone,
          companyName
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  setDefaultProject(projectId) {
    return new Promise((resolve, reject) => {
      put(
        `/profile/defaults/project`,
        {
          projectId
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

}

export const profileService = new ProfileService();