import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Label, InputGroup } from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, emptyFunc } from '../../helpers/utils';

import './contacts.scss';

import { contactService } from "../../services";

const AddContactImportTaskStep3 = (props) => {
  const currentProjectId = props?.currentProjectId;

  let onSelectedGroupIdsChange = props?.onSelectedGroupIdsChange ?? emptyFunc;
  let onFirstRowColumnNamesChange = props?.onFirstRowColumnNamesChange ?? emptyFunc;
  let onUpdateIfRecordExistsChange = props?.onUpdateIfRecordExistsChange ?? emptyFunc;

  const firstRowColumnNames = props?.firstRowColumnNames;
  const updateIfRecordExists = props?.updateIfRecordExists;

  const [contactGroups, setContactGroups] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const getContactGroups = useCallback(async (projectId) => {
    if (!projectId) {
      return;
    }
    try {
      setIsLoading(true);
      setErrorMessage('');

      let groupsResult = await contactService.fetchContactGroups(projectId, 10000, 0); // TODO: get rid of hard-coded limits


      if (!groupsResult?.contactGroups) {
        throw new Error("no contacts groups");
      }
      setContactGroups(groupsResult?.contactGroups);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => {
    if (currentProjectId) {
      getContactGroups(currentProjectId);
    }
  }, [getContactGroups, currentProjectId]);

  const getGroupsOptionGroup = () => {

    if (!contactGroups || !Array.isArray(contactGroups) || contactGroups.length === 0) {
      return [
        {
          options: []
        }
      ];
    }

    let arr = [];

    contactGroups.forEach((e) => {
      arr.push({ label: e.title, value: e.contactGroupId });
    });

    return [
      {
        options: arr
      }
    ];
  }


  return (
    <div>
      {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage ?? "Something went wrong"}</CustomAlert> : null}
      <GlobalProgressBar isLoading={isLoading} />
      {!isLoading && <>
        <div className="form-group contacts-import-settings-form">
          <Row className="mb-3">
            <Col>
              <Label className="form-label">Add imported contacts to groups.</Label>
              <Select
                name="groups"
                isMulti={true}
                isClearable
                onChange={options => onSelectedGroupIdsChange(options.map((o => o.value)))}
                options={getGroupsOptionGroup()}
                maxMenuHeight={300}
              />
            </Col>
          </Row>
          <Row className="mt-5 mb-3">
            <InputGroup>
              <label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="firstRowColumnNames"
                  onChange={(e) => onFirstRowColumnNamesChange(e?.target?.checked)}
                  defaultChecked={firstRowColumnNames}
                />
                {' '}First row in the file is column names.  <small className="text-muted fw-normal">This row will be ignored by the import process.</small>

              </label>
            </InputGroup>
          </Row>
          <Row className="mb-3">
            <InputGroup>
              <label>
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="updateIfRecordsExists"
                  onChange={(e) => onUpdateIfRecordExistsChange(e?.target?.checked)}
                  defaultChecked={updateIfRecordExists}
                />
                {' '} If the contact already exists update it instead of creating a new record

              </label>
            </InputGroup>
          </Row>

        </div>
      </>
      }
    </div>
  )
}

AddContactImportTaskStep3.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddContactImportTaskStep3)));