import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Modal, Form, Label, Input, Button, FormFeedback, InputGroup, Alert, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { projectService } from "../services";
import { constructErrorMessage } from '../helpers/utils';

const CreateProjectDialog = (props) => {

  var dialogIsOpen = props.isOpen ?? false;
  const closeDialog = () => {
    setIsLoading(false);
    setSuccessMessage('');
    setErrorMessage('');
    setInitialProjectName('');
    return props.closeDialog();
  }

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show an error message
  const [initialProjectName, setInitialProjectName] = useState('');

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      projectName: initialProjectName,
    },
    validationSchema: Yup.object({
      projectName: Yup.string().required("Please enter the space title").max(25, "Name is too long").not(["+ Create new space", "Create new space"], "Incorrect space title"),
    }),
    onSubmit: async (values) => {
      if (!isLoading && successMessage) { // we already successfully created a project and waiting for redirect
        return;
      }

      try {
        setIsLoading(true);
        let response = await projectService.createProject(values.projectName);
        let createdProjectId = response?.createdProject?.projectId;
        if (!createdProjectId) {
          setErrorMessage("Something went wrong while creating a new space");
        } else {
          setIsLoading(true);
          setErrorMessage("");
          setSuccessMessage("Successfully created a new space. Redirecting..");
          setTimeout(() => {
            closeDialog();
            window.location = `/projects/${createdProjectId}/dashboard`; // this guarantees full page reload, including setting currentProjectId = createdProjectId
          }, 1500);
        }
      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsLoading(false);
      }
    }
  });

  return (
    <React.Fragment>
      <Modal
        isOpen={dialogIsOpen}
        centered={true}
      >
        <Form
          className="form-group"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Create new space
            </h5>
            <button
              type="button"
              onClick={() => {
                closeDialog();
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div>
              {!isLoading && successMessage ? <Alert color="success" role="alert">{successMessage}</Alert> : null}
              {!isLoading && errorMessage ? <Alert color="danger" role="alert">{errorMessage}</Alert> : null}
              <>
                <Label className="form-label">Space title</Label>
                <InputGroup>
                  <Input
                    name="projectName"
                    placeholder="Enter space title. You can change it later."
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.projectName || ""}
                    invalid={
                      validation.errors.projectName ? true : false
                    }
                  />
                  {validation.errors.projectName ? (
                    <FormFeedback type="invalid">{validation.errors.projectName}</FormFeedback>
                  ) : null}
                </InputGroup>
              </>
            </div>
          </div>
          <div className="modal-footer">
            <>
              <Button
                type="submit"
                color="primary"
                className="w-md"
              >
                {isLoading && <>
                  <Spinner size="sm me-1" color="light" />
                </>}
                {!isLoading && <>
                  Create
                </>}
              </Button>
              <Button
                type="reset"
                color="secondary"
                outline
                className="w-md"
                onClick={() => {
                  if (!isLoading && !successMessage) {
                    closeDialog();
                  }
                }}
              >
                Cancel
              </Button>
            </>
          </div>
        </Form>
      </Modal>
    </React.Fragment>
  )
}

CreateProjectDialog.propTypes = {
  t: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(CreateProjectDialog)));