import React, { useEffect } from "react";
import { Alert } from "reactstrap";
import { toast, Slide } from 'react-toastify';


const useSimpleAlert = false;

// showAlert shows alert by a function call. It's useful for validation errors
export const showAlert = (color, text) => {
  let toasterOptions = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    transition: Slide,
    toastId: JSON.stringify(text), // prevent duplicates
  };
  switch (color) {
    case "success":
      toast.success(text, toasterOptions);
      break;
    case "info":
      toast.info(text, toasterOptions);
      break;

    case "warning":
      toast.warn(text, toasterOptions);
      break;
    case "danger":
      toast.error(text, toasterOptions);
      break;
    default:
      toast(text, toasterOptions);
      break;
  }
}

const CustomAlert = ({ color, children }) => {

  useEffect(() => {
    if (!useSimpleAlert) {
      let tmt = setTimeout(() => showAlert(color, children), 100);
      // see https://dev.to/robmarshall/how-to-use-componentwillunmount-with-functional-components-in-react-2a5g 
      // this allows not to show the alert message in case this is alert disappears right away (like when we go the some page with previously set apiError)
      // the way we implemented it: show alert only if the component stays on the page more than 100ms (enough time to clear the state if needed)
      return () => {clearTimeout(tmt);} 
    }
  }, [children, color]);


  if (!useSimpleAlert) {
    return <></>;
  } else {
    // simple alert:
    return (
      <Alert color={color} role="alert">{children}</Alert>
    );
  }
}

export default CustomAlert;
