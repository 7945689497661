import { Badge } from "reactstrap";

export const constructBrandIdentityStatus = (status) => {
  /* possible values:
  "SELF_DECLARED",
"UNVERIFIED",
"VERIFIED",
"VETTED_VERIFIED"
*/
  switch (status) {
    case "SELF_DECLARED":
      return <Badge className="bg-success rounded-pill">
        Self Declared
      </Badge>;
    case "UNVERIFIED":
      return <Badge className="bg-danger rounded-pill">
        Unverified
      </Badge>;
    case "VERIFIED":
      return <Badge className="bg-success rounded-pill">
        Verified
      </Badge>;
    case "VETTED_VERIFIED":
      return <Badge className="bg-success rounded-pill">
        Vetted
      </Badge>;
    default:
      return <Badge className="bg-info rounded-pill">
        Pending verification
      </Badge>;
  }
}


export const constructCampaignStatus = (status) => {
  /* possible values:
  "PENDING",
  "ACTIVE",
"EXPIRED",
*/
  switch (status) {
    case "ACTIVE":
      return <Badge className="bg-success rounded-pill">
        Active
      </Badge>;
    case "EXPIRED":
      return <Badge className="bg-danger rounded-pill">
        Expired
      </Badge>;
    default:
      return <Badge className="bg-info rounded-pill">
        Pending verification
      </Badge>;
  }
}

export const isSpecialCampaignType = (c) => { // takes use case as an input
  if (!c) {
    return false;
  }
  let reviewRequired = false;
  // iterate through mnoMetadata to see if some MNO requires a review
  if (c.mnoMetadata) {
    for (let mnoId in c.mnoMetadata) {  // iterating object properties
      let d = c.mnoMetadata[mnoId];
      if (d.mnoReview) {
        reviewRequired = true;
        break
      }
    }
  }

  return reviewRequired;
}

export const isSubCaseAvailableForUseCase = (uc) => { // takes use case as an input
  if (!uc) {
    return false;
  }
  
  return uc.minSubUsecases > 0 || uc.maxSubUsecases > 0;
}