import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";

import { EventDescription } from './EventDescription';

import { isNullOrUndefined, formatAmountWithCurrency } from '../../helpers/utils';

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import './events.scss';

import {
  getEventsForProject,
  setEventsLimit,
} from "../../store/actions";

const Events = () => {

  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const eventsList = useSelector((state) => state.Event.eventsList);
  const eventsLimit = useSelector((state) => state.Event.eventsLimit);
  const eventsOffset = useSelector((state) => state.Event.eventsOffset);
  const isLoading = useSelector((state) => state.Event.loading);

  const pageSize = eventsLimit || 20;

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getEventsForProject(currentProjectId, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize]);

  const onNextPageClick = () => {
    if (eventsList && eventsList?.length >= pageSize) {
      dispatch(getEventsForProject(currentProjectId, pageSize, eventsOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (eventsOffset !== 0) {
      dispatch(getEventsForProject(currentProjectId, pageSize, eventsOffset - pageSize));
    }
  }

  const dataRefresh = useCallback(() => { // this func is for naming consistency with other Refresh buttons
    if (currentProjectId) {
      dispatch(getEventsForProject(currentProjectId, pageSize, eventsOffset));
    }
  }, [currentProjectId, pageSize, eventsOffset, dispatch]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Space Events" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      <GlobalProgressBar isLoading={isLoading} />
                      <div className="actions clearfix">
                        <ul>
                          <li>
                            <button
                              className="btn refresh without-outline"
                              type="button"
                              title="Refresh"
                              onClick={dataRefresh}
                            >
                              <i className='mdi mdi-reload h3' />
                            </button>
                          </li>
                        </ul>
                      </div>
                      {!isLoading &&
                        <Table className="table-striped table-responsive">
                          <thead>
                            <tr>
                              <th>Description</th>
                              <th>Balance change</th>
                              <th>User</th>
                              <th>Event date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!eventsList || !Array.isArray(eventsList) || eventsList.length === 0) && <tr><td colSpan={4} className="text-center">No events yet</td></tr>}
                            {
                              eventsList && Array.isArray(eventsList) && eventsList.length > 0 && eventsList.map((e, i) => <tr key={i} className={(e && (e?.eventType.indexOf("failed") > -1 || e?.eventType.indexOf("undelivered") > -1)) ? "table-danger align-middle" : "align-middle"}>
                                <td>
                                  <EventDescription event={e} />
                                </td>
                                <td>
                                  {!isNullOrUndefined(e.balanceChangeAmount) &&
                                    <span>{formatAmountWithCurrency(e.balanceChangeAmount, e.balanceChangeUnit, e.balanceChangeCurrency)}</span>
                                  }
                                  {isNullOrUndefined(e.balanceChangeAmount) &&
                                    <span>N/A</span>
                                  }
                                </td>
                                <td>{e.createdByUserEmail ?? 'SYSTEM'}</td>
                                <td>{!e.createdTimestampMs ? '' : new Date(e.createdTimestampMs).toLocaleString()}</td>
                              </tr>)
                            }
                          </tbody>
                        </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {!isLoading &&
                        <div className="actions clearfix">
                          <ul className='d-flex align-items-center justify-content-end'>
                            <li>
                              {/* we need Number() because without it it uses "String" */}
                              <select
                                className="form-control cursor-pointer"
                                onChange={(e) => dispatch(setEventsLimit(Number(e?.target?.value)))}
                                value={pageSize}
                              >
                                <option value="10">10 rows per page</option>
                                <option value="20">20 rows per page</option>
                                <option value="50">50 rows per page</option>
                                <option value="100">100 rows per page</option>
                                <option value="250">250 rows per page</option>
                              </select>
                            </li>
                            <li
                              className={
                                eventsOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                <i className="fa fa-chevron-left" />
                              </Link>
                            </li>
                            <li
                              className={
                                (!eventsList || eventsList?.length === 0 || eventsList?.length < pageSize) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                <i className="fa fa-chevron-right" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Events.propTypes = {
  t: PropTypes.any,
  event: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    event: state.Event,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Events)));