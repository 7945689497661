import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory, withRouter, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table, Button, Form, Input, Label } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

// Formik Validation
//import * as Yup from "yup";
import { useFormik } from "formik";

import {
  searchInventoryForProject
} from "../../store/actions";

import { constructErrorMessage, usStates, caStates, formatAmountWithCurrency } from "../../helpers/utils";
import { telephoneNumberService } from "../../services";

import './numbers.scss';

const requiredCapabilities = ["sms", "mms", "voice"];

const SearchInventoryFilter = (props) => {

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  //const inventoryTelephoneNumbersList = useSelector((state) => state.Numbers.inventoryTelephoneNumbersList);
  const inventoryTelephoneNumberType = useSelector((state) => state.Numbers.inventoryTelephoneNumberType);
  const inventoryTelephoneNumbersRegionName = useSelector((state) => state.Numbers.inventoryTelephoneNumbersRegionName);
  const inventoryTelephoneNumbersLocationName = useSelector((state) => state.Numbers.inventoryTelephoneNumbersLocationName);
  const inventoryTelephoneNumbersContains = useSelector((state) => state.Numbers.inventoryTelephoneNumbersContains);
  const inventoryTelephoneNumbersLata = useSelector((state) => state.Numbers.inventoryTelephoneNumbersLata);
  const inventoryTelephoneNumbersMaxResults = useSelector((state) => state.Numbers.inventoryTelephoneNumbersMaxResults);
  const inventoryTelephoneNumbersCountryCode = useSelector((state) => state.Numbers.inventoryTelephoneNumbersCountryCode);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      telephoneNumberType: inventoryTelephoneNumberType,
      containsFilter: inventoryTelephoneNumbersContains,
      state: inventoryTelephoneNumbersRegionName,
      city: inventoryTelephoneNumbersLocationName,
      countryCode: inventoryTelephoneNumbersCountryCode,
      lata: inventoryTelephoneNumbersLata,
      maxResults: inventoryTelephoneNumbersMaxResults,
    },
    onSubmit: (values) => {
      onSubmitForm(values);
    }
  });

  const onSubmitForm = useCallback((values) => {
    let cityFilter = values.state ? values.city : '' /* cannot set city if state is empty */
    dispatch(searchInventoryForProject(currentProjectId, values.telephoneNumberType, values.maxResults, values.countryCode, values.lata, values.state, cityFilter, values.containsFilter, requiredCapabilities));
  }, [dispatch, currentProjectId]);

  // this will load phone numbers list for the first page opening
  useEffect(() => {
    if (currentProjectId) {
      onSubmitForm(validation?.initialValues);
    }
  }, [currentProjectId, onSubmitForm, validation?.initialValues]);

  const isTollFree = () => {
    return validation.values.telephoneNumberType === "toll-free"
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-12">
              <Form
                className="form-group search-inventory"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col md={6}>
                    <div className="hstack gap-4">
                      <div className="form-check">
                        <label>
                          <Input type="radio"
                            className="form-check-input"
                            name="telephoneNumberType"
                            value="local"
                            id="telephoneNumberTypeLocal"
                            onChange={validation.handleChange}
                            defaultChecked={!isTollFree()}
                          />
                          <span className="cursor-pointer">Local</span>
                        </label>
                      </div>
                      <div className="form-check">
                        <label>
                          <Input type="radio"
                            className="form-check-input cursor-pointer"
                            name="telephoneNumberType"
                            value="toll-free"
                            id="telephoneNumberTypeTollFree"
                            onChange={validation.handleChange}
                            defaultChecked={isTollFree()}
                          />
                          <span className="cursor-pointer">Toll-Free</span>
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <div className="mb-3">
                      <Label className="form-label">Country</Label>
                      <select
                        className="form-control"
                        name="countryCode"
                        onChange={(e) => { validation.setFieldValue('state', ''); return validation.handleChange(e); }}
                        defaultValue={validation.values.countryCode}
                      >
                        <option key="US" value="US">USA</option>
                        <option key="CA" value="CA">Canada</option>
                      </select>
                    </div>
                  </Col>
                  {!isTollFree() &&
                    <>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label className="form-label">State/Province</Label>
                          <select
                            className="form-control"
                            name="state"
                            onChange={validation.handleChange}
                            defaultValue={validation.values.state}
                            disabled={isTollFree()}
                          >
                            <option key="any" value="">Any</option>
                            {[...(validation.values.countryCode === 'CA' ? caStates : usStates).entries()].map(([stateCode, stateLabel]) =>
                              <option key={stateCode} value={stateCode}>{stateLabel}</option>
                            )}
                          </select>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="mb-3">
                          <Label className="form-label">City</Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="city"
                            value={!isTollFree() && validation.values.state ? validation.values.city : ""}
                            onChange={validation.handleChange}
                            disabled={isTollFree() || !validation.values.state}
                          />
                        </div>
                      </Col>
                    </>
                  }
                  <Col md={isTollFree() ? 6 : 4}>
                    <div className="mb-3">
                      <Label className="form-label">Contains digits</Label>
                      <Input
                        type="text"
                        className="form-control"
                        name="containsFilter"
                        defaultValue={validation.values.containsFilter}
                        minLength={3}
                        onChange={validation.handleChange}
                      />
                    </div>
                  </Col>
                </Row>
                <div>
                  <button type="submit" className="btn btn-primary w-md">
                    Apply filter
                  </button>
                </div>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
}

const SearchInventory = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const inventoryTelephoneNumbersList = useSelector((state) => state.Numbers.inventoryTelephoneNumbersList);
  const telephoneNumbersLoading = useSelector((state) => state.Numbers.loading);
  const apiError = useSelector((state) => state.Numbers.error);

  const [redirectToTelephonesList, setRedirectToTelephonesList] = useState(false); // a flag to redirect back
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const onPurchasePhoneNumberClick = async (telephoneNumber, telephoneNumberType) => {

    setConfirmDialogText(<>
      Are you sure you want to purchase {telephoneNumber}?
      {telephoneNumberType === 'toll-free' && <p>
        Before sending and receiving messages toll-free numbers require to have{" "}
        <Link
          to={`/projects/${currentProjectId}/toll-free-verifications`}
          className="alert-link"
          target="_blank"
        >
          an approved verification
        </Link>.<br />
        The verification process is <strong className='text-success'>free</strong>.
      </p>}
      {telephoneNumberType === 'local' && <p>
        For compliance each local phone number needs to be added to an approved 10DLC campaign.<br />
        Make sure you have registered {' '}<Link
          to={`/projects/${currentProjectId}/tcr/campaigns`}
          className="alert-link"
          target="_blank"
        >
          one
        </Link> before purchasing.<br />
      </p>}
    </>);

    setOnConfirmFunc(() => async () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      try {
        setIsPurchasing(true);
        await telephoneNumberService.purchaseTelephoneNumber(currentProjectId, telephoneNumber);
        //let purchasedTnId = await telephoneNumberService.purchaseTelephoneNumber(currentProjectId, telephoneNumber);
        // redirect to the TN details. we commented it because sometime TN is a pending state.
        // history.push(`/projects/${currentProjectId}/numbers/${purchasedTnId}`, { redirectStatus: `purchase-tn-succeeded`, tn: `${encodeURIComponent(telephoneNumber)}` });
        history.push(`/projects/${currentProjectId}/numbers`, { redirectStatus: `purchase-tn-succeeded`, tn: `${encodeURIComponent(telephoneNumber)}` });
      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsPurchasing(false);
      }
    });

    setIsConfirmDialogOpen(true);
  }

  const onCancelSearchInventoryClick = () => {
    setRedirectToTelephonesList(true);
  }

  const formatTNLocation = (tn) => {
    let locArr = [tn?.locationName, tn?.regionName, tn?.countryCode];
    return locArr.filter(l => l /* filtering non-empty values only */).join(', ');
  }

  const formatTNType = (tn) => {
    if (tn.type === 'local') {
      return 'local';
    }

    if (tn.type === 'toll-free') {
      return 'toll-free';
    }

    if (tn.type === 'short') {
      return 'short code';
    }

    return '';
  }

  return (
    <React.Fragment>
      {redirectToTelephonesList &&
        <Redirect push to={`/projects/${currentProjectId}/numbers`} />
      }
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs
            title="Purchased Phone Numbers"
            titleUrl={`/projects/${currentProjectId}/numbers`}
            breadcrumbItem="Purchase Number"
          />
          <Row>
            <Col className="col-12">
              <SearchInventoryFilter />
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      <GlobalProgressBar isLoading={(telephoneNumbersLoading || isPurchasing)} />
                      {!(telephoneNumbersLoading || isPurchasing) && (errorMessage || apiError) ? <CustomAlert color="danger" role="alert">{errorMessage || constructErrorMessage(apiError)}</CustomAlert> : null}
                      {!telephoneNumbersLoading &&

                        <>
                          <Table className="table-striped table-responsive">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Number</th>
                                <th>Type</th>
                                <th>Capabilities</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {(!inventoryTelephoneNumbersList || !Array.isArray(inventoryTelephoneNumbersList) || inventoryTelephoneNumbersList.length === 0) && <tr><td colSpan={5} className="text-center">No phone numbers for your search criteria</td></tr>}
                              {
                                inventoryTelephoneNumbersList && Array.isArray(inventoryTelephoneNumbersList) && inventoryTelephoneNumbersList.length > 0 && inventoryTelephoneNumbersList.map((tn, i) => <tr key={i} className="align-middle">
                                  <td style={{ width: "2rem" }}>
                                    <Button
                                      color="primary"
                                      outline
                                      className="waves-effect waves-light btn-sm"
                                      title={`Purchase phone number ${tn.telephoneNumber}`}
                                      onClick={() => onPurchasePhoneNumberClick(tn.telephoneNumber, tn.type)}
                                    >
                                      Purchase
                                    </Button>
                                  </td>
                                  <td>
                                    {tn.telephoneNumber}
                                    <br />
                                    <div><strong>{formatTNLocation(tn)}</strong></div>
                                  </td>
                                  <td>
                                    {formatTNType(tn)}
                                  </td>
                                  <td>
                                    {tn.capabilities['voice'] &&
                                      <i className="uil-phone-alt" title="Voice"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['sms'] &&
                                      <i className="uil-comment-alt-message" title="SMS"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['mms'] &&
                                      <i className="uil-comment-alt-image" title="MMS"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['e911'] &&
                                      <i className="uil-exclamation-triangle" title="E911"></i>
                                    }
                                    &nbsp;
                                    {tn.capabilities['cnam'] &&
                                      <i className="uil-search" title="CNAM Lookup"></i>
                                    }
                                  </td>
                                  <td>{formatAmountWithCurrency(tn?.costAmount, tn?.costUnit, tn?.costCurrency)}/mo</td>
                                  <td>{!tn.purchasedTimestampMs ? '' : new Date(tn.purchasedTimestampMs).toLocaleString()}</td>
                                </tr>)
                              }
                            </tbody>
                          </Table>
                        </>
                      }
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <div className="d-flex flex-wrap gap-3 mt-3">
                      <Button
                        type="reset"
                        color="danger"
                        outline
                        className="w-md"
                        onClick={onCancelSearchInventoryClick}
                      >
                        Back
                      </Button>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

SearchInventory.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(SearchInventory)));