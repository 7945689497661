import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

import InviteUserDialog from "./InviteUserDialog";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import {
  getAuthUsersForProject,
  deleteUserFromProject
} from "../../store/actions";

import '../datatables.scss';
import { constructErrorMessage, clearHistoryState } from '../../helpers/utils';

const ownerRoleName = 'owner';

const Users = () => {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const usersList = useSelector((state) => state.AuthUser.projectUsersList);
  const isLoading = useSelector((state) => state.AuthUser.loading);
  const usersAPIError = useSelector((state) => state.AuthUser.error);

  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      dispatch(getAuthUsersForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  const dataRefresh = useCallback(() => { // this func is for naming consistency with other Refresh buttons
    initialDataLoad();
  }, [initialDataLoad]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  useEffect(() => {

    if (redirectStatus === 'delete-user-succeeded') {
      setSuccessMessage("User was successfully deleted from the project");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);
  }, [redirectStatus, history]);

  const onAddUserClick = () => {
    setIsInviteUserDialogOpen(true);
  }

  const hideInviteUserDialog = () => {
    setIsInviteUserDialogOpen(false);
  }

  const onDeleteUserClick = (userId) => {
    setConfirmDialogText(`Are you sure you want to delete this team member from the space?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteUserFromProject(currentProjectId, userId, () => {
        history.push(`/projects/${currentProjectId}/settings/users`, { redirectStatus: `delete-user-succeeded` });
      }));
    });

    setIsConfirmDialogOpen(true);
  }

  const getRoleName = (r) => {
    if (!r) {
      return "";
    }
    if (r.toLowerCase() === "owner") {
      return "Owner";
    }

    if (r.toLowerCase() === "manager") {
      return "Manager";
    }

    if (r.toLowerCase() === "editor") {
      return "Editor";
    }

    if (r.toLowerCase() === "viewer") {
      return "Viewer";
    }

    return "";
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Team" />
          <Card>
            <CardBody>
              {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
              {!isLoading && usersAPIError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(usersAPIError)}</CustomAlert> : null}
              <div className="actions clearfix">
                <ul>
                  <li
                  >
                    <Button
                      type="submit"
                      color="success"
                      className="w-md"
                      onClick={onAddUserClick}
                    >
                      + Invite
                    </Button>
                  </li>
                  <li>
                    <button
                      className="btn refresh without-outline"
                      type="button"
                      title="Refresh"
                      onClick={dataRefresh}
                    >
                      <i className='mdi mdi-reload h3' />
                    </button>
                  </li>
                </ul>
              </div>
              <GlobalProgressBar isLoading={isLoading} />
              {!isLoading &&
                <>
                  <Row>
                    <Col className="col-12">
                      <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Email</th>
                            <th>Name</th>
                            <th>Role</th>
                          </tr>
                        </thead>
                        <tbody>
                          {(!usersList || !Array.isArray(usersList) || usersList.length === 0) && <tr><td colSpan={4} className="text-center">No users yet</td></tr>}
                          {
                            usersList && Array.isArray(usersList) && usersList.length > 0 && usersList.map((u, i) => <tr key={i} className="align-middle">
                              <td style={{ width: "2rem" }}>
                                {u.roles && Array.isArray(u.roles) && !u.roles.includes(ownerRoleName) && /* disabling deletion for an owner */
                                  <i className="uil-trash-alt" title="Remove this user from the project" role="button" onClick={() => onDeleteUserClick(u.userId)}></i>
                                }
                              </td>
                              <td>{u.email}</td>
                              <td>{u.fullName}</td>
                              <td>{u.roles && Array.isArray(u.roles) && <span>{u.roles.map(r => getRoleName(r)).join(", ")}</span>}</td>
                            </tr>)
                          }
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </>
              }
            </CardBody>
          </Card>
        </Container>
      </div>
      <InviteUserDialog
        isOpen={isInviteUserDialogOpen}
        closeDialog={hideInviteUserDialog}
      />
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

Users.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Users)));