import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Row, Card, CardTitle, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { constructPaymentMethodBrandLogo } from "./common";

import {
  getPaymentMethodsForProject,
  updateAutoCharge,
  getProjectDetails
} from "../../store/actions";
import { constructErrorMessage } from '../../helpers/utils';

const AutoReloadBalanceCard = () => {

  const dispatch = useDispatch();

  const projectAutoCharge = useSelector((state) => state.Project.projectDetails?.project?.autoRecharge)
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const paymentMethodsList = useSelector((state) => state.Billing.paymentMethodsList);
  const projectDetailsLoading = useSelector((state) => state.Project.loading);
  const isUpdating = useSelector((state) => state.Project.updating);
  const isDeleting = useSelector((state) => state.Project.deleting);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Project.error);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getPaymentMethodsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  const onAutoRechargeDisableClick = () => {

    setConfirmDialogText(`Are you sure you want to disable automatic recharge?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(updateAutoCharge(currentProjectId, null, null, null, () => { dispatch(getProjectDetails(currentProjectId)); setSuccessMessage("Automatic recharge was disabled"); }));
    });

    setIsConfirmDialogOpen(true);
  }

  const onPaymentMethodChange = (newOption) => {
    validation.setFieldValue("paymentMethodId", newOption.value);
  }

  // constructs payment method name to the user
  const constructPaymentMethodName = (pm) => {
    if (!pm || !pm.card) {
      return '';
    }

    return <div>{constructPaymentMethodBrandLogo(pm?.card?.brand)} **** {pm?.card?.last4} (expires {pm?.card?.expMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 })}/{pm?.card?.expYear})</div>;
  }

  const constructPaymentMethodsOptionGroup = () => {
    let options = [];
    if (Array.isArray(paymentMethodsList)) {
      paymentMethodsList.forEach((val) => {
        options.push({
          "label": constructPaymentMethodName(val),
          "value": val?.paymentMethodId
        });
      })
    }

    return options;
  }

  const paymentMethodOptionGroup = constructPaymentMethodsOptionGroup();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      thresholdAmount: projectAutoCharge?.thresholdAmountFormatted,
      topUpAmount: projectAutoCharge?.topUpAmountFormatted,
      paymentMethodId: projectAutoCharge?.paymentMethodId,

    },
    validationSchema: Yup.object({
      thresholdAmount: Yup.number()
        .integer("Please enter an integer value")
        .min(1, "Threshold balance should be at least $1")
        .max(100000, "Threshold balance should be less than $100000")
        .required("Please enter threshold balance"),
      topUpAmount: Yup.number()
        .integer("Please enter an integer value")
        .min(5, "Top up amount should be at least $5")
        .max(10000, "Top up amount should be less than $10000")
        .required("Please enter amount"),
      paymentMethodId: Yup.string().trim().required("Please select your payment method"),
    }),
    onSubmit: async (values) => {
      dispatch(
        updateAutoCharge(
          currentProjectId,
          Number(values.thresholdAmount),
          Number(values.topUpAmount),
          values.paymentMethodId,
          () => {
            dispatch(getProjectDetails(currentProjectId));
            setSuccessMessage("Auto recharge settings were successfully updated");
          }
        )
      );
    }
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Automatic recharge</CardTitle>
          {apiError ? <CustomAlert color="danger">{constructErrorMessage(apiError)}</CustomAlert> : null}
          {successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
          <GlobalProgressBar isLoading={projectDetailsLoading} />
          {!projectDetailsLoading &&
            <>
              <p className="card-title-desc">Project balance will be automatically topped up when it reaches the threshold amount</p>
              <Form
                className="form-group automatic-recharge"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row className="mb-3">
                  <Label className="form-label">Threshold amount</Label>
                  <InputGroup>
                    <div className="input-group-text">$</div>
                    <Input
                      name="thresholdAmount"
                      placeholder="Enter the threshold balance"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.thresholdAmount || ""}
                      invalid={
                        validation.touched.thresholdAmount && validation.errors.thresholdAmount ? true : false
                      }
                    />
                    {validation.touched.thresholdAmount && validation.errors.thresholdAmount ? (
                      <FormFeedback type="invalid">{validation.errors.thresholdAmount}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">Top up amount</Label>
                  <InputGroup>
                    <div className="input-group-text">$</div>
                    <Input
                      name="topUpAmount"
                      placeholder="This amount will be added to your balance"
                      type="number"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.topUpAmount || ""}
                      invalid={
                        validation.touched.topUpAmount && validation.errors.topUpAmount ? true : false
                      }
                    />
                    {validation.touched.topUpAmount && validation.errors.topUpAmount ? (
                      <FormFeedback type="invalid">{validation.errors.topUpAmount}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">Payment method</Label>
                  {/* see https://github.com/JedWatson/react-select/issues/3263#issuecomment-604237097 fpr explanation. without next two attributes the dropdown is behind the card */}
                  <Select
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    isSearchable={false}
                    options={paymentMethodOptionGroup}
                    value={
                      paymentMethodOptionGroup.filter(option =>
                        option.value === validation.values.paymentMethodId)
                    }
                    onChange={onPaymentMethodChange}
                  />
                  {validation.touched.paymentMethodId && validation.errors.paymentMethodId ? ( /* FormFeedback is not working for <Select> for some reason. <span className="is-invalid"></span> is needed to make FormFeedback visible. See CSS:
                          .is-invalid ~ .invalid-feedback,
                          .is-invalid ~ .invalid-tooltip {
                          display: block; } in bootstrap
                          */
                    <>
                      <span className="is-invalid"></span>
                      <FormFeedback type="invalid">{validation.touched.paymentMethodId && validation.errors.paymentMethodId}</FormFeedback>
                    </>
                  ) : null}
                </Row>
                <Row className="mb-3">
                  {/* aligning all buttons to the left except the last one. see https://stackoverflow.com/questions/58812566/align-all-item-to-left-except-last-in-a-flexbox-container */}
                  <div className="d-flex flex-wrap flex-row gap-3 mt-3">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-md"
                    >
                      {isUpdating && <>
                        <Spinner size="sm me-1" color="light" />
                      </>}
                      {!isUpdating && projectAutoCharge?.topUpAmountFormatted && <>
                        Update
                      </>}
                      {!isUpdating && !projectAutoCharge?.topUpAmountFormatted && <>
                        Set
                      </>}
                    </Button>
                    {projectAutoCharge?.topUpAmountFormatted && <Button
                      type="reset"
                      color="danger"
                      outline
                      className="w-md ms-auto"
                      onClick={onAutoRechargeDisableClick}
                    >
                      {isDeleting && <>
                        <Spinner size="sm me-1" color="danger" />
                      </>}
                      {!isDeleting && <>
                        Disable
                      </>}
                    </Button>
                    }
                  </div>
                </Row>
              </Form>
            </>
          }
        </CardBody>
      </Card>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

AutoReloadBalanceCard.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  project: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    project: state.Project,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AutoReloadBalanceCard)));