import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup } from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { mapToOptions, tcrBrandEntityTypesCodes, altBusinessIdTypesCodes, getMapKeyByValue, getTcrVerticals, stockExchanges } from '../../helpers/utils';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import './tcr.scss';

import { constantsService, tcrService } from "../../services";

import { constructErrorMessage } from "../../helpers/utils";

const getTcrBrandEntityTypesOptions = () => {
  let arr = [];

  tcrBrandEntityTypesCodes.forEach((val, key) => {
    arr.push({ label: key, value: val });
  });

  return arr;
}

const getTcrVerticalsOptions = () => {
  let arr = [];

  getTcrVerticals().forEach((val, key) => {
    arr.push({ label: key, value: val });
  });
  return arr;
}

const getAltBusinessIdTypesOptions = () => {
  let arr = [];

  altBusinessIdTypesCodes.forEach((val, key) => {
    arr.push({ label: key, value: val });
  });

  return arr;
}

const getStockExchangesOptions = () => {
  let arr = [];

  stockExchanges.forEach((val) => {
    arr.push({ label: val, value: val });
  });

  return arr;
}

const AddBrand = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [countriesMap, setCountriesMap] = useState(new Map());
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const getConstantEnums = useCallback(async () => {
    try {
      setIsLoading(true);
      let countriesResult = await constantsService.fetchCountriesMap();

      setCountriesMap(countriesResult);

    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getConstantEnums();
  }, [getConstantEnums]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      companyName: "",
      displayName: "",
      entityType: "",
      countryCode: "",
      ein: "",
      einIssuingCountry: "",
      altBusinessIdType: "",
      altBusinessId: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      website: "",
      phone: "",
      email: "",
      stockSymbol: "",
      stockExchange: "",
      firstName: "",
      lastName: "",
      mobilePhone: "",
      vertical: "",
    },
    // https://csp-api-staging.campaignregistry.com/v2/restAPI
    validationSchema: Yup.object({
      companyName: Yup.string().max(255, "Max field length is 255").when(['entityType'], {
        is: (entityType) => entityType === 'SOLE_PROPRIETOR',
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required("This field is required"),
      }),
      displayName: Yup.string().required("This field is required").max(255, "Max field length is 255"),
      entityType: Yup.string().required("This field is required"),
      countryCode: Yup.string().required("This field is required"),
      ein: Yup.string().when(['entityType'], {
        is: (entityType) => entityType === 'SOLE_PROPRIETOR',
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required("This field is required").max(21, "Max field length is 21"),
      }),
      einIssuingCountry: Yup.string().when(['entityType'], {
        is: (entityType) => entityType === 'SOLE_PROPRIETOR',
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required("This field is required"),
      }),
      //altBusinessIdType - optional
      altBusinessId: Yup.string().max(50, "Max field length is 50"),
      street: Yup.string().required("This field is required").max(100, "Max field length is 100"),
      city: Yup.string().required("This field is required").max(100, "Max field length is 100"),
      state: Yup.string().required("This field is required").when(['countryCode'], {
        is: (countryCode) => countryCode === 'US',
        then: (schema) => schema.max(2, "Max field length is 2"),
        otherwise: (schema) => schema.max(20, "Max field length is 20"),
      }),
      postalCode: Yup.string().required("This field is required").max(20, "Max field length is 20"),
      website: Yup.string().max(100, "Max field length is 100"),
      phone: Yup.string().trim().required("This field is required").max(20, "Max field length is 20").matches(/^\++[1-9]\d{1,14}$/, "Incorrect phone number format. Please use E.164 format."),
      email: Yup.string().max(100, "Max field length is 100").email("Incorrect email format"),
      stockSymbol: Yup.string().when(['entityType'], {
        is: (entityType) => entityType === 'PUBLIC_PROFIT',
        then: (schema) => schema.required("This field is required").max(10, "Max field length is 10"),
        otherwise: (schema) => schema.optional(),
      }),
      stockExchange: Yup.string().when(['entityType'], {
        is: (entityType) => entityType === 'PUBLIC_PROFIT',
        then: (schema) => schema.required("This field is required").max(10, "Max field length is 10"),
        otherwise: (schema) => schema.optional(),
      }),
      vertical: Yup.string().when(['entityType'], {
        is: (entityType) => entityType === 'SOLE_PROPRIETOR',
        then: (schema) => schema.optional(),
        otherwise: (schema) => schema.required("This field is required"),
      }),
      firstName: Yup.string().when(['entityType'], {
        is: (entityType) => entityType === 'SOLE_PROPRIETOR',
        then: (schema) => schema.required("This field is required").max(100, "Max field length is 100"),
        otherwise: (schema) => schema.optional(),
      }),
      lastName: Yup.string().max(200, "Max field length is 100").when(['entityType'], {
        is: (entityType) => entityType === 'SOLE_PROPRIETOR',
        then: (schema) => schema.required("This field is required").max(100, "Max field length is 100"),
        otherwise: (schema) => schema.optional(),
      }),
      mobilePhone: Yup.string().when(['entityType'], {
        is: (entityType) => entityType === 'SOLE_PROPRIETOR',
        then: (schema) => schema.required("This field is required for OTP").max(20, "Max field length is 20").matches(/^\++[1-9]\d{1,14}$/, "Incorrect phone number format. Please use E.164 format."),
        otherwise: (schema) => schema.optional(),
      }),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        setErrorMessage('');
        let response = await tcrService.createBrand(
          currentProjectId,
          values.entityType,
          values.firstName,
          values.lastName,
          values.displayName,
          values.companyName,
          values.ein,
          values.einIssuingCountry,
          values.phone,
          values.mobilePhone,
          values.street,
          values.city,
          values.state,
          values.postalCode,
          values.countryCode,
          values.email,
          values.stockSymbol,
          values.stockExchange,
          values.website,
          values.altBusinessId,
          values.altBusinessIdType,
          values.vertical
        );
        if (!response?.createdBrand?.brandId) {
          throw new Error("contact was not created");
        }
        validation.resetForm();
        history.push(`/projects/${currentProjectId}/tcr/brands/${response?.createdBrand?.brandId}`, {redirectStatus: `create-brand-succeeded`});

      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));

      } finally {
        setIsLoading(false);
      }
    }
  });

  const tcrBrandEntityTypesOptionGroup = [
    {
      // label: group label
      options: getTcrBrandEntityTypesOptions()
    }
  ];


  const tcrVerticalsOptionGroup = [
    {
      // label: group label
      options: getTcrVerticalsOptions()
    }
  ];

  const countriesOptionGroup = [
    {
      // label: group label
      options: mapToOptions(countriesMap)
    }
  ];

  const altBusinessIdTypesOptionGroup = [
    {
      // label: group label
      options: getAltBusinessIdTypesOptions()
    }
  ];

  const stockExchangesOptionGroup = [
    {
      // label: group label
      options: getStockExchangesOptions()
    }
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Brands"
            titleUrl={`/projects/${currentProjectId}/tcr/brands`}
            breadcrumbItem="Register Brand" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading &&
                    <>
                      <Form
                        className="form-group add-brand-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Entity type</Label>
                              {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                              <Select
                                name="entityType"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable
                                onChange={option => validation.setFieldValue("entityType", option?.value)}
                                defaultValue={validation.values.entityType ? { label: getMapKeyByValue(tcrBrandEntityTypesCodes, validation.values.entityType), value: validation.values.entityType } : ""}
                                options={tcrBrandEntityTypesOptionGroup}
                                maxMenuHeight={300}
                              />
                              {validation.touched.entityType && validation.errors.entityType ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.entityType}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Country of registration</Label>
                              {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                              <Select
                                name="countryCode"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable
                                onChange={option => validation.setFieldValue("countryCode", option?.value)}
                                defaultValue={validation.values.countryCode ? { label: getMapKeyByValue(countriesMap, validation.values.countryCode), value: validation.values.countryCode } : ""}
                                options={countriesOptionGroup}
                                maxMenuHeight={300}
                              />
                              {validation.touched.countryCode && validation.errors.countryCode ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.countryCode}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">Legal company name</Label>
                            <InputGroup>
                              <Input
                                name="companyName"
                                type="text"
                                onChange={(e) => {
                                  validation.handleChange(e);
                                  if (!validation.touched.companyName) { // copy value if it was not touched
                                    validation.setFieldValue("displayName", e?.target?.value);
                                  }
                                }}
                                onBlur={validation.handleBlur}
                                value={validation.values.companyName || ""}
                                invalid={
                                  validation.touched.companyName && validation.errors.companyName ? true : false
                                }
                              />
                              {validation.touched.companyName && validation.errors.companyName ? (
                                <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">DBA or brand Name</Label>
                            <InputGroup>
                              <Input
                                name="displayName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.displayName || ""}
                                invalid={
                                  validation.touched.displayName && validation.errors.displayName ? true : false
                                }
                              />
                              {validation.touched.displayName && validation.errors.displayName ? (
                                <FormFeedback type="invalid">{validation.errors.displayName}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className={validation.values.entityType && validation.values.entityType !== 'SOLE_PROPRIETOR' ? 'mb-3' : 'd-none mb-3'}>
                          <Col className="col-6">
                            <Label className="form-label">Tax number/ID/EIN</Label>
                            <InputGroup>
                              <Input
                                name="ein"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.ein || ""}
                                invalid={
                                  validation.touched.ein && validation.errors.ein ? true : false
                                }
                              />
                              {validation.touched.ein && validation.errors.ein ? (
                                <FormFeedback type="invalid">{validation.errors.ein}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Tax number/ID/EIN issuing country</Label>
                              {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                              <Select
                                name="einIssuingCountry"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable
                                onChange={option => validation.setFieldValue("einIssuingCountry", option?.value)}
                                defaultValue={validation.values.einIssuingCountry ? { label: getMapKeyByValue(countriesMap, validation.values.einIssuingCountry), value: validation.values.einIssuingCountry } : ""}
                                options={countriesOptionGroup}
                                maxMenuHeight={300}
                              />
                              {validation.touched.einIssuingCountry && validation.errors.einIssuingCountry ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.einIssuingCountry}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Alternative business ID type</Label>
                              {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                              <Select
                                name="altBusinessIdType"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable
                                onChange={option => validation.setFieldValue("altBusinessIdType", option?.value)}
                                defaultValue={validation.values.altBusinessIdType ? { label: getMapKeyByValue(altBusinessIdTypesCodes, validation.values.altBusinessIdType), value: validation.values.altBusinessIdType } : ""}
                                options={altBusinessIdTypesOptionGroup}
                                maxMenuHeight={300}
                              />
                              {validation.touched.altBusinessIdType && validation.errors.altBusinessIdType ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.altBusinessIdType}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Alternative business ID</Label>
                            <InputGroup>
                              <Input
                                name="altBusinessId"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.altBusinessId || ""}
                                invalid={
                                  validation.touched.altBusinessId && validation.errors.altBusinessId ? true : false
                                }
                              />
                              {validation.touched.altBusinessId && validation.errors.altBusinessId ? (
                                <FormFeedback type="invalid">{validation.errors.altBusinessId}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">Address/Street</Label>
                            <InputGroup>
                              <Input
                                name="street"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.street || ""}
                                invalid={
                                  validation.touched.street && validation.errors.street ? true : false
                                }
                              />
                              {validation.touched.street && validation.errors.street ? (
                                <FormFeedback type="invalid">{validation.errors.street}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">City</Label>
                            <InputGroup>
                              <Input
                                name="city"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.city || ""}
                                invalid={
                                  validation.touched.city && validation.errors.city ? true : false
                                }
                              />
                              {validation.touched.city && validation.errors.city ? (
                                <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">State/Region</Label>
                            <InputGroup>
                              <Input
                                name="state"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.state || ""}
                                invalid={
                                  validation.touched.state && validation.errors.state ? true : false
                                }
                              />
                              {validation.touched.state && validation.errors.state ? (
                                <FormFeedback type="invalid">{validation.errors.state}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Postal code</Label>
                            <InputGroup>
                              <Input
                                name="postalCode"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.postalCode || ""}
                                invalid={
                                  validation.touched.postalCode && validation.errors.postalCode ? true : false
                                }
                              />
                              {validation.touched.postalCode && validation.errors.postalCode ? (
                                <FormFeedback type="invalid">{validation.errors.postalCode}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">Country</Label>
                            <InputGroup>
                              <Input
                                name="state"
                                type="text"
                                readOnly={true}
                                value={getMapKeyByValue(countriesMap, validation.values.countryCode) || ""}
                              />
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Website</Label>
                            <InputGroup>
                              <Input
                                name="website"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.website || ""}
                                invalid={
                                  validation.touched.website && validation.errors.website ? true : false
                                }
                              />
                              {validation.touched.website && validation.errors.website ? (
                                <FormFeedback type="invalid">{validation.errors.website}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className={validation.values.entityType === 'PUBLIC_PROFIT' ? 'mb-3' : 'd-none mb-3'}>
                          <Col className="col-6">
                            <Label className="form-label">Stock symbol</Label>
                            <InputGroup>
                              <Input
                                name="stockSymbol"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.stockSymbol || ""}
                                invalid={
                                  validation.touched.stockSymbol && validation.errors.stockSymbol ? true : false
                                }
                              />
                              {validation.touched.stockSymbol && validation.errors.stockSymbol ? (
                                <FormFeedback type="invalid">{validation.errors.stockSymbol}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Stock exchange</Label>
                              {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                              <Select
                                name="stockExchange"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable
                                onChange={option => validation.setFieldValue("stockExchange", option?.value)}
                                defaultValue={validation.values.stockExchange ? { label: getMapKeyByValue(stockExchanges, validation.values.stockExchange), value: validation.values.stockExchange } : ""}
                                options={stockExchangesOptionGroup}
                                maxMenuHeight={300}
                              />
                              {validation.touched.stockExchange && validation.errors.stockExchange ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.stockExchange}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Vertical</Label>
                              {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                              <Select
                                name="vertical"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                isClearable
                                onChange={option => validation.setFieldValue("vertical", option?.value)}
                                defaultValue={validation.values.vertical ? { label: getMapKeyByValue(getTcrVerticals(), validation.values.vertical), value: validation.values.vertical } : ""}
                                options={tcrVerticalsOptionGroup}
                                maxMenuHeight={300}
                              />
                              {validation.touched.vertical && validation.errors.vertical ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.vertical}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-6"></Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">Support phone number</Label>
                            <InputGroup>
                              <Input
                                name="phone"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                                invalid={
                                  validation.touched.phone && validation.errors.phone ? true : false
                                }
                              />
                              {validation.touched.phone && validation.errors.phone ? (
                                <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Support email</Label>
                            <InputGroup>
                              <Input
                                name="email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}
                            </InputGroup></Col>
                        </Row>
                        <Row className={validation.values.entityType === 'SOLE_PROPRIETOR' ? 'mb-3' : 'd-none mb-3'}>
                          <Col className="col-6">
                            <Label className="form-label">First name</Label>
                            <InputGroup>
                              <Input
                                name="firstName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                  validation.touched.firstName && validation.errors.firstName ? true : false
                                }
                              />
                              {validation.touched.firstName && validation.errors.firstName ? (
                                <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Last name</Label>
                            <InputGroup>
                              <Input
                                name="lastName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                  validation.touched.lastName && validation.errors.lastName ? true : false
                                }
                              />
                              {validation.touched.lastName && validation.errors.lastName ? (
                                <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className={validation.values.entityType === 'SOLE_PROPRIETOR' ? 'mb-3' : 'd-none mb-3'}>
                          <Col className="col-6">
                            <Label className="form-label">Mobile phone for OTP</Label>
                            <InputGroup>
                              <Input
                                name="mobilePhone"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.mobilePhone || ""}
                                invalid={
                                  validation.touched.mobilePhone && validation.errors.mobilePhone ? true : false
                                }
                              />
                              {validation.touched.mobilePhone && validation.errors.mobilePhone ? (
                                <FormFeedback type="invalid">{validation.errors.mobilePhone}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">

                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              Register
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddBrand.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddBrand)));