import {
  GET_NUMBERS_FOR_PROJECT_REQUEST,
  GET_NUMBERS_FOR_PROJECT_NO_LOADING_REQUEST,
  GET_NUMBERS_FOR_PROJECT_SUCCESS,
  SET_NUMBERS_LIMIT,
  GET_NUMBER_DETAILS_REQUEST,
  GET_NUMBER_DETAILS_SUCCESS,
  SEARCH_INVENTORY_FOR_PROJECT_REQUEST,
  SEARCH_INVENTORY_FOR_PROJECT_SUCCESS,
  DELETE_NUMBER_REQUEST,
  DELETE_NUMBER_SUCCESS,
  NUMBER_API_ERROR
} from "./actionTypes";

export const getNumbersForProject = (projectId, limit, offset) => {
  return {
    type: GET_NUMBERS_FOR_PROJECT_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getNumbersForProjectNoLoading = (projectId, limit, offset) => {
  return {
    type: GET_NUMBERS_FOR_PROJECT_NO_LOADING_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getNumbersForProjectSuccess = ({ telephoneNumbers: telephoneNumbersList, offset: telephoneNumbersOffset, limit: telephoneNumbersLimit, totalCount: telephoneNumbersTotalCount }) => {
  return {
    type: GET_NUMBERS_FOR_PROJECT_SUCCESS,
    payload: { telephoneNumbersList, telephoneNumbersOffset, telephoneNumbersLimit, telephoneNumbersTotalCount }
  }
};

export const setNumbersLimit = limit => {
  return {
    type: SET_NUMBERS_LIMIT,
    payload: limit,
  }
}

export const getNumberDetails = (projectId, telephoneNumberId) => {
  return {
    type: GET_NUMBER_DETAILS_REQUEST,
    payload: { projectId, telephoneNumberId }
  }
};

export const getNumberDetailsSuccess = tn => {
  return {
    type: GET_NUMBER_DETAILS_SUCCESS,
    payload: tn
  }
};

export const searchInventoryForProject = (projectId, telephoneNumberType, maxResults, countryCode, lata, regionName, locationName, containsFilter, capabilities) => {
  return {
    type: SEARCH_INVENTORY_FOR_PROJECT_REQUEST,
    payload: { projectId, telephoneNumberType, maxResults, countryCode, lata, regionName, locationName, containsFilter, capabilities }
  }
};

export const searchInventoryForProjectSuccess = ({ telephoneNumbers: inventoryTelephoneNumbersList, totalCount: inventoryTelephoneNumbersTotalCount }) => {
  return {
    type: SEARCH_INVENTORY_FOR_PROJECT_SUCCESS,
    payload: { inventoryTelephoneNumbersList, inventoryTelephoneNumbersTotalCount }
  }
};

export const deleteNumber = (projectId, telephoneNumberId, callback) => {
  return {
    type: DELETE_NUMBER_REQUEST,
    payload: { projectId, telephoneNumberId, callback }
  }
};

export const deleteNumberSuccess = () => {
  return {
    type: DELETE_NUMBER_SUCCESS,
  }
};

export const numbersApiError = error => {
  return {
    type: NUMBER_API_ERROR,
    payload: error,
  }
}