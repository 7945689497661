import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Button, Spinner, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import CampaignDetailsTab1 from './CampaignDetailsTab1';
import CampaignDetailsTab2 from './CampaignDetailsTab2';
import CampaignDetailsTab3 from './CampaignDetailsTab3';

import { constructErrorMessage, clearHistoryState } from '../../helpers/utils';

import {
  deleteCampaign,
  getCampaignDetails,
} from "../../store/actions";

import classnames from 'classnames';
const CampaignDetails = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  // getting brandId and campaignId fromURL
  const { brandId: currentBrandId, campaignId: currentCampaignId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const campaignDetails = useSelector((state) => state.Tcr?.campaignDetails);
  const isLoading = useSelector((state) => state.Tcr.loading);
  const isDeleting = useSelector((state) => state.Tcr.deleting);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Tcr.error);
  const [activeTab, setActiveTab] = useState("campaign-1");

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    if (redirectStatus === 'create-campaign-succeeded') {
      setSuccessMessage("Campaign was successfully registered");
    }

    if (redirectStatus === 'update-campaign-succeeded') {
      setSuccessMessage("Campaign was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  useEffect(() => {
    if (currentProjectId && currentBrandId) {
      dispatch(getCampaignDetails(currentProjectId, currentBrandId, currentCampaignId));
    }
  }, [dispatch, currentProjectId, currentBrandId, currentCampaignId]);

  const onDeleteCampaignClick = () => {
    setConfirmDialogText(`Are you sure you want to deregister this campaign?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteCampaign(currentProjectId, currentBrandId, currentCampaignId, () => {
        history.push(`/projects/${currentProjectId}/tcr/campaigns`, { redirectStatus: `delete-campaign-succeeded` });
      }));
    });

    setIsConfirmDialogOpen(true);
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Registered Campaigns"
            pageTitle = "Campaign Details"
            titleUrl={`/projects/${currentProjectId}/tcr/campaigns`}
            breadcrumbItem={currentCampaignId} />
          <Row>
            <Col className="col-12">

              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
                  {!isLoading && campaignDetails &&
                    <>
                      <Nav tabs className="nav-tabs-custom">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "campaign-1",
                            })}
                            onClick={() => {
                              toggleTab("campaign-1")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="mdi mdi-information-variant"></i>
                            </span>
                            <span className="d-none d-sm-block"><i className="mdi mdi-information-variant"></i>Description</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "campaign-2",
                            })}
                            onClick={() => {
                              toggleTab("campaign-2")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="mdi mdi-order-bool-descending"></i>
                            </span>
                            <span className="d-none d-sm-block"><i className="mdi mdi-order-bool-descending"></i>Use case</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "campaign-3",
                            })}
                            onClick={() => {
                              toggleTab("campaign-3")
                            }}
                          >
                            <span className="d-block d-sm-none">
                              <i className="mdi mdi-android-messages"></i>
                            </span>
                            <span className="d-none d-sm-block"><i className="mdi mdi-android-messages"></i>Samples</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="p-3 text-muted">
                        <TabPane tabId="campaign-1">
                          <CampaignDetailsTab1 campaignDetails={campaignDetails} />
                          {campaignDetails?.tcrCampaignId && <>
                            <Row className="mb-3">
                              <div className="d-flex flex-wrap gap-3 mt-3">
                                <Button
                                  type="reset"
                                  color="danger"
                                  outline
                                  className="w-md"
                                  onClick={onDeleteCampaignClick}
                                >{isDeleting && <>
                                  <Spinner size="sm me-1" color="danger" />
                                </>}
                                  {!isDeleting && <>
                                    Deregister campaign
                                  </>}
                                </Button>
                              </div>
                            </Row>
                            <Row className="mb-3">
                              <p><small>Updating campaign properties is limited due to the regulatory restrictions. Please open a support ticket in case you need to update your campaign info.</small></p>
                            </Row>
                          </>}
                        </TabPane>
                        <TabPane tabId="campaign-2">
                          <CampaignDetailsTab2 campaignDetails={campaignDetails} />
                        </TabPane>
                        <TabPane tabId="campaign-3">
                          <CampaignDetailsTab3
                            campaignDetails={campaignDetails}
                            currentProjectId={currentProjectId}
                            currentBrandId={currentBrandId}
                            currentCampaignId={currentCampaignId}
                          />
                        </TabPane>
                      </TabContent>

                    </>}

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment >
  )
}

CampaignDetails.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(CampaignDetails)));