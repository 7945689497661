import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Card, CardTitle, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getProfileDetails,
  updateProfile
} from "../../store/actions";
import { constructErrorMessage } from '../../helpers/utils';

const ProfileDetailsCard = () => {

  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.Profile.profileDetails);
  const isLoading = useSelector((state) => state.Profile.loading);
  const isUpdating = useSelector((state) => state.Profile.updating);
  const profileAPIError = useSelector((state) => state.Profile.error);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [dispatch]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fullName: profileDetails?.fullName,
      email: profileDetails?.email,
      phone: profileDetails?.phone,
      companyName: profileDetails?.companyName,
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Please enter full name").max(300, "Name is too long"),
      phone: Yup.string().max(300, "Phone number is too long"),
      companyName: Yup.string().max(300, "Company name is too long"),
    }),
    onSubmit: async (values) => {
      dispatch(updateProfile(values.fullName, values.phone, values.companyName, () => setSuccessMessage("User profile was successfully updated")));
    }
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">User Info</CardTitle>
          <GlobalProgressBar isLoading={isLoading} />
          {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
          {!isLoading && profileAPIError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(profileAPIError)}</CustomAlert> : null}
          {!isLoading && profileDetails &&
            <>
              <Form
                className="form-group top-up-balance"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row className="mb-3">
                  <Label className="form-label">Full name</Label>
                  <InputGroup>
                    <Input
                      name="fullName"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.fullName || ""}
                      invalid={
                        validation.touched.fullName && validation.errors.fullName ? true : false
                      }
                    />
                    {validation.touched.fullName && validation.errors.fullName ? (
                      <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">Email</Label>
                  <InputGroup>
                    <Input
                      name="email"
                      type="text"
                      value={validation.values.email || ""}
                      readOnly={true}
                      title="Your email is your login and can not be changed"
                    />
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">Phone</Label>
                  <InputGroup>
                    <Input
                      name="phone"
                      placeholder="Enter your contact phone number"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.phone || ""}
                      invalid={
                        validation.touched.phone && validation.errors.phone ? true : false
                      }
                    />
                    {validation.touched.phone && validation.errors.phone ? (
                      <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">Company name</Label>
                  <InputGroup>
                    <Input
                      name="companyName"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.companyName || ""}
                      invalid={
                        validation.touched.companyName && validation.errors.companyName ? true : false
                      }
                    />
                    {validation.touched.companyName && validation.errors.companyName ? (
                      <FormFeedback type="invalid">{validation.errors.companyName}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <div className="d-flex flex-wrap gap-3 mt-3">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-md"
                    >
                      {isUpdating && <>
                        <Spinner size="sm me-1" color="light" />
                      </>}
                      {!isUpdating && <>
                        Update
                      </>}
                    </Button>
                  </div>
                </Row>
              </Form>
            </>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ProfileDetailsCard.propTypes = {
  t: PropTypes.any,
  profile: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    profile: state.Profile,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ProfileDetailsCard)));