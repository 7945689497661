import {
  GET_CONTACTS_FOR_PROJECT_REQUEST,
  GET_CONTACTS_FOR_PROJECT_SUCCESS,
  SET_CONTACTS_LIMIT,
  GET_CONTACT_DETAILS_REQUEST,
  GET_CONTACT_DETAILS_NO_LOADING_REQUEST,
  GET_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS_REQUEST,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  DELETE_CONTACTS_REQUEST,
  DELETE_CONTACTS_SUCCESS,
  GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST,
  GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST,
  GET_CONTACT_GROUPS_FOR_PROJECT_SUCCESS,
  SET_CONTACTS_GROUP_LIMIT,
  GET_CONTACT_GROUP_DETAILS_REQUEST,
  GET_CONTACT_GROUP_DETAILS_SUCCESS,
  UPDATE_CONTACT_GROUP_DETAILS_REQUEST,
  UPDATE_CONTACT_GROUP_DETAILS_SUCCESS,
  DELETE_CONTACT_GROUP_REQUEST,
  DELETE_CONTACT_GROUP_SUCCESS,
  DELETE_CONTACT_GROUPS_REQUEST,
  DELETE_CONTACT_GROUPS_SUCCESS,
  GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
  GET_CONTACT_ATTRIBUTE_DETAILS_SUCCESS,
  UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
  UPDATE_CONTACT_ATTRIBUTE_DETAILS_SUCCESS,
  DELETE_CONTACT_ATTRIBUTE_REQUEST,
  DELETE_CONTACT_ATTRIBUTE_SUCCESS,
  GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST,
  GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_SUCCESS,
  SET_CONTACT_IMPORT_TASKS_LIMIT,
  CONTACT_API_ERROR
} from "./actionTypes";

export const getContactsForProject = (globalFilter, projectId, limit, offset) => {
  return {
    type: GET_CONTACTS_FOR_PROJECT_REQUEST,
    payload: { globalFilter, projectId, limit, offset }
  }
};

export const getContactsForProjectSuccess = ({ contactsGlobalFilter, contacts: contactsList, offset: contactsOffset, limit: contactsLimit, totalCount: contactsTotalCount }) => {
  return {
    type: GET_CONTACTS_FOR_PROJECT_SUCCESS,
    payload: { contactsGlobalFilter, contactsList, contactsOffset, contactsLimit, contactsTotalCount }
  }
};

export const setContactsLimit = limit => {
  return {
    type: SET_CONTACTS_LIMIT,
    payload: limit,
  }
}

export const getContactDetails = (projectId, contactId) => {
  return {
    type: GET_CONTACT_DETAILS_REQUEST,
    payload: { projectId, contactId }
  }
};

export const getContactDetailsNoLoading = (projectId, contactId) => {
  return {
    type: GET_CONTACT_DETAILS_NO_LOADING_REQUEST,
    payload: { projectId, contactId }
  }
};

export const getContactDetailsSuccess = contactDetails => {
  return {
    type: GET_CONTACT_DETAILS_SUCCESS,
    payload: contactDetails
  }
};

export const updateContact = (contactId, projectId, email, fullName, firstName, lastName, company, jobTitle, countryCode, languageCode, groupIds, additionalAttributes, callback) => {
  return {
    type: UPDATE_CONTACT_DETAILS_REQUEST,
    payload: { contactId, projectId, email, fullName, firstName, lastName, company, jobTitle, countryCode, languageCode, groupIds, additionalAttributes, callback }
  }
};

export const updateContactSuccess = () => {
  return {
    type: UPDATE_CONTACT_DETAILS_SUCCESS,
  }
};

export const deleteContacts = (projectId, contactIds, callback) => {
  return {
    type: DELETE_CONTACTS_REQUEST,
    payload: { projectId, contactIds, callback }
  }
};

export const deleteContactsSuccess = () => {
  return {
    type: DELETE_CONTACTS_SUCCESS,
  }
};

export const getContactGroupsForProject = (projectId, limit, offset) => {
  return {
    type: GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getContactGroupsForProjectNoLoading = (projectId, limit, offset) => {
  return {
    type: GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getContactGroupsForProjectSuccess = ({ contactGroups: contactGroupsList, offset: contactGroupsOffset, limit: contactGroupsLimit, totalCount: contactGroupsTotalCount }) => {
  return {
    type: GET_CONTACT_GROUPS_FOR_PROJECT_SUCCESS,
    payload: { contactGroupsList, contactGroupsOffset, contactGroupsLimit, contactGroupsTotalCount }
  }
};

export const setContactsGroupLimit = limit => {
  return {
    type: SET_CONTACTS_GROUP_LIMIT,
    payload: limit,
  }
}

export const getContactGroupDetails = (projectId, contactGroupId) => {
  return {
    type: GET_CONTACT_GROUP_DETAILS_REQUEST,
    payload: { projectId, contactGroupId }
  }
};

export const getContactGroupDetailsSuccess = contactGroupDetails => {
  return {
    type: GET_CONTACT_GROUP_DETAILS_SUCCESS,
    payload: contactGroupDetails
  }
};

export const updateContactGroup = (contactGroupId, projectId, title, description, callback) => {
  return {
    type: UPDATE_CONTACT_GROUP_DETAILS_REQUEST,
    payload: { contactGroupId, projectId, title, description, callback }
  }
};

export const updateContactGroupSuccess = () => {
  return {
    type: UPDATE_CONTACT_GROUP_DETAILS_SUCCESS,
  }
};

export const getContactAttributeDetails = (projectId, attrId) => {
  return {
    type: GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
    payload: { projectId, attrId }
  }
};

export const getContactAttributeDetailsSuccess = attributeDetails => {
  return {
    type: GET_CONTACT_ATTRIBUTE_DETAILS_SUCCESS,
    payload: attributeDetails
  }
};

export const updateContactAttribute = (attrId, projectId, displayName, description, callback) => {
  return {
    type: UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
    payload: { attrId, projectId, displayName, description, callback }
  }
};

export const updateContactAttributeSuccess = () => {
  return {
    type: UPDATE_CONTACT_ATTRIBUTE_DETAILS_SUCCESS,
  }
};

export const getContactImportTasksForProject = (projectId, limit, offset) => {
  return {
    type: GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getContactImportTasksForProjectSuccess = ({ contactImportTasks: contactImportTasksList, offset: contactImportTasksOffset, limit: contactImportTasksLimit, totalCount: contactImportTasksTotalCount }) => {
  return {
    type: GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_SUCCESS,
    payload: { contactImportTasksList, contactImportTasksOffset, contactImportTasksLimit, contactImportTasksTotalCount }
  }
};

export const setContactImportTasksLimit = limit => {
  return {
    type: SET_CONTACT_IMPORT_TASKS_LIMIT,
    payload: limit,
  }
}

export const deleteContactGroup = (projectId, contactGroupId, callback) => {
  return {
    type: DELETE_CONTACT_GROUP_REQUEST,
    payload: { projectId, contactGroupId, callback }
  }
};

export const deleteContactGroupSuccess = () => {
  return {
    type: DELETE_CONTACT_GROUP_SUCCESS,
  }
};

export const deleteContactGroups = (projectId, contactGroupIds, callback) => {
  return {
    type: DELETE_CONTACT_GROUPS_REQUEST,
    payload: { projectId, contactGroupIds, callback }
  }
};

export const deleteContactGroupsSuccess = () => {
  return {
    type: DELETE_CONTACT_GROUPS_SUCCESS,
  }
};

export const deleteContactAttribute = (projectId, attrId, callback) => {
  return {
    type: DELETE_CONTACT_ATTRIBUTE_REQUEST,
    payload: { projectId, attrId, callback }
  }
};

export const deleteContactAttributeSuccess = () => {
  return {
    type: DELETE_CONTACT_ATTRIBUTE_SUCCESS,
  }
};

export const contactApiError = error => {
  return {
    type: CONTACT_API_ERROR,
    payload: error,
  }
}