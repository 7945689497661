import { all, fork, call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  GET_BRANDS_FOR_PROJECT_REQUEST,
  GET_BRAND_DETAILS_REQUEST,
  DELETE_BRAND_REQUEST,
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGN_DETAILS_REQUEST,
  DELETE_CAMPAIGN_REQUEST,
} from "./actionTypes";
import {
  getBrandsForProjectSuccess,
  getBrandDetailsSuccess,
  deleteBrandSuccess,
  getCampaignsSuccess,
  getCampaignDetailsSuccess,
  deleteCampaignSuccess,
  tcrApiError
} from "./actions";

//Include Both Helper File with needed methods
import { tcrService } from "../../services";

function* getBrands({ payload: {projectId, limit, offset} }) {
  try {
    const response = yield call(
      tcrService.fetchBrands,
      projectId,
      limit,
      offset
    )
    yield put(getBrandsForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(tcrApiError(error));
  }
}

function* getBrandDetails({ payload: { projectId, brandId } }) {
  try {
    const response = yield call(
      tcrService.fetchBrandDetails,
      projectId,
      brandId
    )
    yield put(getBrandDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(tcrApiError(error));
  }
}

function* deleteBrand({ payload: { projectId, brandId, callback } }) {
  try {
    const response = yield call(
      tcrService.deleteBrand,
      projectId,
      brandId
    )
    yield put(deleteBrandSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(tcrApiError(error));
  }
}

function* getCampaigns({ payload: {projectId, brandId, limit, offset} }) {
  try {
    const response = yield call(
      tcrService.fetchCampaigns,
      projectId,
      brandId,
      limit,
      offset
    )
    yield put(getCampaignsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(tcrApiError(error));
  }
}

function* getCampaignDetails({ payload: { projectId, brandId, campaignId } }) {
  try {
    const response = yield call(
      tcrService.fetchCampaignDetails,
      projectId,
      brandId,
      campaignId
    )
    yield put(getCampaignDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(tcrApiError(error));
  }
}

function* deleteCampaign({ payload: { projectId, brandId, campaignId, callback } }) {
  try {
    const response = yield call(
      tcrService.deleteCampaign,
      projectId,
      brandId,
      campaignId
    )
    yield put(deleteCampaignSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(tcrApiError(error));
  }
}

function* watchBrandsRequests() {
  yield takeEvery(GET_BRANDS_FOR_PROJECT_REQUEST, getBrands)
}

function* watchGetBrandDetailsRequests() {
  yield takeEvery(GET_BRAND_DETAILS_REQUEST, getBrandDetails)
}

function* watchDeleteBrandRequests() {
  yield takeEvery(DELETE_BRAND_REQUEST, deleteBrand)
}

function* watchCampaignsRequests() {
  yield takeEvery(GET_CAMPAIGNS_REQUEST, getCampaigns)
}

function* watchGetCampaignDetailsRequests() {
  yield takeEvery(GET_CAMPAIGN_DETAILS_REQUEST, getCampaignDetails)
}

function* watchDeleteCampaignRequests() {
  yield takeEvery(DELETE_CAMPAIGN_REQUEST, deleteCampaign)
}

function* tcrSaga() {
  yield all([
    fork(watchBrandsRequests),
    fork(watchGetBrandDetailsRequests),
    fork(watchDeleteBrandRequests),
    fork(watchCampaignsRequests),
    fork(watchGetCampaignDetailsRequests),
    fork(watchDeleteCampaignRequests),
  ]);
}

export default tcrSaga;
