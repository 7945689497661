import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_API_ERROR
} from "./actionTypes";

export const changePassword = (email, currentPassword, newPassword, callback) => {
  return {
    type: CHANGE_PASSWORD_REQUEST,
    payload: { email, currentPassword, newPassword, callback }
  }
};

export const changePasswordSuccess = () => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
  }
};

export const changePasswordApiError = error => {
  return {
    type: CHANGE_PASSWORD_API_ERROR,
    payload: error,
  }
}