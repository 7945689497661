import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getPaymentMethodsForProject,
  getProjectDetails,
  topUpBalance
} from "../../store/actions";

import { constructPaymentMethodBrandLogo } from "./common";

import './billing.scss';
import { constructErrorMessage } from '../../helpers/utils';

const TopUpBalance = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const paymentMethodsList = useSelector((state) => state.Billing.paymentMethodsList);
  const paymentMethodsLoading = useSelector((state) => state.Billing.loading);
  const isCreating = useSelector((state) => state.Billing.creating);
  const billingError = useSelector((state) => state.Billing.error);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const [redirectToProjectBalance, setRedirectToProjectBalance] = useState(false); // a flag to redirect back

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getPaymentMethodsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  const onCancelTopUpBalanceClick = () => {
    setRedirectToProjectBalance(true);
  }

  const onPaymentMethodChange = (newOption) => {
    validation.setFieldValue("paymentMethodId", newOption.value);
  }

  // constructs payment method name to the user
  const constructPaymentMethodName = (pm) => {
    if (!pm || !pm.card) {
      return '';
    }

    return <div>{constructPaymentMethodBrandLogo(pm?.card?.brand)} **** {pm?.card?.last4} (expires {pm?.card?.expMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 })}/{pm?.card?.expYear})</div>;
  }

  const constructPaymentMethodsOptionGroup = () => {
    let options = [];
    if (Array.isArray(paymentMethodsList)) {
      paymentMethodsList.forEach((val) => {
        options.push({
          "label": constructPaymentMethodName(val),
          "value": val?.paymentMethodId
        });
      })
    }

    return options;
  }

  const paymentMethodOptionGroup = constructPaymentMethodsOptionGroup();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      paymentAmount: 5,
      paymentMethodId: "", // cannot use single quote string, otherwise it's not working
    },
    validationSchema: Yup.object({
      paymentAmount: Yup.number().integer("Please enter an integer value").min(5, "Payment amount should be at least $5").max(10000, "Payment amount should be less than $10000").required("Please enter payment amount"),
      paymentMethodId: Yup.string().trim().required("Please select your payment method"),
    }),
    onSubmit: (values) => {

      setConfirmDialogText(<span><span className='text-primary'>${values.paymentAmount}</span> will be added to your balance via the selected payment method</span>);

      setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
        dispatch(topUpBalance(currentProjectId, values.paymentAmount, values.paymentMethodId, () => {
          dispatch(getProjectDetails(currentProjectId));
          history.push(`/projects/${currentProjectId}/billing/balance`, { redirectStatus: `top-up-balance-succeeded` });
        }
        ));
      });

      setIsConfirmDialogOpen(true);
    }
  });

  return (
    <React.Fragment>
      {redirectToProjectBalance &&
        <Redirect push to={`/projects/${currentProjectId}/billing/balance`} />
      }
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Project Balance"
            titleUrl={`/projects/${currentProjectId}/billing/balance`}
            breadcrumbItem="Top Up" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  {billingError ? <CustomAlert color="danger">{constructErrorMessage(billingError, 'Something went wrong. Please ensure your payment method does not require 3DS authentication')}</CustomAlert> : null}
                  <GlobalProgressBar isLoading={paymentMethodsLoading} />
                  {!paymentMethodsLoading &&
                    <>
                      <Form
                        className="form-group top-up-balance"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Label className="form-label">Amount</Label>
                          <InputGroup>
                            <div className="input-group-text">$</div>
                            <Input
                              name="paymentAmount"
                              type="number"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.paymentAmount || ""}
                              invalid={
                                validation.touched.paymentAmount && validation.errors.paymentAmount ? true : false
                              }
                            />
                            {validation.touched.paymentAmount && validation.errors.paymentAmount ? (
                              <FormFeedback type="invalid">{validation.errors.paymentAmount}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Payment method</Label>
                          {/* see https://github.com/JedWatson/react-select/issues/3263#issuecomment-604237097 fpr explanation. without next two attributes the dropdown is behind the card */}
                          <Select
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            isSearchable={false}
                            options={paymentMethodOptionGroup}

                            onChange={onPaymentMethodChange}
                          />
                          {validation.errors.paymentMethodId ? ( /* FormFeedback is not working for <Select> for some reason. <span className="is-invalid"></span> is needed to make FormFeedback visible. See CSS:
                          .is-invalid ~ .invalid-feedback,
                          .is-invalid ~ .invalid-tooltip {
                          display: block; } in bootstrap
                          */
                            <>
                              <span className="is-invalid"></span>
                              <FormFeedback type="invalid">{validation.errors.paymentMethodId}</FormFeedback>
                            </>
                          ) : null}
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              {isCreating && <>
                                <Spinner size="sm me-1" color="light" />
                              </>}
                              {!isCreating && <>
                                Top up
                              </>}
                            </Button>
                            <Button
                              type="reset"
                              color="secondary"
                              outline
                              className="w-md"
                              onClick={onCancelTopUpBalanceClick}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

TopUpBalance.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  billing: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    billing: state.Billing,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(TopUpBalance)));