import PropTypes from 'prop-types';
import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Label } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import { textOrEmpty } from '../../helpers/utils';

import { constructCampaignStatus } from './utils';

const CampaignDetailsTab1 = (props) => {

  let {
    campaignDetails,
  } = props;

  return (
    <React.Fragment>
      <Row>
        <Col md={4}>
          <Label className="form-label">Campaign ID</Label>
          <p>{campaignDetails?.campaignId}</p>
        </Col>
        <Col md={4}>
          <Label className="form-label">TCR campaign ID</Label>
          <p>{textOrEmpty(campaignDetails?.tcrCampaignId)}</p>
        </Col>
        <Col md={4}>
          <Label className="form-label">Status</Label>
          <h5 className='text-uppercase'>{constructCampaignStatus(campaignDetails?.campaignStatus)}</h5>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Label className="form-label">Brand</Label>
          <p>
            <Link to={`/projects/${campaignDetails.projectId}/tcr/brands/${campaignDetails.brandId}`} title="Go to brand details">
              {campaignDetails.brandName}
            </Link>
          </p>
        </Col>
        <Col md={4}>
          <Label className="form-label">Submitted on</Label>
          <p>{!campaignDetails?.createdTimestampMs ? '' : new Date(campaignDetails.createdTimestampMs).toLocaleString()}</p>
        </Col>
        <Col md={4}>
          <Label className="form-label">Last billed on</Label>
          <p>{!campaignDetails?.lastChargedTimestampMs ? '' : new Date(campaignDetails.lastChargedTimestampMs).toLocaleString()}</p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <Label className="form-label">Cost</Label>
          <p>{campaignDetails?.cost && campaignDetails?.cost !== "0" ? `$${campaignDetails?.cost}/mo` : "-"}</p>
        </Col>
        <Col md={8}>
          <Label className="form-label">Carriers</Label>
          <p>{campaignDetails?.mnos ? campaignDetails?.mnos.join(", ") : "-"}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label className="form-label">Campaign description</Label>
          <p>
            {campaignDetails?.description || '-'}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label className="form-label">Message flow / Call-to-Action</Label>
          <p>
            {campaignDetails?.messageFlowDescription || '-'}
          </p>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CampaignDetailsTab1.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(CampaignDetailsTab1)));