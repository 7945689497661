import { all, fork, call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { GET_TOKENS_FOR_PROJECT_REQUEST, GET_TOKEN_DETAILS_REQUEST, UPDATE_TOKEN_DETAILS_REQUEST, DELETE_TOKEN_REQUEST } from "./actionTypes";
import { getTokensForProjectSuccess, getTokenDetailsSuccess, updateTokenSuccess, deleteTokenSuccess, tokenApiError } from "./actions";

//Include Both Helper File with needed methods
import { tokenService } from "../../services";

function* getTokens({ payload: projectId }) {
  try {
    const response = yield call(
      tokenService.fetchTokens,
      projectId
    )
    yield put(getTokensForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(tokenApiError(error));
  }
}

function* getTokenDetails({ payload: { projectId, tokenId } }) {
  try {
    const response = yield call(
      tokenService.fetchTokenDetails,
      projectId,
      tokenId
    )
    yield put(getTokenDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(tokenApiError(error));
  }
}

function* updateToken({ payload: { projectId, tokenId, tokenName, tokenScopes, callback } }) {
  try {
    const response = yield call(
      tokenService.updateToken,
      tokenId,
      projectId,
      tokenName,
      tokenScopes
    )
    yield put(updateTokenSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(tokenApiError(error));
  }
}

function* deleteToken({ payload: { projectId, tokenId, callback } }) {
  try {
    const response = yield call(
      tokenService.deleteToken,
      projectId,
      tokenId
    )
    yield put(deleteTokenSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(tokenApiError(error));
  }
}

function* watchGetTokensRequests() {
  yield takeEvery(GET_TOKENS_FOR_PROJECT_REQUEST, getTokens)
}

function* watchGetTokenDetailsRequests() {
  yield takeEvery(GET_TOKEN_DETAILS_REQUEST, getTokenDetails)
}

function* watchUpdateTokenRequests() {
  yield takeEvery(UPDATE_TOKEN_DETAILS_REQUEST, updateToken)
}

function* watchDeleteTokenRequests() {
  yield takeEvery(DELETE_TOKEN_REQUEST, deleteToken)
}

function* tokenSaga() {
  yield all([
    fork(watchGetTokensRequests),
    fork(watchGetTokenDetailsRequests),
    fork(watchUpdateTokenRequests),
    fork(watchDeleteTokenRequests),
  ]);
}

export default tokenSaga;
