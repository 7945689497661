import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Form, Label, Input, FormFeedback } from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

import { tollFreeMonthlyVolumes, tollFreeUseCases, getMapKeyByValue, emptyFunc } from '../../helpers/utils';

const AddTollFreeVerificationStep2 = (props) => {

  let onSelectedTnsChange = props?.onSelectedTnsChange ?? emptyFunc;
  let onMonthlyVolumeChange = props?.onMonthlyVolumeChange ?? emptyFunc;
  let onUseCaseChange = props?.onUseCaseChange ?? emptyFunc;
  let onUseCaseSummaryChange = props?.onUseCaseSummaryChange ?? emptyFunc;
  let onMessageContentChange = props?.onMessageContentChange ?? emptyFunc;

  let {
    phoneNumbersList,
    validation
  } = props;

  const getPhoneNumbersOptions = () => {
    let arr = [];

    phoneNumbersList.forEach((tn) => {
      arr.push({ label: tn.telephoneNumber, value: tn.telephoneNumberId });
    });

    return arr;
  }

  const getTollFreeMonthlyVolumesOptions = () => {
    let arr = [];

    tollFreeMonthlyVolumes.forEach((val, key) => {
      arr.push({ label: key, value: val });
    });

    return arr;
  }

  const getTollFreeUseCasesOptions = () => {
    let arr = [];

    tollFreeUseCases.forEach((val, key) => {
      arr.push({ label: key, value: val });
    });

    return arr;
  }

  const phoneNumbersOptionsGroup = [
    {
      // label: group label
      options: getPhoneNumbersOptions()
    }
  ];

  const tollFreeMonthlyVolumesOptionsGroup = [
    {
      // label: group label
      options: getTollFreeMonthlyVolumesOptions()
    }
  ];

  const tollFreeUseCasesOptionsGroup = [
    {
      // label: group label
      options: getTollFreeUseCasesOptions()
    }
  ];



  useEffect(() => {
    onSelectedTnsChange(validation.values.selectedTns.map(({ value }) => value));
  }, [onSelectedTnsChange, validation.values.selectedTns]);

  useEffect(() => {
    onMonthlyVolumeChange(validation.values.monthlyVolume);
  }, [onMonthlyVolumeChange, validation.values.monthlyVolume]);

  useEffect(() => {
    onUseCaseChange(validation.values.useCase);
  }, [onUseCaseChange, validation.values.useCase]);

  useEffect(() => {
    onUseCaseSummaryChange(validation.values.useCaseSummary);
  }, [onUseCaseSummaryChange, validation.values.useCaseSummary]);

  useEffect(() => {
    onMessageContentChange(validation.values.messageContent);
  }, [onMessageContentChange, validation.values.messageContent]);


  return (
    <React.Fragment>
      <>
        <Form
          className="form-group add-toll-free-verification-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="mb-3">
            <Col className="col-12">
              <div>
                <Label className="form-label">Telephone numbers in this use case</Label>
                <Select
                  name="selectedTns"
                  isMulti={true}
                  isClearable
                  onChange={options => { validation.setFieldValue("selectedTns", options); }}
                  options={phoneNumbersOptionsGroup}
                  maxMenuHeight={300}
                />
                {validation.touched.selectedTns && validation.errors.selectedTns ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.selectedTns}</FormFeedback>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-6">
              <div>
                <Label className="form-label">Projected monthly volume</Label>
                {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                <Select
                  name="monthlyVolume"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable
                  onChange={option => validation.setFieldValue("monthlyVolume", option?.value)}
                  defaultValue={validation.values.monthlyVolume ? { label: getMapKeyByValue(tollFreeMonthlyVolumes, validation.values.monthlyVolume), value: validation.values.monthlyVolume } : ""}
                  options={tollFreeMonthlyVolumesOptionsGroup}
                  maxMenuHeight={300}
                />
                {validation.touched.monthlyVolume && validation.errors.monthlyVolume ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.monthlyVolume}</FormFeedback>
                  </>
                ) : null}
              </div>
            </Col>
            <Col className="col-6">
              <div>
                <Label className="form-label">Use case</Label>
                {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                <Select
                  name="useCase"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  isClearable
                  onChange={option => validation.setFieldValue("useCase", option?.value)}
                  defaultValue={validation.values.useCase ? { label: getMapKeyByValue(tollFreeUseCases, validation.values.useCase), value: validation.values.useCase } : ""}
                  options={tollFreeUseCasesOptionsGroup}
                  maxMenuHeight={300}
                />
                {validation.touched.useCase && validation.errors.useCase ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.useCase}</FormFeedback>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <div>
                <Label className="form-label">Use case summarized description</Label>
                <Input
                  type="textarea"
                  name="useCaseSummary"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.useCaseSummary || ""}
                  maxLength="672"
                  rows="3"
                />
                {validation.touched.useCaseSummary && validation.errors.useCaseSummary ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.useCaseSummary}</FormFeedback>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <div>
                <Label className="form-label">Message content</Label>
                <Input
                  type="textarea"
                  name="messageContent"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.messageContent || ""}
                  maxLength="672"
                  rows="3"
                />
                {validation.touched.messageContent && validation.errors.messageContent ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.messageContent}</FormFeedback>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </Form>
      </>
    </React.Fragment>
  )
}

AddTollFreeVerificationStep2.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddTollFreeVerificationStep2)));