import axios from "axios";
import { getFirebaseBackend } from './firebase_helper';
import config from '../config';

//apply base url for axios
const API_URL = config.API_URL;
const APP_VERSION = config.APP_VERSION;

console.log(`API_URL=${API_URL}`);

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.interceptors.request.use((request) => {
  return getFirebaseBackend().getIdToken().then((idToken) => {
    // request.headers['Authorization'] = idToken ? `Bearer ${idToken}` : ''; we do not use "Bearer" keyword
    request.headers['Authorization'] = idToken;

    //adding x-client-env if possible
    if (process.env.REACT_APP_ENV) {
      request.headers['x-client-env'] = process.env.REACT_APP_ENV;
    }
    //adding x-client-version if possible
    if (APP_VERSION) {
      request.headers['x-client-version'] = APP_VERSION;
    }
    return Promise.resolve(request);
  }).catch(error => {
    return Promise.reject(error);
  })
}, (error) => {
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}

export async function downloadFile(url, fileName, config = {}) { // see https://github.com/kennethjiang/js-file-download/blob/master/file-download.js
  let responseData = await axiosApi.get(url, { ...config, responseType: "blob" }).then(response => response.data)
  // create file link in browser's memory
  const href = URL.createObjectURL(responseData);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); //or any other extension
  //document.body.appendChild(link); // no need
  link.click();

  // Fixes "webkit blob resource error 1"
  setTimeout(function () {
    //document.body.removeChild(link); // no need
    window.URL.revokeObjectURL(responseData);
  }, 200)

}

