/* TCR */
export const GET_BRANDS_FOR_PROJECT_REQUEST = "GET_BRANDS_FOR_PROJECT_REQUEST";
export const GET_BRANDS_FOR_PROJECT_SUCCESS = "GET_BRANDS_FOR_PROJECT_SUCCESS";

export const GET_BRAND_DETAILS_REQUEST = "GET_BRAND_DETAILS_REQUEST";
export const GET_BRAND_DETAILS_SUCCESS = "GET_BRAND_DETAILS_SUCCESS";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";

export const GET_CAMPAIGNS_REQUEST = "GET_CAMPAIGNS_REQUEST";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";

export const GET_CAMPAIGN_DETAILS_REQUEST = "GET_CAMPAIGN_DETAILS_REQUEST";
export const GET_CAMPAIGN_DETAILS_SUCCESS = "GET_CAMPAIGN_DETAILS_SUCCESS";

export const DELETE_CAMPAIGN_REQUEST = "DELETE_CAMPAIGN_REQUEST";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";

export const TCR_API_ERROR = "TCR_API_ERROR";
