import React from "react";

const EmptyBlock = ({ children }) => {

  return (
    <div className=" empty-card d-flex align-items-center justify-content-center">
      {children}
    </div>
  );

}

export default EmptyBlock;
