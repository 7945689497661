// @flow
import {
  GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST,
  GET_PAYMENTS_HISTORY_FOR_PROJECT_SUCCESS,
  SET_PAYMENTS_HISTORY_LIMIT,
  GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST,
  GET_PAYMENT_METHODS_FOR_PROJECT_SUCCESS,
  GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST,
  GET_SETUP_INTENT_FOR_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_SUCCESS,
  TOP_UP_BALANCE_REQUEST,
  TOP_UP_BALANCE_SUCCESS,
  STRIPE_API_ERROR,
  BILLING_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
  creating: false,
  updating: false,
  deleting: false,

  paymentMethodsList: [],
  paymentMethodsCount: 0,

  paymentsHistoryList: [],
  paymentsHistoryLimit: 20,
  paymentsHistoryOffset: 0,
  paymentsHistoryCount: 0,

  setupIntentForPaymentMethodSecret: '',
  stripeError: '',
};

const Billing = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_PAYMENTS_HISTORY_FOR_PROJECT_SUCCESS:
      let paymentsHistory = [];
      if (Array.isArray(action.payload.paymentsHistoryList) && action.payload.paymentsHistoryList.length > 0) {
        paymentsHistory = action.payload.paymentsHistoryList;
      }

      return {
        ...state,
        loading: false,
        paymentsHistoryList: paymentsHistory,
        paymentsHistoryLimit: action.payload.paymentsHistoryLimit,
        paymentsHistoryOffset: action.payload.paymentsHistoryOffset,
        paymentsHistoryTotalCount: action.payload.paymentsHistoryTotalCount,
      }

    case SET_PAYMENTS_HISTORY_LIMIT:
      return {
        ...state,
        paymentsHistoryLimit: action.payload,
        paymentsHistoryOffset: 0, // reset the offset
      };

    case GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_PAYMENT_METHODS_FOR_PROJECT_SUCCESS:
      let paymentMethods = [];
      if (Array.isArray(action.payload.paymentMethodsList) && action.payload.paymentMethodsList.length > 0) {
        paymentMethods = action.payload.paymentMethodsList;
      }

      return {
        ...state,
        loading: false,
        paymentMethodsList: paymentMethods,
        paymentMethodsCount: action.payload.paymentMethodsTotalCount,
      }

    case GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case GET_SETUP_INTENT_FOR_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        setupIntentForPaymentMethodSecret: action.payload,
      };

    case DELETE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        loading: true,
        error: ''
      };

    case DELETE_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TOP_UP_BALANCE_REQUEST:
      return {
        ...state,
        creating: true,
        error: ''
      };

    case TOP_UP_BALANCE_SUCCESS:
      return {
        ...state,
        creating: false
      };

    case STRIPE_API_ERROR:
      return {
        ...state,
        loading: false,
        creating: false,
        updating: false,
        deleting: false,
        stripeError: action.payload,
      };

    case BILLING_API_ERROR:
      return {
        ...state,
        loading: false,
        creating: false,
        updating: false,
        deleting: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Billing;
