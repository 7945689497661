import { get, post, del } from "../helpers/api_helper";
const _ = require('lodash');

class KeywordService {

  fetchKeywords(projectId) {

    let url = `/projects/${projectId}/keywords`;

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); 

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchKeywordDetails(projectId, keywordId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/keywords/${keywordId}`)
        .then(response => {
          const k = _.get(response, 'keyword', {});
          resolve(k);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createKeyword(projectId, keyword, autoResponseMessage, subscribeAction, addToContactGroupIds, removeFromContactGroupIds) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/keywords`,
        {
          keyword,
          autoResponseMessage,
          subscribeAction,
          addToContactGroupIds,
          removeFromContactGroupIds,
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  deleteKeyword(projectId, keywordId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/keywords/${keywordId}`)
        .then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

}

export const keywordService = new KeywordService();