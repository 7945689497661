import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Redirect, withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { clearHistoryState, constructErrorMessage } from '../../helpers/utils';

import {
  getPaymentMethodsForProject,
  deletePaymentMethod,
  stripeApiError
} from "../../store/actions";

import { constructPaymentMethodBrandLogo } from "./common";

import '../datatables.scss';

const PaymentMethodsTable = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const paymentMethodsList = useSelector((state) => state.Billing.paymentMethodsList);
  const paymentMethodsLoading = useSelector((state) => state.Billing.loading);
  const apiError = useSelector((state) => state.Billing.error);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message when we added a payment method
  const [redirectToAddPaymentMethod, setRedirectToAddPaymentMethod] = useState(false); // a flag to redirect to AddPaymentMethod form

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    /*
        if (redirectStatus === 'succeeded') {
          let queryParam = new URLSearchParams(window?.location?.search).get('redirect_status');
          // did we get it from query string? if yes, than reload with state but without query string to prevent duplication toasters
          if (queryParam) {
            history.push(window?.location?.pathname, {redirectStatus: `add-payment-method-succeeded`});
          }
        }*/

    if (redirectStatus === 'succeeded') {
      setSuccessMessage("Payment method was successfully added");
    }

    if (redirectStatus === 'delete-payment-method-succeeded') {
      setSuccessMessage("Payment method was successfully deleted");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getPaymentMethodsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  const onAddPaymentMethodClick = () => {
    dispatch(stripeApiError('')); // clear the error message first
    setRedirectToAddPaymentMethod(true); // show the add payment method form
  }

  const onDeletePaymentMethodClick = (paymentMethodId) => {
    setConfirmDialogText("Are you sure you want to delete this payment method?");

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      setSuccessMessage("");
      dispatch(deletePaymentMethod(currentProjectId, paymentMethodId, () => {
        dispatch(getPaymentMethodsForProject(currentProjectId));
        setSuccessMessage("Payment method was successfully deleted");
      }))
    }
    );
    setIsConfirmDialogOpen(true);
  }

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (paymentMethodsLoading === undefined || paymentMethodsLoading === true) {
      return undefined;
    }

    return Array.isArray(paymentMethodsList) && paymentMethodsList.length > 0;
  }, [paymentMethodsLoading, paymentMethodsList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbItem="Payment Methods" />
          <Card>
            <CardBody>
              <GlobalProgressBar isLoading={paymentMethodsLoading} />
              {redirectToAddPaymentMethod && <Redirect push to={`/projects/${currentProjectId}/billing/payment-methods/add`} />}
              {!paymentMethodsLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
              {!paymentMethodsLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
              {isDataAvailable() &&
                <div className="actions clearfix">
                  <ul>
                    <li
                    >
                      <Button
                        type="submit"
                        color="success"
                        className="w-md"
                        onClick={onAddPaymentMethodClick}
                      >
                        + Add payment method
                      </Button>
                    </li>
                  </ul>
                </div>
              }
              <Row>
                <Col className="col-12">

                  {isDataAvailable() === false && <EmptyBlock>
                    <div className="text-center">
                      <p className="h3">No Payment Methods</p>
                      <p>You haven't add your payment method yet</p>
                      <p>
                        <Button
                          type="submit"
                          color="success"
                          className="w-md"
                          onClick={onAddPaymentMethodClick}
                        >
                          + Add payment method
                        </Button>
                      </p>
                    </div>
                  </EmptyBlock>}
                  {isDataAvailable() && <Table className="table table-striped table-responsive">
                    <tbody>
                      {paymentMethodsList.map((h, i) => <tr key={i} className="align-middle">
                        <td style={{ width: "2rem" }}><i className="uil-trash-alt" title="Remove this payment method" role="button" onClick={() => onDeletePaymentMethodClick(h?.paymentMethodId)}></i></td>
                        <td>
                          <div>{constructPaymentMethodBrandLogo(h?.card?.brand)}<strong>
                            &nbsp;**** {h?.card?.last4}</strong></div>
                          <div><i>expires {h?.card?.expMonth.toLocaleString('en-US', { minimumIntegerDigits: 2 })}/{h?.card?.expYear}</i></div>
                        </td>
                      </tr>)
                      }
                    </tbody>
                  </Table>}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment >
  )
}

PaymentMethodsTable.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  billing: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    billing: state.Billing,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(PaymentMethodsTable)));