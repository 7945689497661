// @flow
import {
  CONTACT_API_ERROR,
  DELETE_CONTACTS_REQUEST,
  DELETE_CONTACTS_SUCCESS,
  DELETE_CONTACT_ATTRIBUTE_REQUEST,
  DELETE_CONTACT_ATTRIBUTE_SUCCESS,
  DELETE_CONTACT_GROUPS_REQUEST,
  DELETE_CONTACT_GROUPS_SUCCESS,
  DELETE_CONTACT_GROUP_REQUEST,
  DELETE_CONTACT_GROUP_SUCCESS,
  GET_CONTACTS_FOR_PROJECT_REQUEST,
  GET_CONTACTS_FOR_PROJECT_SUCCESS,
  SET_CONTACTS_LIMIT,
  GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
  GET_CONTACT_ATTRIBUTE_DETAILS_SUCCESS,
  GET_CONTACT_DETAILS_REQUEST,
  GET_CONTACT_DETAILS_NO_LOADING_REQUEST,
  GET_CONTACT_DETAILS_SUCCESS,
  GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST,
  GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST,
  GET_CONTACT_GROUPS_FOR_PROJECT_SUCCESS,
  SET_CONTACTS_GROUP_LIMIT,
  GET_CONTACT_GROUP_DETAILS_REQUEST,
  GET_CONTACT_GROUP_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS_REQUEST,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_GROUP_DETAILS_REQUEST,
  UPDATE_CONTACT_GROUP_DETAILS_SUCCESS,
  UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
  UPDATE_CONTACT_ATTRIBUTE_DETAILS_SUCCESS,
  GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST,
  GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_SUCCESS,
  SET_CONTACT_IMPORT_TASKS_LIMIT,
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
  updating: false,
  deleting: false,

  contactDetails: undefined,
  contactGroupDetails: undefined,
  contactAttributeDetails: undefined,

  contactsList: [],
  contactsLimit: 10,
  contactsOffset: 0,
  contactsGlobalFilter: "",
  contactsTotalCount: 0,

  contactGroupsList: [],
  contactGroupsLimit: 10,
  contactGroupsOffset: 0,
  contactGroupsTotalCount: 0,

  contactImportTasksList: [],
  contactImportTasksLimit: 10,
  contactImportTasksOffset: 0,
  contactImportTasksTotalCount: 0,

};

const Contact = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CONTACTS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        contactsList: [],
        contactsTotalCount: 0,
      };

    case GET_CONTACTS_FOR_PROJECT_SUCCESS:
      let l = [];
      if (Array.isArray(action.payload.contactsList) && action.payload.contactsList.length > 0) {
        l = action.payload.contactsList;
      }

      return {
        ...state,
        loading: false,
        contactsList: l,
        contactsGlobalFilter: action.payload.contactsGlobalFilter,
        contactsLimit: action.payload.contactsLimit,
        contactsOffset: action.payload.contactsOffset,
        contactsTotalCount: action.payload.contactsTotalCount,
      }

    case SET_CONTACTS_LIMIT:
      return {
        ...state,
        contactsLimit: action.payload,
        contactsOffset: 0, // reset the offset
      };

    case GET_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        contactDetails: undefined, // following the pattern similar to tokens
        error: ''
      };

    case GET_CONTACT_DETAILS_NO_LOADING_REQUEST:
      return {// same as GET_CONTACT_DETAILS_REQUEST, but without "loading" and clearing contactDetails. It's used for the contact panel.
        ...state,
        error: ''
      };

    case GET_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactDetails: action.payload,
      }

    case UPDATE_CONTACT_DETAILS_REQUEST:
      return {
        ...state,
        updating: true,
        error: ''
      };

    case UPDATE_CONTACT_DETAILS_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case DELETE_CONTACTS_REQUEST:
      return {
        ...state,
        deleting: true,
        error: ''
      };

    case DELETE_CONTACTS_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        contactGroupsList: [],
        contactGroupsOffset: 0,
        contactGroupsTotalCount: 0,
      };

    case GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST:
      return {// same as GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST, but without "loading" and clearing contactGroupsList. It's used for the contact panel.
        ...state,
        error: '',
      };

    case GET_CONTACT_GROUPS_FOR_PROJECT_SUCCESS:
      let lg = [];
      if (Array.isArray(action.payload.contactGroupsList) && action.payload.contactGroupsList.length > 0) {
        lg = action.payload.contactGroupsList;
      }

      return {
        ...state,
        loading: false,
        contactGroupsList: lg,
        contactGroupsLimit: action.payload.contactGroupsLimit,
        contactGroupsOffset: action.payload.contactGroupsOffset,
        contactGroupsTotalCount: action.payload.contactGroupsTotalCount,
      }

    case SET_CONTACTS_GROUP_LIMIT:
      return {
        ...state,
        contactGroupsLimit: action.payload,
        contactGroupsOffset: 0, // reset the offset
      };

    case GET_CONTACT_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        contactGroupDetails: undefined, // following the pattern similar to tokens
        error: ''
      };

    case GET_CONTACT_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactGroupDetails: action.payload,
      }

    case UPDATE_CONTACT_GROUP_DETAILS_REQUEST:
      return {
        ...state,
        updating: true,
        error: ''
      };

    case UPDATE_CONTACT_GROUP_DETAILS_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        contactAttributeDetails: undefined, // following the pattern similar to tokens
        error: ''
      };

    case GET_CONTACT_ATTRIBUTE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        contactAttributeDetails: action.payload,
      }

    case UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST:
      return {
        ...state,
        updating: true,
        error: ''
      };

    case UPDATE_CONTACT_ATTRIBUTE_DETAILS_SUCCESS:
      return {
        ...state,
        updating: false,
      };

    case GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
        contactImportTasksList: [],
        contactImportTasksTotalCount: 0,
      };

    case GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_SUCCESS:
      let lit = [];
      if (Array.isArray(action.payload.contactImportTasksList) && action.payload.contactImportTasksList.length > 0) {
        lit = action.payload.contactImportTasksList;
      }

      return {
        ...state,
        loading: false,
        contactImportTasksList: lit,
        contactImportTasksLimit: action.payload.contactImportTasksLimit,
        contactImportTasksOffset: action.payload.contactImportTasksOffset,
        contactImportTasksTotalCount: action.payload.contactImportTasksTotalCount,
      }

    case SET_CONTACT_IMPORT_TASKS_LIMIT:
      return {
        ...state,
        contactImportTasksLimit: action.payload,
        contactImportTasksOffset: 0, // reset the offset
      };

    case DELETE_CONTACT_GROUP_REQUEST:
      return {
        ...state,
        deleting: true,
        error: ''
      };

    case DELETE_CONTACT_GROUP_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case DELETE_CONTACT_GROUPS_REQUEST:
      return {
        ...state,
        deleting: true,
        error: ''
      };

    case DELETE_CONTACT_GROUPS_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case DELETE_CONTACT_ATTRIBUTE_REQUEST:
      return {
        ...state,
        deleting: true,
        error: ''
      };

    case DELETE_CONTACT_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        deleting: false,
      };

    case CONTACT_API_ERROR:
      return {
        ...state,
        loading: false,
        updating: false,
        deleting: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default Contact;
