import React from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Row, Col, Button } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import { emptyFunc } from '../../helpers/utils';


const CustomConfirmDialog = (props) => {

  var dialogIsOpen = props.isOpen ?? false;
  var onConfirm = props.onConfirm ?? emptyFunc;

  const
    { confirmationTitle,
      confirmationText,
      confirmationStyle,
    } = props;

  const closeDialog = () => {
    return props.closeDialog();
  }

  const onConfirmClick = async () => {
    closeDialog();
    onConfirm();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={dialogIsOpen}
        centered={true}
        contentClassName="width-min-content"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="AddContactsToContactGroupModalLabel">
            {confirmationTitle === undefined ? 'Are you sure?' : confirmationTitle}
          </h5>
          <button
            type="button"
            onClick={() => {
              closeDialog();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {confirmationStyle === 'warning' && <Row>
            <Col md={2} className="text-center align-self-center" >
              <i className="uil uil-exclamation-triangle d-block display-4 text-warning"></i>
            </Col>
            <Col md={10} className="text-start align-self-center" >
              {confirmationText}
            </Col>
          </Row>}
          {!confirmationStyle /* empty by default */ && <>
            {confirmationText}
          </>}
        </div>
        <div className="modal-footer">
          <div className='d-flex flex-wrap gap-3 mt-3'>
            <Button
              type="button"
              color="danger"
              className="w-md"
              onClick={onConfirmClick}
            >
              OK
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              className="w-md"
              onClick={() => {
                closeDialog();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(CustomConfirmDialog));