import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col, Label, Button, Spinner, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledTooltip } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, isNullOrUndefined, clearHistoryState, formatAmountWithCurrency } from '../../helpers/utils';

import { formatTNLocation, formatTNType, getTelephoneNumberBadgeCssClassName, isTollFree } from './utils';
import { telephoneNumberService } from '../../services';

import {
  getNumbersForProject,
  getCampaigns,
  getNumberDetails,
  deleteNumber,
} from "../../store/actions";

const NumberInfo = (props) => {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  // getting telephoneNumberId fromURL
  const { telephoneNumberId: currentTelephoneNumberId } = useParams();

  let {
    telephoneNumberDetails,
  } = props;

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const allCampaignsList = useSelector((state) => state.Tcr.campaignsList);
  const isLoading = useSelector((state) => state.Numbers.loading);
  const isDeleting = useSelector((state) => state.Numbers.deleting);
  const apiError = useSelector((state) => state.Numbers.error);
  const telephoneNumbersLimit = useSelector((state) => state.Numbers.telephoneNumbersLimit);
  const [activeCampaignsList, setActiveCampaignsList] = useState(undefined);
  const [isUpdating, setIsUpdating] = useState(false);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show a success message
  const pageSize = telephoneNumbersLimit || 20;

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
  const [selectedCampaignId, setSelectedCampaignId] = useState(telephoneNumberDetails?.campaignId);

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectTelephoneNumber = (location.state && location.state.tn !== undefined) ? location.state.tn : new URLSearchParams(window?.location?.search).get(
    'tn'
  );

  useEffect(() => {

    if (redirectStatus === 'purchase-tn-succeeded') {
      setSuccessMessage(`Telephone number ${decodeURIComponent(redirectTelephoneNumber)} was successfully purchased`);
    }

    if (redirectStatus === 'update-tn-succeeded') {
      setSuccessMessage(`Saved changes for telephone number ${decodeURIComponent(redirectTelephoneNumber)}`);
      dispatch(getNumberDetails(currentProjectId, currentTelephoneNumberId));
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, redirectTelephoneNumber, history, dispatch, currentProjectId, currentTelephoneNumberId]);

  const loadCampaigns = useCallback((prjId) => {
    if (prjId) {
      dispatch(getCampaigns(prjId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentProjectId && currentTelephoneNumberId) { // don't use isTollFree() because it causes multiple loadCampaigns() calls. loadCampaigns() will be called (once) for a toll-free number, but it's okay for now.
      loadCampaigns(currentProjectId);
    }
  }, [loadCampaigns, currentProjectId, currentTelephoneNumberId]);

  const findCampaignByCampaignId = useCallback((cmpId) => {
    if (!Array.isArray(activeCampaignsList)) {
      return undefined;
    }

    return activeCampaignsList.find((c) => c.campaignId === cmpId);

  }, [activeCampaignsList]);

  useEffect(() => {
    if (Array.isArray(activeCampaignsList)) {
      setSelectedCampaignId(telephoneNumberDetails?.campaignId);
    }
  }, [activeCampaignsList, telephoneNumberDetails]);

  useEffect(() => {
    if (!Array.isArray(allCampaignsList)) {
      setActiveCampaignsList(undefined);
      return;
    }
    setActiveCampaignsList(
      allCampaignsList.filter(c => c.campaignStatus?.toUpperCase() === "ACTIVE")
    );
  }, [allCampaignsList]);

  const onUpdateNumberClick = async () => {
    try {
      setIsUpdating(false);
      setSuccessMessage('');
      setErrorMessage('');

      await telephoneNumberService.setCampaign(currentProjectId, currentTelephoneNumberId, findCampaignByCampaignId(selectedCampaignId)?.brandId, selectedCampaignId);

      // this will reset "Update" button. another way to do so if to reload telephoneNumberDetails from server
      telephoneNumberDetails.campaignId = selectedCampaignId;

      history.push(`/projects/${currentProjectId}/numbers/${currentTelephoneNumberId}`, { redirectStatus: `update-tn-succeeded`, tn: telephoneNumberDetails?.telephoneNumber });

    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsUpdating(false);
    }
  }

  const onDeleteNumberClick = () => {

    setConfirmDialogText(
      <>
        Are you sure you want to release <span className='text-primary'>{telephoneNumberDetails?.telephoneNumber}</span>?
        <br />
        <span className='text-danger'><strong>You will not be able to undo this action!</strong></span>
      </>);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteNumber(currentProjectId, currentTelephoneNumberId, () => {
        dispatch(getNumbersForProject(currentProjectId, pageSize, 0));
        history.push(`/projects/${currentProjectId}/numbers`, { redirectStatus: `delete-tn-succeeded`, tn: `${telephoneNumberDetails?.telephoneNumber}` });
      }))
    });

    setIsConfirmDialogOpen(true);
  }

  const constructCampaignLabel = useCallback((campaign) => {
    if (!campaign) {
      return ``;
    }
    return ` ${campaign.useCase} (${campaign.campaignId})`;
  }, [])

  const constructCampaignUrl = useCallback((campaign) => {
    if (!campaign) {
      return ``;
    }
    return `/projects/${currentProjectId}/tcr/brands/${campaign.brandId}/campaigns/${campaign.campaignId}`;
  }, [currentProjectId])

  const isChangingCampaignInProgress = (tn) => {
    if (!tn || !tn.addingToCampaignStatus) {
      return false;
    }

    if (tn.addingToCampaignStatus.indexOf("pending") > -1 || tn.addingToCampaignStatus.indexOf("failed") > -1 || tn.addingToCampaignStatus.indexOf("error") > -1) {
      return true;
    }

    return false;
  }

  const isChangingCampaignFailed = (tn) => {
    if (!tn || !tn.addingToCampaignStatus) {
      return false;
    }

    if (tn.addingToCampaignStatus.indexOf("failed") > -1 || tn.addingToCampaignStatus.indexOf("error") > -1) {
      return true;
    }

    return false;
  }

  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={isLoading} />
      {!isLoading && !isUpdating && !isDeleting && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
      {!isLoading && !isUpdating && !isDeleting && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
      {!isLoading && !isUpdating && !isDeleting && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
      {!isLoading &&
        <>
          <Row>
            <Col md={4}>
              <Label className="form-label">Telephone Number ID</Label>
              <p>{telephoneNumberDetails?.telephoneNumberId}</p>
            </Col>
            <Col md={4}>
              <Label className="form-label">E.164</Label>
              <p>{telephoneNumberDetails?.telephoneNumber}</p>
            </Col>
            <Col md={4}>
              <Label className="form-label">Type</Label>
              <p>{formatTNType(telephoneNumberDetails)}</p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label className="form-label">Status</Label>
              <p>
                <span className='text-uppercase h5'>
                  <Badge className={getTelephoneNumberBadgeCssClassName(telephoneNumberDetails) + " rounded-pill"}>
                    {telephoneNumberDetails?.status ? telephoneNumberDetails?.status.replaceAll("_", " ") : ""}
                  </Badge>
                </span>
              </p>
            </Col>
            <Col md={4}>
              <Label className="form-label">Location</Label>
              <p>{formatTNLocation(telephoneNumberDetails)}</p>
            </Col>
            <Col md={4}>
              <Label className="form-label">Cost</Label>
              <p>
                {!isNullOrUndefined(telephoneNumberDetails?.costAmount) &&
                  <span>{formatAmountWithCurrency(telephoneNumberDetails?.costAmount, telephoneNumberDetails?.costUnit, telephoneNumberDetails?.costCurrency)}/mo</span>
                }
                {isNullOrUndefined(telephoneNumberDetails?.costAmount) &&
                  <span>N/A</span>
                }
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Label className="form-label">Capabilities</Label>
              <p>
                {telephoneNumberDetails?.capabilities['voice'] &&
                  <i className="uil-phone-alt" title="Voice"></i>
                }
                &nbsp;
                {telephoneNumberDetails?.capabilities['sms'] &&
                  <i className="uil-comment-alt-message" title="SMS"></i>
                }
                &nbsp;
                {telephoneNumberDetails?.capabilities['mms'] &&
                  <i className="uil-comment-alt-image" title="MMS"></i>
                }
              </p>
            </Col>
            <Col md={4}>
              <Label className="form-label">Purchase date</Label>
              <p>{!telephoneNumberDetails?.purchasedTimestampMs ? '' : new Date(telephoneNumberDetails?.purchasedTimestampMs).toLocaleString()}</p>
            </Col>
            <Col md={4}>
              <Label className="form-label">Last billed on</Label>
              <p>{!telephoneNumberDetails?.lastChargedTimestampMs ? '' : new Date(telephoneNumberDetails.lastChargedTimestampMs).toLocaleString()}</p>
            </Col>
          </Row>
          {!isTollFree(telephoneNumberDetails) &&
            <Row className="mb-3">
              <Col md={12} >
                <Label className="form-label">10DLC campaign</Label>
                {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                {activeCampaignsList?.length > 0 && !selectedCampaignId && !isChangingCampaignInProgress(telephoneNumberDetails) &&
                  <UncontrolledDropdown direction="down">
                    <DropdownToggle tag="a" id="tn-campaign-selector" className="text-reset active" caret role="button" >
                      <span className={!selectedCampaignId ? 'text-danger' : ''}>{selectedCampaignId ? constructCampaignLabel(findCampaignByCampaignId(selectedCampaignId)) : <span>No 10DLC campaign assigned</span>}<i className="mdi mdi-chevron-down ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-start">
                      {activeCampaignsList.map((c, i) => <DropdownItem key={i} onClick={() => setSelectedCampaignId(c.campaignId)}><span>{constructCampaignLabel(c)}</span></DropdownItem>)}
                      {/*  THIS IS THE ANOTHER APPROACH TO DELETE THE CAMPAIGN.<DropdownItem onClick={() => setSelectedCampaignId(null)}><span> --- </span></DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                }
                {activeCampaignsList !== undefined && activeCampaignsList.length === 0 && <p>
                  <span className="text-danger">
                    <strong>You don't have an active 10DLC campaign</strong>
                  </span>
                </p>}
                {selectedCampaignId && !isChangingCampaignInProgress(telephoneNumberDetails) && <>
                  <div className='text-nowrap'>
                    <Link to={constructCampaignUrl(findCampaignByCampaignId(selectedCampaignId))} target="_blank">{constructCampaignLabel(findCampaignByCampaignId(selectedCampaignId))}</Link>
                    {" "}
                    <button
                      className="btn btn-link text-danger fw-bold without-outline"
                      type="button"
                      title="Unassign"
                      id="btn-unassign-campaign"
                      onClick={() => setSelectedCampaignId(null)}
                    ><i className="uil uil-times" />
                    </button>
                    <UncontrolledTooltip
                      placement="top"
                      target="btn-unassign-campaign"
                    >
                      Unassign this 10DLC campaign from {telephoneNumberDetails?.telephoneNumber}
                    </UncontrolledTooltip>
                  </div>
                </>}
                {selectedCampaignId && isChangingCampaignInProgress(telephoneNumberDetails) && <div>
                  {!isChangingCampaignFailed(telephoneNumberDetails) && <span className="text-info">
                    {/*{telephoneNumberDetails?.addingToCampaignStatus === 'pending_deleting' ? 'Removing' : 'Assigning' }{' '}<Link to={constructCampaignUrl(findCampaignByCampaignId(selectedCampaignId))} target="_blank">{constructCampaignLabel(findCampaignByCampaignId(selectedCampaignId))}</Link>{' ...'}*/}
                    <Spinner size="sm me-1" />{' '}{telephoneNumberDetails?.addingToCampaignStatus === 'pending_deleting' ? 'Removing' : 'Assigning'}{' '}{constructCampaignLabel(findCampaignByCampaignId(selectedCampaignId))}
                  </span>}
                  {isChangingCampaignFailed(telephoneNumberDetails) && <span className="text-danger">
                    There was an error while processing {constructCampaignLabel(findCampaignByCampaignId(selectedCampaignId))}. We are notified and are working on fixing the issue.
                  </span>}
                </div>}
              </Col>
            </Row>
          }
          <Row className="mb-3">
            <Col>
              <div className="d-flex flex-wrap gap-3 mt-3">
                {!isTollFree(telephoneNumberDetails) &&
                  <Button
                    type="submit"
                    color="primary"
                    className={telephoneNumberDetails?.campaignId === (selectedCampaignId || null) ? "w-md text-nowrap disabled" : "w-md text-nowrap"}
                    onClick={onUpdateNumberClick}
                  >
                    {isUpdating && <>
                      <Spinner size="sm me-1" color="light" />
                    </>}
                    {!isUpdating && <>
                      Save changes
                    </>}
                  </Button>
                }
                <Button
                  type="reset"
                  color="danger"
                  outline
                  className='w-md'
                  onClick={onDeleteNumberClick}
                >
                  {isDeleting && <>
                    <Spinner size="sm me-1" color="danger" />
                  </>}
                  {!isDeleting && <>
                    Release
                  </>}
                </Button>
              </div>
            </Col>
          </Row>
        </>
      }
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

NumberInfo.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(NumberInfo)));