import PropTypes from 'prop-types';
import React from 'react';
import { withRouter} from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Row, Col, Label, Badge } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage } from '../../helpers/utils';

import { getTollFreeVerificationStatusBadgeCssClassName } from './utils';

const TollFreeVerificationInfo = (props) => {

  let {
    tollFreeVerification,
  } = props;

  const isLoading = useSelector((state) => state.Numbers.loading);
  const apiError = useSelector((state) => state.Numbers.error);

  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={isLoading} />
      {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
      {!isLoading &&
        <div className="toll-free-verification-info">
          <Row>
            <Col md={6}>
              <Label className="form-label">Business name</Label>
              <p>{tollFreeVerification?.businessName}</p>
            </Col>
            <Col md={6}>
              <Label className="form-label">Corporate website</Label>
              <p>{tollFreeVerification?.corporateWebsite}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="form-label">Status</Label>
              <p>
                <span className='text-uppercase h5'>
                  <Badge className={getTollFreeVerificationStatusBadgeCssClassName(tollFreeVerification) + " rounded-pill"}>
                    {tollFreeVerification?.verificationStatus ? tollFreeVerification?.verificationStatus.replaceAll("_", " ") : ""}
                  </Badge>
                </span>
              </p>
            </Col>
            <Col md={6}>
              <Label className="form-label">Request submitted on</Label>
              <p>{!tollFreeVerification?.createdTimestampMs ? '' : new Date(tollFreeVerification?.createdTimestampMs).toLocaleString()}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="form-label">First name</Label>
              <p>{tollFreeVerification?.firstName}</p>
            </Col>
            <Col md={6}>
              <Label className="form-label">Last name</Label>
              <p>{tollFreeVerification?.lastName}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="form-label">Email</Label>
              <p>{tollFreeVerification?.email}</p>
            </Col>
            <Col md={6}>
              <Label className="form-label">Phone number</Label>
              <p>{tollFreeVerification?.phoneNumber}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="form-label">Address 1</Label>
              <p>{tollFreeVerification?.address1}</p>
            </Col>
            <Col md={6}>
              <Label className="form-label">Address 2</Label>
              <p>{tollFreeVerification?.address2}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="form-label">State</Label>
              <p>{tollFreeVerification?.stateName}</p>
            </Col>
            <Col md={6}>
              <Label className="form-label">ZIP code</Label>
              <p>{tollFreeVerification?.zipCode}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="form-label">City</Label>
              <p>{tollFreeVerification?.city}</p>
            </Col>
            <Col md={6}>
              <Label className="form-label">Country</Label>
              <p>{tollFreeVerification?.country}</p>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label className="form-label">Monthly volume</Label>
              <p>{tollFreeVerification?.monthlyVolume}</p>
            </Col>
            <Col md={6}>
              <Label className="form-label">Use case</Label>
              <p>{tollFreeVerification?.useCase}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label className="form-label">Use case summary</Label>
              <p>{tollFreeVerification?.useCaseSummary}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label className="form-label">Message content</Label>
              <p>{tollFreeVerification?.messageContent}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label className="form-label">Opt-in workflow</Label>
              <p>{tollFreeVerification?.optInWorkflow}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label className="form-label">Opt-in workflow image URL</Label>
              <p>{tollFreeVerification?.optInWorkflowImageUrl}</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label className="form-label">Additional use case details</Label>
              <p>{tollFreeVerification?.additionalUseCaseDetails}</p>
            </Col>
          </Row>
        </div>
      }
    </React.Fragment>
  )
}

TollFreeVerificationInfo.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(TollFreeVerificationInfo)));