import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getContactGroupsForProject,
  getContactGroupDetails,
  updateContactGroup,
  deleteContactGroups
} from "../../store/actions";

import { constructErrorMessage, clearHistoryState } from '../../helpers/utils';

const ContactsGroupInfo = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const contactGroupDetails = useSelector((state) => state.Contact.contactGroupDetails);
  const isLoading = useSelector((state) => state.Contact.loading);
  const isUpdating = useSelector((state) => state.Contact.updating);
  const isDeleting = useSelector((state) => state.Contact.deleting);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Contact.error);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  // getting contactGroupId fromURL
  const { contactGroupId: currentContactGroupId } = useParams();

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getContactGroupsForProject(currentProjectId, null, null));
    }

    if (currentProjectId && currentContactGroupId) {
      dispatch(getContactGroupDetails(currentProjectId, currentContactGroupId));
    }
  }, [dispatch, currentProjectId, currentContactGroupId]);

  useEffect(() => {

    if (redirectStatus === 'create-contacts-group-succeeded') {
      setSuccessMessage("Contacts group was successfully created");
    }

    if (redirectStatus === 'update-contacts-group-succeeded') {
      setSuccessMessage("Contacts group was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }


    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  const onDeleteContactGroupClick = () => {
    setConfirmDialogText(`Are you sure you want to delete this group? Contacts of this group will not be deleted.`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteContactGroups(currentProjectId, [currentContactGroupId], () => {
        history.push(`/projects/${currentProjectId}/directory/contacts/groups`, { redirectStatus: `delete-contacts-group-succeeded` });
      }));
    });

    setIsConfirmDialogOpen(true);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: contactGroupDetails?.title,
      description: contactGroupDetails?.description,
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please enter group title"),
    }),
    onSubmit: async (values) => {
      dispatch(updateContactGroup(
        currentContactGroupId,
        currentProjectId,
        values.title,
        values.description,
        () => history.push(`/projects/${currentProjectId}/directory/contacts/groups/${currentContactGroupId}`, { redirectStatus: `update-contacts-group-succeeded` })))
    }
  });

  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={isLoading} />
      {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
      {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
      {!isLoading && contactGroupDetails &&
        <>
          <Form
            className="form-group add-contact-group-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="mb-3">
              <Col className="col-8">
                <Label className="form-label">Group title</Label>
                <InputGroup>
                  <Input
                    name="title"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.title || ""}
                    invalid={
                      validation.touched.title && validation.errors.title ? true : false
                    }
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">{validation.errors.title}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
              <Col className="col-4">
                <Label className="form-label">Created Date</Label>
                <p>{!contactGroupDetails?.createdTimestampMs ? '-' : new Date(contactGroupDetails?.createdTimestampMs).toLocaleString()}</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-8">
                <Label className="form-label">Description</Label>
                <InputGroup>
                  <Input
                    name="description"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.description || ""}
                    invalid={
                      validation.touched.description && validation.errors.description ? true : false
                    }
                  />
                  {validation.touched.description && validation.errors.description ? (
                    <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-8">
                {/* aligning all buttons to the left except the last one. see https://stackoverflow.com/questions/58812566/align-all-item-to-left-except-last-in-a-flexbox-container */}
                <div className="d-flex flex-wrap flex-row gap-3 mt-3">
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md text-nowrap"
                  >
                    {isUpdating && <>
                      <Spinner size="sm me-1" color="light" />
                    </>}
                    {!isUpdating && <>
                      Update
                    </>}
                  </Button>
                  <Button
                    type="reset"
                    color="danger"
                    outline
                    className="w-md text-nowrap ms-auto"
                    onClick={onDeleteContactGroupClick}
                  >
                    {isDeleting && <>
                      <Spinner size="sm me-1" color="danger" />
                    </>}
                    {!isDeleting && <>
                      Delete
                    </>}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      }
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

ContactsGroupInfo.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ContactsGroupInfo)));