import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useLocation } from "react-router-dom"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions"

// import images
import loginLogo from "../../assets/images/logo-1.png";

const ForgetPasswordPage = props => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please enter your email"),
    }),
    onSubmit: (values) => {
      dispatch(userForgetPassword(values, props.history));
    }
  });


  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });
  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));


  return (
    <React.Fragment>
      <div className="account-pages my-5  pt-sm-5">
        <Container>
          <Row className="justify-content-center">

            <Col md={8} lg={6} xl={5}>
              <div>

                <a href="/" className="mb-5 d-block auth-logo">
                  <img src={loginLogo} alt="" height="44" className="logo logo-dark" />
                </a>
                <Card>

                  <CardBody className="p-4">

                    <div className="text-center mt-2">
                      <h5 className="text-primary">Reset Password</h5>
                      <p className="text-muted">Reset Password with TextSpaces.</p>
                    </div>
                    <div className="p-2 mt-4">
                      {forgetError && !forgetError?.includes('user-not-found') ? (
                        <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          Something went wrong.
                        </Alert>
                      ) : null}
                      {forgetSuccessMsg || forgetError?.includes('user-not-found') ? (
                        <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                          Reset link was sent to your mailbox.
                        </Alert>
                      ) : null}

                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>
                        <Row className="mb-0">
                          <Col xs={12} className="text-end">
                            <button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Reset
                            </button>
                          </Col>
                        </Row>
                        <div className="mt-4 text-center">
                          <p className="mb-0">Remember It ? <Link to={`/login${search}`} className="fw-medium text-primary"> Sign in </Link></p>
                        </div>
                      </Form>
                    </div>

                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>© {new Date().getFullYear()} Built by Corelink Telecom</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

export default withRouter(ForgetPasswordPage);