import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Table, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, clearHistoryState } from "../../helpers/utils";

import { telephoneNumberService } from "../../services";

const UnsubscribedTelephoneNumbers = () => {

  const history = useHistory();
  const location = useLocation();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [unsubscribedTNsList, setUnsubscribedTNsList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message
  const [isLoading, setIsLoading] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const getUnsubscribedTelephoneNumbers = useCallback(async (projectId) => {
    if (!projectId) {
      return;
    }
    try {
      setIsLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      let result = await telephoneNumberService.fetchUnsubscribedTelephoneNumbers(projectId);

      if (!result?.unsubscribedTelephoneNumbers) {
        throw new Error("no telephone numbers available");
      }

      setUnsubscribedTNsList(result?.unsubscribedTelephoneNumbers);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      getUnsubscribedTelephoneNumbers(currentProjectId);
    }
  }, [getUnsubscribedTelephoneNumbers, currentProjectId]);

  const dataRefresh = useCallback(() => { // this func is for naming consistency with other Refresh buttons
    initialDataLoad();
  }, [initialDataLoad]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  useEffect(() => {

    if (redirectStatus === 'create-attr-succeeded') {
      setSuccessMessage("Attribute was successfully created");
    }

    if (redirectStatus === 'delete-attr-succeeded') {
      setSuccessMessage("Attribute was successfully deleted");
    }

    if (redirectStatus === 'update-attr-succeeded') {
      setSuccessMessage("Attribute was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (isLoading === undefined || isLoading === true) {
      return undefined;
    }

    return Array.isArray(unsubscribedTNsList) && unsubscribedTNsList.length > 0;
  }, [isLoading, unsubscribedTNsList]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Unsubscribed Telephone Numbers" />
          <Card>
            <CardBody>
              {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
              {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
              <div className="actions clearfix">
                <ul>
                  <li>
                    <button
                      className="btn refresh without-outline"
                      type="button"
                      title="Refresh"
                      onClick={dataRefresh}
                    >
                      <i className='mdi mdi-reload h3' />
                    </button>
                  </li>
                </ul>
              </div>
              <GlobalProgressBar isLoading={isLoading} />
              <Row>
                <Col className="col-12">
                  {isDataAvailable() === false && <EmptyBlock>
                    <div className="text-center">
                      <p className="h3">No Numbers</p>
                      <p>Nobody has unsubscribed from your messages.</p>
                    </div>
                  </EmptyBlock>}
                  {isDataAvailable() && <Table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th>Number</th>
                        <th>Unsubscribed date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unsubscribedTNsList.map((tn, i) => <tr key={i} className="align-middle">
                        <td>
                          {tn?.telephoneNumber}
                        </td>
                        <td>{!tn.unsubscribedTimestampMs ? '' : new Date(tn.unsubscribedTimestampMs).toLocaleString()}</td>
                      </tr>)
                      }
                    </tbody>
                  </Table>}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

UnsubscribedTelephoneNumbers.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(UnsubscribedTelephoneNumbers)));