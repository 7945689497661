import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthAction from "../pages/Authentication/AuthAction"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"


////// TextSpaces pages

import Tokens from "../pages/API/Tokens";
import AddToken from "../pages/API/AddToken";
import TokenDetails from "../pages/API/TokenDetails";
import ProjectBalance from "../pages/Billing/ProjectBalance";
import TopUpBalance from "../pages/Billing/TopUpBalance";
import PaymentMethodsTable from "../pages/Billing/PaymentMethodsTable";
import AddPaymentMethod from "../pages/Billing/AddPaymentMethod";
import PaymentsHistory from "../pages/Billing/PaymentsHistory";
import ProfileDetails from "../pages/Profile/ProfileDetails";
import InboundMessageWebHook from "../pages/Settings/InboundMessageWebHook";
import Numbers from "../pages/Numbers/Numbers";
import NumberDetails from "../pages/Numbers/NumberDetails";
import AddTollFreeVerification from "../pages/Numbers/AddTollFreeVerification";
import TollFreeVerifications from "../pages/Numbers/TollFreeVerifications";
import TollFreeVerificationDetails from "../pages/Numbers/TollFreeVerificationDetails";
import UnsubscribedTelephoneNumbers from "../pages/Numbers/UnsubscribedTelephoneNumbers";
import SearchInventory from "../pages/Numbers/SearchInventory";
import Chats from "../pages/Chats/Chat";
import Events from "../pages/Events/Events";
import Messages from "../pages/Messages/Messages";
import MessageDetails from "../pages/Messages/MessageDetails";
import MessageGroups from "../pages/Messages/MessageGroups";
import MessageGroupDetails from "../pages/Messages/MessageGroupDetails";
import Contacts from "../pages/Directory/Contacts";
import AddContact from "../pages/Directory/AddContact";
import AdditionalContactAttributes from "../pages/Directory/AdditionalContactAttributes";
import AddAdditionalContactAttribute from "../pages/Directory/AddAdditionalContactAttribute";
import AdditionalContactAttributeDetails from "../pages/Directory/AdditionalContactAttributeDetails";
import ContactDetails from "../pages/Directory/ContactDetails";
import ContactsGroups from "../pages/Directory/ContactsGroups";
import ContactsGroupDetails from "../pages/Directory/ContactsGroupDetails";
import AddContactGroup from "../pages/Directory/AddContactGroup";
import ContactsImportTasks from "../pages/Directory/ContactsImportTasks";
import AddContactImportTask from "../pages/Directory/AddContactImportTask";
import ProjectDetails from "../pages/Settings/ProjectDetails";
import Brands from "../pages/Tcr/Brands";
import AddBrand from "../pages/Tcr/AddBrand";
import BrandDetails from "../pages/Tcr/BrandDetails";
import Campaigns from "../pages/Tcr/Campaigns";
import AddCampaign from "../pages/Tcr/AddCampaign";
import CampaignDetails from "../pages/Tcr/CampaignDetails";
import Users from "../pages/Settings/Users";
import InviteDetails from "../pages/InviteDetails";
import Compose from "../pages/Compose/Compose";
import MessagingReport from "../pages/Reporting/MessagingReport";
import SubscriptionsReport from "../pages/Reporting/SubscriptionsReport";
import Keywords from "../pages/Keywords/Keywords";
import AddKeyword from "../pages/Keywords/AddKeyword";
import KeywordDetails from "../pages/Keywords/KeywordDetails";


const userRoutes = [
  { path: "/dashboard", component: Dashboard },

  //// TextSpaces routes
  { path: "/projects/:projectId/dashboard", component: Dashboard },
  { path: "/projects/:projectId/api/tokens", component: Tokens },
  { path: "/projects/:projectId/api/tokens/add", component: AddToken },
  { path: "/projects/:projectId/api/tokens/:tokenId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: TokenDetails }, // matching only GUID. Otherwise "/tokens/add" will be matched as well
  { path: "/projects/:projectId/billing/balance", component: ProjectBalance },
  { path: "/projects/:projectId/billing/balance/top-up", component: TopUpBalance },
  { path: "/projects/:projectId/billing/payment-methods", component: PaymentMethodsTable },
  { path: "/projects/:projectId/billing/payment-methods/add", component: AddPaymentMethod },
  { path: "/projects/:projectId/billing/payments-history", component: PaymentsHistory },
  { path: "/projects/:projectId/compose", component: Compose },
  { path: "/projects/:projectId/settings/project-details", component: ProjectDetails },
  { path: "/projects/:projectId/settings/inbound-message-webhook", component: InboundMessageWebHook },
  { path: "/projects/:projectId/settings/users", component: Users },
  { path: "/projects/:projectId/numbers", component: Numbers },
  { path: "/projects/:projectId/numbers/:telephoneNumberId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: NumberDetails }, // matching only GUID. Otherwise "/numbers/search" will be matched as well
  { path: "/projects/:projectId/numbers/search", component: SearchInventory },
  { path: "/projects/:projectId/tcr/brands", component: Brands },
  { path: "/projects/:projectId/tcr/brands/add", component: AddBrand },
  { path: "/projects/:projectId/tcr/brands/:brandId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: BrandDetails }, // matching only GUID. Otherwise "/brands/add" will be matched as well
  { path: "/projects/:projectId/tcr/campaigns", component: Campaigns },
  { path: "/projects/:projectId/tcr/campaigns/add", component: AddCampaign },
  { path: "/projects/:projectId/tcr/brands/:brandId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})/campaigns/:campaignId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: CampaignDetails }, // matching only GUID. Otherwise "/campaigns/add" will be matched as well
  { path: "/projects/:projectId/events", component: Events },
  { path: "/projects/:projectId/messages", component: Messages },
  { path: "/projects/:projectId/messages/groups", component: MessageGroups },
  { path: "/projects/:projectId/chats", component: Chats },
  { path: "/projects/:projectId/messages/:direction(outbound)", component: Messages }, /* :direction(outbound) guarantees only "outbound" as a value for direction param. It's a regexp with one value equal "outbound" */
  { path: "/projects/:projectId/messages/:direction(inbound)", component: Messages }, /* :direction(inbound) guarantees only "inbound" as a value for direction param. It's a regexp with one value equal "inbound" */
  { path: "/projects/:projectId/messages/:messageId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: MessageDetails }, // matching only GUID. Otherwise "/message/outbound" will be matched as well
  { path: "/projects/:projectId/messages/groups/:direction(outbound)", component: MessageGroups }, /* :direction(outbound) guarantees only "outbound" as a value for direction param. It's a regexp with one value equal "outbound" */
  { path: "/projects/:projectId/messages/groups/:direction(inbound)", component: MessageGroups }, /* :direction(inbound) guarantees only "inbound" as a value for direction param. It's a regexp with one value equal "inbound" */
  { path: "/projects/:projectId/messages/groups/:messageGroupId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: MessageGroupDetails }, // matching only GUID. Otherwise "/message/outbound" will be matched as well
  { path: "/projects/:projectId/directory/contacts", component: Contacts },
  { path: "/projects/:projectId/directory/contacts/:contactId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: ContactDetails }, // matching only GUID. Otherwise "/contacts/add" will be matched as well
  { path: "/projects/:projectId/directory/contacts/add", component: AddContact },
  { path: "/projects/:projectId/directory/contacts/attributes", component: AdditionalContactAttributes },
  { path: "/projects/:projectId/directory/contacts/attributes/add", component: AddAdditionalContactAttribute },
  { path: "/projects/:projectId/directory/contacts/attributes/:attributeId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: AdditionalContactAttributeDetails },
  { path: "/projects/:projectId/directory/contacts/groups", component: ContactsGroups },
  { path: "/projects/:projectId/directory/contacts/groups/add", component: AddContactGroup },
  { path: "/projects/:projectId/directory/contacts/groups/:contactGroupId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: ContactsGroupDetails }, // matching only GUID. Otherwise "/contacts/groups/add" will be matched as well
  { path: "/projects/:projectId/directory/contacts/imports", component: ContactsImportTasks },
  { path: "/projects/:projectId/directory/contacts/imports/add", component: AddContactImportTask },
  { path: "/projects/:projectId/toll-free-verifications", component: TollFreeVerifications },
  { path: "/projects/:projectId/toll-free-verifications/add", component: AddTollFreeVerification },
  { path: "/projects/:projectId/toll-free-verifications/:verificationId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: TollFreeVerificationDetails },
  { path: "/projects/:projectId/reports/messaging", component: MessagingReport },
  { path: "/projects/:projectId/reports/subscriptions", component: SubscriptionsReport },
  { path: "/projects/:projectId/keywords", component: Keywords },
  { path: "/projects/:projectId/keywords/add", component: AddKeyword },
  { path: "/projects/:projectId/keywords/:keywordId([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})", component: KeywordDetails }, // matching only GUID. Otherwise "/keywords/add" will be matched as well
  { path: "/projects/:projectId/unsubscribed", component: UnsubscribedTelephoneNumbers },
  { path: "/profile", component: ProfileDetails },

  { path: "/invites/:inviteId", component: InviteDetails },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
];

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/auth/action", component: AuthAction },


  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
]

export { userRoutes, authRoutes }