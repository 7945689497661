import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, Table } from "reactstrap";

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { EventDescription } from "../Events/EventDescription";

import { constructErrorMessage, isNullOrUndefined, formatAmountWithCurrency } from "../../helpers/utils";

import {
  eventService
} from "../../services/event-service";

import "./dashboard.scss";

const LatestEventsCard = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [eventsList, setEventsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const apiError = useSelector((state) => state.Event.error);

  // WE CANNOT USE EVENTS REDUX STORE NOT TO INTERFERE WITH "EVENTS" PAGE
  const loadRecentEvents = useCallback(async () => {
    if (!currentProjectId) {
      return;
    }
    const pageSize = 5;

    setIsLoading(true);
    let l = await eventService.fetchEvents(currentProjectId, pageSize, 0);
    setEventsList(l?.events);
    setIsLoading(false);
  }, [currentProjectId]);

  useEffect(() => {
    loadRecentEvents();
  }, [loadRecentEvents]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle>Recent Events</CardTitle>
          <GlobalProgressBar isLoading={isLoading} />
          {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
          {!isLoading &&
            <>
              <Table className="table-striped table-responsive">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Balance change</th>
                    <th>Event date</th>
                  </tr>
                </thead>
                <tbody>
                  {(!eventsList || !Array.isArray(eventsList) || eventsList.length === 0) && <tr><td colSpan={3} className="text-center">No events yet</td></tr>}
                  {
                    eventsList && Array.isArray(eventsList) && eventsList.length > 0 && eventsList.map((e, i) => <tr key={i} className={(e && (e?.eventType.indexOf("failed") > -1 || e?.eventType.indexOf("undelivered") > -1)) ? "table-danger align-middle" : "align-middle"}>
                      <td>
                        <EventDescription event={e} />
                      </td>
                      <td>
                        {!isNullOrUndefined(e.balanceChangeAmount) &&
                          <span>{formatAmountWithCurrency(e.balanceChangeAmount, e.balanceChangeUnit, e.balanceChangeCurrency)}</span>
                        }
                        {isNullOrUndefined(e.balanceChangeAmount) &&
                          <span>N/A</span>
                        }
                      </td>
                      <td>{!e.createdTimestampMs ? '' : new Date(e.createdTimestampMs).toLocaleString()}</td>
                    </tr>)
                  }
                </tbody>
              </Table>
              <p className="text-muted mt-3 mb-0 text-nowrap text-end">
                <Link className="dashboard-report-link" to={`/projects/${currentProjectId}/events`}><>ALL EVENTS <i className="mdi mdi-arrow-right"></i></></Link>
              </p>
            </>
          }
        </CardBody>
      </Card>

    </React.Fragment>
  );
};

export default LatestEventsCard;