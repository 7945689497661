import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Label, Button, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { tcrBrandEntityTypesCodes, getMapKeyByValue, getTcrVerticals, textOrEmpty } from '../../helpers/utils';

import { constructBrandIdentityStatus } from './utils';

import { constructErrorMessage, clearHistoryState } from "../../helpers/utils";

import { constantsService } from "../../services";

import {
  deleteBrand,
  getBrandDetails,
} from "../../store/actions";

const BrandDetails = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  // getting brandId fromURL
  const { brandId: currentBrandId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const brandDetails = useSelector((state) => state.Tcr?.brandDetails);
  const [countriesMap, setCountriesMap] = useState(new Map());

  const [isEnumsLoading, setIsEnumsLoading] = useState(false);
  const isLoading = useSelector((state) => state.Tcr.loading);
  const isDeleting = useSelector((state) => state.Tcr.deleting);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Tcr.error);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const getConstantEnums = useCallback(async () => {
    try {
      setIsEnumsLoading(true);
      let countriesResult = await constantsService.fetchCountriesMap();

      setCountriesMap(countriesResult);

    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsEnumsLoading(false);
    }
  }, []);

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    if (redirectStatus === 'create-brand-succeeded') {
      setSuccessMessage("Brand was successfully registered");
    }

    if (redirectStatus === 'update-brand-succeeded') {
      setSuccessMessage("Brand was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  useEffect(() => {
    if (currentProjectId && currentBrandId) {
      dispatch(getBrandDetails(currentProjectId, currentBrandId));
      getConstantEnums();
    }
  }, [dispatch, getConstantEnums, currentProjectId, currentBrandId]);


  const onDeleteBrandClick = () => {
    setConfirmDialogText(`Are you sure you want to deregister this brand?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteBrand(currentProjectId, currentBrandId, () => {
        history.push(`/projects/${currentProjectId}/tcr/brands`, { redirectStatus: `delete-brand-succeeded` });
      }));
    });

    setIsConfirmDialogOpen(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Registered Brands"
            pageTitle = "Brand Details"
            titleUrl={`/projects/${currentProjectId}/tcr/brands`}
            breadcrumbItem={brandDetails?.displayName ?? brandDetails?.companyName} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && !isEnumsLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
                  {!isEnumsLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading && !isEnumsLoading &&
                    <>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Brand ID</Label>
                          <p>{brandDetails?.brandId}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">TCR brand ID</Label>
                          <p>{textOrEmpty(brandDetails?.tcrBrandId)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Status</Label>
                          <h5 className='text-uppercase'>{constructBrandIdentityStatus(brandDetails?.identityStatus)}</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Company name</Label>
                          <p>{brandDetails?.displayName ?? brandDetails?.companyName}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Entity type</Label>
                          <p>{getMapKeyByValue(tcrBrandEntityTypesCodes, brandDetails?.entityType)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Submitted on</Label>
                          <p>{!brandDetails?.createdTimestampMs ? '' : new Date(brandDetails.createdTimestampMs).toLocaleString()}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Address/Street</Label>
                          <p>{textOrEmpty(brandDetails?.street)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">City</Label>
                          <p>{textOrEmpty(brandDetails?.city)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">State/Region</Label>
                          <p>{textOrEmpty(brandDetails?.state)}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Country</Label>
                          <p>{getMapKeyByValue(countriesMap, brandDetails?.country)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Postal code</Label>
                          <p>{textOrEmpty(brandDetails?.postalCode)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Website</Label>
                          <p>{textOrEmpty(brandDetails?.website)}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={brandDetails?.entityType === 'PUBLIC_PROFIT' ? 4 : 12}>
                          <Label className="form-label">Vertical</Label>
                          <p>{getMapKeyByValue(getTcrVerticals(), brandDetails?.vertical)}</p>
                        </Col>
                        {brandDetails?.entityType === 'PUBLIC_PROFIT' &&
                          <>
                            <Col md={4}>
                              <Label className="form-label">Stock symbol</Label>
                              <p>{textOrEmpty(brandDetails?.stockSymbol)}</p>
                            </Col>
                            <Col md={4}>
                              <Label className="form-label">Stock exchange</Label>
                              <p>{textOrEmpty(brandDetails?.stockExchange)}</p>
                            </Col>
                          </>
                        }
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Label className="form-label">Support phone number</Label>
                          <p>{textOrEmpty(brandDetails?.phone)}</p>
                        </Col>
                        <Col md={4}>
                          <Label className="form-label">Support email</Label>
                          <p>{textOrEmpty(brandDetails?.email)}</p>
                        </Col>
                        <Col md={4}>
                        </Col>
                      </Row>
                      {brandDetails?.tcrBrandId && <Row className="mb-3">
                        <div className="d-flex flex-wrap gap-3 mt-3">
                          <Button
                            type="reset"
                            color="danger"
                            outline
                            className="w-md"
                            onClick={onDeleteBrandClick}
                          >{isDeleting && <>
                            <Spinner size="sm me-1" color="danger" />
                          </>}
                            {!isDeleting && <>
                              Deregister brand
                            </>}
                          </Button>
                        </div>
                      </Row>}
                      <Row className="mb-3">
                        <p><small>{props.t("Updating brand properties is limited due to the regulatory restrictions. Please open a support ticket in case you need to update your brand info.")}</small></p>
                      </Row>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

BrandDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  tokens: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(BrandDetails)));