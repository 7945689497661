import { all, fork, call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  GET_MESSAGES_FOR_PROJECT_REQUEST,
  GET_MESSAGE_DETAILS_REQUEST,
  GET_MESSAGE_GROUPS_FOR_PROJECT_REQUEST,
  GET_MESSAGE_GROUP_DETAILS_REQUEST,
  GET_MESSAGE_GROUP_DETAILS_NO_LOADING_REQUEST,
} from "./actionTypes";
import {
  getMessagesForProjectSuccess,
  getMessageDetailsSuccess,
  getMessageGroupsForProjectSuccess,
  getMessageGroupDetailsSuccess,
  messageApiError
} from "./actions";

//Include Both Helper File with needed methods
import { messageService } from "../../services";

function* getMessages({ payload: {projectId, direction, limit, offset} }) {
  try {
    const response = yield call(
      messageService.fetchMessages,
      projectId,
      direction,
      limit,
      offset
    )
    yield put(getMessagesForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(messageApiError(error));
  }
}

function* getMessageDetails({ payload: { projectId, messageId } }) {
  try {
    const response = yield call(
      messageService.fetchMessageDetails,
      projectId,
      messageId
    )
    yield put(getMessageDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(messageApiError(error));
  }
}

function* getMessageGroups({ payload: {projectId, direction, limit, offset} }) {
  try {
    const response = yield call(
      messageService.fetchMessageGroups,
      projectId,
      direction,
      limit,
      offset
    )
    yield put(getMessageGroupsForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(messageApiError(error));
  }
}

function* getMessageGroupDetails({ payload: { projectId, messageGroupId } }) {
  try {
    const response = yield call(
      messageService.fetchMessageGroupDetails,
      projectId,
      messageGroupId
    )
    yield put(getMessageGroupDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(messageApiError(error));
  }
}

function* watchMessagesRequests() {
  yield takeEvery(GET_MESSAGES_FOR_PROJECT_REQUEST, getMessages)
}

function* watchGetMessageDetailsRequests() {
  yield takeEvery(GET_MESSAGE_DETAILS_REQUEST, getMessageDetails)
}

function* watchMessageGroupsRequests() {
  yield takeEvery(GET_MESSAGE_GROUPS_FOR_PROJECT_REQUEST, getMessageGroups)
}

function* watchGetMessageGroupDetailsRequests() {
  yield takeEvery(GET_MESSAGE_GROUP_DETAILS_REQUEST, getMessageGroupDetails)
}

function* watchGetMessageGroupDetailsNoLoadingRequests() {
  yield takeEvery(GET_MESSAGE_GROUP_DETAILS_NO_LOADING_REQUEST, getMessageGroupDetails)
}

function* messageSaga() {
  yield all([
    fork(watchMessagesRequests),
    fork(watchGetMessageDetailsRequests),
    fork(watchMessageGroupsRequests),
    fork(watchGetMessageGroupDetailsRequests),
    fork(watchGetMessageGroupDetailsNoLoadingRequests),
  ]);
}

export default messageSaga;
