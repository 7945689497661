
export const usStates = new Map([
  ['AL', 'Alabama'],
  ['AK', 'Alaska'],
  ['AZ', 'Arizona'],
  ['AR', 'Arkansas'],
  ['CA', 'California'],
  ['CO', 'Colorado'],
  ['CT', 'Connecticut'],
  ['DE', 'Delaware'],
  ['DC', 'Washington DC'],
  ['FL', 'Florida'],
  ['GA', 'Georgia'],
  ['HI', 'Hawaii'],
  ['ID', 'Idaho'],
  ['IL', 'Illinois'],
  ['IN', 'Indiana'],
  ['IA', 'Iowa'],
  ['KS', 'Kansas'],
  ['KY', 'Kentucky'],
  ['LA', 'Louisiana'],
  ['ME', 'Maine'],
  ['MD', 'Maryland'],
  ['MA', 'Massachusetts'],
  ['MI', 'Michigan'],
  ['MN', 'Minnesota'],
  ['MS', 'Mississippi'],
  ['MO', 'Missouri'],
  ['MT', 'Montana'],
  ['NE', 'Nebraska'],
  ['NV', 'Nevada'],
  ['NH', 'New Hampshire'],
  ['NJ', 'New Jersey'],
  ['NM', 'New Mexico'],
  ['NY', 'New York'],
  ['NC', 'North Carolina'],
  ['ND', 'North Dakota'],
  ['OH', 'Ohio'],
  ['OK', 'Oklahoma'],
  ['OR', 'Oregon'],
  ['PA', 'Pennsylvania'],
  ['PR', 'Puerto Rico'],
  ['RI', 'Rhode Island'],
  ['SC', 'South Carolina'],
  ['SD', 'South Dakota'],
  ['TN', 'Tennessee'],
  ['TX', 'Texas'],
  ['UT', 'Utah'],
  ['VT', 'Vermont'],
  ['VI', 'Virgin Islands'],
  ['VA', 'Virginia'],
  ['WA', 'Washington'],
  ['WV', 'West Virginia'],
  ['WI', 'Wisconsin'],
  ['WY', 'Wyoming'],
]);


export const caStates = new Map([
  ['AB', 'Alberta'],
  ['BC', 'British Columbia'],
  ['MB', 'Manitoba'],
  ['NB', 'New Brunswick'],
  ['NL', 'Newfoundland and Labrador'],
  ['NT', 'Northwest Territories'],
  ['NS', 'Nova Scotia'],
  ['NU', 'Nunavut'],
  ['ON', 'Ontario'],
  ['PE', 'Prince Edward Island'],
  ['QC', 'Quebec'],
  ['SK', 'Saskatchewan'],
  ['YT', 'Yukon'],
]);

/* // NOT USED ANYMORE, BUT LET'S KEEP IT
export const countriesCodes = new Map([
  ['United States of America', 'US'],
  ['Afghanistan', 'AF'],
  ['Åland Islands', 'AX'],
  ['Albania', 'AL'],
  ['Algeria', 'DZ'],
  ['American Samoa', 'AS'],
  ['Andorra', 'AD'],
  ['Angola', 'AO'],
  ['Anguilla', 'AI'],
  ['Antarctica', 'AQ'],
  ['Antigua and Barbuda', 'AG'],
  ['Argentina', 'AR'],
  ['Armenia', 'AM'],
  ['Aruba', 'AW'],
  ['Australia', 'AU'],
  ['Austria', 'AT'],
  ['Azerbaijan', 'AZ'],
  ['Bahamas', 'BS'],
  ['Bahrain', 'BH'],
  ['Bangladesh', 'BD'],
  ['Barbados', 'BB'],
  ['Belarus', 'BY'],
  ['Belgium', 'BE'],
  ['Belize', 'BZ'],
  ['Benin', 'BJ'],
  ['Bermuda', 'BM'],
  ['Bhutan', 'BT'],
  ['Bolivia (Plurinational State of)', 'BO'],
  ['Bonaire, Sint Eustatius and Saba', 'BQ'],
  ['Bosnia and Herzegovina', 'BA'],
  ['Botswana', 'BW'],
  ['Bouvet Island', 'BV'],
  ['Brazil', 'BR'],
  ['British Indian Ocean Territory', 'IO'],
  ['Brunei Darussalam', 'BN'],
  ['Bulgaria', 'BG'],
  ['Burkina Faso', 'BF'],
  ['Burundi', 'BI'],
  ['Cabo Verde', 'CV'],
  ['Cambodia', 'KH'],
  ['Cameroon', 'CM'],
  ['Canada', 'CA'],
  ['Cayman Islands', 'KY'],
  ['Central African Republic', 'CF'],
  ['Chad', 'TD'],
  ['Chile', 'CL'],
  ['China', 'CN'],
  ['Christmas Island', 'CX'],
  ['Cocos (Keeling) Islands', 'CC'],
  ['Colombia', 'CO'],
  ['Comoros', 'KM'],
  ['Congo', 'CG'],
  ['Congo, Democratic Republic of the', 'CD'],
  ['Cook Islands', 'CK'],
  ['Costa Rica', 'CR'],
  ['Côte d\'Ivoire', 'CI'],
  ['Croatia', 'HR'],
  ['Cuba', 'CU'],
  ['Curaçao', 'CW'],
  ['Cyprus', 'CY'],
  ['Czechia', 'CZ'],
  ['Denmark', 'DK'],
  ['Djibouti', 'DJ'],
  ['Dominica', 'DM'],
  ['Dominican Republic', 'DO'],
  ['Ecuador', 'EC'],
  ['Egypt', 'EG'],
  ['El Salvador', 'SV'],
  ['Equatorial Guinea', 'GQ'],
  ['Eritrea', 'ER'],
  ['Estonia', 'EE'],
  ['Eswatini', 'SZ'],
  ['Ethiopia', 'ET'],
  ['Falkland Islands (Malvinas)', 'FK'],
  ['Faroe Islands', 'FO'],
  ['Fiji', 'FJ'],
  ['Finland', 'FI'],
  ['France', 'FR'],
  ['French Guiana', 'GF'],
  ['French Polynesia', 'PF'],
  ['French Southern Territories', 'TF'],
  ['Gabon', 'GA'],
  ['Gambia', 'GM'],
  ['Georgia', 'GE'],
  ['Germany', 'DE'],
  ['Ghana', 'GH'],
  ['Gibraltar', 'GI'],
  ['Greece', 'GR'],
  ['Greenland', 'GL'],
  ['Grenada', 'GD'],
  ['Guadeloupe', 'GP'],
  ['Guam', 'GU'],
  ['Guatemala', 'GT'],
  ['Guernsey', 'GG'],
  ['Guinea', 'GN'],
  ['Guinea-Bissau', 'GW'],
  ['Guyana', 'GY'],
  ['Haiti', 'HT'],
  ['Heard Island and McDonald Islands', 'HM'],
  ['Holy See', 'VA'],
  ['Honduras', 'HN'],
  ['Hong Kong', 'HK'],
  ['Hungary', 'HU'],
  ['Iceland', 'IS'],
  ['India', 'IN'],
  ['Indonesia', 'ID'],
  ['Iran (Islamic Republic of)', 'IR'],
  ['Iraq', 'IQ'],
  ['Ireland', 'IE'],
  ['Isle of Man', 'IM'],
  ['Israel', 'IL'],
  ['Italy', 'IT'],
  ['Jamaica', 'JM'],
  ['Japan', 'JP'],
  ['Jersey', 'JE'],
  ['Jordan', 'JO'],
  ['Kazakhstan', 'KZ'],
  ['Kenya', 'KE'],
  ['Kiribati', 'KI'],
  ['Korea (Democratic People\'s Republic of)', 'KP'],
  ['Korea, Republic of', 'KR'],
  ['Kuwait', 'KW'],
  ['Kyrgyzstan', 'KG'],
  ['Lao People\'s Democratic Republic', 'LA'],
  ['Latvia', 'LV'],
  ['Lebanon', 'LB'],
  ['Lesotho', 'LS'],
  ['Liberia', 'LR'],
  ['Libya', 'LY'],
  ['Liechtenstein', 'LI'],
  ['Lithuania', 'LT'],
  ['Luxembourg', 'LU'],
  ['Macao', 'MO'],
  ['Madagascar', 'MG'],
  ['Malawi', 'MW'],
  ['Malaysia', 'MY'],
  ['Maldives', 'MV'],
  ['Mali', 'ML'],
  ['Malta', 'MT'],
  ['Marshall Islands', 'MH'],
  ['Martinique', 'MQ'],
  ['Mauritania', 'MR'],
  ['Mauritius', 'MU'],
  ['Mayotte', 'YT'],
  ['Mexico', 'MX'],
  ['Micronesia (Federated States of)', 'FM'],
  ['Moldova, Republic of', 'MD'],
  ['Monaco', 'MC'],
  ['Mongolia', 'MN'],
  ['Montenegro', 'ME'],
  ['Montserrat', 'MS'],
  ['Morocco', 'MA'],
  ['Mozambique', 'MZ'],
  ['Myanmar', 'MM'],
  ['Namibia', 'NA'],
  ['Nauru', 'NR'],
  ['Nepal', 'NP'],
  ['Netherlands', 'NL'],
  ['New Caledonia', 'NC'],
  ['New Zealand', 'NZ'],
  ['Nicaragua', 'NI'],
  ['Niger', 'NE'],
  ['Nigeria', 'NG'],
  ['Niue', 'NU'],
  ['Norfolk Island', 'NF'],
  ['North Macedonia', 'MK'],
  ['Northern Mariana Islands', 'MP'],
  ['Norway', 'NO'],
  ['Oman', 'OM'],
  ['Pakistan', 'PK'],
  ['Palau', 'PW'],
  ['Palestine, State of', 'PS'],
  ['Panama', 'PA'],
  ['Papua New Guinea', 'PG'],
  ['Paraguay', 'PY'],
  ['Peru', 'PE'],
  ['Philippines', 'PH'],
  ['Pitcairn', 'PN'],
  ['Poland', 'PL'],
  ['Portugal', 'PT'],
  ['Puerto Rico', 'PR'],
  ['Qatar', 'QA'],
  ['Réunion', 'RE'],
  ['Romania', 'RO'],
  ['Russian Federation', 'RU'],
  ['Rwanda', 'RW'],
  ['Saint Barthélemy', 'BL'],
  ['Saint Helena, Ascension and Tristan da Cunha', 'SH'],
  ['Saint Kitts and Nevis', 'KN'],
  ['Saint Lucia', 'LC'],
  ['Saint Martin (French part)', 'MF'],
  ['Saint Pierre and Miquelon', 'PM'],
  ['Saint Vincent and the Grenadines', 'VC'],
  ['Samoa', 'WS'],
  ['San Marino', 'SM'],
  ['Sao Tome and Principe', 'ST'],
  ['Saudi Arabia', 'SA'],
  ['Senegal', 'SN'],
  ['Serbia', 'RS'],
  ['Seychelles', 'SC'],
  ['Sierra Leone', 'SL'],
  ['Singapore', 'SG'],
  ['Sint Maarten (Dutch part)', 'SX'],
  ['Slovakia', 'SK'],
  ['Slovenia', 'SI'],
  ['Solomon Islands', 'SB'],
  ['Somalia', 'SO'],
  ['South Africa', 'ZA'],
  ['South Georgia and the South Sandwich Islands', 'GS'],
  ['South Sudan', 'SS'],
  ['Spain', 'ES'],
  ['Sri Lanka', 'LK'],
  ['Sudan', 'SD'],
  ['Suriname', 'SR'],
  ['Svalbard and Jan Mayen', 'SJ'],
  ['Sweden', 'SE'],
  ['Switzerland', 'CH'],
  ['Syrian Arab Republic', 'SY'],
  ['Taiwan, Province of China', 'TW'],
  ['Tajikistan', 'TJ'],
  ['Tanzania, United Republic of', 'TZ'],
  ['Thailand', 'TH'],
  ['Timor-Leste', 'TL'],
  ['Togo', 'TG'],
  ['Tokelau', 'TK'],
  ['Tonga', 'TO'],
  ['Trinidad and Tobago', 'TT'],
  ['Tunisia', 'TN'],
  ['Turkey', 'TR'],
  ['Turkmenistan', 'TM'],
  ['Turks and Caicos Islands', 'TC'],
  ['Tuvalu', 'TV'],
  ['Uganda', 'UG'],
  ['Ukraine', 'UA'],
  ['United Arab Emirates', 'AE'],
  ['United Kingdom of Great Britain and Northern Ireland', 'GB'],
  ['United States Minor Outlying Islands', 'UM'],
  ['Uruguay', 'UY'],
  ['Uzbekistan', 'UZ'],
  ['Vanuatu', 'VU'],
  ['Venezuela (Bolivarian Republic of)', 'VE'],
  ['Viet Nam', 'VN'],
  ['Virgin Islands (British)', 'VG'],
  ['Virgin Islands (U.S.)', 'VI'],
  ['Wallis and Futuna', 'WF'],
  ['Western Sahara', 'EH'],
  ['Yemen', 'YE'],
  ['Zambia', 'ZM'],
  ['Zimbabwe', 'ZW'],

]);

export const languagesCodes = new Map([
  [`English`, `en`],
  [`Abkhazian`, `ab`],
  [`Afar`, `aa`],
  [`Afrikaans`, `af`],
  [`Akan`, `ak`],
  [`Albanian`, `sq`],
  [`Amharic`, `am`],
  [`Arabic`, `ar`],
  [`Aragonese`, `an`],
  [`Armenian`, `hy`],
  [`Assamese`, `as`],
  [`Avaric`, `av`],
  [`Avestan`, `ae`],
  [`Aymara`, `ay`],
  [`Azerbaijani`, `az`],
  [`Bambara`, `bm`],
  [`Bashkir`, `ba`],
  [`Basque`, `eu`],
  [`Belarusian`, `be`],
  [`Bengali`, `bn`],
  [`Bihari languages`, `bh`],
  [`Bislama`, `bi`],
  [`Bokmål, Norwegian; Norwegian Bokmål`, `nb`],
  [`Bosnian`, `bs`],
  [`Breton`, `br`],
  [`Bulgarian`, `bg`],
  [`Burmese`, `my`],
  [`Catalan; Valencian`, `ca`],
  [`Central Khmer`, `km`],
  [`Chamorro`, `ch`],
  [`Chechen`, `ce`],
  [`Chichewa; Chewa; Nyanja`, `ny`],
  [`Chinese`, `zh`],
  [`Church Slavic`, `cu`],
  [`Chuvash`, `cv`],
  [`Cornish`, `kw`],
  [`Corsican`, `co`],
  [`Cree`, `cr`],
  [`Croatian`, `hr`],
  [`Czech`, `cs`],
  [`Danish`, `da`],
  [`Divehi; Dhivehi; Maldivian`, `dv`],
  [`Dutch; Flemish`, `nl`],
  [`Dzongkha`, `dz`],
  [`Esperanto`, `eo`],
  [`Estonian`, `et`],
  [`Ewe`, `ee`],
  [`Faroese`, `fo`],
  [`Fijian`, `fj`],
  [`Finnish`, `fi`],
  [`French`, `fr`],
  [`Fulah`, `ff`],
  [`Gaelic; Scottish Gaelic`, `gd`],
  [`Galician`, `gl`],
  [`Ganda`, `lg`],
  [`Georgian`, `ka`],
  [`German`, `de`],
  [`Greek`, `el`],
  [`Guarani`, `gn`],
  [`Gujarati`, `gu`],
  [`Haitian; Haitian Creole`, `ht`],
  [`Hausa`, `ha`],
  [`Hebrew`, `he`],
  [`Herero`, `hz`],
  [`Hindi`, `hi`],
  [`Hiri Motu`, `ho`],
  [`Hungarian`, `hu`],
  [`Icelandic`, `is`],
  [`Ido`, `io`],
  [`Igbo`, `ig`],
  [`Indonesian`, `id`],
  [`Interlingua (International Auxiliary Language Association)`, `ia`],
  [`Interlingue; Occidental`, `ie`],
  [`Inuktitut`, `iu`],
  [`Inupiaq`, `ik`],
  [`Irish`, `ga`],
  [`Italian`, `it`],
  [`Japanese`, `ja`],
  [`Javanese`, `jv`],
  [`Kalaallisut; Greenlandic`, `kl`],
  [`Kannada`, `kn`],
  [`Kanuri`, `kr`],
  [`Kashmiri`, `ks`],
  [`Kazakh`, `kk`],
  [`Kikuyu; Gikuyu`, `ki`],
  [`Kinyarwanda`, `rw`],
  [`Kirghiz; Kyrgyz`, `ky`],
  [`Komi`, `kv`],
  [`Kongo`, `kg`],
  [`Korean`, `ko`],
  [`Kuanyama; Kwanyama`, `kj`],
  [`Kurdish`, `ku`],
  [`Lao`, `lo`],
  [`Latin`, `la`],
  [`Latvian`, `lv`],
  [`Limburgan; Limburger; Limburgish`, `li`],
  [`Lingala`, `ln`],
  [`Lithuanian`, `lt`],
  [`Luba-Katanga`, `lu`],
  [`Luxembourgish; Letzeburgesch`, `lb`],
  [`Macedonian`, `mk`],
  [`Malagasy`, `mg`],
  [`Malay`, `ms`],
  [`Malayalam`, `ml`],
  [`Maltese`, `mt`],
  [`Manx`, `gv`],
  [`Maori`, `mi`],
  [`Marathi`, `mr`],
  [`Marshallese`, `mh`],
  [`Mongolian`, `mn`],
  [`Nauru`, `na`],
  [`Navajo; Navaho`, `nv`],
  [`Ndebele, North; North Ndebele`, `nd`],
  [`Ndebele, South; South Ndebele`, `nr`],
  [`Ndonga`, `ng`],
  [`Nepali`, `ne`],
  [`Northern Sami`, `se`],
  [`Norwegian`, `no`],
  [`Norwegian Nynorsk; Nynorsk, Norwegian`, `nn`],
  [`Occitan (post 1500)`, `oc`],
  [`Ojibwa`, `oj`],
  [`Oriya`, `or`],
  [`Oromo`, `om`],
  [`Ossetian; Ossetic`, `os`],
  [`Pali`, `pi`],
  [`Panjabi; Punjabi`, `pa`],
  [`Persian`, `fa`],
  [`Polish`, `pl`],
  [`Portuguese`, `pt`],
  [`Pushto; Pashto`, `ps`],
  [`Quechua`, `qu`],
  [`Romanian; Moldavian; Moldovan`, `ro`],
  [`Romansh`, `rm`],
  [`Rundi`, `rn`],
  [`Russian`, `ru`],
  [`Samoan`, `sm`],
  [`Sango`, `sg`],
  [`Sanskrit`, `sa`],
  [`Sardinian`, `sc`],
  [`Serbian`, `sr`],
  [`Shona`, `sn`],
  [`Sichuan Yi; Nuosu`, `ii`],
  [`Sindhi`, `sd`],
  [`Sinhala; Sinhalese`, `si`],
  [`Slovak`, `sk`],
  [`Slovenian`, `sl`],
  [`Somali`, `so`],
  [`Sotho, Southern`, `st`],
  [`Spanish; Castilian`, `es`],
  [`Sundanese`, `su`],
  [`Swahili`, `sw`],
  [`Swati`, `ss`],
  [`Swedish`, `sv`],
  [`Tagalog`, `tl`],
  [`Tahitian`, `ty`],
  [`Tajik`, `tg`],
  [`Tamil`, `ta`],
  [`Tatar`, `tt`],
  [`Telugu`, `te`],
  [`Thai`, `th`],
  [`Tibetan`, `bo`],
  [`Tigrinya`, `ti`],
  [`Tonga (Tonga Islands)`, `to`],
  [`Tsonga`, `ts`],
  [`Tswana`, `tn`],
  [`Turkish`, `tr`],
  [`Turkmen`, `tk`],
  [`Twi`, `tw`],
  [`Uighur; Uyghur`, `ug`],
  [`Ukrainian`, `uk`],
  [`Urdu`, `ur`],
  [`Uzbek`, `uz`],
  [`Venda`, `ve`],
  [`Vietnamese`, `vi`],
  [`Volapük`, `vo`],
  [`Walloon`, `wa`],
  [`Welsh`, `cy`],
  [`Western Frisian`, `fy`],
  [`Wolof`, `wo`],
  [`Xhosa`, `xh`],
  [`Yiddish`, `yi`],
  [`Yoruba`, `yo`],
  [`Zhuang; Chuang`, `za`],
  [`Zulu`, `zu`],
]);
*/

export const tcrBrandEntityTypesCodes = new Map([
  ['Publicly Traded Company', 'PUBLIC_PROFIT'],
  ['Private Company', 'PRIVATE_PROFIT'],
  ['Non-Profit Organization', 'NON_PROFIT'],
  ['Government Corporation', 'GOVERNMENT'],
  ['	Sole proprietorship', 'SOLE_PROPRIETOR'],
]);

export const altBusinessIdTypesCodes = new Map([
  ['DUNS', 'DUNS'],
  ['GIIN', 'GIIN'],
  ['LEI', 'LEI'],
]);

const tcrVerticals = {
  "PROFESSIONAL": {
    "industryId": "professional-services",
    "displayName": "Professional Services",
    "description": "Professional services."
  },
  "REAL_ESTATE": {
    "industryId": "real-estate",
    "displayName": "Real Estate",
    "description": "Buying and Selling of residential and commercial properties."
  },
  "HEALTHCARE": {
    "industryId": "healthcare-and-lifesciences",
    "displayName": "Healthcare and Life Sciences",
    "description": "Healthcare services, including hospital, health insurances."
  },
  "HUMAN_RESOURCES": {
    "industryId": "human-resources-staffing-recruitment",
    "displayName": "HR, Staffing or Recruitment",
    "description": "Human resources, staffing, or recruitment."
  },
  "ENERGY": {
    "industryId": "energy-and-utilities",
    "displayName": "Energy and Utilities",
    "description": "Energy and Utilities."
  },
  "ENTERTAINMENT": {
    "industryId": "entertainment",
    "displayName": "Entertainment",
    "description": "Entertainment, including gaming and dating services."
  },
  "RETAIL": {
    "industryId": "retail-and-consumer-products",
    "displayName": "Retail and Consumer Products",
    "description": "Consumer products and services, including Retails and eTails."
  },
  "TRANSPORTATION": {
    "industryId": "transportation-logistics",
    "displayName": "Transportation or Logistics",
    "description": "Transportation or logistics services."
  },
  "AGRICULTURE": {
    "industryId": "agriculture",
    "displayName": "Agriculture",
    "description": "Agriculture businesses and services."
  },
  "INSURANCE": {
    "industryId": "insurance",
    "displayName": "Insurance",
    "description": "Insurance products and services"
  },
  "POSTAL": {
    "industryId": "postal-delivery",
    "displayName": "Postal and Delivery",
    "description": "Postal and delivery services."
  },
  "EDUCATION": {
    "industryId": "education",
    "displayName": "Education",
    "description": "Educational institutions providing education to students in educational settings."
  },
  "HOSPITALITY": {
    "industryId": "hospitality",
    "displayName": "Hospitality and Travel",
    "description": "Travel, hospitality and transportation."
  },
  "FINANCIAL": {
    "industryId": "financial-services",
    "displayName": "Financial Services",
    "description": "Banking and financial institution."
  },
  "POLITICAL": {
    "industryId": "political",
    "displayName": "Political",
    "description": "Political campaigns and services."
  },
  "GAMBLING": {
    "industryId": "gambling-and-lottery",
    "displayName": "Gambling and Lottery",
    "description": "Gambling and Lotteries."
  },
  "LEGAL": {
    "industryId": "legal",
    "displayName": "Legal",
    "description": "Legal services and law firms."
  },
  "CONSTRUCTION": {
    "industryId": "construction-and-materials",
    "displayName": "Construction, Materials, and Trade Services",
    "description": "Construction, materials, and trade services."
  },
  "NGO": {
    "industryId": "non-profit-organization",
    "displayName": "Non-profit Organization",
    "description": "Non-profit organization with valid tax-exempted status."
  },
  "MANUFACTURING": {
    "industryId": "manufacturing",
    "displayName": "Manufacturing",
    "description": "Goods producing industries."
  },
  "GOVERNMENT": {
    "industryId": "public-sector",
    "displayName": "Government Services and Agencies",
    "description": "Local, state or federal agencies, utilities."
  },
  "TECHNOLOGY": {
    "industryId": "information-technology-services",
    "displayName": "Information Technology Services",
    "description": "Information technology and information services."
  },
  "COMMUNICATION": {
    "industryId": "mass-media-and-communication",
    "displayName": "Media and Communication",
    "description": "Communication and mass media."
  }
};

export const getTcrVerticals = () => {
  var result = new Map();
  for (var p in tcrVerticals) {
    if (tcrVerticals.hasOwnProperty(p)) {
      result.set(tcrVerticals[p].displayName, p);
    }
  }

  return result;
}

export const stockExchanges = [
  "NONE",
  "NASDAQ",
  "NYSE",
  "AMEX",
  "AMX",
  "ASX",
  "B3",
  "BME",
  "BSE",
  "FRA",
  "ICEX",
  "JPX",
  "JSE",
  "KRX",
  "LON",
  "NSE",
  "OMX",
  "SEHK",
  "SGX",
  "SSE",
  "STO",
  "SWX",
  "SZSE",
  "TSX",
  "TWSE",
  "VSE",
  "OTHER"
];

export const tollFreeMonthlyVolumes = new Map([
  ['1,000', '1,000'],
  ['10,000', '10,000'],
  ['100,000', '100,000'],
  ['250,000', '250,000'],
  ['500,000', '500,000'],
  ['750,000', '750,000'],
  ['1M', '1M'],
  ['5M', '5M'],
  ['10M+', '10M+']
]);

export const tollFreeUseCases = new Map([
  ['2FA', '2FA'],
  ['App Notifications', 'App Notifications'],
  ['Appointments', 'Appointments'],
  ['Auctions', 'Auctions'],
  ['Auto Repair Services', 'Auto Repair Services'],
  ['Bank Transfers', 'Bank Transfers'],
  ['Billing', 'Billing'],
  ['Booking Confirmations', 'Booking Confirmations'],
  ['Business Updates', 'Business Updates'],
  ['COVID-19 Alerts', 'COVID-19 Alerts'],
  ['Career Training', 'Career Training'],
  ['Chatbot', 'Chatbot'],
  ['Conversational / Alerts', 'Conversational / Alerts'],
  ['Courier Services & Deliveries', 'Courier Services & Deliveries'],
  ['Emergency Alerts', 'Emergency Alerts'],
  ['Events & Planning', 'Events & Planning'],
  ['Financial Services', 'Financial Services'],
  ['Fraud Alerts', 'Fraud Alerts'],
  ['Fundraising', 'Fundraising'],
  ['General Marketing', 'General Marketing'],
  ['General School Updates', 'General School Updates'],
  ['HR / Staffing', 'HR / Staffing'],
  ['Healthcare Alerts', 'Healthcare Alerts'],
  ['Housing Community Updates', 'Housing Community Updates'],
  ['Insurance Services', 'Insurance Services'],
  ['Job Dispatch', 'Job Dispatch'],
  ['Legal Services', 'Legal Services'],
  ['Mixed', 'Mixed'],
  ['Motivational Reminders', 'Motivational Reminders'],
  ['Notary Notifications', 'Notary Notifications'],
  ['Order Notifications', 'Order Notifications'],
  ['Political', 'Political'],
  ['Public Works', 'Public Works'],
  ['Real Estate Services', 'Real Estate Services'],
  ['Religious Services', 'Religious Services'],
  ['Repair and Diagnostics Alerts', 'Repair and Diagnostics Alerts'],
  ['Rewards Program', 'Rewards Program'],
  ['Surveys', 'Surveys'],
  ['System Alerts', 'System Alerts'],
  ['Voting Reminders', 'Voting Reminders'],
  ['Waitlist Alerts', 'Waitlist Alerts'],
  ['Webinar Reminders', 'Webinar Reminders'],
  ['Workshop Alerts', 'Workshop Alerts']
]);

export const userHasProjects = (projectsList) => {

  return Array.isArray(projectsList) && projectsList.length > 0;
}

export const isNullOrUndefined = (v) => {

  return v === undefined || v === null;
}

export const textOrEmpty = (v) => {
  if (v) {
    return <span>{v}</span>;
  }

  return <span className="text-muted">-</span>;
}

export const getMapKeyByValue = (map, searchValue) => {
  for (let [key, value] of map.entries()) {
    if (value === searchValue)
      return key;
  }
}

export const constructErrorMessage = (err, defaultMessage) => {
  if (Array.isArray(err?.response?.data?.errors)) {
    return err?.response?.data?.errors.join(" ");
  } else if (err?.response?.data) {
    return String(err?.response?.data);
  } else if (err?.response?.status === 400) {
    return 'Invalid input parameter';
  } else if (defaultMessage !== undefined) {
    return defaultMessage;
  } else {
    return "Something went wrong";
  }
}

export const emptyFunc = () => void 0; // This func is being used to make sure we always have some default func

export const boolToYesNo = (b) => b ? 'Yes' : 'No';

/**
 * Formats the size
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

export const mapToOptions = (m) => {
  let arr = [];

  m.forEach((val, key) => {
    arr.push({ label: key, value: val });
  });

  return arr;
}

export const createOption = (label, value, title) => ({
  label: <span data-toggle="tooltip" data-placement="top" title={title}>{label}</span>,
  value: value ?? label,
});

export const clearHistoryState = (history) => {
  // clearing state to avoid showing the same messages after a page refresh
  // see https://stackoverflow.com/questions/40099431/how-do-i-clear-location-state-in-react-router-on-page-reload
  //window.history.replaceState({}, document.title);
  setTimeout(() => history.replace({ state: {} }), 1000); // clearing state after 1 sec (we need it to show toaster)
}

export const truncateString = (str, num) => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + '...'
}

export const extractDate = (d /* should be date type */) => {
  let day = d.getDate();
  let month = d.getMonth() + 1;
  let year = d.getFullYear();

  // see https://stackoverflow.com/questions/8043026/how-to-format-numbers-by-prepending-0-to-single-digit-numbers
  //str.padStart(2, '0');
  let dayWithZeros = (String(day)).padStart(2, '0');
  let monthWithZeros = (String(month)).padStart(2, '0');

  return `${year}-${monthWithZeros}-${dayWithZeros}`;
}

export const isNumber = (value) => typeof value === 'number';

export const containsNonLatinCodepoints = (s) => {
  // eslint-disable-next-line no-control-regex
  return /[^\u0000-\u00ff]/.test(s);
}

export const constructContactName = (contact, defaultValue) => {
  if (!contact) {
    return defaultValue;
  }

  if (contact.fullName) {
    return contact.fullName;
  }

  if (contact.firstName && contact.lastName) {
    return `${contact.firstName} ${contact.lastName}`;
  }

  if (contact.firstName) {
    return contact.firstName;
  }

  if (contact.lastName) {
    return contact.lastName;
  }

  return defaultValue;
}

/*
func formatTelephoneNumberCost(
	costAmount int64,
) string {
	return fmt.Sprintf("%.5f", float64(costAmount)/100000.0)
}
*/


export const formatAmount = (amount, unit) => {
  if (amount === undefined) {
    return "";
  }
  if (unit === '5_digits' || !unit) {
    return (amount/100000.0).toLocaleString();
  }

  return amount;
}

export const formatAmountWithCurrency = (amount, unit, currency) => {
  if (amount === undefined) {
    return "";
  }
  if (!unit || unit === '5_digits') {
    if (!currency || currency.toLowerCase() === 'usd') {
      return (amount < 0 ? "-$" : "$") + (Math.abs(amount/100000.0)).toLocaleString();
    }
    return (amount/100000.0).toLocaleString();
  }

  return amount;
}
