import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

import ProjectSelector from "../CommonForBoth/ProjectSelector";

const SidebarContent = props => {
  const ref = useRef();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }, []);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {

    const pathName = props.location.pathname

    new MetisMenu("#side-menu")
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      // "alternativeurls" is a custom html tag containing an array of links that should belong to the same menu item. It helps to highlight "add"/"edit" dialogs.
      // the array is represented as a ","-separated string

      //checking  alternativeURLs patterns first
      let matchAlternativeUrls = false;
      if (items[i].getAttribute('alternativeurls') && typeof items[i].getAttribute('alternativeurls' === "string")) {
        let urls = items[i].getAttribute('alternativeurls').split(","); // converting string with "," delimiter to an array
        for (let idx = 0; idx < urls.length; idx++) {
          if (new RegExp(urls[idx], "g").test(pathName)) {
            matchAlternativeUrls = true; // we found a match. no need to continue
            break;
          }
        }
      }

      // checking against items[i].pathname or petterns in "alternativeurls"
      if ((pathName === items[i].pathname || matchAlternativeUrls) && items[i].id !== 'project-selector') {
        matchingMenuItem = items[i];
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

  }, [props.location.pathname, currentProjectId, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate();
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }


  // menu icons are described here: https://icon-sets.iconify.design/uil/calling/
  // href="/" will not work because it redirects to "/dashboard" and "dashboard" does not match with any of the <Link>. That's why an url should be "/dashboard"
  return (
    <React.Fragment>

      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <ProjectSelector />
            </li>
            {currentProjectId /* this prevents a bug with non-clickable "Billing" menu item */  && <>
              <li className="menu-title">{props.t("Menu")} </li>
              <li>
                <Link to={`/projects/${currentProjectId}/dashboard`} className="waves-effect">
                  <i className="uil uil-home-alt"></i>
                  {/* no need for a badge for now 
                  <span className="badge rounded-pill bg-primary float-end">
                    01
                  </span>
                  */}
                  <span>{props.t("Home")}</span>
                </Link>
              </li>
              <li>
                <Link to={`/projects/${currentProjectId}/compose`} className="waves-effect">
                  <i className="mdi mdi-email-send-outline"></i>
                  <span>{props.t("Send")}</span>
                </Link>
              </li>
              <li>
                <Link to={`/projects/${currentProjectId}/chats`} className="waves-effect">
                  <i className="uil uil-comments-alt"></i>
                  <span>{props.t("Inbox")}</span>
                </Link>
              </li>
              <li>
                <Link to={`/projects/${currentProjectId}/keywords`} alternativeurls={[`^/projects/${currentProjectId}/keywords/add$`, `^/projects/${currentProjectId}/keywords/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`]} className="waves-effect">
                  <i className="mdi mdi-key-variant"></i>
                  <span>{props.t("Keywords")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-account-multiple"></i>
                  <span>{props.t("Directory")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`/projects/${currentProjectId}/directory/contacts`} alternativeurls={[`^/projects/${currentProjectId}/directory/contacts/add$`, `^/projects/${currentProjectId}/directory/contacts/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`]}>{props.t("Contacts")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/directory/contacts/groups`} alternativeurls={[`^/projects/${currentProjectId}/directory/contacts/groups/add$`, `^/projects/${currentProjectId}/directory/contacts/groups/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`]}>{props.t("Groups")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/directory/contacts/attributes`} alternativeurls={[`^/projects/${currentProjectId}/directory/contacts/attributes/add$`, `^/projects/${currentProjectId}/directory/contacts/attributes/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`]}>{props.t("Custom attributes")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/directory/contacts/imports`} alternativeurls={[`^/projects/${currentProjectId}/directory/contacts/imports/add$`, `^/projects/${currentProjectId}/directory/contacts/imports/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`]}>{props.t("Import")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/unsubscribed`}>{props.t("Unsubscribed")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={`/projects/${currentProjectId}/numbers`} alternativeurls={[`/projects/${currentProjectId}/numbers/search`, `/projects/${currentProjectId}/numbers/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}`]} className="waves-effect">
                  <i className="uil uil-phone-volume"></i>
                  <span>{props.t("Phone numbers")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil uil-envelopes"></i>
                  <span>{props.t("SMS/MMS")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`/projects/${currentProjectId}/messages/groups`} alternativeurls={[`/projects/${currentProjectId}/messages/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}`, `/projects/${currentProjectId}/messages/groups/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}`]}>{props.t("All")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/messages/groups/outbound`}>{props.t("Outbound")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/messages/groups/inbound`}>{props.t("Inbound")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil uil-file-check-alt"></i>
                  <span>{props.t("Compliance")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`/projects/${currentProjectId}/tcr/brands`} alternativeurls={[`^/projects/${currentProjectId}/tcr/brands/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`, `^/projects/${currentProjectId}/tcr/brands/add$`]}>{props.t("10DLC Brands")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/tcr/campaigns`} alternativeurls={[`^/projects/${currentProjectId}/tcr/brands/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/campaigns/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`, `^/projects/${currentProjectId}/tcr/campaigns/add$`]}>{props.t("10DLC Campaigns")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/toll-free-verifications`} alternativeurls={[`^/projects/${currentProjectId}/toll-free-verifications/[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$`, `^/projects/${currentProjectId}/toll-free-verifications/add$`]}>{props.t("Toll-Free Verification")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil uil-analytics"></i>
                  <span>{props.t("Analytics")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`/projects/${currentProjectId}/reports/messaging`} alternativeurls={[]}>{props.t("Messaging")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/reports/subscriptions`} alternativeurls={[]}>{props.t("Subscriptions")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil uil-wallet"></i>
                  <span>{props.t("Billing")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`/projects/${currentProjectId}/billing/balance`} alternativeurls={[`/projects/${currentProjectId}/billing/balance/top-up`]}>{props.t("Balance")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/billing/payment-methods`} alternativeurls={[`/projects/${currentProjectId}/billing/payment-methods/add`]}>{props.t("Payment methods")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/billing/payments-history`}>{props.t("History")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to={`/projects/${currentProjectId}/events`} className="waves-effect">
                  <i className="uil-history"></i>
                  <span>{props.t("Events")}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil-setting"></i>
                  <span>{props.t("Settings")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={`/projects/${currentProjectId}/settings/project-details`}>{props.t("General")}</Link>
                  </li>
                  <li>
                    <Link to={`/projects/${currentProjectId}/settings/users`}>{props.t("Team")}</Link>
                  </li>
                </ul>
              </li>
            </>
            /* end of "currentProjectId is not empty */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(SidebarContent)));