import { all, fork, call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  GET_CONTACTS_FOR_PROJECT_REQUEST,
  GET_CONTACT_DETAILS_REQUEST,
  GET_CONTACT_DETAILS_NO_LOADING_REQUEST,
  UPDATE_CONTACT_DETAILS_REQUEST,
  DELETE_CONTACTS_REQUEST,
  GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST,
  GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST,
  GET_CONTACT_GROUP_DETAILS_REQUEST,
  UPDATE_CONTACT_GROUP_DETAILS_REQUEST,
  DELETE_CONTACT_GROUP_REQUEST,
  DELETE_CONTACT_GROUPS_REQUEST,
  GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
  UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST,
  DELETE_CONTACT_ATTRIBUTE_REQUEST,
  GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST,
} from "./actionTypes";
import {
  getContactsForProjectSuccess,
  getContactDetailsSuccess,
  updateContactSuccess,
  deleteContactsSuccess,
  getContactGroupsForProjectSuccess,
  getContactGroupDetailsSuccess,
  updateContactGroupSuccess,
  getContactAttributeDetailsSuccess,
  updateContactAttributeSuccess,
  getContactImportTasksForProjectSuccess,
  deleteContactGroupSuccess,
  deleteContactGroupsSuccess,
  deleteContactAttributeSuccess,
  contactApiError
} from "./actions";

import { contactService } from "../../services";

function* getContacts({ payload: { globalFilter, projectId, limit, offset } }) {
  try {
    const response = yield call(
      contactService.fetchContacts,
      globalFilter,
      projectId,
      limit,
      offset
    );
    let r = response;
    r.contactsGlobalFilter = globalFilter; // adding globalFilter to the response object to use in reducer
    yield put(getContactsForProjectSuccess(r));
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* getContactDetails({ payload: { projectId, contactId } }) {
  try {
    const response = yield call(
      contactService.fetchContactDetails,
      projectId,
      contactId
    )
    yield put(getContactDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* updateContact({ payload: { contactId, projectId, email, fullName, firstName, lastName, company, jobTitle, countryCode, languageCode, groupIds, additionalAttributes, callback } }) {
  try {
    const response = yield call(
      contactService.updateContact,
      contactId,
      projectId,
      email,
      fullName,
      firstName,
      lastName,
      company,
      jobTitle,
      countryCode,
      languageCode,
      groupIds,
      additionalAttributes
    )
    yield put(updateContactSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* deleteContacts({ payload: { projectId, contactIds, callback } }) {
  try {
    const response = yield call(
      contactService.deleteContacts,
      projectId,
      contactIds
    )
    yield put(deleteContactsSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* getContactGroups({ payload: { projectId, limit, offset } }) {
  try {
    const response = yield call(
      contactService.fetchContactGroups,
      projectId,
      limit,
      offset
    )
    yield put(getContactGroupsForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* getContactGroupDetails({ payload: { projectId, contactGroupId } }) {
  try {
    const response = yield call(
      contactService.fetchContactGroupDetails,
      projectId,
      contactGroupId
    )
    yield put(getContactGroupDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* updateContactGroup({ payload: { contactGroupId, projectId, title, description, callback } }) {
  try {
    const response = yield call(
      contactService.updateContactGroup,
      contactGroupId,
      projectId,
      title,
      description
    )
    yield put(updateContactGroupSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* deleteContactGroup({ payload: { projectId, contactGroupId, callback } }) {
  try {
    const response = yield call(
      contactService.deleteContactGroup,
      projectId,
      contactGroupId
    )
    yield put(deleteContactGroupSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* deleteContactGroups({ payload: { projectId, contactGroupIds, callback } }) {
  try {
    const response = yield call(
      contactService.deleteContactGroups,
      projectId,
      contactGroupIds
    )
    yield put(deleteContactGroupsSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* getContactAttributeDetails({ payload: { projectId, attrId } }) {
  try {
    const response = yield call(
      contactService.fetchAdditionalContactAttributeDetails,
      projectId,
      attrId
    )
    yield put(getContactAttributeDetailsSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* updateContactAttribute({ payload: { attrId, projectId, displayName, description, callback } }) {
  try {
    const response = yield call(
      contactService.updateAdditionalContactAttribute,
      attrId,
      projectId,
      displayName,
      description
    )
    yield put(updateContactAttributeSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* deleteContactAttribute({ payload: { projectId, attrId, callback } }) {
  try {
    const response = yield call(
      contactService.deleteContactAttribute,
      projectId,
      attrId
    )
    yield put(deleteContactAttributeSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* getContactImportTasks({ payload: { projectId, limit, offset } }) {
  try {
    const response = yield call(
      contactService.fetchContactImportTasks,
      projectId,
      limit,
      offset
    )
    yield put(getContactImportTasksForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(contactApiError(error));
  }
}

function* watchGetContactsRequests() {
  yield takeEvery(GET_CONTACTS_FOR_PROJECT_REQUEST, getContacts)
}

function* watchGetContactDetailsRequests() {
  yield takeEvery(GET_CONTACT_DETAILS_REQUEST, getContactDetails)
}

function* watchGetContactDetailsNoLoadingRequests() {
  yield takeEvery(GET_CONTACT_DETAILS_NO_LOADING_REQUEST, getContactDetails)
}

function* watchUpdateContactRequests() {
  yield takeEvery(UPDATE_CONTACT_DETAILS_REQUEST, updateContact)
}

function* watchDeleteContactsRequests() {
  yield takeEvery(DELETE_CONTACTS_REQUEST, deleteContacts)
}

function* watchContactGroupsRequests() {
  yield takeEvery(GET_CONTACT_GROUPS_FOR_PROJECT_REQUEST, getContactGroups)
}

function* watchContactGroupsNoLoadingRequests() {
  yield takeEvery(GET_CONTACT_GROUPS_FOR_PROJECT_NO_LOADING_REQUEST, getContactGroups)
}

function* watchGetContactGroupDetailsRequests() {
  yield takeEvery(GET_CONTACT_GROUP_DETAILS_REQUEST, getContactGroupDetails)
}

function* watchUpdateContactGroupRequests() {
  yield takeEvery(UPDATE_CONTACT_GROUP_DETAILS_REQUEST, updateContactGroup)
}

function* watchGetContactAttributeDetailsRequests() {
  yield takeEvery(GET_CONTACT_ATTRIBUTE_DETAILS_REQUEST, getContactAttributeDetails)
}

function* watchUpdateContactAttributeRequests() {
  yield takeEvery(UPDATE_CONTACT_ATTRIBUTE_DETAILS_REQUEST, updateContactAttribute)
}

function* watchContactImportTasksRequests() {
  yield takeEvery(GET_CONTACT_IMPORT_TASKS_FOR_PROJECT_REQUEST, getContactImportTasks)
}

function* watchDeleteContactGroupRequests() {
  yield takeEvery(DELETE_CONTACT_GROUP_REQUEST, deleteContactGroup)
}

function* watchDeleteContactGroupsRequests() {
  yield takeEvery(DELETE_CONTACT_GROUPS_REQUEST, deleteContactGroups)
}

function* watchDeleteContactAttributeRequest() {
  yield takeEvery(DELETE_CONTACT_ATTRIBUTE_REQUEST, deleteContactAttribute)
}

function* contactSaga() {
  yield all([
    fork(watchGetContactsRequests),
    fork(watchGetContactDetailsRequests),
    fork(watchGetContactDetailsNoLoadingRequests),
    fork(watchUpdateContactRequests),
    fork(watchDeleteContactsRequests),
    fork(watchContactGroupsRequests),
    fork(watchContactGroupsNoLoadingRequests),
    fork(watchGetContactGroupDetailsRequests),
    fork(watchUpdateContactGroupRequests),
    fork(watchGetContactAttributeDetailsRequests),
    fork(watchUpdateContactAttributeRequests),
    fork(watchContactImportTasksRequests),
    fork(watchDeleteContactGroupRequests),
    fork(watchDeleteContactGroupsRequests),
    fork(watchDeleteContactAttributeRequest),
  ]);
}

export default contactSaga;
