import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Modal, Table, Button, Form } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { textOrEmpty, constructErrorMessage, emptyFunc } from '../../helpers/utils';

import { contactService } from "../../services";

const AddContactsDialog = (props) => {

  var dialogIsOpen = props.isOpen ?? false;
  var onAddContacts = props.onAddContacts ?? emptyFunc;
  const closeDialog = (needsRefresh) => {
    setIsLoading(false);
    setSuccessMessage('');
    setErrorMessage('');
    return props.closeDialog(needsRefresh);
  }

  const currentProjectId = props?.currentProjectId;

  const pageSize = 10;

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show an error message
  const [contactSelectedIds, setContactSelectedIds] = useState(new Map());

  const [contactsList, setContactsList] = useState([]);
  const [contactsLimit, setContactsLimit] = useState(pageSize);
  const [contactsOffset, setContactsOffset] = useState(0);
  const [contactsTotalCount, setContactsTotalCount] = useState(0);

  const [searchFilterInput, setSearchFilterInput] = useState(''); // this is just to save a search value in the input
  const [searchTimer, setSearchTimer] = useState(null);

  const onAddContactsClick = async () => {
    let selectedIds = [...contactSelectedIds.keys()];
    let selectedContacts = [...contactSelectedIds.values()];
    closeDialog(true);
    onAddContacts(selectedIds, selectedContacts);
  };

  const loadContacts = useCallback(async (filter, projectId, limit, offset) => {
    try {
      setIsLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      setSearchFilterInput(filter);
      //setContactsList([]); commenting this will makes the table stop "blinking"
      let l = await contactService.fetchContacts(filter, projectId, limit, offset);

      if (!l?.contacts) {
        throw new Error('empty contacts');
      }
      setContactsList(l?.contacts);
      setContactsLimit(l?.limit ?? pageSize);
      setContactsOffset(l?.offset ?? 0);
      setContactsTotalCount(l?.totalCount ?? 0);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentProjectId && dialogIsOpen) {
      setContactSelectedIds(new Map());
      loadContacts('', currentProjectId, pageSize, 0);
    }
  }, [loadContacts, dialogIsOpen, currentProjectId, pageSize]);

  const onNextPageClick = () => {
    if (contactsOffset + contactsLimit < contactsTotalCount) {
      loadContacts('', currentProjectId, contactsLimit, contactsOffset + contactsLimit);
    }
  }

  const onPreviousPageClick = () => {
    if (contactsOffset !== 0) {
      loadContacts('', currentProjectId, contactsLimit, contactsOffset - contactsLimit);
    }
  }

  /*
  const handleContactSelectedIdsChange = (e) => {
    let contactId = e?.target?.value;

    if (!contactId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (contactSelectedIds.has(contactId)) {
      let m = contactSelectedIds;
      m.delete(contactId);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update

    } else {
      let m = contactSelectedIds;
      // finding contact to add to the map (by contactId)
      let contactToAdd = contactsList.find(c => c.contactId === contactId);
      m.set(contactId, contactToAdd);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }

  }
  */

  const handleContactSelectedIdsChangeByContactId = (contactId) => {

    if (!contactId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (contactSelectedIds.has(contactId)) {
      let m = contactSelectedIds;
      m.delete(contactId);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update

    } else {
      let m = contactSelectedIds;
      // finding contact to add to the map (by contactId)
      let contactToAdd = contactsList.find(c => c.contactId === contactId);
      m.set(contactId, contactToAdd);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }

  }

  const handleSearchFilterInputChange = (e) => {
    setSearchFilterInput(e?.target?.value);

    // Clears running timer and starts a new one each time the user types
    clearTimeout(searchTimer);
    setSearchTimer(setTimeout(() => {
      loadContacts(e?.target?.value, currentProjectId, pageSize, 0);
    }, 1000));

  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (currentProjectId) {
      clearTimeout(searchTimer); // cancel any ongoing searching
      loadContacts(searchFilterInput, currentProjectId, pageSize, 0);
    }
    return false;
  }

  const rowCheckboxIsChecked = (contactId) => {
    return contactSelectedIds.has(contactId);
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={dialogIsOpen}
        centered={true}
        contentClassName="width-min-content"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="AddContactsToContactGroupModalLabel">
            Add Contacts
          </h5>
          <button
            type="button"
            onClick={() => {
              closeDialog(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
            <div className="actions clearfix actions-and-search">
              <ul className='d-flex'>
                <li className='flex-grow-1'>
                  <Form className="app-search d-none d-lg-block p-0" onSubmit={handleSearchSubmit} >
                    <div className="position-relative">
                      <input
                        type="text"
                        className="form-control "
                        placeholder="Search..."
                        onChange={handleSearchFilterInputChange}
                        value={searchFilterInput}
                      />
                      <span className="uil-search"></span>
                    </div>
                  </Form>
                </li>
                <li
                  className={
                    contactsOffset === 0 ? "previous disabled" : "previous"
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={onPreviousPageClick}
                  >
                    <i className="fa fa-chevron-left" />
                  </Link>
                </li>
                <li
                  className={
                    (contactsOffset + contactsLimit >= contactsTotalCount) ? "next disabled" : "next"
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={onNextPageClick}
                  >
                    <i className="fa fa-chevron-right" />
                  </Link>
                </li>
              </ul>
            </div>
            <GlobalProgressBar isLoading={isLoading} />
            <Table className="table-striped table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th>Phone or ID</th>
                  <th>First name</th>
                  <th>Last name</th>
                </tr>
              </thead>
              <tbody>
                {(!contactsList || !Array.isArray(contactsList) || contactsList.length === 0) && <tr><td colSpan={4} className="text-center">No contacts yet</td></tr>}
                {
                  contactsList && Array.isArray(contactsList) && contactsList.length > 0 && contactsList.map((c, i) => <tr key={i} className={rowCheckboxIsChecked(c.contactId) ? "align-middle cursor-pointer table-info" : "align-middle cursor-pointer"} onClick={() => handleContactSelectedIdsChangeByContactId(c.contactId)}>
                    <td style={{ width: "2rem" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={c.contactId}
                        id={`chk-contact-${i}`}
                        readOnly={true}
                        checked={rowCheckboxIsChecked(c.contactId)}
                      />
                    </td>
                    <td>
                      {c.phoneNumber ?? c.contactId}
                    </td>
                    <td>
                      {textOrEmpty(c.firstName)}
                    </td>
                    <td>
                      {textOrEmpty(c.lastName)}
                    </td>
                  </tr>)
                }
              </tbody>
            </Table>
          </div>
        </div>
        <div className="modal-footer">
          <div className='d-flex flex-wrap gap-3 mt-3'>
            <Button
              type="button"
              color="primary"
              className="w-md"
              onClick={onAddContactsClick}
              disabled={!contactSelectedIds || [...contactSelectedIds.keys()].length === 0}
            >
              Add
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              className="w-md"
              onClick={() => {
                if (!isLoading && !successMessage) {
                  closeDialog(false);
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AddContactsDialog));