import { get, post, put, del } from "../helpers/api_helper";
const _ = require('lodash');

class ContactService {

  fetchContacts(globalFilter, projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/contacts?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    if (globalFilter) {
      url = url + `&globalFilter=${encodeURIComponent(globalFilter)}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchContactsByContactGroupId(contactGroupId, projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/contacts/groups/${contactGroupId}/contacts?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchContactDetails(projectId, contactId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/contacts/${contactId}`)
        .then(response => {
          const contact = _.get(response, 'contact', {});
          resolve(contact);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createContact(projectId, phoneNumber, email, fullName, firstName, lastName, company, jobTitle, countryCode, languageCode, groupIds, additionalAttributes) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts`,
        {
          phoneNumber,
          email,
          fullName,
          firstName,
          lastName,
          company,
          jobTitle,
          countryCode,
          languageCode,
          groupIds,
          additionalAttributes
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  updateContact(contactId, projectId, email, fullName, firstName, lastName, company, jobTitle, countryCode, languageCode, groupIds, additionalAttributes) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}/contacts/${contactId}`,
        {
          email,
          fullName,
          firstName,
          lastName,
          company,
          jobTitle,
          countryCode,
          languageCode,
          groupIds,
          additionalAttributes
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  deleteContacts(projectId, contactIds) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/delete-requests`,
        {
          contactIds
        }
      ).then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchContactGroups(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/contacts/groups?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchContactGroupDetails(projectId, contactGroupId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/contacts/groups/${contactGroupId}`)
        .then(response => {
          const group = _.get(response, 'contactGroup', {});
          resolve(group);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createContactGroup(projectId, title, description) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/groups`,
        {
          title,
          description
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  updateContactGroup(contactGroupId, projectId, title, description) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}/contacts/groups/${contactGroupId}`,
        {
          title,
          description
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  deleteContactGroup(projectId, contactGroupId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/contacts/groups/${contactGroupId}`)
        .then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  deleteContactGroups(projectId, contactGroupIds) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/groups/delete-requests`,
        {
          groupIds: contactGroupIds
        }
      ).then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });

  }

  addContactsToContactGroup(projectId, contactGroupId, contactIds) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/groups/${contactGroupId}/contacts`,
        {
          contactIds
        }
      ).then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });

  }

  deleteContactsFromContactGroup(projectId, contactGroupId, contactIds) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/groups/${contactGroupId}/contacts/delete-requests`,
        {
          contactIds
        }
      ).then(() => {
        resolve();
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchAllContactAttributes(projectId) {

    let url = `/projects/${projectId}/contacts/attributes`;

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response);

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchAdditionalContactAttributes(projectId) {

    let url = `/projects/${projectId}/contacts/additional-attributes`;

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response);

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchAdditionalContactAttributeDetails(projectId, attrId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/contacts/additional-attributes/${attrId}`)
        .then(response => {
          const attr = _.get(response, 'additionalContactAttribute', {});
          resolve(attr);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createAdditionalContactAttribute(projectId, attributeName, displayName, description) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/additional-attributes`,
        {
          attributeName,
          displayName,
          description
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  updateAdditionalContactAttribute(attrId, projectId, displayName, description) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}/contacts/additional-attributes/${attrId}`,
        {
          displayName,
          description
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  deleteContactAttribute(projectId, attrId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/contacts/additional-attributes/${attrId}`)
        .then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchContactImportTasks(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/contacts/import-tasks?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  uploadContactImportTaskFile(projectId, files) {
    if (!Array.isArray(files) || files.length === 0) {
      throw new Error('empty files');
    }

    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/import-tasks/upload-file`,
        {
          "task_import_file": files[0], // using internal Axios serializer https://axios-http.com/docs/multipart FormData does not work for some reason
        },
        {
          'headers': {
            'Content-Type': 'multipart/form-data',
          }
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchContactImportTaskFilePreview(projectId, fileId, delimiter) {

    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/contacts/import-tasks/file/${fileId}/preview?delimiter=${encodeURIComponent(delimiter)}`)
        .then(response => {
          resolve(response);

        }).catch(error => {
          reject(error);
        })
    });

  }


  createContactImportTask(projectId, fileId, fileName, isFirstRowHeader, mergeRecords, contactGroupIds, csvDelimiter, csvColumnsMappingMap) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/contacts/import-tasks`,
        {
          fileId,
          fileName,
          isFirstRowHeader,
          mergeRecords,
          contactGroupIds,
          csvDelimiter,
          csvColumnsMapping: Object.fromEntries(csvColumnsMappingMap) // for some reason axios cannot serialize a map (sends it as empty). So we have to convert the map to the object. 
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

}

export const contactService = new ContactService();