import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, clearHistoryState } from '../../helpers/utils';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { keywordService } from "../../services";

import {
  getContactGroupsForProject
} from "../../store/actions";


const AddKeyword = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const contactGroupsList = useSelector((state) => state.Contact.contactGroupsList);
  const [isLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );


  useEffect(() => {
    if (currentProjectId) {
      dispatch(getContactGroupsForProject(currentProjectId, null, null));
    }
  }, [dispatch, currentProjectId]);

  useEffect(() => {

    if (redirectStatus === 'create-keyword-succeeded') {
      setSuccessMessage("Keyword was successfully created");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      keyword: "",
      addToGroups: [],
      removedFromGroups: [],
      autoResponseMessage: '',
    },
    validationSchema: Yup.object({
      keyword: Yup.string().matches(/^\S+$/, "Please exclude spaces from your keyword").required("Please enter the keyword."),
    }),
    onSubmit: async (values) => {
      try {
        setSuccessMessage('');
        setErrorMessage('');

        setIsCreating(true);
        let response = await keywordService.createKeyword(
          currentProjectId,
          values.keyword,
          values.autoResponseMessage,
          'subscribe', // temporary
          values.addToGroups?.map(g => g.value),
          values.removedFromGroups?.map(g => g.value),
        );
        if (!response?.createdKeyword?.keywordId) {
          throw new Error("keyword was not created");
        }
        validation.resetForm();
        history.push(`/projects/${currentProjectId}/keywords/${response?.createdKeyword?.keywordId}`, { redirectStatus: `create-keyword-succeeded` });
      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsCreating(false);
      }
    }
  });

  const getGroupsOptionGroup = () => {

    if (!contactGroupsList || !Array.isArray(contactGroupsList) || contactGroupsList.length === 0) {
      return [
        {
          options: []
        }
      ];
    }

    let arr = [];

    contactGroupsList.forEach((e) => {
      arr.push({ label: <><i className="uil uil-users-alt" /> {e.title}</>, value: e.contactGroupId });
    });

    return [
      {
        options: arr
      }
    ];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Keywords"
            titleUrl={`/projects/${currentProjectId}/keywords`}
            breadcrumbItem="New Keyword" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading &&
                    <>
                      <Form
                        className="form-group add-keyword-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Col className="col-12">
                            <Label className="form-label">Keyword</Label>
                            <InputGroup>
                              <Input
                                name="keyword"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.keyword || ""}
                                invalid={
                                  validation.touched.keyword && validation.errors.keyword ? true : false
                                }
                              />
                              {validation.touched.keyword && validation.errors.keyword ? (
                                <FormFeedback type="invalid">{validation.errors.keyword}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <div>
                            <Label className="form-label">Add to groups</Label>
                            <Select
                              name="addToGroupIds"
                              isMulti={true}
                              isClearable
                              onChange={options => { validation.setFieldValue("addToGroups", options); }}
                              options={getGroupsOptionGroup()}
                              maxMenuHeight={300}
                            />
                          </div>
                        </Row>
                        {false /* temporary hide */ && <Row className="mb-3">
                          <div>
                            <Label className="form-label">Remove from groups</Label>
                            <Select
                              name="removeFromGroupIds"
                              isMulti={true}
                              isClearable
                              onChange={options => { validation.setFieldValue("removedFromGroups", options); }}
                              options={getGroupsOptionGroup()}
                              maxMenuHeight={300}
                            />
                          </div>
                        </Row>}
                        <Row className="mb-3">
                          <Col className="col-12">
                            <Label>Auto response message</Label>
                            <Input
                              type="textarea"
                              name="autoResponseMessage"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.autoResponseMessage || ""}
                              rows="5"
                            />
                            {validation.touched.autoResponseMessage && validation.errors.autoResponseMessage ? (
                              <>
                                <span className="is-invalid"></span>
                                <FormFeedback type="invalid">{validation.errors.autoResponseMessage}</FormFeedback>
                              </>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md text-nowrap"
                            >
                              {isCreating && <>
                                <Spinner size="sm me-1" color="light" />
                              </>}
                              {(!isCreating) && <>
                                Create
                              </>}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddKeyword.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AddKeyword)));