import { get, post, put, del } from "../helpers/api_helper";
const _ = require('lodash');

class TelephoneNumberService {

  fetchTelephoneNumbers(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/numbers?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  searchInventory(projectId, telephoneNumberType, maxResults, countryCode, lata, regionName, locationName, contains, capabilities) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/numbers/search`,
        {
          type: telephoneNumberType,
          maxResults,
          countryCode,
          lata,
          regionName,
          locationName,
          contains,
          capabilities,
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  releaseTelephoneNumber(projectId, telephoneNumberId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/numbers/${telephoneNumberId}`)
        .then( () => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  purchaseTelephoneNumber(projectId, telephoneNumber) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/numbers`,
        {
          telephoneNumber
        }
      ).then((response) => {
        const telephoneNumberId = _.get(response, 'telephoneNumberId');
        resolve(telephoneNumberId);
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchTelephoneNumberDetails(projectId, telephoneNumberId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/numbers/${telephoneNumberId}`)
        .then(response => {
          const message = _.get(response, 'telephoneNumber', {});
          resolve(message);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createTollFreeVerification(
    projectId,
    telephoneNumberIds,
    businessName,
    corporateWebsite,
    firstName,
    lastName,
    email,
    phoneNumber,
    address1,
    address2,
    stateName,
    zipCode,
    city,
    country,
    monthlyVolume,
    useCase,
    useCaseSummary,
    messageContent,
    optInWorkflow,
    optInWorkflowImageUrl,
    additionalUseCaseDetails,
    ) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/numbers/toll-free-verifications`,
        {
          telephoneNumberIds,
          businessName,
          corporateWebsite,
          firstName,
          lastName,
          email,
          phoneNumber,
          address1,
          address2,
          stateName,
          zipCode,
          city,
          country,
          monthlyVolume,
          useCase,
          useCaseSummary,
          messageContent,
          optInWorkflow,
          optInWorkflowImageUrl,
          additionalUseCaseDetails,
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchTollFreeVerifications(projectId) {

    let url = `/projects/${projectId}/numbers/toll-free-verifications`;

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response);

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchTollFreeVerificationDetails(projectId, verificationId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/numbers/toll-free-verifications/${verificationId}`)
        .then(response => {
          const attr = _.get(response, 'tollFreeVerification', {});
          resolve(attr);
        }).catch(error => {
          reject(error);
        })
    });

  }

  deleteTollFreeVerification(projectId, verificationId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/numbers/toll-free-verifications/${verificationId}`)
        .then( () => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  setCampaign(projectId, telephoneNumberId, brandId, campaignId) {
    return new Promise((resolve, reject) => {
      put(
        `/projects/${projectId}/numbers/${telephoneNumberId}/campaigns`,
        {
          brandId,
          campaignId
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchUnsubscribedTelephoneNumbers(projectId) {

    let url = `/projects/${projectId}/unsubscribed`;

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response);

        }).catch(error => {
          reject(error);
        })
    });

  }
  
}

export const telephoneNumberService = new TelephoneNumberService();