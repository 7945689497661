import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Label, Table } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import { downloadFile } from '../../helpers/api_helper';

const CampaignDetailsTab3 = (props) => {

  let {
    currentProjectId,
    currentBrandId,
    currentCampaignId,
    campaignDetails,
  } = props;

  return (
    <React.Fragment>
      {campaignDetails?.sample1 && <Row>
        <Col md={12}>
          <Label className="form-label">Sample 1</Label>
          <p>
            {campaignDetails?.sample1}
          </p>
        </Col>
      </Row>}
      {campaignDetails?.sample2 && <Row>
        <Col md={12}>
          <Label className="form-label">Sample 2</Label>
          <p>
            {campaignDetails?.sample2}
          </p>
        </Col>
      </Row>}
      {campaignDetails?.sample3 && <Row>
        <Col md={12}>
          <Label className="form-label">Sample 3</Label>
          <p>
            {campaignDetails?.sample3}
          </p>
        </Col>
      </Row>}
      {campaignDetails?.sample4 && <Row>
        <Col md={12}>
          <Label className="form-label">Sample 4</Label>
          <p>
            {campaignDetails?.sample4}
          </p>
        </Col>
      </Row>}
      {campaignDetails?.sample5 && <Row>
        <Col md={12}>
          <Label className="form-label">Sample 5</Label>
          <p>
            {campaignDetails?.sample5}
          </p>
        </Col>
      </Row>}
      {campaignDetails?.mediaSamples && Array.isArray(campaignDetails?.mediaSamples) && campaignDetails?.mediaSamples.length > 0 &&
        <Row>
          <Col md={12}>
            <Label className="form-label">Media samples</Label>
            <Table className="table table-striped table-responsive">
              <tbody>
                {campaignDetails?.mediaSamples.map((md, i) => <tr key={i} className="align-middle">
                  <td>
                    <button
                      className="btn btn-link text-start without-outline"
                      type="button"
                      title={`Download ${md?.name}`}
                      onClick={() => downloadFile(`/projects/${currentProjectId}/tcr/brands/${currentBrandId}/campaigns/${currentCampaignId}/media/${md?.name}`, md?.name)}
                    ><span className="d-none d-md-inline">{md?.name}</span>
                    </button>
                  </td>
                </tr>)
                }
              </tbody>
            </Table>
          </Col>
        </Row>
      }
    </React.Fragment>
  )
}

CampaignDetailsTab3.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(CampaignDetailsTab3)));