import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Row, Col, NavItem, NavLink, TabPane, TabContent, Spinner, Container, Card, CardBody } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

// Formik Validation
import * as Yup from "yup";
import { useFormik, setNestedObjectValues } from "formik";

import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import AddTollFreeVerificationStep1 from './AddTollFreeVerificationStep1';
import AddTollFreeVerificationStep2 from './AddTollFreeVerificationStep2';
import AddTollFreeVerificationStep3 from './AddTollFreeVerificationStep3';
import AddTollFreeVerificationStep4 from './AddTollFreeVerificationStep4';

import { telephoneNumberService } from "../../services";

import { constructErrorMessage } from "../../helpers/utils";

const AddTollFreeVerification = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [phoneNumbersList, setPhoneNumbersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message
  const [activeTab, setActiveTab] = useState(1);

  const [businessName, setBusinessName] = useState('');
  const [corporateWebsite, setCorporateWebsite] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('USA');

  const [selectedTns, setSelectedTns] = useState([]);
  const [monthlyVolume, setMonthlyVolume] = useState('');
  const [useCase, setUseCase] = useState('');
  const [useCaseSummary, setUseCaseSummary] = useState('');
  const [messageContent, setMessageContent] = useState('');

  const [optInWorkflow, setOptInWorkflow] = useState('');
  const [optInWorkflowImageUrl, setOptInWorkflowImageUrl] = useState('');
  const [additionalUseCaseDetails, setAdditionalUseCaseDetails] = useState('');

  const onBusinessNameChange = useCallback((v) => setBusinessName(v), []);
  const onCorporateWebsiteChange = useCallback((v) => setCorporateWebsite(v), []);
  const onFirstNameChange = useCallback((v) => setFirstName(v), []);
  const onLastNameChange = useCallback((v) => setLastName(v), []);
  const onEmailChange = useCallback((v) => setEmail(v), []);
  const onPhoneNumberChange = useCallback((v) => setPhoneNumber(v), []);
  const onAddress1Change = useCallback((v) => setAddress1(v), []);
  const onAddress2Change = useCallback((v) => setAddress2(v), []);
  const onStateNameChange = useCallback((v) => setStateName(v), []);
  const onZipCodeChange = useCallback((v) => setZipCode(v), []);
  const onCityChange = useCallback((v) => setCity(v), []);
  const onCountryChange = useCallback((v) => setCountry(v), []);

  const onSelectedTnsChange = useCallback((v) => setSelectedTns(v), []);
  const onMonthlyVolumeChange = useCallback((v) => setMonthlyVolume(v), []);
  const onUseCaseChange = useCallback((v) => setUseCase(v), []);
  const onUseCaseSummaryChange = useCallback((v) => setUseCaseSummary(v), []);
  const onMessageContentChange = useCallback((v) => setMessageContent(v), []);

  const onOptInWorkflowChange = useCallback((v) => setOptInWorkflow(v), []);
  const onOptInWorkflowImageUrlChange = useCallback((v) => setOptInWorkflowImageUrl(v), []);
  const onAdditionalUseCaseDetailsChange = useCallback((v) => setAdditionalUseCaseDetails(v), []);

  const getPhoneNumbers = useCallback(async (projectId) => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      let phoneNumbersResult = await telephoneNumberService.fetchTelephoneNumbers(projectId); // fetching with default limit and offset
      if (!phoneNumbersResult?.telephoneNumbers) {
        throw new Error('No phone numbers were loaded');
      }

      let tns = phoneNumbersResult?.telephoneNumbers;
      // sorting by phone number
      // sort by value
      tns.sort((a, b) => a.telephoneNumber - b.telephoneNumber);

      setPhoneNumbersList(tns.filter(tn => tn.type === "toll-free"));
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);


  useEffect(() => {
    if (currentProjectId) {
      getPhoneNumbers(currentProjectId);
    }
  }, [getPhoneNumbers, currentProjectId]);

  const submitVerificationRequest = useCallback(async () => {
    try {
      setSuccessMessage('');
      setErrorMessage('');

      setIsCreating(true);

      let response = await telephoneNumberService.createTollFreeVerification(
        currentProjectId,
        selectedTns,
        businessName,
        corporateWebsite,
        firstName,
        lastName,
        email,
        phoneNumber,
        address1,
        address2,
        stateName,
        zipCode,
        city,
        country,
        monthlyVolume,
        useCase,
        useCaseSummary,
        messageContent,
        optInWorkflow,
        optInWorkflowImageUrl,
        additionalUseCaseDetails,
      );
      if (!response?.createdTollFreeVerification?.verificationId) {
        throw new Error("The request was not created");
      }

      history.push(`/projects/${currentProjectId}/toll-free-verifications/${response?.createdTollFreeVerification?.verificationId}`, { redirectStatus: `create-tfv-succeeded` });

    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsCreating(false);
    }

  }, [
    history,
    currentProjectId,
    selectedTns,
    businessName,
    corporateWebsite,
    firstName,
    lastName,
    email,
    phoneNumber,
    address1,
    address2,
    stateName,
    zipCode,
    city,
    country,
    monthlyVolume,
    useCase,
    useCaseSummary,
    messageContent,
    optInWorkflow,
    optInWorkflowImageUrl,
    additionalUseCaseDetails,
  ]);

  const step1Validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true, // to validate event untouched elements: https://github.com/jaredpalmer/formik/issues/1116

    initialValues: {
      businessName: "",
      corporateWebsite: "",

      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",

      address1: "",
      address2: "",
      stateName: "",
      zipCode: "",
      city: "",
      country: "USA",
    },
    validationSchema: Yup.object({
      businessName: Yup.string().required("This field is required"),
      corporateWebsite: Yup.string().url("Please specify the website with http://yourwebsite or https://yourwebsite and the domain zone (for example .com, .net)").required("This field is required"),
      firstName: Yup.string().required("This field is required"),
      lastName: Yup.string().required("This field is required"),
      email: Yup.string().email("Incorrect email format").required("This field is required"),
      phoneNumber: Yup.string().required("This field is required"),

      address1: Yup.string().required("This field is required"),
      stateName: Yup.string().required("This field is required"),
      zipCode: Yup.string().required("This field is required"),
      city: Yup.string().required("This field is required"),
      country: Yup.string().required("This field is required"),
    }),
    // onSubmit: async (values) => {
    //  alert('not_implemented');
    //  }
  });

  const step2Validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true, // to validate event untouched elements: https://github.com/jaredpalmer/formik/issues/1116

    initialValues: {
      monthlyVolume: "",
      useCase: "",

      useCaseSummary: "",
      messageContent: "",

      selectedTns: [],

    },
    validationSchema: Yup.object({
      monthlyVolume: Yup.string().required("This field is required"),
      useCase: Yup.string().required("This field is required"),
      useCaseSummary: Yup.string().required("This field is required"),
      messageContent: Yup.string().required("This field is required"),
      selectedTns: Yup.array().min(1, "Please select at least one toll-free number"),
    }),
    //onSubmit: async (values) => {
    //  alert('not_implemented');
   // }
  });

  const step3Validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true, // to validate event untouched elements: https://github.com/jaredpalmer/formik/issues/1116

    initialValues: {
      optInWorkflow: "",
      optInWorkflowImageUrl: "",
      additionalUseCaseDetails: "",

    },
    validationSchema: Yup.object({
      optInWorkflow: Yup.string().required("This field is required"),
      optInWorkflowImageUrl: Yup.string().required("This field is required"),
      additionalUseCaseDetails: Yup.string().required("This field is required"),

    }),
    onSubmit: async (values) => {
      alert('not_implemented');
    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Toll-Free Verifications"
            titleUrl={`/projects/${currentProjectId}/toll-free-verifications`}
            breadcrumbItem="Add Toll-Free Verification Request" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading &&
                    <>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}>
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                              >
                                <span className="number">1</span>{" "}
                                Business Info
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                              >
                                <span className="number me-2">2</span>{" "}
                                Use Case Summary
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 3 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 3 })}
                              >
                                <span className="number">3</span>{" "}
                                Use case Details
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 4 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 4 })}
                              >
                                <span className="number">4</span>{" "}
                                Review
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          <div className="body">
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={1}>
                                <AddTollFreeVerificationStep1
                                  validation={step1Validation}
                                  onBusinessNameChange={onBusinessNameChange}
                                  onCorporateWebsiteChange={onCorporateWebsiteChange}
                                  onFirstNameChange={onFirstNameChange}
                                  onLastNameChange={onLastNameChange}
                                  onEmailChange={onEmailChange}
                                  onPhoneNumberChange={onPhoneNumberChange}
                                  onAddress1Change={onAddress1Change}
                                  onAddress2Change={onAddress2Change}
                                  onStateNameChange={onStateNameChange}
                                  onZipCodeChange={onZipCodeChange}
                                  onCityChange={onCityChange}
                                  onCountryChange={onCountryChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={
                                        () => {

                                          if (step1Validation.isValid) {
                                            setActiveTab(2);
                                          } else { // see https://github.com/jaredpalmer/formik/issues/2734
                                            step1Validation.setTouched(setNestedObjectValues(step1Validation.errors, true));
                                          }
                                        }
                                      }
                                    >
                                      Next
                                    </Link>
                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={2}>
                                <AddTollFreeVerificationStep2
                                  phoneNumbersList={phoneNumbersList}
                                  validation={step2Validation}
                                  onSelectedTnsChange={onSelectedTnsChange}
                                  onMonthlyVolumeChange={onMonthlyVolumeChange}
                                  onUseCaseChange={onUseCaseChange}
                                  onUseCaseSummaryChange={onUseCaseSummaryChange}
                                  onMessageContentChange={onMessageContentChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(1)}
                                    >
                                      Previous
                                    </Link>

                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={
                                        () => {

                                          if (step2Validation.isValid) {
                                            setActiveTab(3);
                                          } else { // see https://github.com/jaredpalmer/formik/issues/2734
                                            step2Validation.setTouched(setNestedObjectValues(step2Validation.errors, true));
                                          }
                                        }
                                      }
                                    >
                                      Next
                                    </Link>
                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={3}>
                                <AddTollFreeVerificationStep3
                                  validation={step3Validation}
                                  onOptInWorkflowChange={onOptInWorkflowChange}
                                  onOptInWorkflowImageUrlChange={onOptInWorkflowImageUrlChange}
                                  onAdditionalUseCaseDetailsChange={onAdditionalUseCaseDetailsChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(2)}
                                    >
                                      Previous
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={
                                        () => {
                                          if (step3Validation.isValid) {
                                            setActiveTab(4);
                                          } else { // see https://github.com/jaredpalmer/formik/issues/2734
                                            step3Validation.setTouched(setNestedObjectValues(step3Validation.errors, true));
                                          }
                                        }
                                      }
                                    >
                                      Next
                                    </Link>
                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={4}>
                                <AddTollFreeVerificationStep4

                                  selectedTns={selectedTns}
                                  phoneNumbersList={phoneNumbersList}

                                  businessName={businessName}
                                  corporateWebsite={corporateWebsite}
                                  firstName={firstName}
                                  lastName={lastName}
                                  email={email}
                                  phoneNumber={phoneNumber}
                                  address1={address1}
                                  address2={address2}
                                  stateName={stateName}
                                  zipCode={zipCode}
                                  city={city}
                                  country={country}

                                  monthlyVolume={monthlyVolume}
                                  useCase={useCase}
                                  useCaseSummary={useCaseSummary}
                                  messageContent={messageContent}

                                  optInWorkflow={optInWorkflow}
                                  optInWorkflowImageUrl={optInWorkflowImageUrl}
                                  additionalUseCaseDetails={additionalUseCaseDetails}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(3)}
                                    >
                                      Previous
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={() => submitVerificationRequest()}
                                    >
                                      {isCreating && <>
                                        <Spinner size="sm me-1" color="light" />
                                      </>}
                                      {!isCreating && <>
                                        Submit
                                      </>}
                                    </Link>
                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddTollFreeVerification.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddTollFreeVerification)));