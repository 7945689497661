// @flow
import {
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_API_ERROR
} from "./actionTypes";

const INIT_STATE = {
  error: '',
  loading: false,
  updating: false,
};

const AuthChangePassword = (state = INIT_STATE, action) => {
  switch (action.type) {

    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        updating: true,
        error: ''
      };

    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        updating: false,
      };
 
    case CHANGE_PASSWORD_API_ERROR:
      return {
        ...state,
        updating: false,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default AuthChangePassword;
