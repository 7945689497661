import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, InputGroup } from "reactstrap";
import ReactEcharts from "echarts-for-react";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';
import CustomAlert from '../../components/Common/CustomAlert';

import { extractDate, constructErrorMessage } from "../../helpers/utils";

import { projectService } from '../../services';

const baseSubscriptionsOptions = {
  grid: {
    zlevel: 0,
    x: 50,
    x2: 50,
    y: 30,
    y2: 30,
    borderWidth: 0,
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: "rgba(0,0,0,0)",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  //toolbox: {
  // feature: {
  //   dataView: { show: true, readOnly: false },
  //   magicType: { show: true, type: ["line", "bar"] },
  //   restore: { show: true },
  //   saveAsImage: { show: true },
  // },
  //},
  color: ["#02a499", "#ff0000"],
  legend: {
    data: ["Subscribed", "Unsubscribed"],
    textStyle: {
      color: ["#74788d"],
    },
  },
  xAxis: [], // overridden
  yAxis: [
    {
      type: "value",
      name: "Amount",
      position: "right",
      ///min: 0,
      //max: 250,
      //interval: 50,
      axisLabel: {
        // formatter: "{value} ml",
        formatter: "{value}",
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    }
  ],
  series: [], // overridden
  textStyle: {
    color: ["#74788d"],
  },
}

const SubscriptionsReport = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [isLoading, setIsLoading] = useState(false);
  //const [statsMessaging, setStatsMessaging] = useState({});
  const [dateRange, setDateRange] = useState([ undefined, undefined]);
  const [subscribedChartOptions, setSubscribedChartOptions] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const getStatsSubscriptions = useCallback(async (projectId, extractedStartDate, extractedEndDate) => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      let sm = await projectService.fetchProjectStatsSubscriptions(projectId, Intl.DateTimeFormat().resolvedOptions().timeZone, extractedStartDate, extractedEndDate);

      let dateLabels = [];
      let subscribedCounts = [];
      let unsubscribedCounts = []
      if (sm?.statsSubscriptions) {
        dateLabels = sm?.statsSubscriptions.map(s => s.dwDate.replaceAll("T00:00:00Z", ""));

        subscribedCounts = sm?.statsSubscriptions.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.telephoneNumberSubscribedCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart
        unsubscribedCounts = sm?.statsSubscriptions.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), -s.telephoneNumberUnsubscribedCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart
      }

      let subscriptionsOptions = {
        ...baseSubscriptionsOptions,
        xAxis: [
          {
            type: "time",
            data: dateLabels,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#74788d",
              },
            },
            splitLine: {
              show: false, // this controls vertical grid lines
            }
          }
        ],
        series: [
          {
            name: "Subscribed",
            type: "line",
            data: subscribedCounts,
            areaStyle: {},
            smooth: true,
          },
          {
            name: "Unsubscribed",
            type: "line",
            data: unsubscribedCounts,
            areaStyle: {},
            smooth: true,
          }
        ],
      };

      setSubscribedChartOptions(subscriptionsOptions);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentProjectId) {

      var startDate = new Date();
      startDate.setDate(startDate.getDate() - 7); // -7 days for now
      let extractedStartDate = extractDate(startDate);
      let extractedEndDate = extractDate(new Date());
      setDateRange([extractedStartDate, extractedEndDate]);
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (currentProjectId && Array.isArray(dateRange) && dateRange.length === 2) {
      if (dateRange[0] && dateRange[1]) {
        getStatsSubscriptions(currentProjectId, dateRange[0], dateRange[1]);
      }
    }
  }, [dateRange, getStatsSubscriptions, currentProjectId]);


  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Row>
            <Col className="col-form-label d-none d-sm-block">
              <Breadcrumbs breadcrumbItem="Subscriptions Report"/>
            </Col>
            <Col className="col-12 col-sm-6">
              <div className="mb-3 row">
                <Label className="col col-form-label text-end d-none d-lg-block">Date range</Label>
                <InputGroup className="col">
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="select date range"
                    value={dateRange}
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      maxDate: "today",
                    }}
                    onChange={([startDate, endDate]) => {
                      if (startDate && endDate) {
                        setDateRange([extractDate(startDate), extractDate(endDate)]);
                      }
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row>

            <GlobalProgressBar isLoading={isLoading} />
            {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
            {!isLoading &&
              <>
                <Col lg={12} xxl={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>Subscribed vs Unsubscribed trend</CardTitle>
                      <Row>
                        <Col>
                          <ReactEcharts style={{ height: "300px" }} option={subscribedChartOptions} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </>
            }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(SubscriptionsReport));