import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Table } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

const AddTollFreeVerificationStep4 = (props) => {

  let {
    businessName,
    corporateWebsite,
    firstName,
    lastName,
    email,
    address1,
    address2,
    stateName,
    zipCode,
    city,
    country,

    selectedTns,
    phoneNumbersList,
    monthlyVolume,
    useCase,
    useCaseSummary,
    messageContent,

    optInWorkflow,
    optInWorkflowImageUrl,
    additionalUseCaseDetails,
  } = props;

  return (
    <React.Fragment>
      <>
        <p>Let's review your use case details before submitting.</p>
        <Table className="table table-striped table-responsive">
          <tbody>
            <tr>
              <td className="col-4">
                <strong>Business name</strong>
              </td>
              <td className="col-8">
                {businessName}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Corporate website</strong>
              </td>
              <td className="col-8">
                {corporateWebsite}
              </td>
            </tr>
            <tr><td colSpan={2}></td></tr>
            <tr>
              <td className="col-4">
                <strong>First name</strong>
              </td>
              <td className="col-8">
                {firstName}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Last name</strong>
              </td>
              <td className="col-8">
                {lastName}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Email</strong>
              </td>
              <td className="col-8">
                {email}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Phone numbers</strong>
              </td>
              <td className="col-8">
                {/* filtering only selected TNs */}
                {phoneNumbersList.filter(tn => selectedTns.includes(tn.telephoneNumberId)).map(tn => tn.telephoneNumber).join(", ")}
              </td>
            </tr>
            <tr><td colSpan={2}></td></tr>
            <tr>
              <td className="col-4">
                <strong>Address 1</strong>
              </td>
              <td className="col-8">
                {address1}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Address 2</strong>
              </td>
              <td className="col-8">
                {address2 || '-'}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>State</strong>
              </td>
              <td className="col-8">
                {stateName}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Postal code</strong>
              </td>
              <td className="col-8">
                {zipCode}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>City</strong>
              </td>
              <td className="col-8">
                {city}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Country</strong>
              </td>
              <td className="col-8">
                {country}
              </td>
            </tr>
            <tr><td colSpan={2}></td></tr>
            <tr>
              <td className="col-4">
                <strong>Monthly volume</strong>
              </td>
              <td className="col-8">
                {monthlyVolume}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Use case</strong>
              </td>
              <td className="col-8">
                {useCase}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Use case description</strong>
              </td>
              <td className="col-8">
                {useCaseSummary}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Message content</strong>
              </td>
              <td className="col-8">
                {messageContent}
              </td>
            </tr>
            <tr><td colSpan={2}></td></tr>
            <tr>
              <td className="col-4">
                <strong>Opt-in workflow</strong>
              </td>
              <td className="col-8">
                {optInWorkflow}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Opt-in workflow image URL</strong>
              </td>
              <td className="col-8">
                {optInWorkflowImageUrl}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Additional use case details</strong>
              </td>
              <td className="col-8">
                {additionalUseCaseDetails}
              </td>
            </tr>
          </tbody>
        </Table>
      </>
    </React.Fragment>
  )
}

AddTollFreeVerificationStep4.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddTollFreeVerificationStep4)));