import { get } from "../helpers/api_helper";

class ConstantsService {

  fetchCountriesMap() {

    return new Promise((resolve, reject) => {
      get(`/enums/countries`)
        .then(response => {
          resolve(new Map(Object.entries(response.countries)));

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchLanguagesMap() {

    return new Promise((resolve, reject) => {
      get(`/enums/languages`)
        .then(response => {
          resolve(new Map(Object.entries(response.languages)));

        }).catch(error => {
          reject(error);
        })
    });

  }
}

export const constantsService = new ConstantsService();