import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter, Link, useParams } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table, Badge } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { getMessageTypeAndDirectionString, getMessageBadgeCssClassName } from './utils';

import './messages.scss';

import {
  getMessagesForProject
} from "../../store/actions";

const Messages = () => {

  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const messagesList = useSelector((state) => state.Message.messagesList);
  const messagesLimit = useSelector((state) => state.Message.messagesLimit);
  const messagesOffset = useSelector((state) => state.Message.messagesOffset);
  const isLoading = useSelector((state) => state.Message.loading);

  const pageSize = messagesLimit || 20;

  const { direction } = useParams();

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getMessagesForProject(currentProjectId, direction, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize, direction]);

  const onNextPageClick = () => {
    if (messagesList && messagesList?.length >= pageSize) {
      dispatch(getMessagesForProject(currentProjectId, direction, pageSize, messagesOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (messagesOffset !== 0) {
      dispatch(getMessagesForProject(currentProjectId, direction, pageSize, messagesOffset - pageSize));
    }
  }

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs title="SMS/MMS" breadcrumbItem={direction === "inbound" ? "Inbound Messages" : (direction === "outbound" ? "Outbound Messages" : "All Messages")} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      <GlobalProgressBar isLoading={isLoading} />
                      {!isLoading &&
                        <Table className="table-striped table-responsive">
                          <thead>
                            <tr>
                              <th>Message</th>
                              <th>From</th>
                              <th>To</th>
                              <th>Sent date</th>
                              <th>Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {(!messagesList || !Array.isArray(messagesList) || messagesList.length === 0) && <tr><td colSpan={5} className="text-center">No messages yet</td></tr>}
                            {
                              messagesList && Array.isArray(messagesList) && messagesList.length > 0 && messagesList.map((m, i) => <tr key={i} className="align-middle">
                                <td>
                                  <span>{getMessageTypeAndDirectionString(m)}</span><br />
                                  {(m?.status !== 'created' && m?.status !== 'receiving' && m?.status !== 'queued' && m?.status !== 'queuing') &&
                                    <Link to={`/projects/${currentProjectId}/messages/${m.messageId}`} title="Go to message details" className="small">
                                      {m.messageId}
                                    </Link>}
                                  {(m?.status === 'created' || m?.status === 'receiving' || m?.status === 'queued' || m?.status === 'queuing') &&
                                    <span>
                                      {m.messageId}
                                    </span>}
                                </td>
                                <td>
                                  {m.from}
                                </td>
                                <td>{!m.to ? '' : m.to.join(", ")}</td>
                                <td>{!m.sentTimestampMs ? '' : new Date(m.sentTimestampMs).toLocaleString()}</td>
                                <td>
                                  <span className='text-uppercase'><Badge className={getMessageBadgeCssClassName(m)}>
                                    {m?.status ? m?.status.replaceAll("_", " ") : ""}
                                  </Badge></span>
                                </td>
                              </tr>)
                            }
                          </tbody>
                        </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {!isLoading &&
                        <div className="actions clearfix">
                          <ul>
                            <li
                              className={
                                messagesOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary w-md"
                                onClick={onPreviousPageClick}
                              >
                                Previous
                              </Link>
                            </li>
                            <li
                              className={
                                (!messagesList || messagesList?.length === 0 || messagesList?.length < pageSize) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary w-md"
                                onClick={onNextPageClick}
                              >
                                Next
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Messages.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    message: state.Message,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Messages)));