import React from "react";
import { Card, CardBody } from "reactstrap";

const EmptyCard = ({ children }) => {

  return (
    <Card className="empty-card">
      <CardBody className="d-flex align-items-center justify-content-center">
        {children}
      </CardBody>
    </Card>
  );

}

export default EmptyCard;
