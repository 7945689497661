import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Link, withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Row, Col, Label, Button, Spinner, Badge, Container, Card, CardBody } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import CustomAlert from '../../components/Common/CustomAlert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, clearHistoryState } from '../../helpers/utils';

import { getTollFreeVerificationStatusBadgeCssClassName } from './utils';
import { telephoneNumberService } from '../../services';

const TollFreeVerificationDetails = () => {

  const location = useLocation();
  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  // getting verificationId fromURL
  const { verificationId: currentVerificationId } = useParams();

  const [tollFreeVerification, setTollFreeVerification] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState('');

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    if (redirectStatus === 'create-tfv-succeeded') {
      setSuccessMessage("Toll-free verification request was successfully registered");
    }

    if (redirectStatus === 'update-tfv-succeeded') {
      setSuccessMessage("Toll-free verification request was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  const loadTollFreeVerification = useCallback(async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      let resp = await telephoneNumberService.fetchTollFreeVerificationDetails(currentProjectId, currentVerificationId);

      setTollFreeVerification(resp);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, [currentProjectId, currentVerificationId]);

  useEffect(() => {
    if (currentProjectId && currentVerificationId) {
      loadTollFreeVerification(currentProjectId, currentVerificationId);
    }
  }, [currentProjectId, currentVerificationId, loadTollFreeVerification]);


  const onDeleteTollFreeVerificationClick = () => {
    setConfirmDialogText(`Are you sure you want to delete this verification?`);

    setOnConfirmFunc(() => async () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      try {

        setIsDeleting(true);
        setErrorMessage('');

        await telephoneNumberService.deleteTollFreeVerification(currentProjectId, currentVerificationId);
        history.push(`/projects/${currentProjectId}/toll-free-verifications`, { redirectStatus: `delete-tfv-succeeded` });
      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsDeleting(false);
      }

    });

    setIsConfirmDialogOpen(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Toll-Free Verifications"
            pageTitle = "Toll-Free Verification Details"
            titleUrl={`/projects/${currentProjectId}/toll-free-verifications`}
            breadcrumbItem={currentVerificationId} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{constructErrorMessage(errorMessage)}</CustomAlert> : null}
                  {!isLoading &&
                    <div className="toll-free-verification-info">
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">Business name</Label>
                          <p>{tollFreeVerification?.businessName}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Corporate website</Label>
                          <p>{tollFreeVerification?.corporateWebsite}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">Status</Label>
                          <p>
                            <span className='text-uppercase h5'>
                              <Badge className={getTollFreeVerificationStatusBadgeCssClassName(tollFreeVerification) + " rounded-pill"}>
                                {tollFreeVerification?.verificationStatus ? tollFreeVerification?.verificationStatus.replaceAll("_", " ") : ""}
                              </Badge>
                            </span>
                          </p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Request submitted on</Label>
                          <p>{!tollFreeVerification?.createdTimestampMs ? '' : new Date(tollFreeVerification?.createdTimestampMs).toLocaleString()}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">First name</Label>
                          <p>{tollFreeVerification?.firstName}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Last name</Label>
                          <p>{tollFreeVerification?.lastName}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">Email</Label>
                          <p>{tollFreeVerification?.email}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Phone number</Label>
                          <p>{tollFreeVerification?.phoneNumber}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">Address 1</Label>
                          <p>{tollFreeVerification?.address1}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Address 2</Label>
                          <p>{tollFreeVerification?.address2}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">State</Label>
                          <p>{tollFreeVerification?.stateName}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">ZIP code</Label>
                          <p>{tollFreeVerification?.zipCode}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">City</Label>
                          <p>{tollFreeVerification?.city}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Country</Label>
                          <p>{tollFreeVerification?.country}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label className="form-label">Phone numbers</Label>
                          <p>{tollFreeVerification?.telephoneNumbers?.map((tn, idx) => <span key={`tn_${idx}`}>
                            <Link to={`/projects/${currentProjectId}/numbers/${tn.telephoneNumberId}`} target="_blank">{tn.telephoneNumber}</Link>
                            {idx !== tollFreeVerification?.telephoneNumbers?.length-1 ? ', ' : ''}</span>
                          )}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Label className="form-label">Monthly volume</Label>
                          <p>{tollFreeVerification?.monthlyVolume}</p>
                        </Col>
                        <Col md={6}>
                          <Label className="form-label">Use case</Label>
                          <p>{tollFreeVerification?.useCase}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label className="form-label">Use case summary</Label>
                          <p>{tollFreeVerification?.useCaseSummary}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label className="form-label">Message content</Label>
                          <p>{tollFreeVerification?.messageContent}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label className="form-label">Opt-in workflow</Label>
                          <p>{tollFreeVerification?.optInWorkflow}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label className="form-label">Opt-in workflow image URL</Label>
                          <p>{tollFreeVerification?.optInWorkflowImageUrl}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Label className="form-label">Additional use case details</Label>
                          <p>{tollFreeVerification?.additionalUseCaseDetails}</p>
                        </Col>
                      </Row>


                      <Row className="mb-3">
                        <div className="d-flex flex-wrap gap-3 mt-3">
                          <Button
                            type="reset"
                            color="danger"
                            outline
                            className="w-md"
                            onClick={onDeleteTollFreeVerificationClick}
                          >
                            {isDeleting && <>
                              <Spinner size="sm me-1" color="danger" />
                            </>}
                            {!isDeleting && <>
                              Delete
                            </>}
                          </Button>
                        </div>
                      </Row>
                    </div>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

TollFreeVerificationDetails.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(TollFreeVerificationDetails)));