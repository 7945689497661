import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Container, Row, Col, Card, CardBody, Table, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, textOrEmpty, clearHistoryState } from '../../helpers/utils';

import './contacts.scss';

import {
  getContactGroupsForProject,
  setContactsGroupLimit,
  deleteContactGroups
} from "../../store/actions";

const ContactsGroups = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const contactGroupsList = useSelector((state) => state.Contact.contactGroupsList);
  const contactGroupsLimit = useSelector((state) => state.Contact.contactGroupsLimit);
  const contactGroupsOffset = useSelector((state) => state.Contact.contactGroupsOffset);
  const contactGroupsTotalCount = useSelector((state) => state.Contact.contactGroupsTotalCount);
  const isLoading = useSelector((state) => state.Contact.loading);
  const apiError = useSelector((state) => state.Contact.error);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [btnActionOpen, setBtnActionOpen] = useState(false);
  const [contactGroupSelectedIds, setContactGroupSelectedIds] = useState(new Map());

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const pageSize = contactGroupsLimit || 20;

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      dispatch(getContactGroupsForProject(currentProjectId, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize]);

  const dataRefresh = useCallback(() => {
    if (currentProjectId) {
      dispatch(getContactGroupsForProject(currentProjectId, contactGroupsLimit, contactGroupsOffset));
    }
  }, [dispatch, currentProjectId, contactGroupsLimit, contactGroupsOffset]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [currentProjectId, initialDataLoad]);

  useEffect(() => {

    if (redirectStatus === 'delete-contacts-group-succeeded') {
      setSuccessMessage("Contacts group was successfully deleted");
    }

    if (redirectStatus === 'delete-contacts-groups-succeeded') {
      setSuccessMessage("Contacts groups were successfully deleted");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

    setContactGroupSelectedIds(new Map());

  }, [redirectStatus, history]);

  const onNextPageClick = () => {
    if (contactGroupsOffset + pageSize < contactGroupsTotalCount) {
      dispatch(getContactGroupsForProject(currentProjectId, pageSize, contactGroupsOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (contactGroupsOffset !== 0) {
      dispatch(getContactGroupsForProject(currentProjectId, pageSize, contactGroupsOffset - pageSize));
    }
  }

  const onDeleteContactGroupsClick = () => {
    let selectedIds = [...contactGroupSelectedIds.keys()];

    setConfirmDialogText(`Are you sure you want to delete selected group${selectedIds.length > 1 ? 's' : ''}? Contacts inside groups will not be deleted.`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteContactGroups(currentProjectId, selectedIds, () => {
        dispatch(getContactGroupsForProject(currentProjectId, pageSize, 0));
        history.push(`/projects/${currentProjectId}/directory/contacts/groups`, { redirectStatus: `delete-contacts-group${selectedIds.length > 1 ? 's' : ''}-succeeded` });
      }));
    });

    setIsConfirmDialogOpen(true);
  }

  const handleContactGroupSelectedIdsChange = (e) => {
    let groupId = e?.target?.value;

    if (!groupId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (contactGroupSelectedIds.has(groupId)) {
      let m = contactGroupSelectedIds;
      m.delete(groupId);
      setContactGroupSelectedIds(new Map(m));
    } else {
      let m = contactGroupSelectedIds;
      m.set(groupId, true);
      setContactGroupSelectedIds(new Map(m));
    }
  }

  const rowCheckboxIsChecked = (contactGroupId) => {
    return contactGroupSelectedIds.has(contactGroupId);
  }

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (isLoading === undefined || isLoading === true) {
      return undefined;
    }

    return Array.isArray(contactGroupsList) && contactGroupsList.length > 0;
  }, [isLoading, contactGroupsList]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Contacts Groups" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                      {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
                      <div className="actions clearfix">
                        <ul>
                          {isDataAvailable() && <li>
                            <Dropdown
                              isOpen={btnActionOpen}
                              toggle={() => setBtnActionOpen(!btnActionOpen)}
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-secondary"
                              >
                                Actions <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem disabled={!contactGroupSelectedIds || [...contactGroupSelectedIds.keys()].length === 0} onClick={onDeleteContactGroupsClick}>Delete selected</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </li>}
                          {isDataAvailable() && <li>
                            <Link
                              to={`/projects/${currentProjectId}/directory/contacts/groups/add`}
                              className="btn btn-success"
                            >
                              + New group
                            </Link>
                          </li>}
                          <button
                            className="btn refresh without-outline"
                            type="button"
                            title="Refresh"
                            onClick={dataRefresh}
                          >
                            <i className='mdi mdi-reload h3' />
                          </button>
                        </ul>
                      </div>
                      <GlobalProgressBar isLoading={isLoading} />
                      {isDataAvailable() === false && <EmptyBlock>
                        <div className="text-center">
                          <p className="h3">No Groups</p>
                          <p>Your contacts groups will appear here</p>
                          <p>
                            <Link
                              to={`/projects/${currentProjectId}/directory/contacts/groups/add`}
                              className="btn btn-success"
                            >
                              + New group
                            </Link>
                          </p>
                        </div>
                      </EmptyBlock>}
                      {isDataAvailable() && <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Created date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactGroupsList.map((cg, i) => <tr key={i} className={rowCheckboxIsChecked(cg.contactGroupId) ? "align-middle table-info" : "align-middle"}>
                            <td style={{ width: "2rem" }}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={cg.contactGroupId}
                                onChange={handleContactGroupSelectedIdsChange}
                                id={`chk-contact-group-${i}`}
                                defaultChecked={rowCheckboxIsChecked(cg.contactGroupId)}
                              />
                            </td>
                            <td>
                              <Link to={`/projects/${currentProjectId}/directory/contacts/groups/${cg.contactGroupId}`} title="Go to contact group details">
                                {cg.title}
                              </Link>
                            </td>
                            <td>{textOrEmpty(cg.description)}</td>
                            <td>{!cg.createdTimestampMs ? '' : new Date(cg.createdTimestampMs).toLocaleString()}</td>
                          </tr>)
                          }
                        </tbody>
                      </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {(isDataAvailable() || contactGroupsTotalCount > 0) &&
                        <div className="actions clearfix">
                          <ul className='d-flex align-items-center justify-content-end'>
                            <li>
                              {/* we need Number() because without it it uses "String" */}
                              <select
                                className="form-control cursor-pointer"
                                onChange={(e) => dispatch(setContactsGroupLimit(Number(e?.target?.value)))}
                                value={pageSize}
                              >
                                <option value="10">10 rows per page</option>
                                <option value="20">20 rows per page</option>
                                <option value="50">50 rows per page</option>
                                <option value="100">100 rows per page</option>
                                <option value="250">250 rows per page</option>
                              </select>
                            </li>
                            <li
                              className={
                                contactGroupsOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                <i className="fa fa-chevron-left" />
                              </Link>
                            </li>
                            <li
                              className={
                                (contactGroupsOffset + pageSize >= contactGroupsTotalCount) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                <i className="fa fa-chevron-right" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

ContactsGroups.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    contact: state.Contact,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ContactsGroups)));