import { all, fork, call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import {
  GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST,
  GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST,
  GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_REQUEST,
  TOP_UP_BALANCE_REQUEST
} from "./actionTypes";
import {
  getPaymentsHistoryForProjectSuccess,
  getPaymentMethodsForProjectSuccess,
  getSetupIntentForPaymentMethodSuccess,
  topUpBalanceSuccess,
  deletePaymentMethodSuccess,
  billingApiError
} from "./actions";

//Include Both Helper File with needed methods
import { billingService} from "../../services";

function* getPaymentsHistory({ payload: {projectId, limit, offset} }) {
  try {
    const response = yield call(
      billingService.fetchPaymentsHistory,
      projectId,
      limit,
      offset
    )
    yield put(getPaymentsHistoryForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(billingApiError(error));
  }
}

function* getPaymentMethods({ payload: projectId }) {
  try {
    const response = yield call(
      billingService.fetchPaymentMethods,
      projectId
    )
    yield put(getPaymentMethodsForProjectSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(billingApiError(error));
  }
}

function* getSetupIntentForPaymentMethod({ payload: projectId }) {
  try {
    const response = yield call(
      billingService.fetchSetupIntentForPaymentMethod,
      projectId
    )
    yield put(getSetupIntentForPaymentMethodSuccess(response));
  } catch (error) {
    console.log(error);
    yield put(billingApiError(error));
  }
}

function* deletePaymentMethod({ payload: {projectId, paymentMethodId, callback} }) {
  try {
    const response = yield call(
      billingService.deletePaymentMethod,
      projectId,
      paymentMethodId
    )
    yield put(deletePaymentMethodSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(billingApiError(error));
  }
}

function* topUpBalance({ payload: {projectId, amount, paymentMethodId, callback} }) {
  try {
    const response = yield call(
      billingService.topUpBalance,
      projectId,
      paymentMethodId,
      amount
    )
    yield put(topUpBalanceSuccess(response));
    if (callback) {
      callback();
    }
  } catch (error) {
    console.log(error);
    yield put(billingApiError(error));
  }
}

function* watchPaymentsHistoryRequests() {
  yield takeEvery(GET_PAYMENTS_HISTORY_FOR_PROJECT_REQUEST, getPaymentsHistory)
}

function* watchPaymentMethodsRequests() {
  yield takeEvery(GET_PAYMENT_METHODS_FOR_PROJECT_REQUEST, getPaymentMethods)
}

function* watchSetupIntentForPaymentMethodsRequests() {
  yield takeEvery(GET_SETUP_INTENT_FOR_PAYMENT_METHOD_REQUEST, getSetupIntentForPaymentMethod)
}

function* watchDeletePaymentMethodRequests() {
  yield takeLatest(DELETE_PAYMENT_METHOD_REQUEST, deletePaymentMethod)
}

function* watchTopUpBalanceRequests() {
  yield takeLatest(TOP_UP_BALANCE_REQUEST, topUpBalance)
}

function* billingSaga() {
  yield all([
    fork(watchPaymentsHistoryRequests),
    fork(watchPaymentMethodsRequests),
    fork(watchSetupIntentForPaymentMethodsRequests),
    fork(watchTopUpBalanceRequests),
    fork(watchDeletePaymentMethodRequests),
  ]);
}

export default billingSaga;
