import {
  GET_BRANDS_FOR_PROJECT_REQUEST,
  GET_BRANDS_FOR_PROJECT_SUCCESS,
  GET_BRAND_DETAILS_REQUEST,
  GET_BRAND_DETAILS_SUCCESS,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  GET_CAMPAIGNS_REQUEST,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGN_DETAILS_REQUEST,
  GET_CAMPAIGN_DETAILS_SUCCESS,
  DELETE_CAMPAIGN_REQUEST,
  DELETE_CAMPAIGN_SUCCESS,
  TCR_API_ERROR
} from "./actionTypes";

export const getBrandsForProject = (projectId, limit, offset) => {
  return {
    type: GET_BRANDS_FOR_PROJECT_REQUEST,
    payload: { projectId, limit, offset }
  }
};

export const getBrandsForProjectSuccess = ({ brands: brandsList, offset: brandsOffset, limit: brandsLimit, totalCount: brandsTotalCount }) => {
  return {
    type: GET_BRANDS_FOR_PROJECT_SUCCESS,
    payload: { brandsList, brandsOffset, brandsLimit, brandsTotalCount }
  }
};

export const getBrandDetails = (projectId, brandId) => {
  return {
    type: GET_BRAND_DETAILS_REQUEST,
    payload: { projectId, brandId }
  }
};

export const getBrandDetailsSuccess = brandDetails => {
  return {
    type: GET_BRAND_DETAILS_SUCCESS,
    payload: brandDetails
  }
};

export const deleteBrand = (projectId, brandId, callback) => {
  return {
    type: DELETE_BRAND_REQUEST,
    payload: { projectId, brandId, callback }
  }
};

export const deleteBrandSuccess = () => {
  return {
    type: DELETE_BRAND_SUCCESS,
  }
};

export const getCampaigns = (projectId, brandId, limit, offset) => {
  return {
    type: GET_CAMPAIGNS_REQUEST,
    payload: { projectId, brandId, limit, offset }
  }
};

export const getCampaignsSuccess = ({ campaigns: campaignsList, offset: campaignsOffset, limit: campaignsLimit, totalCount: campaignsTotalCount }) => {
  return {
    type: GET_CAMPAIGNS_SUCCESS,
    payload: { campaignsList, campaignsOffset, campaignsLimit, campaignsTotalCount }
  }
};

export const getCampaignDetails = (projectId, brandId, campaignId) => {
  return {
    type: GET_CAMPAIGN_DETAILS_REQUEST,
    payload: { projectId, brandId, campaignId }
  }
};

export const getCampaignDetailsSuccess = campaignDetails => {
  return {
    type: GET_CAMPAIGN_DETAILS_SUCCESS,
    payload: campaignDetails
  }
};

export const deleteCampaign = (projectId, brandId, campaignId, callback) => {
  return {
    type: DELETE_CAMPAIGN_REQUEST,
    payload: { projectId, brandId, campaignId, callback }
  }
};

export const deleteCampaignSuccess = () => {
  return {
    type: DELETE_CAMPAIGN_SUCCESS,
  }
};

export const tcrApiError = error => {
  return {
    type: TCR_API_ERROR,
    payload: error,
  }
}