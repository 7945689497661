import React from "react";
import { Container, Row, Col } from "reactstrap";

import config from "../../config";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © Built by Corelink Telecom</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                {config?.APP_VERSION && <span>
                  version {config?.APP_VERSION}
                </span>
                }
                &nbsp;
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
