import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Container, Row, Col, Card, CardBody, Table, Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle, Form
} from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import ContactPanel from './ContactPanel';

import { constructErrorMessage, textOrEmpty, clearHistoryState } from '../../helpers/utils';

import './contacts.scss';

import {
  getContactsForProject,
  setContactsLimit,
  deleteContacts
} from "../../store/actions";

const Contacts = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const contactsList = useSelector((state) => state.Contact.contactsList);
  const contactsGlobalFilter = useSelector((state) => state.Contact.contactsGlobalFilter);
  const contactsLimit = useSelector((state) => state.Contact.contactsLimit);
  const contactsOffset = useSelector((state) => state.Contact.contactsOffset);
  const contactsTotalCount = useSelector((state) => state.Contact.contactsTotalCount);
  const isLoading = useSelector((state) => state.Contact.loading);
  const apiError = useSelector((state) => state.Contact.error);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [btnActionOpen, setBtnActionOpen] = useState(false);
  const [contactSelectedIds, setContactSelectedIds] = useState(new Map());
  const [searchTimer, setSearchTimer] = useState(null);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const [searchFilterInput, setSearchFilterInput] = useState(''); // this is just to save a search value in the input

  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);
  const [panelContactId, setPanelContactId] = useState('');

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  let pageSize = contactsLimit || 20;
  const loadContacts = useCallback((filter, projectId, limit, offset) => {
    setSearchFilterInput(filter);
    dispatch(getContactsForProject(filter, projectId, limit, offset));
  }, [dispatch]);

  useEffect(() => {


    if (redirectStatus === 'create-contact-succeeded') {
      setSuccessMessage("Contact was successfully created");
    }

    if (redirectStatus === 'update-contact-succeeded') {
      setSuccessMessage("Contact was successfully updated"); // see onContactUpdated() also
    }

    if (redirectStatus === 'delete-contact-succeeded') {
      setSuccessMessage("Contact was successfully deleted"); // see onContactDeleted() also
    }

    if (redirectStatus === 'delete-contacts-succeeded') {
      setSuccessMessage("Contacts were successfully deleted");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

    setContactSelectedIds(new Map());

  }, [redirectStatus, history]);

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      loadContacts('', currentProjectId, pageSize, 0);
    }
  }, [loadContacts, currentProjectId, pageSize]);

  const dataRefresh = useCallback(() => {
    if (currentProjectId) {
      loadContacts(contactsGlobalFilter, currentProjectId, contactsLimit, contactsOffset);
    }
  }, [loadContacts, contactsGlobalFilter, currentProjectId, contactsLimit, contactsOffset]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  const onNextPageClick = () => {
    if (contactsOffset + pageSize < contactsTotalCount) {
      loadContacts(contactsGlobalFilter, currentProjectId, pageSize, contactsOffset + pageSize);
    }
  }

  const onPreviousPageClick = () => {
    if (contactsOffset !== 0) {
      loadContacts(contactsGlobalFilter, currentProjectId, pageSize, contactsOffset - pageSize);
    }
  }

  const onDeleteContactsClick = () => {
    let selectedIds = [...contactSelectedIds.keys()];
    setConfirmDialogText(`Are you sure you want to delete selected ${selectedIds.length > 1 ? selectedIds.length : ''} contact${selectedIds.length > 1 ? 's' : ''}?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteContacts(currentProjectId, selectedIds, () => {
        loadContacts(contactsGlobalFilter, currentProjectId, pageSize, 0);
        history.push(`/projects/${currentProjectId}/directory/contacts`, { redirectStatus: `delete-contact${selectedIds.length > 1 ? 's' : ''}-succeeded` });
      }));
    });

    setIsConfirmDialogOpen(true);
  }

  const handleContactSelectedIdsChange = (e) => {
    let contactId = e?.target?.value;

    if (!contactId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (contactSelectedIds.has(contactId)) {
      let m = contactSelectedIds;
      m.delete(contactId);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    } else {
      let m = contactSelectedIds;
      m.set(contactId, true);
      setContactSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }
  }

  const handleSearchFilterInputChange = (e) => {
    setSearchFilterInput(e?.target?.value);

    // Clears running timer and starts a new one each time the user types
    clearTimeout(searchTimer);
    setSearchTimer(setTimeout(() => {
      loadContacts(e?.target?.value, currentProjectId, pageSize, 0);
      setContactSelectedIds(new Map());
    }, 1000));

  }

  const handleSearchFilterInputBlur = (e) => {
    if (searchFilterInput !== contactsGlobalFilter) {
      clearTimeout(searchTimer); // cancel any ongoing searching
      loadContacts(searchFilterInput, currentProjectId, pageSize, 0);
      setContactSelectedIds(new Map());
    }
  }

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (currentProjectId) {
      clearTimeout(searchTimer); // cancel any ongoing searching
      loadContacts(searchFilterInput, currentProjectId, pageSize, 0);
      setContactSelectedIds(new Map());
    }
    return false;
  }

  const rowCheckboxIsChecked = (contactId) => {
    return contactSelectedIds.has(contactId);
  }

  const onContactUpdated = useCallback(() => {
    setIsContactPanelOpen(false);
    dataRefresh();
    setSuccessMessage("Contact was successfully updated");
    setTimeout(() => { // clearing the message to avoid showing it multiple times
      setSuccessMessage('');
    }, 1000);
  }, [dataRefresh]);

  const onContactDeleted = useCallback(() => {
    setIsContactPanelOpen(false);
    dataRefresh();
    setSuccessMessage("Contact was successfully deleted");
    setTimeout(() => { // clearing the message to avoid showing it multiple times
      setSuccessMessage('');
    }, 1000);
  }, [dataRefresh]);

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (isLoading === undefined || isLoading === true) {
      return undefined;
    }

    return Array.isArray(contactsList) && contactsList.length > 0;
  }, [isLoading, contactsList]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Contacts" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                      {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
                      <div className="actions clearfix">
                        <ul>
                          {!isLoading && <li>
                            <Form className="app-search without-margin without-padding d-none d-lg-block" onSubmit={handleSearchSubmit} >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control "
                                  placeholder="Search..."
                                  onChange={handleSearchFilterInputChange}
                                  value={searchFilterInput}
                                  onBlur={handleSearchFilterInputBlur}
                                />
                                <span className="uil-search"></span>
                              </div>
                            </Form>
                          </li>}
                          {isDataAvailable() === true && <>
                          <li>
                            <Dropdown
                              isOpen={btnActionOpen}
                              toggle={() => setBtnActionOpen(!btnActionOpen)}
                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-secondary"
                              >
                                Actions <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem disabled={!contactSelectedIds || [...contactSelectedIds.keys()].length === 0} onClick={onDeleteContactsClick}>Delete selected</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </li>
                          <li>
                            <Link
                              to={`/projects/${currentProjectId}/directory/contacts/add`}
                              className="btn btn-success"
                              role="button"
                            >
                              + New contact
                            </Link>
                          </li>
                          </>}
                          <li>
                            <button
                              className="btn refresh without-outline"
                              type="button"
                              title="Refresh"
                              onClick={dataRefresh}
                            >
                              <i className='mdi mdi-reload h3' />
                            </button>
                          </li>
                        </ul>
                      </div>
                      <GlobalProgressBar isLoading={isLoading} />
                      {isDataAvailable() === false && <EmptyBlock>
                        <div className="text-center">
                          <p className="h3">No Contacts</p>
                          <p>Your contacts will appear here.</p>
                          <p>
                            <Link
                              to={`/projects/${currentProjectId}/directory/contacts/add`}
                              className="btn btn-success"
                              role="button"
                            >
                              + New contact
                            </Link>
                          </p>
                        </div>
                      </EmptyBlock>}
                      {isDataAvailable() && <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Phone or ID</th>
                            <th>First name</th>
                            <th>Last name</th>
                            <th>Created date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactsList.map((c, i) => <tr key={i} className={rowCheckboxIsChecked(c.contactId) ? "align-middle table-info" : "align-middle"}>
                            <td style={{ width: "2rem" }}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={c.contactId}
                                onChange={handleContactSelectedIdsChange}
                                id={`chk-contact-${i}`}
                                defaultChecked={rowCheckboxIsChecked(c.contactId)}
                              />
                            </td>
                            <td>
                              <Link
                                to={`/projects/${currentProjectId}/directory/contacts/${c.contactId}`}
                                onClick={(e) => { setIsContactPanelOpen(true); setPanelContactId(c.contactId); return e.preventDefault(); }}
                                title="Go to contact details"
                              >
                                {c.phoneNumber ?? c.contactId}
                              </Link>
                            </td>
                            <td>
                              {textOrEmpty(c.firstName)}
                            </td>
                            <td>
                              {textOrEmpty(c.lastName)}
                            </td>
                            <td>{!c.createdTimestampMs ? '' : new Date(c.createdTimestampMs).toLocaleString()}</td>
                          </tr>)
                          }
                        </tbody>
                      </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {(isDataAvailable() || contactsTotalCount > 0) &&
                        <div className="actions clearfix">
                          <ul className='d-flex align-items-center justify-content-end'>
                            <li>
                              {/* we need Number() because without it it uses "String" */}
                              <select
                                className="form-control cursor-pointer"
                                onChange={(e) => dispatch(setContactsLimit(Number(e?.target?.value)))}
                                value={pageSize}
                              >
                                <option value="10">10 rows per page</option>
                                <option value="20">20 rows per page</option>
                                <option value="50">50 rows per page</option>
                                <option value="100">100 rows per page</option>
                                <option value="250">250 rows per page</option>
                              </select>
                            </li>
                            <li
                              className={
                                contactsOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                <i className="fa fa-chevron-left" />
                              </Link>
                            </li>
                            <li
                              className={
                                (contactsOffset + pageSize >= contactsTotalCount) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                <i className="fa fa-chevron-right" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
      <ContactPanel
        isOpen={isContactPanelOpen}
        toggle={() => setIsContactPanelOpen(!isContactPanelOpen)}
        currentProjectId={currentProjectId}
        currentContactId={panelContactId}
        onUpdateCallback={onContactUpdated}
        onDeleteCallback={onContactDeleted}
      />
    </React.Fragment>
  )
}

Contacts.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    contact: state.Contact,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Contacts)));