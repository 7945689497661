import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, NavItem, NavLink, TabPane, TabContent } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';


// Formik Validation
import * as Yup from "yup";
import { useFormik, setNestedObjectValues } from "formik";

import './tcr.scss';

import { tcrService } from "../../services";

import { constructErrorMessage } from "../../helpers/utils";

import AddCampaignStep1 from './AddCampaignStep1';
import AddCampaignStep2 from './AddCampaignStep2';
import AddCampaignStep3 from './AddCampaignStep3';
import AddCampaignStep4 from './AddCampaignStep4';
import AddCampaignStep5 from './AddCampaignStep5';

const AddCampaign = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message
  const [activeTab, setActiveTab] = useState(1);

  const [selectedBrandId, setSelectedBrandId] = useState('');
  const [selectedBrandUseCase, setSelectedBrandUseCase] = useState('');
  const [selectedBrandSubUseCaseList, setSelectedBrandSubUseCaseList] = useState([]);
  const [minSubUseCases, setMinSubUseCases] = useState(0);
  const [maxSubUseCases, setMaxSubUseCases] = useState(0);
  const [excludedMnosList, setExcludedMnosList] = useState([]);
  const [allMnosList, setAllMnosList] = useState([]);

  const [campaignDescription, setCampaignDescription] = useState('');
  const [messageFlow, setMessageFlow] = useState('');
  const [subscriberOptin, setSubscriberOptin] = useState(false);
  const [optinMessage, setOptinMessage] = useState('');
  const [optinKeywords, setOptinKeywords] = useState('');
  const [subscriberOptout, setSubscriberOptout] = useState(false);
  const [optoutMessage, setOptoutMessage] = useState('');
  const [optoutKeywords, setOptoutKeywords] = useState('');
  const [subscriberHelp, setSubscriberHelp] = useState(false);
  const [helpMessage, setHelpMessage] = useState('');
  const [helpKeywords, setHelpKeywords] = useState('');
  const [numberPool, setNumberPool] = useState(false);
  const [directLending, setDirectLending] = useState(false);
  const [embeddedLink, setEmbeddedLink] = useState(false);
  const [embeddedPhone, setEmbeddedPhone] = useState(false);
  const [ageGated, setAgeGated] = useState(false);

  const [sampleMessage1, setSampleMessage1] = useState('');
  const [sampleMessage2, setSampleMessage2] = useState('');
  const [sampleMessage3, setSampleMessage3] = useState('');
  const [sampleMessage4, setSampleMessage4] = useState('');
  const [sampleMessage5, setSampleMessage5] = useState('');
  const [sampleMediaFiles, setSampleMediaFiles] = useState([]);

  const onBrandIdChange = useCallback((brandId) => setSelectedBrandId(brandId), []);
  const onBrandUseCaseChange = useCallback((useCase) => setSelectedBrandUseCase(useCase), []);
  const onBrandSubUseCaseListChange = useCallback((useCaseList) => setSelectedBrandSubUseCaseList(useCaseList), []);
  const onMinSubUseCasesChange = useCallback((amount) => setMinSubUseCases(amount), []);
  const onMaxSubUseCasesChange = useCallback((amount) => setMaxSubUseCases(amount), []);
  const onExcludedMnosListChange = useCallback((list) => setExcludedMnosList(list), []);
  const onAllMnosListChange = useCallback((list) => setAllMnosList(list), []);

  const onCampaignDescriptionChange = useCallback((v) => setCampaignDescription(v), []);
  const onMessageFlowChange = useCallback((v) => setMessageFlow(v), []);
  const onSubscriberOptinChange = useCallback((v) => setSubscriberOptin(v), []);
  const onOptinMessageChange = useCallback((v) => setOptinMessage(v), []);
  const onOptinKeywordsChange = useCallback((v) => setOptinKeywords(v), []);
  const onSubscriberOptoutChange = useCallback((v) => setSubscriberOptout(v), []);
  const onOptoutMessageChange = useCallback((v) => setOptoutMessage(v), []);
  const onOptoutKeywordsChange = useCallback((v) => setOptoutKeywords(v), []);
  const onSubscriberHelpChange = useCallback((v) => setSubscriberHelp(v), []);
  const onHelpMessageChange = useCallback((v) => setHelpMessage(v), []);
  const onHelpKeywordsChange = useCallback((v) => setHelpKeywords(v), []);
  const onNumberPoolChange = useCallback((v) => setNumberPool(v), []);
  const onDirectLendingChange = useCallback((v) => setDirectLending(v), []);
  const onEmbeddedLinkChange = useCallback((v) => setEmbeddedLink(v), []);
  const onEmbeddedPhoneChange = useCallback((v) => setEmbeddedPhone(v), []);
  const onAgeGatedChange = useCallback((v) => setAgeGated(v), []);

  const onSampleMessage1Change = useCallback((v) => setSampleMessage1(v), []);
  const onSampleMessage2Change = useCallback((v) => setSampleMessage2(v), []);
  const onSampleMessage3Change = useCallback((v) => setSampleMessage3(v), []);
  const onSampleMessage4Change = useCallback((v) => setSampleMessage4(v), []);
  const onSampleMessage5Change = useCallback((v) => setSampleMessage5(v), []);

  const onSampleMediaFilesChange = useCallback((v) => setSampleMediaFiles(v), []);

  const isStep1Completed = () => {

    if (!selectedBrandId) {
      return false;
    }

    if (!selectedBrandUseCase) {
      return false;
    }

    if (!Array.isArray(selectedBrandSubUseCaseList)) {
      return false;
    }

    if (selectedBrandSubUseCaseList.length < minSubUseCases || selectedBrandSubUseCaseList.length > maxSubUseCases) {
      return false;
    }

    return true;
  }

  const isStep2Completed = () => {

    if (!Array.isArray(allMnosList) || !Array.isArray(excludedMnosList)) {
      return false;
    }

    if (allMnosList.length === 0) {
      return false;
    }

    if (allMnosList.length === excludedMnosList.length) {
      return false;
    }

    return true;
  }

  const submitCampaign = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      let response = await tcrService.createCampaign(
        currentProjectId,
        selectedBrandId,
        selectedBrandUseCase,
        Array.isArray(selectedBrandSubUseCaseList) ? selectedBrandSubUseCaseList : [],
        campaignDescription,
        embeddedLink,
        embeddedPhone,
        true, //termsAndConditions,
        numberPool,
        ageGated,
        directLending,
        subscriberOptin,
        subscriberOptout,
        subscriberHelp,
        sampleMessage1,
        sampleMessage2,
        sampleMessage3,
        sampleMessage4,
        sampleMessage5,
        messageFlow,
        helpMessage,
        excludedMnosList.length === 0 ? '' : allMnosList.filter(m => excludedMnosList.indexOf(m) === -1), // all MNOs (empty string) or allMnosList without excluded list
        optinKeywords,
        optoutKeywords,
        helpKeywords,
        optinMessage,
        optoutMessage,
        sampleMediaFiles,
      );
      if (!response?.createdCampaign?.campaignId) {
        throw new Error("campaign was not created");
      }

      history.push(`/projects/${currentProjectId}/tcr/brands/${selectedBrandId}/campaigns/${response?.createdCampaign?.campaignId}`, { redirectStatus: `create-campaign-succeeded` });
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }

  const step3Validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true, // to validate event untouched elements: https://github.com/jaredpalmer/formik/issues/1116

    initialValues: {
      campaignDescription: "",
      messageFlow: "",
      subscriberOptin: false,
      optinKeywords: "",
      optinMessage: "",
      subscriberOptout: false,
      optoutKeywords: "",
      optoutMessage: "",
      subscriberHelp: false,
      helpKeywords: "",
      helpMessage: "",
      numberPool: false,
      directLending: false,
      embeddedLink: false,
      embeddedPhone: false,
      ageGated: false,
      sampleMessage1: "",
      sampleMessage2: "",
      sampleMessage3: "",
      sampleMessage4: "",
      sampleMessage5: "",
    },
    validationSchema: Yup.object({
      campaignDescription: Yup.string().required("This field is required").min(40, "Please type at least 40 characters").max(4096, "This field can not be more than 4096 characters long"),
      messageFlow: Yup.string().required("This field is required").min(40, "Please type at least 40 characters").max(4096, "This field can not be more than 4096 characters long"),
      optinKeywords: Yup.string().max(255, "Max field length is 255").when(['subscriberOptin'], {
        is: (subscriberOptin) => subscriberOptin === true,
        then: (schema) => schema.required("This field is required"),
        otherwise: (schema) => schema.optional(),
      }),
      optinMessage: Yup.string().max(320, "Max field length is 320").when(['subscriberOptin'], {
        is: (subscriberOptin) => subscriberOptin === true,
        then: (schema) => schema.required("This field is required").min(20, "Please type at least 20 characters"),
        otherwise: (schema) => schema.optional(),
      }),
      optoutKeywords: Yup.string().max(255, "Max field length is 255").when(['subscriberOptout'], {
        is: (subscriberOptin) => subscriberOptin === true,
        then: (schema) => schema.required("This field is required"),
        otherwise: (schema) => schema.optional(),
      }),
      optoutMessage: Yup.string().max(320, "Max field length is 320").when(['subscriberOptout'], {
        is: (subscriberOptin) => subscriberOptin === true,
        then: (schema) => schema.required("This field is required").min(20, "Please type at least 20 characters"),
        otherwise: (schema) => schema.optional(),
      }),
      helpKeywords: Yup.string().max(255, "Max field length is 255").when(['subscriberHelp'], {
        is: (subscriberOptin) => subscriberOptin === true,
        then: (schema) => schema.required("This field is required"),
        otherwise: (schema) => schema.optional(),
      }),
      helpMessage: Yup.string().max(320, "Max field length is 320").when(['subscriberHelp'], {
        is: (subscriberOptin) => subscriberOptin === true,
        then: (schema) => schema.required("This field is required").min(20, "Please type at least 20 characters"),
        otherwise: (schema) => schema.optional(),
      }),



    }),
    //onSubmit: async (values) => {
    //  alert('not_implemented')
    //}
  });

  const step4Validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnMount: true, // to validate event untouched elements: https://github.com/jaredpalmer/formik/issues/1116

    initialValues: {

      sampleMessage1: "",
      sampleMessage2: "",
      sampleMessage3: "",
      sampleMessage4: "",
      sampleMessage5: "",
    },
    validationSchema: Yup.object({
      sampleMessage1: Yup.string().max(1024, "Max field length is 320")
        .test(
          "len",
          "Please type at least 20 characters",
          (val) => {
            if (val === undefined) {
              return true;
            }
            return val.length === 0 || (val.length >= 20);
          }
        ),
      sampleMessage2: Yup.string().max(1024, "Max field length is 320")
        .test(
          "len",
          "Please type at least 20 characters",
          (val) => {
            if (val === undefined) {
              return true;
            }
            return val.length === 0 || (val.length >= 20);
          }
        ),
      sampleMessage3: Yup.string().max(1024, "Max field length is 320")
        .test(
          "len",
          "Please type at least 20 characters",
          (val) => {
            if (val === undefined) {
              return true;
            }
            return val.length === 0 || (val.length >= 20);
          }
        ),
      sampleMessage4: Yup.string().max(1024, "Max field length is 320")
        .test(
          "len",
          "Please type at least 20 characters",
          (val) => {
            if (val === undefined) {
              return true;
            }
            return val.length === 0 || (val.length >= 20);
          }
        ),
      sampleMessage5: Yup.string().max(1024, "Max field length is 320")
        .test(
          "len",
          "Please type at least 20 characters",
          (val) => {
            if (val === undefined) {
              return true;
            }
            return val.length === 0 || (val.length >= 20);
          }
        ),


    }),
    //onSubmit: async (values) => {
    //  alert('not_implemented')
    //}
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Campaigns"
            titleUrl={`/projects/${currentProjectId}/tcr/campaigns`}
            breadcrumbItem="Register Campaign" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading &&
                    <>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}>
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                              >
                                <span className="number">1</span>{" "}
                                Use Case Selection
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                              >
                                <span className="number me-2">2</span>{" "}
                                Carrier Terms Preview
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 3 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 3 })}
                              >
                                <span className="number">3</span>{" "}
                                Campaign Details
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 4 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 4 })}
                              >
                                <span className="number">4</span>{" "}
                                Content Examples
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 5 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 5 })}
                              >
                                <span className="number">5</span>{" "}
                                Review
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          <div className="body">
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={1}>
                                <AddCampaignStep1
                                  onBrandIdChange={onBrandIdChange}
                                  onBrandUseCaseChange={onBrandUseCaseChange}
                                  onBrandSubUseCaseListChange={onBrandSubUseCaseListChange}
                                  onMinSubUseCasesChange={onMinSubUseCasesChange}
                                  onMaxSubUseCasesChange={onMaxSubUseCasesChange}
                                />
                                {isStep1Completed() && <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={() => /*isStep1Completed() && */setActiveTab(2)}
                                    >
                                      Next
                                    </Link>
                                  </div>
                                </Row>
                                }
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={2}>
                                <AddCampaignStep2
                                  selectedBrandId={selectedBrandId}
                                  selectedBrandUseCase={selectedBrandUseCase}
                                  isComponentVisible={activeTab === 2}
                                  onExcludedMnosListChange={onExcludedMnosListChange}
                                  onAllMnosListChange={onAllMnosListChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(1)}
                                    >
                                      Previous
                                    </Link>

                                    {isStep2Completed() && <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={() => setActiveTab(3)}
                                    >
                                      Next
                                    </Link>
                                    }

                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={3}>
                                <AddCampaignStep3
                                  validation={step3Validation}
                                  onCampaignDescriptionChange={onCampaignDescriptionChange}
                                  onMessageFlowChange={onMessageFlowChange}
                                  onSubscriberOptinChange={onSubscriberOptinChange}
                                  onOptinMessageChange={onOptinMessageChange}
                                  onOptinKeywordsChange={onOptinKeywordsChange}
                                  onSubscriberOptoutChange={onSubscriberOptoutChange}
                                  onOptoutMessageChange={onOptoutMessageChange}
                                  onOptoutKeywordsChange={onOptoutKeywordsChange}
                                  onSubscriberHelpChange={onSubscriberHelpChange}
                                  onHelpMessageChange={onHelpMessageChange}
                                  onHelpKeywordsChange={onHelpKeywordsChange}
                                  onNumberPoolChange={onNumberPoolChange}
                                  onDirectLendingChange={onDirectLendingChange}
                                  onEmbeddedLinkChange={onEmbeddedLinkChange}
                                  onEmbeddedPhoneChange={onEmbeddedPhoneChange}
                                  onAgeGatedChange={onAgeGatedChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(2)}
                                    >
                                      Previous
                                    </Link>

                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={
                                        () => {

                                          if (step3Validation.isValid) {
                                            setActiveTab(4);
                                          } else { // see https://github.com/jaredpalmer/formik/issues/2734
                                            step3Validation.setTouched(setNestedObjectValues(step3Validation.errors, true));
                                          }
                                        }
                                      }
                                    >
                                      Next
                                    </Link>

                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={4}>
                                <AddCampaignStep4
                                  validation={step4Validation}
                                  onSampleMessage1Change={onSampleMessage1Change}
                                  onSampleMessage2Change={onSampleMessage2Change}
                                  onSampleMessage3Change={onSampleMessage3Change}
                                  onSampleMessage4Change={onSampleMessage4Change}
                                  onSampleMessage5Change={onSampleMessage5Change}
                                  onSampleMediaFilesChange={onSampleMediaFilesChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(3)}
                                    >
                                      Previous
                                    </Link>

                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={
                                        () => {

                                          if (step4Validation.isValid) {
                                            setActiveTab(5);
                                          } else { // see https://github.com/jaredpalmer/formik/issues/2734
                                            step4Validation.setTouched(setNestedObjectValues(step4Validation.errors, true));
                                          }
                                        }
                                      }
                                    >
                                      Next
                                    </Link>

                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={5}>
                                <AddCampaignStep5
                                  selectedBrandUseCase={selectedBrandUseCase}
                                  selectedBrandSubUseCaseList={selectedBrandSubUseCaseList}
                                  campaignDescription={campaignDescription}
                                  messageFlow={messageFlow}
                                  subscriberOptin={subscriberOptin}
                                  optinKeywords={optinKeywords}
                                  optinMessage={optinMessage}
                                  subscriberOptout={subscriberOptout}
                                  optoutKeywords={optoutKeywords}
                                  optoutMessage={optoutMessage}
                                  subscriberHelp={subscriberHelp}
                                  helpKeywords={helpKeywords}
                                  helpMessage={helpMessage}
                                  numberPool={numberPool}
                                  directLending={directLending}
                                  embeddedLink={embeddedLink}
                                  embeddedPhone={embeddedPhone}
                                  ageGated={ageGated}
                                  sampleMessage1={sampleMessage1}
                                  sampleMessage2={sampleMessage2}
                                  sampleMessage3={sampleMessage3}
                                  sampleMessage4={sampleMessage4}
                                  sampleMessage5={sampleMessage5}
                                  sampleMediaFiles={sampleMediaFiles}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(4)}
                                    >
                                      Previous
                                    </Link>
                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={() => submitCampaign()}
                                    >
                                      Register
                                    </Link>
                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddCampaign.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddCampaign)));