import { Badge } from "reactstrap";

export const formatTNLocation = (tn) => {
  if (!tn) {
    return '';
  }
  
  let locArr = [tn?.locationName, tn?.regionName, tn?.countryCode];
  return locArr.filter(l => l /* filtering non-empty values only */).join(', ');
}

export const formatTNType = (tn) => {
  if (!tn) {
    return '';
  }

  if (tn.type === 'local') {
    return 'local';
  }
  
  if (tn.type === 'toll-free') {
    return 'toll-free';
  }

  if (tn.type === 'short') {
    return 'short code';
  }

  return '';
}

export const getTelephoneNumberBadgeCssClassName = (tn) => {

  if (!tn) {
    return 'bg-dark';
  }

  if (tn.status === 'active') {
    return 'bg-success';
  }

  if (tn.status === 'pending_purchase') {
    return 'bg-soft-success';
  }

  if (tn.status === 'disconnected' || tn.status.includes('fail')) {
    return 'bg-danger';
  }

  if (tn.status === 'pending_disconnect') {
    return 'bg-soft-danger';
  }

  return 'bg-soft-dark';
}

export const getTollFreeVerificationStatusBadgeCssClassName = (tfv) => {

  if (!tfv) {
    return 'bg-dark';
  }

  if (tfv.verificationStatus === 'verified') {
    return 'bg-success';
  }

  if (tfv.verificationStatus === 'pending_purchase' || tfv.verificationStatus === 'in_review') {
    return 'bg-info';
  }

  if (tfv.verificationStatus === 'declined') {
    return 'bg-danger';
  }

  if (tfv.verificationStatus === 'pending_deletion') {
    return 'bg-soft-danger';
  }

  return 'bg-soft-dark';
}

export const isTollFree = (tn) => {
  if (!tn || !tn.type) {
    return false;
  }

  return tn.type.toLowerCase() === 'toll-free';
}

export const constructTollFreeVerificationStatus = (status) => {
    /* possible values:
    "PENDING",
    "ACTIVE",
  "EXPIRED",
  */
    switch (status.toUpperCase()) {
      case "VERIFIED":
        return <Badge className="bg-success rounded-pill">
          Verified
        </Badge>;
      case "DECLINED":
        return <Badge className="bg-danger rounded-pill">
          Declined
        </Badge>;
      default:
        return <Badge className="bg-info rounded-pill">
          In review
        </Badge>;
    }
  }