import { get, post, del } from "../helpers/api_helper";
const _ = require('lodash');

class TcrService {

  fetchBrands(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/tcr/brands?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchBrandDetails(projectId, brandId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/tcr/brands/${brandId}`)
        .then(response => {
          const brand = _.get(response, 'brand', {});
          resolve(brand);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createBrand(
    projectId,
    entityType,
    firstName,
    lastName,
    displayName,
    companyName,
    ein,
    einIssuingCountry,
    phone,
    mobilePhone,
    street,
    city,
    state,
    postalCode,
    countryCode,
    email,
    stockSymbol,
    stockExchange,
    website,
    altBusinessId,
    altBusinessIdType,
    vertical
  ) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/tcr/brands`,
        {
          entityType,
          firstName,
          lastName,
          displayName,
          companyName,
          ein,
          einIssuingCountry,
          phone,
          mobilePhone,
          street,
          city,
          state,
          postalCode,
          countryCode,
          email,
          stockSymbol,
          stockExchange,
          website,
          altBusinessId,
          altBusinessIdType,
          vertical,
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  qualifyBrand(projectId, brandId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/tcr/brands/${brandId}/qualify`)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
    });

  }

  qualifyBrandForUseCase(projectId, brandId, useCase) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/tcr/brands/${brandId}/usecases/${encodeURIComponent(useCase)}/qualify`)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
    });

  }

  deleteBrand(projectId, brandId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/tcr/brands/${brandId}`)
        .then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchCampaigns(projectId, brandId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/tcr/campaigns?offset=${offsetParam}`;

    if (brandId) { // using different URL in case we need to get campaigns only for a particular brand
      url = `/projects/${projectId}/tcr/brands/${brandId}/campaigns?offset=${offsetParam}`;
    }

    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchCampaignDetails(projectId, brandId, campaignId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/tcr/brands/${brandId}/campaigns/${campaignId}`)
        .then(response => {
          const campaign = _.get(response, 'campaign', {});
          resolve(campaign);
        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchUseCases(projectId, validSubUseCaseOptional) {
    let query = "";
    if (validSubUseCaseOptional !== undefined) {
      query = `?validSubUseCase=${validSubUseCaseOptional}`
    }
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/tcr/usecases${query}`)
        .then(response => {
          resolve(response);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createCampaign(
    projectId,
    brandId,
    useCase,
    subUseCases,
    description,
    embeddedLink,
    embeddedPhone,
    termsAndConditions,
    numberPool,
    ageGated,
    directLending,
    subscriberOptin,
    subscriberOptout,
    subscriberHelp,
    sample1,
    sample2,
    sample3,
    sample4,
    sample5,
    messageFlow,
    helpMessage,
    mnoIds,
    optinKeywords,
    optoutKeywords,
    helpKeywords,
    optinMessage,
    optoutMessage,
    mediaSamples
  ) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/tcr/brands/${brandId}/campaigns`,
        {
          useCase,
          subUseCases,
          description,
          embeddedLink,
          embeddedPhone,
          termsAndConditions,
          numberPool,
          ageGated,
          directLending,
          subscriberOptin,
          subscriberOptout,
          subscriberHelp,
          sample1,
          sample2,
          sample3,
          sample4,
          sample5,
          messageFlow,
          helpMessage,
          mnoIds,
          optinKeywords,
          optoutKeywords,
          helpKeywords,
          optinMessage,
          optoutMessage,
          mediaSamples
        },
        {
          'headers': {
            'Content-Type': 'multipart/form-data',
          }
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });
  }

  deleteCampaign(projectId, brandId, campaignId) {
    return new Promise((resolve, reject) => {
      del(`/projects/${projectId}/tcr/brands/${brandId}/campaigns/${campaignId}`)
        .then(() => {
          resolve();
        }).catch(error => {
          reject(error);
        })
    });

  }
}

export const tcrService = new TcrService();