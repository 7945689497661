import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Card, Form } from "reactstrap";
import Dropzone from "react-dropzone";

//i18n
import { withTranslation } from 'react-i18next';

import { formatBytes, emptyFunc } from "../../helpers/utils";

const AddContactImportTaskStep1 = (props) => {

  let onSelectedFilesChange = props?.onSelectedFilesChange ?? emptyFunc;
  let onFileDelimiterChange = props?.onFileDelimiterChange ?? emptyFunc;
  let onConsentObtainedChange = props?.onConsentObtainedChange ?? emptyFunc;
  let consentObtained = props?.consentObtained ?? false;

  const selectedFiles = props?.selectedFiles;
  const fileDelimiter = props?.fileDelimiter;

  function handleAcceptedFiles(files) {

    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    onSelectedFilesChange(files);
  }

  return (
    <React.Fragment>
      <p className="card-title-desc">
        Start by uploading your .csv file with contacts to import.<br />
      </p>
      <Form>
        <Dropzone
          acceptCommented="text/csv"
          maxFiles={1}
          onDrop={acceptedFiles => {
            handleAcceptedFiles(acceptedFiles);
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div className="dropzone">
              <div
                className="dz-message needsclick"
                {...getRootProps()}
              >
                <input {...getInputProps()} />
                <div className="mb-3">
                  <i className="display-4 text-muted uil uil-cloud-upload" />
                </div>
                <h4>Drop file here or click to upload</h4>
              </div>
            </div>
          )}
        </Dropzone>
        <div className="dropzone-previews mt-3" id="file-previews">
          {selectedFiles.map((f, i) => {
            return (
              <Card
                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                key={i + "-file"}
              >
                <Row className="align-items-center">
                  <Col>
                    <span
                      className="text-muted font-weight-bold"
                    >
                      {f.name}
                    </span>
                    <p className="mb-0">
                      <strong>{f.formattedSize}</strong>
                    </p>
                  </Col>
                </Row>
              </Card>
            )
          })}
        </div>
        <div className="mt-3 mb-3">
          <label>
            CSV file delimiter
          </label>
          <div className='col-sm-1 col-2'>
            <select
              className="form-select"
              aria-label="select CSV file delimiter"
              onChange={(e) => onFileDelimiterChange(e?.target?.value)}
              defaultValue={fileDelimiter}
            >
              <option value=",">,</option>
              <option value=";">;</option>
              <option value="|">|</option>
            </select>
          </div>
        </div>
        <div>
          <label>
            <input
              className="form-check-input"
              type="checkbox"
              name="consentToSendMessages"
              onChange={onConsentObtainedChange}
              defaultChecked={consentObtained}
            />
            <span className="m-1 text-info cursor-pointer"><i>In compliance with the TCPA legislation I confirm that I obtained a written consent from the contacts in this file to send promotional text messages</i></span>
          </label>
        </div>
      </Form>
    </React.Fragment>
  )
}

AddContactImportTaskStep1.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddContactImportTaskStep1)));