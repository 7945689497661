import React, { useState, useEffect, useCallback } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { useSelector } from "react-redux";
import CountUp from 'react-countup';
import ReactApexChart from "react-apexcharts";
import { withRouter, Link } from 'react-router-dom';

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomAlert from "../../components/Common/CustomAlert";
import GlobalProgressBar from "../../components/Common/GlobalProgressBar";


import LatestEventsCard from "./LatestEventsCard";
import EmptyProjectsList from "../EmptyProjectsList";

import { userHasProjects, constructErrorMessage, isNumber } from "../../helpers/utils";

import { projectService } from '../../services';

const baseBarReportOptions = {
  fill: {
    //colors: ['#5b73e8']
  },
  stroke: {
    width: 1
  },
  markers: {
    //strokeColors: '#5b73e8',
    hover: {
      size: 1,
    }
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
  },
  //labels: [],
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: true, // timezones are handled on the server. no need to convert here
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      show: false
    },
  },
  tooltip: {
    followCursor: false,
    fixed: {
      enabled: true,
      offsetX: -100,
    },
    x: {
      show: true,
      format: "yyyy-MM-dd", //"dd MMM yyyy",
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  },
};

const basePieReportOptions = {
  fill: {
    colors: ['#5b73e8', '#34c38f']
  },
  colors: ['#5b73e8', '#34c38f'], //see https://github.com/apexcharts/apexcharts.js/issues/2980
  stroke: {
    width: 0,
  },
  markers: {
    strokeColors: ['#5b73e8', '#34c38f'],
    hover: {
      size: 1,
    }
  },
  chart: {
    type: 'pie',
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    pie: {
      expandOnClick: false,
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  },
  tooltip: {
    followCursor: false,
    hideEmptySeries: true,
    fixed: {
      enabled: true,
      offsetX: -100,
    },
    marker: {
      show: !1
    }
  },
};

let contactsReportOptions = {
  ...baseBarReportOptions,
  fill: {
    ...baseBarReportOptions.fill,
    colors: ['#5b73e8']
  },
  stroke: {
    ...baseBarReportOptions.stroke,
    colors: ['#5b73e8'],
  },
  markers: {
    ...baseBarReportOptions.markers,
    strokeColors: ['#5b73e8'],
  },
  tooltip: {
    ...baseBarReportOptions.tooltip,
    y: {
      ...baseBarReportOptions.tooltip.y,
      formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
        if (value === 0) {
          return "no changes";
        }

        if (value < 0) {
          return `${value} contact${value < -1 ? 's' : ''}`;
        }

        if (value > 0) {
          return `+${value} contact${value > 1 ? 's' : ''}`;
        }
        return value;
      },
    },
  },
};

let messagesReportOptions = {
  ...baseBarReportOptions,
  fill: {
    ...baseBarReportOptions.fill,
    colors: ['#34c38f']
  },
  stroke: {
    ...baseBarReportOptions.stroke,
    colors: ['#34c38f'],
  },
  markers: {
    ...baseBarReportOptions.markers,
    strokeColors: ['#34c38f'],
  },
  tooltip: {
    ...baseBarReportOptions.tooltip,
    y: {
      ...baseBarReportOptions.tooltip.y,
      formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
        if (value === 0) {
          return "no messages";
        }
        return `${value} message${value > 1 ? 's' : ''}`;
      },
    },
  },
};

let tnsReportOptions = {
  ...basePieReportOptions,
  labels: ['Local', 'Toll-Free'],
}

let deliveryRateReportOptions = {
  ...basePieReportOptions,
  fill: {
    colors: ['#34c38f', '#ff0000']
  },
  colors: ['#34c38f', '#ff0000'], //see https://github.com/apexcharts/apexcharts.js/issues/2980
  markers: {
    strokeColors: ['#34c38f', '#ff0000'],
    hover: {
      size: 1,
    }
  },
  labels: ['Delivered', 'Undelivered'],
}

/////////////
/*
const series1 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54]
}];

const options1 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};

const series2 = [70];

const options2 = {
  fill: {
    colors: ['#34c38f']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ['#5b73e8']
  },
  chart: {
    sparkline: {
      enabled: !0
    }
  },
  dataLabels: {
    enabled: !1
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: '60%'
      },
      track: {
        margin: 0
      },
      dataLabels: {
        show: !1
      }
    }
  }
};

const series4 = [{
  data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54]
}];

const options4 = {

  fill: {
    colors: ['#f1b44c']
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0
    }
  },
  plotOptions: {
    bar: {
      columnWidth: '50%'
    }
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1
    },
  },
  tooltip: {
    fixed: {
      enabled: !1
    },
    x: {
      show: !1
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return '';
        }
      }
    },
    marker: {
      show: !1
    }
  }
};
*/

const Dashboard = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const projectsList = useSelector((state) => state.AuthUser.projectsList);
  const isLoading = useSelector((state) => state.AuthUser.loading);
  const [statsOverview, setStatsOverview] = useState({});
  const [statsOverviewReports, setStatsOverviewReports] = useState([]);

  const [isStatsLoading, setIsStatsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /* const reports = [
     {
       id: 1,
       icon: "mdi mdi-arrow-up-bold",
       title: "Total Revenue",
       value: 34152,
       prefix: "$",
       suffix: "",
       badgeValue: "2.65%",
       decimal: 0,
       charttype: "bar",
       chartheight: 40,
       chartwidth: 70,
       color: "success",
       desc: "since last week",
       series: series1,
       options: options1,
 
     },
     {
       id: 2,
       icon: "mdi mdi-arrow-down-bold",
       title: "Contacts",
       value: 45254,
       decimal: 0,
       charttype: "radialBar",
       chartheight: 45,
       chartwidth: 45,
       prefix: "",
       suffix: "",
       badgeValue: "0.82%",
       color: "danger",
       desc: "since last week",
       series: series2,
       options: options2,
     },
     {
       id: 3,
       icon: "mdi mdi-arrow-down-bold",
       title: "Phone Numbers",
       value: 5643,
       decimal: 0,
       prefix: "",
       suffix: "",
       charttype: "radialBar",
       chartheight: 45,
       chartwidth: 45,
       badgeValue: "6.24%",
       color: "danger",
       desc: "since last week",
       series: series3,
       options: options3,
     },
     {
       id: 4,
       icon: "mdi mdi-arrow-up-bold",
       title: "Delivery Rate",
       value: 99.58,
       decimal: 2,
       prefix: "",
       suffix: "%",
       charttype: "bar",
       chartheight: 40,
       chartwidth: 70,
       badgeValue: "10.51%",
       color: "success",
       desc: "since last week",
       series: series4,
       options: options4,
     },
   ];*/

  const getProjectStatsOverview = useCallback(async (projectId) => {
    try {
      setIsStatsLoading(true);
      setErrorMessage('');
      let so = await projectService.fetchProjectStatsOverview(projectId, Intl.DateTimeFormat().resolvedOptions().timeZone);

      setStatsOverview(so);

      let statsContactsSeries = so?.statsContacts.map(s => { return { "x": s.dwDate, "y": s.contactsCreatedCount - s.contactsDeletedCount } });
      //  this is for string sorting:
      //statsContactsSeries = statsContactsSeries.sort((a, b) => a.x.localeCompare(b.x)); // sorting dates just in case the backend returned it incorrectly
      // so just sorting dates
      //statsContactsSeries = statsContactsSeries.sort((a, b) => a.x - b.x); // sorting dates just in case the backend returned it incorrectly

      let statsContactsTotalDelta = 0;
      so?.statsContacts.forEach(s => {
        statsContactsTotalDelta += s.contactsCreatedCount - s.contactsDeletedCount;
      });
      let statsContactsTotalPercent = 0;
      if (statsContactsTotalDelta !== 0) {
        statsContactsTotalPercent = so.contactsCount - statsContactsTotalDelta === 0 ? 100.0 : 100.0 * (statsContactsTotalDelta / (so.contactsCount - statsContactsTotalDelta));
      };

      // messages 
      let totalMessagesCount = 0;
      let totalMessagesSentCount = 0;
      let totalMessagesReceivedCount = 0;
      let totalDeliveredCount = 0;
      let totalUndeliveredCount = 0;
      let statsMessagesSeries = so?.statsMessages.map(s => {
        totalMessagesCount += s.smsSentCount + s.mmsSentCount + s.smsReceivedCount + s.mmsReceivedCount;
        totalMessagesSentCount += s.smsSentCount + s.mmsSentCount;
        totalMessagesReceivedCount += s.smsReceivedCount + s.mmsReceivedCount;
        totalDeliveredCount += s.smsDeliveredCount + s.mmsDeliveredCount;
        totalUndeliveredCount += s.smsUndeliveredCount + s.mmsUndeliveredCount;
        return { "x": s.dwDate, "y": s.smsSentCount + s.mmsSentCount + s.smsReceivedCount + s.mmsReceivedCount };
      });

      let totalTnsCount = so?.localTelephoneNumbersCount + so?.tollFreeTelephoneNumbersCount + so?.shortCodeTelephoneNumbersCount + so?.otherTelephoneNumbersCount;
      let deliveryRate = (totalDeliveredCount + totalUndeliveredCount) === 0 ? 'N/A' : (100.0*totalDeliveredCount)/(totalDeliveredCount + totalUndeliveredCount);

      setStatsOverviewReports([
        {
          id: 1,
          icon: statsContactsTotalPercent > 0 ? "mdi mdi-arrow-up-bold" : (statsContactsTotalPercent < 0 ? "mdi mdi-arrow-down-bold" : "mdi"),
          title: `Contact${so.contactsCount === 1 ? '' : 's'}`, // to show "0 contacts", "1 contact", "2 contacts"
          value: so.contactsCount,
          prefix: "",
          suffix: "",
          badgeValue: `${Math.round(statsContactsTotalPercent * 100) / 100}%`, // with rounding https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
          decimal: 0,
          charttype: "bar",
          chartheight: 40,
          chartwidth: 70,
          color: statsContactsTotalPercent > 0 ? "success" : (statsContactsTotalPercent < 0 ? "danger" : "info"),
          desc: "since last week",
          series: [{ data: statsContactsSeries }],
          options: contactsReportOptions,
          linkUrl: `/projects/${currentProjectId}/directory/contacts/add`,
          linkText: <>CREATE NEW <i className="mdi mdi-arrow-right"></i></>,
        },
        {
          id: 2,
          icon: "mdi",
          title: `Message${so.totalMessagesCount === 1 ? '' : 's'} this week`, // to show "0 messages", "1 message", "2 messages"
          value: totalMessagesCount,
          prefix: "",
          suffix: "",
          badgeValue: `${totalMessagesSentCount}|${totalMessagesReceivedCount}`,
          decimal: 0,
          charttype: "bar",
          chartheight: 40,
          chartwidth: 70,
          color: "info",
          desc: " sent|received last week",
          series: [{ data: statsMessagesSeries }],
          options: messagesReportOptions,
          linkUrl: `/projects/${currentProjectId}/compose`,
          linkText: <>COMPOSE <i className="mdi mdi-arrow-right"></i></>
        },
        {
          id: 3,
          icon: "mdi",
          title: "Phone numbers",
          value: totalTnsCount,
          decimal: 0,
          prefix: "",
          suffix: "",
          charttype: "pie",
          chartheight: 45,
          chartwidth: 45,
          badgeValue: `${so?.localTelephoneNumbersCount}|${so?.tollFreeTelephoneNumbersCount}`,
          color: "info",
          desc: "local|toll-free numbers",
          series: [so?.localTelephoneNumbersCount, so?.tollFreeTelephoneNumbersCount/* ignore other types for now: , so?.shortCodeTelephoneNumbersCount, so?.otherTelephoneNumbersCount*/],
          options: tnsReportOptions,
          linkUrl: `/projects/${currentProjectId}/numbers/search`,
          linkText: <>PURCHASE MORE <i className="mdi mdi-arrow-right"></i></>
        }, 
        {
          id: 4,
          icon: "mdi",
          title: "Delivery rate this week",
          value: deliveryRate,
          decimal: 2,
          prefix: "",
          suffix: "%",
          charttype: "pie",
          chartheight: 45,
          chartwidth: 45,
          badgeValue: `${totalUndeliveredCount}`,
          color: "info",
          desc: "undelivered last week",
          series: [totalDeliveredCount, totalUndeliveredCount],
          options: deliveryRateReportOptions,
          linkUrl: `/projects/${currentProjectId}/reports/messaging`,
          linkText: <>MORE INFO <i className="mdi mdi-arrow-right"></i></>
        },
      ]);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsStatsLoading(false);
    }
  }, [currentProjectId]);


  useEffect(() => {
    if (currentProjectId) {
      getProjectStatsOverview(currentProjectId);
    }
  }, [getProjectStatsOverview, currentProjectId]);


  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={isLoading} />
      {!isLoading && !userHasProjects(projectsList) &&
        <EmptyProjectsList />
      }
      {userHasProjects(projectsList) &&
        <div className="page-content width-min-content">
          <Container fluid>
            <Breadcrumbs title="" breadcrumbItem="Home" />
            {/*<Row>
              {/* reports.map((report, key) => (
                <Col md={6} xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="float-end mt-2">
                        <ReactApexChart
                          options={report.options}
                          series={report.series}
                          type={report.charttype}
                          height={report.chartheight}
                          width={report.chartwidth}
                        />
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1"><span><CountUp end={report.value} separator="," prefix={report.prefix} suffix={report.suffix} decimals={report.decimal} /></span></h4>
                        <p className="text-muted mb-0">{report.title}</p>
                      </div>
                      <p className="text-muted mt-3 mb-0"><span className={"text-" + report.color + " me-1"}><i className={report.icon + " me-1"}></i>{report.badgeValue}</span>{" "}{report.desc}
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>*/}
            <Row>
              {!(isLoading && isStatsLoading) && errorMessage ? <CustomAlert color="danger" role="alert">{constructErrorMessage(errorMessage)}</CustomAlert> : null}
              {!(isLoading && isStatsLoading) && Object.keys(statsOverview) !== 0 && statsOverviewReports.map((report, key) => (
                <Col md={6} xl={3} key={key}>
                  <Card>
                    <CardBody>
                      <div className="float-end mt-2">
                        <ReactApexChart
                          options={report.options}
                          series={report.series}
                          type={report.charttype}
                          height={report.chartheight}
                          width={report.chartwidth}
                        />
                      </div>
                      <div>
                        <h4 className="mb-1 mt-1">
                          {isNumber(report.value) && <span><CountUp end={report.value} separator="," prefix={report.prefix} suffix={report.suffix} decimals={report.decimal} /></span>}
                          {!isNumber(report.value) && <span>{report.value}</span>}
                        </h4>
                        <p className="text-muted mb-0">{report.title}</p>
                      </div>
                      {/*<p className="text-muted mt-3 mb-0 text-nowrap"><span className={"text-" + report.color + (report.badgeValue ? " me-1" : "")}><i className={report.icon + (report.color !== "info" ? " me-1" : "")}></i>
                        {report?.linkUrl && <Link to={report?.linkUrl} className={"text-" + report.color}>{report.badgeValue}</Link>}
                        {!report?.linkUrl && <>{report.badgeValue}</>}
                      </span>{" "}{report.desc}
              </p>*/}
                      <p className="text-muted mt-3 mb-0 text-nowrap text-end">
                        <Link className="dashboard-report-link" to={report.linkUrl}>{report.linkText}</Link>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row>
            <Row>
              {/*<Col>
                <ProjectInfoCard />
              </Col>*/}
              {/* temporary disable because it needs some work 
            <Col xl={4}>
 
              <PhoneNumbersStatsChart />
            </Col>
                         */}
            </Row>
            <Row>
              <Col>
                <LatestEventsCard />
              </Col>
            </Row>
          </Container>
        </div>
      }
    </React.Fragment>
  );
};

export default withRouter(Dashboard);