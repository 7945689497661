import PropTypes from 'prop-types';
import React, { useEffect, useCallback, useState } from 'react';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import {
  Container, Row, Col, Card, CardBody, Table, Modal
} from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, textOrEmpty, clearHistoryState } from '../../helpers/utils';

import './contacts.scss';

import { constructContactImportTaskStatus } from './utils';

import {
  getContactImportTasksForProject,
  setContactImportTasksLimit,
} from "../../store/actions";

const ErrorDetailsDialog = (props) => {
  const errorText = props?.errorText;
  const isOpen = props?.isOpen;
  const toggleIsOpen = props?.toggleIsOpen;

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleIsOpen()
      }}
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0 text-danger" id="ErrorDetailsDialogLabel">
          Import Task Failure
        </h5>
        <button
          type="button"
          onClick={() => {
            toggleIsOpen()
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <p className="text-danger">
          {errorText}
        </p>
      </div>
    </Modal>
  );
}

const ContactsImportTasks = () => {

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const isLoading = useSelector((state) => state.Contact.loading);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Contact.error);
  const [isErrorDetailsDialogOpen, setIsErrorDetailsDialogOpen] = useState(false); // to show error details dialog
  const [errorDetailsDialogText, setErrorDetailsDialogText] = useState(''); // to show error details dialog text 

  const tasksList = useSelector((state) => state.Contact.contactImportTasksList);
  const tasksLimit = useSelector((state) => state.Contact.contactImportTasksLimit);
  const tasksOffset = useSelector((state) => state.Contact.contactImportTasksOffset);
  const tasksTotalCount = useSelector((state) => state.Contact.contactImportTasksTotalCount);

  let pageSize = tasksLimit || 20;

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    if (redirectStatus === 'create-import-task-succeeded') {
      setSuccessMessage("Task was successfully created");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);
  }, [redirectStatus, history]);

  const loadTasks = useCallback((projectId, limit, offset) => {
    dispatch(getContactImportTasksForProject(projectId, limit, offset));
  }, [dispatch]);

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      loadTasks(currentProjectId, pageSize, 0);
    }
  }, [loadTasks, currentProjectId, pageSize]);

  const dataRefresh = useCallback(() => {
    if (currentProjectId) {
      loadTasks(currentProjectId, tasksLimit, tasksOffset);
    }
  }, [loadTasks, currentProjectId, tasksLimit, tasksOffset]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  const onNextPageClick = () => {
    if (tasksOffset + pageSize < tasksTotalCount) {
      loadTasks(currentProjectId, pageSize, tasksOffset + pageSize);
    }
  }

  const onPreviousPageClick = () => {
    if (tasksOffset !== 0) {
      loadTasks(currentProjectId, pageSize, tasksOffset - pageSize);
    }
  }

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (isLoading === undefined || isLoading === true) {
      return undefined;
    }

    return Array.isArray(tasksList) && tasksList.length > 0;
  }, [isLoading, tasksList]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Import Contacts" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                      {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
                      <div className="actions clearfix">
                        <ul>
                          {isDataAvailable() && <li>
                            <Link
                              to={`/projects/${currentProjectId}/directory/contacts/imports/add`}
                              className="btn btn-success"
                            >
                              + Import contacts
                            </Link>
                          </li>}
                          <li>
                            <button
                              className="btn refresh without-outline"
                              type="button"
                              title="Refresh"
                              onClick={dataRefresh}
                            >
                              <i className='mdi mdi-reload h3' />
                            </button>
                          </li>
                        </ul>
                      </div>
                      <GlobalProgressBar isLoading={isLoading} />
                      {isDataAvailable() === false && <EmptyBlock>
                        <div className="text-center">
                          <p className="h3">No Files</p>
                          <p>You can import your contacts here.</p>
                          <p>
                            <Link
                              to={`/projects/${currentProjectId}/directory/contacts/imports/add`}
                              className="btn btn-success"
                            >
                              + Import contacts
                            </Link>
                          </p>
                        </div>
                      </EmptyBlock>}
                      {isDataAvailable() && <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th>File name</th>
                            <th>Status</th>
                            <th>Created date</th>
                            <th>Completed date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tasksList.map((t, i) => <tr key={i} className="align-middle">
                            <td>
                              {textOrEmpty(t.fileName)}
                            </td>
                            <td>
                              <span className='text-uppercase'>{constructContactImportTaskStatus(t.taskStatus)}</span>
                              {t.taskStatus && t.taskStatus.toLowerCase() === 'failed' &&
                                <>
                                  {' '}
                                  <i
                                    className="uil-exclamation-circle text-danger cursor-pointer"
                                    title={'Click for error details'}
                                    onClick={() => {
                                      setErrorDetailsDialogText(t.processingError ?? 'Unfortunately an internal error happened');
                                      setIsErrorDetailsDialogOpen(true);
                                    }}
                                  ></i>
                                </>
                              }
                            </td>
                            <td>{!t.createdTimestampMs ? '' : new Date(t.createdTimestampMs).toLocaleString()}</td>
                            <td>{t.taskStatus.toLowerCase() === 'processing' || t.taskStatus.toLowerCase() === 'pending' || !t.modifiedTimestampMs ? '-' : new Date(t.modifiedTimestampMs).toLocaleString()}</td>
                          </tr>)
                          }
                        </tbody>
                      </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {(isDataAvailable() || tasksTotalCount > 0) &&
                        <div className="actions clearfix">
                          <ul className='d-flex align-items-center justify-content-end'>
                            <li>
                              {/* we need Number() because without it it uses "String" */}
                              <select
                                className="form-control cursor-pointer"
                                onChange={(e) => dispatch(setContactImportTasksLimit(Number(e?.target?.value)))}
                                value={pageSize}
                              >
                                <option value="10">10 rows per page</option>
                                <option value="20">20 rows per page</option>
                                <option value="50">50 rows per page</option>
                                <option value="100">100 rows per page</option>
                                <option value="250">250 rows per page</option>
                              </select>
                            </li>
                            <li
                              className={
                                tasksOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                <i className="fa fa-chevron-left" />
                              </Link>
                            </li>
                            <li
                              className={
                                (tasksOffset + pageSize >= tasksTotalCount) ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                <i className="fa fa-chevron-right" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ErrorDetailsDialog
        isOpen={isErrorDetailsDialogOpen}
        toggleIsOpen={() => setIsErrorDetailsDialogOpen(!isErrorDetailsDialogOpen)}
        errorText={errorDetailsDialogText}
      />
    </React.Fragment>
  )
}

ContactsImportTasks.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    contact: state.Contact,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ContactsImportTasks)));