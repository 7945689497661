import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import {Table } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import { boolToYesNo } from '../../helpers/utils';

import './tcr.scss';

const AddCampaignStep5 = (props) => {

  let {
    selectedBrandUseCase,
    selectedBrandSubUseCaseList,
    campaignDescription,
    messageFlow,
    subscriberOptin,
    optinKeywords,
    optinMessage,
    subscriberOptout,
    optoutKeywords,
    optoutMessage,
    subscriberHelp,
    helpKeywords,
    helpMessage,

    numberPool,
    directLending,
    embeddedLink,
    embeddedPhone,
    ageGated,

    sampleMessage1,
    sampleMessage2,
    sampleMessage3,
    sampleMessage4,
    sampleMessage5,

    sampleMediaFiles
  } = props;

  return (
    <React.Fragment>
      <p>Let's review your campaign details before registering.</p>
      <Table className="table table-striped table-responsive">
        <tbody>
          <tr>
            <td className="col-4">
              <strong>Use case</strong>
            </td>
            <td className="col-8">
              {selectedBrandUseCase}
            </td>
          </tr>
          {selectedBrandSubUseCaseList && Array.isArray(selectedBrandSubUseCaseList) && selectedBrandSubUseCaseList.length > 0 && <tr>
            <td className="col-4">
              <strong>Sub use cases</strong>
            </td>
            <td className="col-8">
              {selectedBrandSubUseCaseList.join(", ")}
            </td>
          </tr>}
          <tr>
            <td className="col-4">
              <strong>Campaign description</strong>
            </td>
            <td className="col-8">
              {campaignDescription || "-"}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <strong>Message flow</strong>
            </td>
            <td className="col-8">
              {messageFlow || "-"}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <strong>Subscriber opt-in</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(subscriberOptin)}
            </td>
          </tr>
          {subscriberOptin && <>
            <tr>
              <td className="col-4">
                <strong>Opt-in keywords</strong>
              </td>
              <td className="col-8">
                {optinKeywords ? optinKeywords.replaceAll(",", ", ") : "-"}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Opt-in message</strong>
              </td>
              <td className="col-8">
                {optinMessage || "-"}
              </td>
            </tr>
          </>}
          <tr>
            <td className="col-4">
              <strong>Subscriber opt-in</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(subscriberOptin)}
            </td>
          </tr>
          {subscriberOptout && <>
            <tr>
              <td className="col-4">
                <strong>Opt-out keywords</strong>
              </td>
              <td className="col-8">
                {optoutKeywords ? optoutKeywords.replaceAll(",", ", ") : "-"}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Opt-out message</strong>
              </td>
              <td className="col-8">
                {optoutMessage || "-"}
              </td>
            </tr>
          </>}
          <tr>
            <td className="col-4">
              <strong>Subscriber help</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(subscriberHelp)}
            </td>
          </tr>
          {subscriberHelp && <>
            <tr>
              <td className="col-4">
                <strong>Help keywords</strong>
              </td>
              <td className="col-8">
                {helpKeywords ? helpKeywords.replaceAll(",", ", ") : "-"}
              </td>
            </tr>
            <tr>
              <td className="col-4">
                <strong>Help message</strong>
              </td>
              <td className="col-8">
                {helpMessage || "-"}
              </td>
            </tr>
          </>}
          <tr>
            <td className="col-4">
              <strong>Number pooling</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(numberPool)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <strong>Direct lending or loan arrangement</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(directLending)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <strong>Embedded link</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(embeddedLink)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <strong>Embedded phone number</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(embeddedPhone)}
            </td>
          </tr>
          <tr>
            <td className="col-4">
              <strong>Age-gated content</strong>
            </td>
            <td className="col-8">
              {boolToYesNo(ageGated)}
            </td>
          </tr>
          {sampleMessage1 && <tr>
            <td className="col-4">
              <strong>Sample message 1</strong>
            </td>
            <td className="col-8">
              {sampleMessage1 || "-"}
            </td>
          </tr>}
          {sampleMessage2 && <tr>
            <td className="col-4">
              <strong>Sample message 2</strong>
            </td>
            <td className="col-8">
              {sampleMessage2 || "-"}
            </td>
          </tr>}
          {sampleMessage3 && <tr>
            <td className="col-4">
              <strong>Sample message 3</strong>
            </td>
            <td className="col-8">
              {sampleMessage3 || "-"}
            </td>
          </tr>}
          {sampleMessage4 && <tr>
            <td className="col-4">
              <strong>Sample message 4</strong>
            </td>
            <td className="col-8">
              {sampleMessage4 || "-"}
            </td>
          </tr>}
          {sampleMessage5 && <tr>
            <td className="col-4">
              <strong>Sample message 5</strong>
            </td>
            <td className="col-8">
              {sampleMessage5 || "-"}
            </td>
          </tr>}
          {sampleMediaFiles && Array.isArray(sampleMediaFiles) && sampleMediaFiles.length > 0 && <tr>
            <td className="col-4">
              <strong>Multimedia samples</strong>
            </td>
            <td className="col-8">
            {sampleMediaFiles.map((f, idx) => {
              return (<div key={idx}>
                {f.name}
              </div>)
            })}
          </td>
          </tr>
          }
        </tbody>
      </Table>
    </React.Fragment>
  )
}

AddCampaignStep5.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddCampaignStep5)));