import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { mapToOptions, constructErrorMessage, clearHistoryState } from '../../helpers/utils';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { constantsService, contactService } from "../../services";

import {
  updateContact,
  deleteContacts,
} from "../../store/actions";


const ContactEditComponent = (props) => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    currentContactId,
    currentProjectId,
    onUpdateCallback,
    onDeleteCallback,
    onContactDetailsLoadedCallback,
    onIsLoadingChangeCallback,
  } = props;

  const [contactGroupsList, setContactGroupsList] = useState([]);
  const [contactDetails, setContactDetails] = useState({});
  const isUpdating = useSelector((state) => state.Contact.updating);
  const isDeleting = useSelector((state) => state.Contact.deleting);
  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Contact.error);
  const [attributesList, setAttributesList] = useState([]);
  const [countriesMap, setCountriesMap] = useState(new Map());
  const [languagesMap, setLanguagesMap] = useState(new Map());
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const getData = useCallback(async (projectId, contactId) => {
    if (!projectId) {
      return;
    }
    try {
      setIsLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      setContactDetails({});
      let [countriesResult, languagesResult, attrResult, contactGroupsResult, contactResult] = await Promise.all([
        constantsService.fetchCountriesMap(),
        constantsService.fetchLanguagesMap(),
        contactService.fetchAdditionalContactAttributes(projectId),
        contactService.fetchContactGroups(projectId, null, null),
        contactService.fetchContactDetails(projectId, contactId),
      ]);

      setCountriesMap(countriesResult);
      setLanguagesMap(languagesResult);
      setAttributesList(attrResult?.additionalContactAttributes);
      setContactGroupsList(contactGroupsResult?.contactGroups);
      setContactDetails(contactResult);

      if (onContactDetailsLoadedCallback) {
        onContactDetailsLoadedCallback(contactResult)
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, onContactDetailsLoadedCallback]);

  useEffect(() => {
    if (currentProjectId && currentContactId) {
      getData(currentProjectId, currentContactId);
    }
  }, [getData, currentProjectId, currentContactId]);

  useEffect(() => {

    if (redirectStatus === 'create-contact-succeeded') {
      setSuccessMessage("Contact was successfully created");
    }

    if (redirectStatus === 'update-contact-succeeded') {
      setSuccessMessage("Contact was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);
  }, [redirectStatus, history]);

  const onDeleteContactClick = () => {

    setConfirmDialogText(`Are you sure you want to delete this contact?`);
    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteContacts(currentProjectId, [currentContactId], onDeleteCallback));
    });

    setIsConfirmDialogOpen(true);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      phoneNumber: contactDetails?.phoneNumber,
      email: contactDetails?.email,
      firstName: contactDetails?.firstName,
      lastName: contactDetails?.lastName,
      fullName: contactDetails?.fullName,
      company: contactDetails?.company,
      jobTitle: contactDetails?.jobTitle,
      countryCode: contactDetails?.countryCode,
      languageCode: contactDetails?.languageCode,
      groups: contactDetails?.groups?.map(g => { return { label: <><i className="uil uil-users-alt" /> {g.title}</>, value: g.contactGroupId } }),
      ...contactDetails?.additionalAttributes
    },
    validationSchema: Yup.object({
      email: Yup.string().nullable().email("Incorrect email format."),
    }),
    onSubmit: async (values) => {
      let attrObj = {};
      attributesList.filter((v) => values[v.attributeName] /* filter non-empty. equal to if (values[v.attributeName]){..}  */).forEach(v => attrObj[v.attributeName] = values[v.attributeName]);

      dispatch(updateContact(
        currentContactId,
        currentProjectId,
        values.email,
        values.fullName,
        values.firstName,
        values.lastName,
        values.company,
        values.jobTitle,
        values.countryCode,
        values.languageCode,
        values.groups?.map(g => g.value),
        attrObj,
        onUpdateCallback))
    }

  });

  const languagesOptionGroup = [
    {
      // label: group label
      options: mapToOptions(languagesMap)
    }
  ];

  const countriesOptionGroup = [
    {
      // label: group label
      options: mapToOptions(countriesMap)
    }
  ];

  const getGroupsOptionGroup = () => {

    if (!contactGroupsList || !Array.isArray(contactGroupsList) || contactGroupsList.length === 0) {
      return [];
    }

    let arr = [];

    contactGroupsList.forEach((e) => {
      arr.push({ label: <><i className="uil uil-users-alt" /> {e.title}</>, value: e.contactGroupId });
    });

    return arr;
  }

  // defaultValue returns an option based on the value. It works both with a simple map or the {options: {}} object.
  const defaultValue = (optionsOrOptionsGroup, value) => {
    if (!optionsOrOptionsGroup) {
      return "";
    };

    let optList = optionsOrOptionsGroup;

    if (Array.isArray(optionsOrOptionsGroup) && optionsOrOptionsGroup.length > 0 && optionsOrOptionsGroup[0].options) {
      optList = optionsOrOptionsGroup[0].options;
    }
    return optList ? optList.find(option => option.value === value) : "";
  };

  useEffect(() => {
    if (onIsLoadingChangeCallback) {
      onIsLoadingChangeCallback(isLoading);
    }
  }, [onIsLoadingChangeCallback, isLoading]);

  return (
    <React.Fragment>
        <GlobalProgressBar isLoading={isLoading} />
        {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
        {!isLoading && (apiError || errorMessage) ? <CustomAlert color="danger" role="alert">{errorMessage ?? 'Something went wrong'}</CustomAlert> : null}
        {!isLoading && contactDetails &&
          <Form
            className="form-group add-contact-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="mb-3">
              <Col className="col-8">
                <Label className="form-label">Phone number</Label>
                <p>{contactDetails?.phoneNumber || '-'}</p>
              </Col>
              <Col className="col-4">
                <Label className="form-label">Created date</Label>
                <p>{!contactDetails?.createdTimestampMs ? '-' : new Date(contactDetails?.createdTimestampMs).toLocaleString()}</p>
              </Col>
            </Row>
            <Row className="mb-3">
              <div>
                <Label className="form-label">Added to groups</Label>
                <Select
                  name="groupIds"
                  isMulti={true}
                  isClearable
                  onChange={options => { validation.setFieldValue("groups", options); }}
                  value={validation.values.groups}
                  options={getGroupsOptionGroup()}
                  maxMenuHeight={300}
                />
              </div>
            </Row>
            <Row className="mb-3">
              <div className="col"><hr className="hr hr-blurry" /></div>
              <div className="col-auto text-muted">other contact attributes</div>
              <div className="col"><hr className="hr hr-blurry" /></div>
            </Row>
            <Row className="mb-3">
              <Col className="col-6">
                <Label className="form-label">First name</Label>
                <InputGroup>
                  <Input
                    name="firstName"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName && validation.errors.firstName ? true : false
                    }
                  />
                  {validation.touched.firstName && validation.errors.firstName ? (
                    <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
              <Col className="col-6">
                <Label className="form-label">Last name</Label>
                <InputGroup>
                  <Input
                    name="lastName"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ""}
                    invalid={
                      validation.touched.lastName && validation.errors.lastName ? true : false
                    }
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-6">
                <Label className="form-label">Full name</Label>
                <InputGroup>
                  <Input
                    name="fullName"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.fullName || ""}
                    invalid={
                      validation.touched.fullName && validation.errors.fullName ? true : false
                    }
                  />
                  {validation.touched.fullName && validation.errors.fullName ? (
                    <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
              <Col className="col-6">
                <Label className="form-label">Email</Label>
                <InputGroup>
                  <Input
                    name="email"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-6">
                <Label className="form-label">Company</Label>
                <InputGroup>
                  <Input
                    name="company"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.company || ""}
                    invalid={
                      validation.touched.company && validation.errors.company ? true : false
                    }
                  />
                  {validation.touched.company && validation.errors.company ? (
                    <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
              <Col className="col-6">
                <Label className="form-label">Job title</Label>
                <InputGroup>
                  <Input
                    name="jobTitle"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.jobTitle || ""}
                    invalid={
                      validation.touched.jobTitle && validation.errors.jobTitle ? true : false
                    }
                  />
                  {validation.touched.jobTitle && validation.errors.jobTitle ? (
                    <FormFeedback type="invalid">{validation.errors.jobTitle}</FormFeedback>
                  ) : null}
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-6">
                <div>
                  <Label className="form-label">Preferred language</Label>
                  <Select
                    name="languageCode"
                    isClearable
                    onChange={option => validation.setFieldValue("languageCode", option?.value)}
                    value={defaultValue(languagesOptionGroup, validation.values.languageCode)}
                    options={languagesOptionGroup}
                    maxMenuHeight={170}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div>
                  <Label className="form-label">Country</Label>
                  <Select
                    name="countryCode"
                    isClearable
                    onChange={option => validation.setFieldValue("countryCode", option?.value)}
                    value={defaultValue(countriesOptionGroup, validation.values.countryCode)}
                    options={countriesOptionGroup}
                    maxMenuHeight={170}
                  />
                </div>
              </Col>
            </Row>
            {/* adding custom attributes */}
            {
              attributesList && Array.isArray(attributesList) && attributesList.length > 0 && attributesList.map((a, i) => <Row key={i} className="mb-3">
                <Col className="col-12">
                  <Label className="form-label">{a?.displayName ?? a?.attributeName}</Label>
                  <InputGroup>
                    <Input
                      name={a?.attributeName}
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values[a?.attributeName] || ""}
                    />
                  </InputGroup>
                </Col>
              </Row>)
            }
            {/* done adding custom attributes */}
            <Row className="mb-3">
              {/* aligning all buttons to the left except the last one. see https://stackoverflow.com/questions/58812566/align-all-item-to-left-except-last-in-a-flexbox-container */}
              <div className="d-flex flex-wrap flex-row gap-3 mt-3">
                <Button
                  type="submit"
                  color="primary"
                  className="w-md text-nowrap"
                >
                  {isUpdating && <>
                    <Spinner size="sm me-1" color="light" />
                  </>}
                  {!isUpdating && <>
                    Update
                  </>}
                </Button>
                <Button
                  type="reset"
                  color="danger"
                  outline
                  className="w-md text-nowrap ms-auto"
                  onClick={onDeleteContactClick}
                >
                  {isDeleting && <>
                    <Spinner size="sm me-1" color="danger" />
                  </>}
                  {!isDeleting && <>
                    Delete
                  </>}
                </Button>
              </div>
            </Row>
          </Form>
        }
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(ContactEditComponent));