import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { Table, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, clearHistoryState } from "../../helpers/utils";

import { contactService } from "../../services";

import './contacts.scss';

const AdditionalContactAttributes = () => {

  const history = useHistory();
  const location = useLocation();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [attributesList, setAttributesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message
  const [isLoading, setIsLoading] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const getAdditionalContactAttributesForProject = useCallback(async (projectId) => {
    if (!projectId) {
      return;
    }
    try {
      setIsLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      let result = await contactService.fetchAdditionalContactAttributes(projectId);

      if (!result?.additionalContactAttributes) {
        throw new Error("no attributes available");
      }

      setAttributesList(result?.additionalContactAttributes);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      getAdditionalContactAttributesForProject(currentProjectId);
    }
  }, [getAdditionalContactAttributesForProject, currentProjectId]);

  const dataRefresh = useCallback(() => { // this func is for naming consistency with other Refresh buttons
    initialDataLoad();
  }, [initialDataLoad]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  useEffect(() => {

    if (redirectStatus === 'create-attr-succeeded') {
      setSuccessMessage("Attribute was successfully created");
    }

    if (redirectStatus === 'delete-attr-succeeded') {
      setSuccessMessage("Attribute was successfully deleted");
    }

    if (redirectStatus === 'update-attr-succeeded') {
      setSuccessMessage("Attribute was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (isLoading === undefined || isLoading === true) {
      return undefined;
    }

    return Array.isArray(attributesList) && attributesList.length > 0;
  }, [isLoading, attributesList]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Custom Contacts Attributes" />
          <Card>
            <CardBody>
              {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
              {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
              <div className="actions clearfix">
                <ul>
                  {isDataAvailable() && <li>
                    <Link
                      to={`/projects/${currentProjectId}/directory/contacts/attributes/add`}
                      className="btn btn-success"
                    >
                      + New Attribute
                    </Link>
                  </li>}
                  <li>
                    <button
                      className="btn refresh without-outline"
                      type="button"
                      title="Refresh"
                      onClick={dataRefresh}
                    >
                      <i className='mdi mdi-reload h3' />
                    </button>
                  </li>
                </ul>
              </div>
              <GlobalProgressBar isLoading={isLoading} />
              <Row>
                <Col className="col-12">
                  {isDataAvailable() === false && <EmptyBlock>
                    <div className="text-center">
                      <p className="h3">No Custom Attributes</p>
                      <p>You haven't created any custom attribute yet.</p>
                      <p>
                        <Link
                          to={`/projects/${currentProjectId}/directory/contacts/attributes/add`}
                          className="btn btn-success"
                        >
                          + New Attribute
                        </Link>
                      </p>
                    </div>
                  </EmptyBlock>}
                  {isDataAvailable() && <Table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attributesList.map((a, i) => <tr key={i} className="align-middle">
                        <td style={{ width: "30%" }}>
                          <Link to={`/projects/${currentProjectId}/directory/contacts/attributes/${a.attributeId}`} title="Go to attribute details">
                            {a.displayName ?? a.attributeName}
                          </Link>
                        </td>
                        <td>{!a.createdTimestampMs ? '' : new Date(a.createdTimestampMs).toLocaleString()}</td>
                      </tr>)
                      }
                    </tbody>
                  </Table>}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

AdditionalContactAttributes.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AdditionalContactAttributes)));