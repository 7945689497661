import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

export const EventDescription = (props) => {
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const e = props.event;
  let description = '';
  switch (e?.eventType) {
    case 'balance_topped_up':
      description = `Space balance top up`;
      break;
    case 'balance_topped_up_auto_charge':
      description = `Space balance auto recharge`;
      break;
    case 'telephone_number_released':
      description = <>Telephone number release<br /><small>{e.telephoneNumber}</small></>;
      break;
    case 'telephone_number_10dlc_purchased':
      description = <>10DLC telephone number purchase<br /><Link to={`/projects/${currentProjectId}/numbers/${e.telephoneNumberId}`} title="Go to telephone number details" className="small">{e.telephoneNumber}</Link></>;
      break;
    case 'telephone_number_10dlc_updated':
      description = <>10DLC telephone number update<br /><Link to={`/projects/${currentProjectId}/numbers/${e.telephoneNumberId}`} title="Go to telephone number details" className="small">{e.telephoneNumber}</Link></>;
      break;
    case 'telephone_number_toll_free_purchased':
      description = <>Toll-free telephone number purchase<br /><Link to={`/projects/${currentProjectId}/numbers/${e.telephoneNumberId}`} title="Go to telephone number details" className="small">{e.telephoneNumber}</Link></>;
      break;
    case 'telephone_number_toll_free_updated':
      description = <>Toll-free telephone number update<br /><Link to={`/projects/${currentProjectId}/numbers/${e.telephoneNumberId}`} title="Go to telephone number details" className="small">{e.telephoneNumber}</Link></>;
      break;
    case 'telephone_number_10dlc_renewal':
      description = <>10DLC telephone number renewal<br /><Link to={`/projects/${currentProjectId}/numbers/${e.telephoneNumberId}`} title="Go to telephone number details" className="small">{e.telephoneNumber}</Link></>;
      break;
    case 'telephone_number_toll_free_renewal':
      description = <>Toll-free telephone number renewal<br /><Link to={`/projects/${currentProjectId}/numbers/${e.telephoneNumberId}`} title="Go to telephone number details" className="small">{e.telephoneNumber}</Link></>;
      break;
    case 'token_created':
      description = <>Token creation<br /><Link to={`/projects/${currentProjectId}/api/tokens/${e.tokenId}`} title="Go to token details" className="small">{e.tokenId}</Link></>;
      break;
    case 'token_updated':
      description = <>Token update<br /><Link to={`/projects/${currentProjectId}/api/tokens/${e.tokenId}`} title="Go to token details" className="small">{e.tokenId}</Link></>;
      break;
    case 'token_deleted':
      description = <>Token delete<br /><small>{e.tokenId}</small></>;
      break;
    case 'invite_created':
      description = <>Invite sent<br /><small>{e.inviteEmail}</small></>;
      break;
    case 'invite_accepted':
      description = <>Invite accepted<br /><small>{e.inviteEmail}</small></>;
      break;
    case 'user_deleted':
      description = <>Invite accepted<br /><small>{e.userId}</small></>;
      break;
    case 'inbound_sms_completed':
      description = <>Inbound SMS<br /><Link to={`/projects/${currentProjectId}/messages/${e.messageId}`} title="Go to message details" className="small">{e.messageId}</Link></>;
      break;
    case 'inbound_mms_completed':
      description = <>Inbound MMS<br /><Link to={`/projects/${currentProjectId}/messages/${e.messageId}`} title="Go to message details" className="small">{e.messageId}</Link></>;
      break;
    case 'inbound_message_web_hook_completed':
      description = <>Inbound web hook success<br /><Link to={`/projects/${currentProjectId}/messages/${e.messageId}`} title="Go to message details" className="small">{e.messageId}</Link></>;
      break;
    case 'inbound_message_web_hook_failed':
      description = <>Inbound web hook failure<br /><Link to={`/projects/${currentProjectId}/messages/${e.messageId}`} title="Go to message details" className="small">{e.messageId}</Link></>;
      break;
    case 'contact_created':
      description = <>Contact created<br /><Link to={`/projects/${currentProjectId}/directory/contacts/${e.contactId}`} title="Go to contact details" className="small">{e.contactId}</Link></>;
      break;
    case 'contact_updated':
      description = <>Contact updated<br /><Link to={`/projects/${currentProjectId}/directory/contacts/${e.contactId}`} title="Go to contact details" className="small">{e.contactId}</Link></>;
      break;
    case 'contact_deleted':
      description = <>Contact deleted<br /><small>{e.contactId}</small></>;
      break;
    case 'contact_group_created':
      description = <>Contacts group created<br /><Link to={`/projects/${currentProjectId}/directory/contacts/groups/${e.contactGroupId}`} title="Go to contacts group details" className="small">{e.contactGroupId}</Link></>;
      break;
    case 'contact_group_updated':
      description = <>Contacts group updated<br /><Link to={`/projects/${currentProjectId}/directory/contacts/groups/${e.contactGroupId}`} title="Go to contacts group details" className="small">{e.contactGroupId}</Link></>;
      break;
    case 'contact_group_deleted':
      description = <>Contacts group deleted<br /><small>{e.contactGroupId}</small></>;
      break;
    case 'contact_import_task_created':
      description = <>Import task created</>;
      break;
    case 'additional_contact_attribute_created':
      description = <>Custom contact attribute created<br /><Link to={`/projects/${currentProjectId}/directory/contacts/attributes/${e.contactAttributeId}`} title="Go to contact attribute details" className="small">{e.contactAttributeId}</Link></>;
      break;
    case 'additional_contact_attribute_updated':
      description = <>Custom contact attribute updated<br /><Link to={`/projects/${currentProjectId}/directory/contacts/attributes/${e.contactAttributeId}`} title="Go to contact attribute details" className="small">{e.contactAttributeId}</Link></>;
      break;
    case 'additional_contact_attribute_deleted':
      description = <>Custom contact attribute deleted<br /><small>{e.contactAttributeId}</small></>;
      break;
    case 'brand_created':
      description = <>Brand created<br /><Link to={`/projects/${currentProjectId}/tcr/brands/${e.brandId}`} title="Go to brand details" className="small">{e.brandId}</Link></>;
      break;
    case 'brand_updated':
      description = <>Brand updated<br /><Link to={`/projects/${currentProjectId}/tcr/brands/${e.brandId}`} title="Go to brand details" className="small">{e.brandId}</Link></>;
      break;
    case 'brand_deleted':
      description = <>Brand deleted<br /><small>{e.brandId}</small></>;
      break;
    case 'campaign_created':
      description = <>Campaign created<br /><Link to={`/projects/${currentProjectId}/tcr/brands/${e.brandId}/campaigns/${e.campaignId}`} title="Go to campaign details" className="small">{e.campaignId}</Link></>;
      break;
    case 'campaign_updated':
      description = <>Campaign updated<br /><Link to={`/projects/${currentProjectId}/tcr/brands/${e.brandId}/campaigns/${e.campaignId}`} title="Go to campaign details" className="small">{e.campaignId}</Link></>;
      break;
    case 'campaign_deleted':
      description = <>Campaign deleted<br /><small>{e.campaignId}</small></>;
      break;
    case 'campaign_renewal':
      description = <>Campaign renewal<br /><Link to={`/projects/${currentProjectId}/tcr/brands/${e.brandId}/campaigns/${e.campaignId}`} title="Go to campaign details" className="small">{e.campaignId}</Link></>;
      break;
    case 'toll_free_verification_created':
      description = <>Toll-free verification request<br /><Link to={`/projects/${currentProjectId}/toll-free-verifications/${e.tollFreeVerificationId}`} title="Go to request details" className="small">{e.tollFreeVerificationId}</Link></>;
      break;
    case 'toll_free_verification_deleted':
      description = <>Toll-free verification request deleted<br /><small>{e.tollFreeVerificationId}</small></>;
      break;
    case 'sms_sent':
      description = <>SMS sent<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'mms_sent':
      description = <>MMS sent<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'sms_delivered':
      description = <>SMS delivered<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'mms_delivered':
      description = <>MMS delivered<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'sms_undelivered':
      description = <>SMS undelivered<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'mms_undelivered':
      description = <>MMS undelivered<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'sms_received':
      description = <>SMS received<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'mms_received':
      description = <>MMS received<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'sms_failed':
      description = <>SMS failed<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'mms_failed':
      description = <>MMS failed<br /><Link to={`/projects/${currentProjectId}/messages/groups/${e.messageGroupId}`} title="Go to message details" className="small">{e.messageGroupId}</Link></>;
      break;
    case 'keyword_created':
      description = <>Keyword created<br /><Link to={`/projects/${currentProjectId}/keywords/${e.keywordId}`} title="Go to keyword details" className="small">{e.keywordId}</Link></>;
      break;
    case 'keyword_updated':
      description = <>Keyword updated<br /><Link to={`/projects/${currentProjectId}/keywords/${e.keywordId}`} title="Go to keyword details" className="small">{e.keywordId}</Link></>;
      break;
    case 'keyword_deleted':
      description = <>Keyword deleted<br /><small>{e.keywordId}</small></>;
      break;
    case 'telephone_number_subscribed':
      description = <>Telephone number subscribed<br /><small>{e.subscribedTelephoneNumber}</small></>;
      break;
    case 'telephone_number_unsubscribed':
      description = <>Telephone number unsubscribed<br /><small>{e.unsubscribedTelephoneNumber}</small></>;
      break;
    default:
      description = '';
  }

  return (
    <React.Fragment>
      <span>
        {description}
      </span>
    </React.Fragment>
  );
}