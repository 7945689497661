import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withRouter, useParams, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import EmptyBlock from '../../components/Common/EmptyBlock';

import NumberInfo from './NumberInfo';
import TollFreeVerificationInfo from './TollFreeVerificationInfo';

import { isTollFree } from './utils';

import {
  getNumberDetails,
} from "../../store/actions";

import classnames from 'classnames';
const NumberDetails = () => {

  const dispatch = useDispatch();

  // getting telephoneNumberId fromURL
  const { telephoneNumberId: currentTelephoneNumberId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const telephoneNumberDetails = useSelector((state) => state.Numbers?.telephoneNumberDetails);
  const [activeTab, setActiveTab] = useState("tn-info");

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  useEffect(() => {
    if (currentProjectId && currentTelephoneNumberId) {
      dispatch(getNumberDetails(currentProjectId, currentTelephoneNumberId));
    }
  }, [dispatch, currentProjectId, currentTelephoneNumberId]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Purchased Phone Numbers"
            pageTitle="Phone Number Details"
            titleUrl={`/projects/${currentProjectId}/numbers`}
            breadcrumbItem={telephoneNumberDetails?.telephoneNumber}
          />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "tn-info",
                        })}
                        onClick={() => {
                          toggleTab("tn-info")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-information-variant"></i>
                        </span>
                        <span className="d-none d-sm-block"><i className="mdi mdi-information-variant"></i>Number Info</span>
                      </NavLink>
                    </NavItem>
                    {isTollFree(telephoneNumberDetails) &&
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "tn-toll-free-verification",
                          })}
                          onClick={() => {
                            toggleTab("tn-toll-free-verification")
                          }}
                        >
                          <span className="d-block d-sm-none">
                          {!telephoneNumberDetails?.tollFreeVerification && <><i className="uil uil-exclamation-triangle text-warning"></i>{' '}</>}<i className="mdi mdi-phone-check-outline"></i>
                          </span>
                          <span className="d-none d-sm-block">
                            {!telephoneNumberDetails?.tollFreeVerification && <><i className="uil uil-exclamation-triangle text-warning"></i>{' '}</>}
                            <i className="mdi mdi-phone-check-outline"></i>{' '}Verification</span>
                        </NavLink>
                      </NavItem>
                    }
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="tn-info">
                      <NumberInfo telephoneNumberDetails={telephoneNumberDetails} />
                    </TabPane>
                    {isTollFree(telephoneNumberDetails) &&
                      <TabPane tabId="tn-toll-free-verification">
                        {telephoneNumberDetails?.tollFreeVerification && <TollFreeVerificationInfo currentTelephoneNumberId={currentTelephoneNumberId} tollFreeVerification={telephoneNumberDetails?.tollFreeVerification} />}
                        {!telephoneNumberDetails?.tollFreeVerification && <EmptyBlock>
                          <div className="text-center">
                            <i className="uil uil-exclamation-triangle d-block display-4 mt-2 mb-3 text-warning"></i>
                            <h5 className="text-warning">Not Verified</h5>
                            <p>This telephone number is not verified yet</p>
                            <p>
                              <Link
                                to={`/projects/${currentProjectId}/toll-free-verifications/add`}
                                className="btn btn-success"
                              >
                                + New verification request
                              </Link>
                            </p>
                          </div>
                        </EmptyBlock>}
                      </TabPane>
                    }
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

NumberDetails.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(NumberDetails)));