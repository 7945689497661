/* Messages */
export const GET_MESSAGES_FOR_PROJECT_REQUEST = "GET_MESSAGES_FOR_PROJECT_REQUEST";
export const GET_MESSAGES_FOR_PROJECT_SUCCESS = "GET_MESSAGES_FOR_PROJECT_SUCCESS";

export const GET_MESSAGE_DETAILS_REQUEST = "GET_MESSAGE_DETAILS_REQUEST";
export const GET_MESSAGE_DETAILS_SUCCESS = "GET_MESSAGE_DETAILS_SUCCESS";

export const GET_MESSAGE_GROUPS_FOR_PROJECT_REQUEST = "GET_MESSAGE_GROUPS_FOR_PROJECT_REQUEST";
export const GET_MESSAGE_GROUPS_FOR_PROJECT_SUCCESS = "GET_MESSAGE_GROUPS_FOR_PROJECT_SUCCESS";

export const SET_MESSAGE_GROUPS_LIMIT = "SET_MESSAGE_GROUPS_LIMIT";

export const GET_MESSAGE_GROUP_DETAILS_REQUEST = "GET_MESSAGE_GROUP_DETAILS_REQUEST";
export const GET_MESSAGE_GROUP_DETAILS_NO_LOADING_REQUEST = "GET_MESSAGE_GROUP_DETAILS_NO_LOADING_REQUEST";
export const GET_MESSAGE_GROUP_DETAILS_SUCCESS = "GET_MESSAGE_GROUP_DETAILS_SUCCESS";

export const MESSAGE_API_ERROR = "MESSAGE_API_ERROR";
