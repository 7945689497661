import React, {useEffect, useCallback} from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {

  const changeTitleIfDifferent = useCallback((newTitle) => {
    if (document.title !== newTitle) {
      document.title = newTitle;
  }
  }, []);

  useEffect(() => {
    if (props.pageTitle) {
      changeTitleIfDifferent(props.pageTitle + " | TextSpaces");
    } else if (props.breadcrumbItem && (typeof props.breadcrumbItem === 'string' || props.breadcrumbItem instanceof String)) {
      changeTitleIfDifferent(props.breadcrumbItem + " | TextSpaces");
    } else {
      changeTitleIfDifferent("TextSpaces");
    }
  }, [props.breadcrumbItem, props.pageTitle, changeTitleIfDifferent]);

  return (
    <Row>
      <Col className="col-12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <h4 className="mb-0">
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {/* show only if we have a title */ props.title && <BreadcrumbItem>
                  <strong><Link to={props.titleUrl ? props.titleUrl : '#'}>{props.title}</Link></strong>
                </BreadcrumbItem>
                }
                <BreadcrumbItem active>
                  {props.breadcrumbItem}
                </BreadcrumbItem>
              </ol>
            </div>
          </h4>
          {props?.children}
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
