import PropTypes from 'prop-types';
import React, { useEffect, useCallback } from 'react';
import { Table, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import EmptyBlock from '../../components/Common/EmptyBlock';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { formatAmountWithCurrency } from '../../helpers/utils';

import {
  getPaymentsHistoryForProject,
  setPaymentsHistoryLimit,
} from "../../store/actions";

import { constructPaymentMethodBrandLogo } from "./common";

import '../datatables.scss';
const PaymentsHistory = () => {

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const paymentsHistoryList = useSelector((state) => state.Billing.paymentsHistoryList);
  const paymentsHistoryLimit = useSelector((state) => state.Billing.paymentsHistoryLimit);
  const paymentsHistoryOffset = useSelector((state) => state.Billing.paymentsHistoryOffset);
  const paymentsHistoryTotalCount = useSelector((state) => state.Billing.paymentsHistoryTotalCount);
  const paymentsHistoryLoading = useSelector((state) => state.Billing.loading);

  const pageSize = paymentsHistoryLimit || 20;

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getPaymentsHistoryForProject(currentProjectId, pageSize, 0));
    }
  }, [dispatch, currentProjectId, pageSize]);

  const onNextPageClick = () => {
    if (paymentsHistoryOffset + pageSize < paymentsHistoryTotalCount) {
      dispatch(getPaymentsHistoryForProject(currentProjectId, pageSize, paymentsHistoryOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (paymentsHistoryOffset !== 0) {
      dispatch(getPaymentsHistoryForProject(currentProjectId, pageSize, paymentsHistoryOffset - pageSize));
    }
  }

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (paymentsHistoryLoading === undefined || paymentsHistoryLoading === true) {
      return undefined;
    }

    return Array.isArray(paymentsHistoryList) && paymentsHistoryList.length > 0;
  }, [paymentsHistoryLoading, paymentsHistoryList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            breadcrumbItem="Payments History" />
          <Card>
            <CardBody>
              <Row>
                <Col className="col-12">
                  <GlobalProgressBar isLoading={paymentsHistoryLoading} />
                  {isDataAvailable() === false && <EmptyBlock>
                    <div className="text-center">
                      <p className="h3">No Payments History</p>
                      <p>Your payments history will appear here.</p>
                    </div>
                  </EmptyBlock>}
                  {isDataAvailable() && <Table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th>Amount</th>
                        <th>Payment method</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentsHistoryList.map((h, i) => <tr key={i} className="align-middle">
                        <td>{formatAmountWithCurrency(h.paymentAmount, h.paymentUnit, h.paymentCurrency)}</td>
                        <td>
                          {constructPaymentMethodBrandLogo(h?.brand)}
                          &nbsp; **** {h.last4}
                        </td>
                        <td>{!h.paymentTimestampMs ? '' : new Date(h.paymentTimestampMs).toLocaleString()}</td>
                      </tr>)
                      }
                    </tbody>
                  </Table>
                  }
                </Col>
              </Row>
              <Row>
                <Col className="col-12">
                  {(isDataAvailable() || paymentsHistoryTotalCount > 0) &&
                    <div className="actions clearfix">
                      <ul className='d-flex align-items-center justify-content-end'>
                        <li>
                          {/* we need Number() because without it it uses "String" */}
                          <select
                            className="form-control cursor-pointer"
                            onChange={(e) => dispatch(setPaymentsHistoryLimit(Number(e?.target?.value)))}
                            value={pageSize}
                          >
                            <option value="10">10 rows per page</option>
                            <option value="20">20 rows per page</option>
                            <option value="50">50 rows per page</option>
                            <option value="100">100 rows per page</option>
                            <option value="250">250 rows per page</option>
                          </select>
                        </li>
                        <li
                          className={
                            paymentsHistoryOffset === 0 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={onPreviousPageClick}
                          >
                            <i className="fa fa-chevron-left" />
                          </Link>
                        </li>
                        <li
                          className={
                            paymentsHistoryOffset + pageSize >= paymentsHistoryTotalCount ? "next disabled" : "next"
                          }
                        >
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={onNextPageClick}
                          >
                            <i className="fa fa-chevron-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

PaymentsHistory.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  billing: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    billing: state.Billing,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(PaymentsHistory)));