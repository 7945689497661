import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter, Link, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructBrandIdentityStatus } from './utils';

import {
  getBrandsForProject
} from "../../store/actions";

import '../datatables.scss';
import { constructErrorMessage } from '../../helpers/utils';


const Brands = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const brandsList = useSelector((state) => state.Tcr.brandsList);
  const loading = useSelector((state) => state.Tcr.loading);
  const apiError = useSelector((state) => state.Tcr.error);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      dispatch(getBrandsForProject(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  const dataRefresh = useCallback(() => { // this func is for naming consistency with other Refresh buttons
    initialDataLoad();
  }, [initialDataLoad]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  useEffect(() => {

    if (redirectStatus === 'delete-brand-succeeded') {
      setSuccessMessage("Brand was successfully deregistered");
    }

    if (redirectStatus === 'update-brand-succeeded') {
      setSuccessMessage("Brand was successfully updated");
    }

    if (redirectStatus === 'delete-brand-succeeded') {
      setSuccessMessage("Brand was successfully deleted");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }
  }, [redirectStatus]);

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (loading === undefined || loading === true) {
      return undefined;
    }

    return Array.isArray(brandsList) && brandsList.length > 0;
  }, [loading, brandsList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Registered Brands" />
          <Card>
            <CardBody>
              {!loading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
              {!loading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
              <div className="actions clearfix">
                <ul>
                  {isDataAvailable() && <li>
                    <Link
                      to={`/projects/${currentProjectId}/tcr/brands/add`}
                      className="btn btn-success"
                    >
                      + Register brand
                    </Link>
                  </li>}
                  <li>
                    <button
                      className="btn refresh without-outline"
                      type="button"
                      title="Refresh"
                      onClick={dataRefresh}
                    >
                      <i className='mdi mdi-reload h3' />
                    </button>
                  </li>
                </ul>
              </div>
              <GlobalProgressBar isLoading={loading} />
                  <Row>
                    <Col className="col-12">
                      {isDataAvailable() === false && <EmptyBlock>
                        <div className="text-center">
                          <p className="h3">No Brands</p>
                          <p>You haven't register your 10DLC brand yet.</p>
                          <p>
                            <Link
                              to={`/projects/${currentProjectId}/tcr/brands/add`}
                              className="btn btn-success"
                            >
                              + Register brand
                            </Link>
                          </p>
                        </div>
                      </EmptyBlock>}
                      {isDataAvailable() &&
                        <Table className="table table-striped table-responsive">
                          <thead>
                            <tr>
                              <th>Company</th>
                              <th>Status</th>
                              <th>Submitted on</th>
                            </tr>
                          </thead>
                          <tbody>
                            {brandsList.map((b, i) => <tr key={i} className="align-middle">
                              <td style={{ width: "50%" }}>
                                <Link to={`/projects/${currentProjectId}/tcr/brands/${b.brandId}`} title="Go to brand details">
                                  {b.companyName}
                                </Link>
                              </td>
                              <td style={{ width: "20%" }}><span className='text-uppercase'>{constructBrandIdentityStatus(b?.identityStatus)}</span></td>
                              <td style={{ width: "30%" }}>{!b.createdTimestampMs ? '' : new Date(b.createdTimestampMs).toLocaleString()}</td>
                            </tr>)
                            }
                          </tbody>
                        </Table>}
                    </Col>
                  </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

Brands.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  Tcr: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    tcr: state.Tcr,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Brands)));