import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withRouter, useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getContactAttributeDetails,
  updateContactAttribute,
  deleteContactAttribute
} from "../../store/actions";
import { constructErrorMessage, clearHistoryState } from '../../helpers/utils';


const AdditionalContactAttributeDetails = () => {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const contactAttributeDetails = useSelector((state) => state.Contact.contactAttributeDetails);
  const isLoading = useSelector((state) => state.Contact.loading);
  const isUpdating = useSelector((state) => state.Contact.updating);
  const isDeleting = useSelector((state) => state.Contact.deleting);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const apiError = useSelector((state) => state.Contact.error);

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react


  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  // getting contactGroupId fromURL
  const { attributeId: currentAttributeId } = useParams();


  useEffect(() => {
    if (currentProjectId && currentAttributeId) {
      dispatch(getContactAttributeDetails(currentProjectId, currentAttributeId));
    }
  }, [dispatch, currentProjectId, currentAttributeId]);

  useEffect(() => {

    if (redirectStatus === 'create-attr-succeeded') {
      setSuccessMessage("Attribute was successfully created");
    }

    if (redirectStatus === 'delete-attr-succeeded') {
      setSuccessMessage("Attribute was successfully deleted");
    }

    if (redirectStatus === 'update-attr-succeeded') {
      setSuccessMessage("Attribute was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  const onDeleteContactAttributeClick = () => {
    setConfirmDialogText(`Are you sure you want to delete this contact attribute?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteContactAttribute(currentProjectId, currentAttributeId, () => {
        history.push(`/projects/${currentProjectId}/directory/contacts/attributes`, { redirectStatus: `delete-attr-succeeded` });
      }));
    });

    setIsConfirmDialogOpen(true);
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      displayName: contactAttributeDetails?.displayName,
      description: contactAttributeDetails?.description,
    },
    validationSchema: Yup.object({
      // title: Yup.string().required("Please enter attribute name").matches(/^[a-zA-Z]+\w*$/, 'The attribute name should be alphanumeric and start with a word character'),
    }),
    onSubmit: async (values) => {

      dispatch(updateContactAttribute(
        currentAttributeId,
        currentProjectId,
        values.displayName,
        values.description,
        () => history.push(`/projects/${currentProjectId}/directory/contacts/attributes/${currentAttributeId}`, { redirectStatus: `update-attr-succeeded` })))

    }
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Custom Contacts Attributes"
            pageTitle = "Attribute Details"
            titleUrl={`/projects/${currentProjectId}/directory/contacts/attributes`}
            breadcrumbItem={contactAttributeDetails?.displayName}/>
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
                  {!isLoading && contactAttributeDetails &&
                    <>
                      <Form
                        className="form-group add-contact-attr-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Col className="col-12">
                            <Label className="form-label">Attribute name</Label>
                            <p>{contactAttributeDetails?.attributeName || '-'}</p>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Display name</Label>
                          <InputGroup>
                            <Input
                              name="displayName"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.displayName || ""}
                              invalid={
                                validation.touched.displayName && validation.errors.displayName ? true : false
                              }
                            />
                            {validation.touched.displayName && validation.errors.displayName ? (
                              <FormFeedback type="invalid">{validation.errors.displayName}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <Label className="form-label">Description</Label>
                          <InputGroup>
                            <Input
                              name="description"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.description || ""}
                              invalid={
                                validation.touched.description && validation.errors.description ? true : false
                              }
                            />
                            {validation.touched.description && validation.errors.description ? (
                              <FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          {/* aligning all buttons to the left except the last one. see https://stackoverflow.com/questions/58812566/align-all-item-to-left-except-last-in-a-flexbox-container */}
                          <div className="d-flex flex-wrap flex-row gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md"
                            >
                              {isUpdating && <>
                                <Spinner size="sm me-1" color="light" />
                              </>}
                              {!isUpdating && <>
                                Update
                              </>}
                            </Button>
                            <Button
                              type="reset"
                              color="danger"
                              outline
                              className="w-md ms-auto"
                              onClick={onDeleteContactAttributeClick}
                            >
                              {isDeleting && <>
                                <Spinner size="sm me-1" color="danger" />
                              </>}
                              {!isDeleting && <>
                                Delete
                              </>}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

AdditionalContactAttributeDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AdditionalContactAttributeDetails)));