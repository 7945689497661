import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, useHistory, Link } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import {
  Container, Row, Col, Card, CardBody, Button, Label, Form, FormFeedback, Alert, Input, Spinner
} from "reactstrap";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber';

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert, { showAlert } from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import AddContactsDialog from '../Directory/AddContactsDialog';
import AddContactsGroupsDialog from '../Directory/AddContactsGroupsDialog';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import './compose.scss';

import { telephoneNumberService, messageService } from "../../services";

import { constructErrorMessage, createOption, formatBytes, containsNonLatinCodepoints, isNullOrUndefined } from '../../helpers/utils';
import { SingleValue, customFilterForSearchIndex } from '../../helpers/react_select_helpers';

// Get an instance of `PhoneNumberUtil`.
const phoneUtil = PhoneNumberUtil.getInstance();

const sendToComponents = {
  DropdownIndicator: null,
};

const Compose = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [phoneNumbersList, setPhoneNumbersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const [messageBodyLimit, setMessageBodyLimit] = useState(1520);

  // utility state:
  const [sendToInputValue, setSendToInputValue] = React.useState('');
  const [sendToValue, setSendToValue] = React.useState([]);
  const [isAddContactsDialogOpen, setIsAddContactsDialogOpen] = useState(false);
  const [isAddContactsGroupsDialogOpen, setIsAddContactsGroupsDialogOpen] = useState(false);
  const [attachedFilesMap, setAttachedFilesMap] = React.useState(new Map());

  const formatCreateSendToLabel = useCallback((inputValue) => `Send text to ${inputValue}. Press <Enter> to add.`, []);

  const getPhoneNumbers = useCallback(async (projectId) => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      let phoneNumbersResult = await telephoneNumberService.fetchTelephoneNumbers(projectId); // fetching with default limit and offset
      if (!phoneNumbersResult?.telephoneNumbers) {
        throw new Error('No phone numbers were loaded');
      }

      let tns = phoneNumbersResult?.telephoneNumbers;
      // sorting by phone number
      // sort by value
      tns.sort((a, b) => a.telephoneNumber - b.telephoneNumber);

      setPhoneNumbersList(tns);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);


  useEffect(() => {
    if (currentProjectId) {
      getPhoneNumbers(currentProjectId);
    }
  }, [getPhoneNumbers, currentProjectId]);

  const telephoneNumbersOptionGroup = [
    {
      // label: group label
      options: phoneNumbersList?.map((tn) => {
        let tnType = tn.type === 'local' ? 'local' : 'toll-free'
        return {
          "label": <>
            <div className="d-flex justify-content-between cursor-pointer" data-toggle="tooltip" data-placement="top">
              <div>{tn.telephoneNumber}</div>
              <div className='text-info'>{tnType}</div>
              <div className='text-info'>{tnType === 'toll-free' ? '1200 msg/min' : '10 msg/min'}</div>
            </div>
          </>,
          "value": tn.telephoneNumberId,
          "searchIndex": `${tn.telephoneNumber} ${tnType}`,
          "chipLabel": <>{/* this allows different labels for dropdown vs selected item (dropdown item is too wide) It's also required by SingleValue */}
            <div className="d-flex justify-content-between" data-toggle="tooltip" data-placement="top">
              <div>{tn.telephoneNumber}</div>
              <div className='text-info'>{tnType}</div>
              <div className='text-info'>{tnType === 'toll-free' ? '1200 msg/min' : '10 msg/min'}</div>
            </div>
          </>
        };

      }) // end map()
    }
  ];

  const handleSendToKeyDown = (event) => {
    if (!sendToInputValue) {
      if (event.key === 'Enter') { // this prevent hte form submission in case of hitting Enter without any value typed
        event.preventDefault();
      }
      return;
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        // validating and adding a phone number. see https://www.ronaldjamesgroup.com/blog/phone-numbers-in-javascript-using-e164-libphonenumber-and-microdata
        try {
          let tel = phoneUtil.parse(sendToInputValue);
          let e164Tel = phoneUtil.format(tel, PNF.E164);
          setSendToValue((prev) => [...prev, createOption(<><i className="uil uil-mobile-android-alt" /> {e164Tel}</>, e164Tel)]);
          setSendToInputValue('');
          event.preventDefault();
        } catch (e) {
          showAlert("danger", `Invalid phone number format: ${e.message ?? e}`);
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  const constructContactLabel = (c) => {
    if (!isNullOrUndefined(c.fullName)) {
      return c.fullName;
    }

    if (!isNullOrUndefined(c.firstName) && !isNullOrUndefined(c.lastName)) {
      return c.firstName + " " + c.lastName;
    }

    if (!isNullOrUndefined(c.lastName)) {
      return c.lastName;
    }

    if (!isNullOrUndefined(c.firstName)) {
      return c.firstName;
    }

    if (!isNullOrUndefined(c.phoneNumber)) {
      return c.phoneNumber;
    }

    return c.contactId;
  }

  const addContactsToSendTo = (ids, contacts) => {
    const contactToValue = (c) => `contactId:${c.contactId}`; // helper func to construct the contact value
    const contactToLabel = (c) => <><i className="uil uil-user" /> {constructContactLabel(c)}</>;
    // adding a contact if it was not added already
    contacts
      .filter(c => !sendToValue.find(s => s.value === contactToValue(c))) // a filter that allows only values that were not added yet
      .forEach((c) => setSendToValue((prev) => [...prev, createOption(contactToLabel(c), contactToValue(c), c.phoneNumber)]));
  }

  const addContactsGroupsToSendTo = (ids, groups) => {
    const groupToValue = (g) => `groupId:${g.contactGroupId}`; // helper func to construct the group value
    // adding a group if it was not added already
    groups
      .filter(g => !sendToValue.find(s => s.value === groupToValue(g))) // a filter that allows only values that were not added yet
      .forEach((g) => setSendToValue((prev) => [...prev, createOption(<><i className="uil uil-users-alt" /> {g.title}</>, groupToValue(g), g.description)]));
  }

  const showAddContactsDialog = () => {
    setIsAddContactsDialogOpen(true);
  }

  const hideAddContactsDialog = () => {
    setIsAddContactsDialogOpen(false);
  }

  const showAddContactsGroupsDialog = () => {
    setIsAddContactsGroupsDialogOpen(true);
  }

  const hideAddContactsGroupsDialog = () => {
    setIsAddContactsGroupsDialogOpen(false);
  }

  function handleAttachedFiles(files) {

    let mapCopy = new Map(attachedFilesMap); // this will create a new map

    Array.from(files).forEach(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })

      mapCopy.set(file.name, file); // Adding file to the map or override if the file with the same name exists. This will prevent duplicates.
    });

    setAttachedFilesMap(mapCopy); // setting changed map to the state variable
  }

  function removeAttachedFile(fileName) {
    if (attachedFilesMap.has(fileName)) {
      let m = new Map(attachedFilesMap);
      m.delete(fileName);
      setAttachedFilesMap(m);
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      phoneNumberIdFrom: "",
      messageBody: "",
      sendToCount: 0,
    },
    validationSchema: Yup.object({
      phoneNumberIdFrom: Yup.string().required("Please select the phone number to send this message from"),
      messageBody: Yup.string().max(messageBodyLimit, "Message is too long").required("Please type your message"),
      sendToCount: Yup.number().test(
        "sendToCountNot0",
        "Please select or type addressees of your message",
        () => sendToValue.length > 0,
      ),
    }),
    onSubmit: async (values) => {
      try {
        setIsCreating(true);
        setErrorMessage('');
        let toTelephoneNumbers = [];
        let toContactIds = [];
        let toContactGroupIds = [];

        // "decoding" values by prefix. sendToValue contains an array of options ({label:.., value:..}). We are interested only in values
        sendToValue.forEach(({ value: v }) => {
          if (v.startsWith("contactId:")) {
            toContactIds.push(
              v.replaceAll("contactId:", "")
            ); // appending without "contactId:" prefix
          } else if (v.startsWith("groupId:")) {
            toContactGroupIds.push(
              v.replaceAll("groupId:", "")
            ); // appending without "groupId:" prefix
          } else { // no prefixes found. let's append as is
            toTelephoneNumbers.push(v);
          }
        });

        let response = await messageService.createMessageGroupFromTelephoneNumberId(
          currentProjectId,
          values.messageBody,
          values.phoneNumberIdFrom,
          toTelephoneNumbers,
          toContactIds,
          toContactGroupIds,
          [...attachedFilesMap.values()], // attachedFiles
        );


        if (!response?.createdMessageGroup?.messageGroupId) {
          throw new Error("message was not created");
        }
        validation.resetForm();
        history.push(`/projects/${currentProjectId}/messages/groups/${response?.createdMessageGroup?.messageGroupId}`, { redirectStatus: `create-message-group-succeeded` });
      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsCreating(false);
      }
    }
  });

  useEffect(() => {
    if (containsNonLatinCodepoints(validation.values.messageBody)) { // we support up to 10 parts. see https://developers.sinch.com/docs/sms/resources/message-info/character-support
      setMessageBodyLimit(672);
    } else {
      setMessageBodyLimit(1520);
    }
  }, [validation.values.messageBody]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Send Message" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading && (!Array.isArray(phoneNumbersList) || phoneNumbersList.length === 0) && <Alert color="warning">
                    <i className="uil uil-exclamation-triangle text-warning"></i>{" "}You have no phone numbers to send a message from.{" "}
                    You can buy a new number <Link to={`/projects/${currentProjectId}/numbers/search`} className="alert-link">
                      here
                    </Link>.
                  </Alert>}
                  {!isLoading &&
                    <>
                      <Form
                        className="form-group compose-message-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Col className="col-9">
                            <div>
                              <Label className="form-label">Send from</Label>
                              {/* to change zIndex see https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown */}
                              <Select
                                name="phoneNumberIdFrom"
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }), singleValue: base => ({ ...base, width: "max-content", minWidth: "100%", height: "max-content", paddingRight: "10px" }) }}
                                isClearable
                                onChange={option => validation.setFieldValue("phoneNumberIdFrom", option?.value)}
                                components={{ SingleValue }}
                                filterOption={customFilterForSearchIndex}
                                options={telephoneNumbersOptionGroup}
                                maxMenuHeight={300}
                                placeholder="Choose your number"
                              />
                              {validation.touched.phoneNumberIdFrom && validation.errors.phoneNumberIdFrom ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.phoneNumberIdFrom}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-9">
                            <div>
                              <Label className="form-label">Send to</Label>
                              {/* see https://react-select.com/creatable */}
                              <CreatableSelect
                                name="sendTo"
                                components={sendToComponents}
                                inputValue={sendToInputValue}
                                isClearable
                                isMulti
                                menuIsOpen={false}
                                onChange={(newValue) => setSendToValue(newValue)}
                                onInputChange={(newValue) => setSendToInputValue(newValue)}
                                onKeyDown={handleSendToKeyDown}
                                formatCreateLabel={formatCreateSendToLabel}
                                placeholder="Type recipient number"
                                value={sendToValue}
                              />
                              {validation.touched.sendToCount && validation.errors.sendToCount && sendToValue.length === 0 /* this is a hack to hide the unused error message */ ? (
                                <>
                                  <span className="is-invalid"></span>
                                  <FormFeedback type="invalid">{validation.errors.sendToCount}</FormFeedback>
                                </>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-3">
                            <div className="list-group ">
                              <button
                                className="btn btn-link text-start without-outline"
                                type="button"
                                title="Contacts"
                                onClick={showAddContactsDialog}
                              ><i className="uil uil-user" /><span className="d-none d-md-inline">{" "}Contacts</span>
                              </button>
                              <button
                                className="btn btn-link text-start without-outline"
                                type="button"
                                title="Groups"
                                onClick={showAddContactsGroupsDialog}
                              ><i className="uil uil-users-alt" /><span className="d-none d-md-inline">{" "}Groups</span>
                              </button>
                            </div>
                          </Col>
                        </Row>
                        <Row className='mb-3'>
                          <Col className="col-12">
                            <Label>Message text</Label>
                            <Input
                              type="textarea"
                              name="messageBody"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.messageBody || ""}
                              maxLength={messageBodyLimit}
                              rows="10"
                            />
                            <span className="badgecount badge bg-info">
                              {" "}
                              {("" + validation.values.messageBody).length} / {messageBodyLimit}{" characters"}
                            </span>
                            {validation.touched.messageBody && validation.errors.messageBody ? (
                              <>
                                <span className="is-invalid"></span>
                                <FormFeedback type="invalid">{validation.errors.messageBody}</FormFeedback>
                              </>
                            ) : null}
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12">
                            <label htmlFor="formFileSm" className="form-label text-primary attach-files-label">
                              <i className="mdi mdi-attachment me-1" />
                              Attach files to this message</label>
                            <input
                              className="form-control form-control-sm d-none"
                              id="formFileSm"
                              type="file"
                              multiple
                              onChange={(e) => handleAttachedFiles(e.target.files)}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-12">

                            <div className="dropzone-previews mt-3" id="file-previews">
                              {[...attachedFilesMap.values()].map((f, i) => {
                                return (
                                  <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                  >
                                    <Row>
                                      <Col className="align-items-center">
                                        <span
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </span>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                      <Col className="d-flex flex-row-reverse align-items-center">
                                        <div>
                                          <i className="uil uil-trash-alt h5 cursor-pointer" title='Remove this attachment' onClick={() => removeAttachedFile(f.name)}></i>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                )
                              })}
                            </div>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="flex-fill"
                            >
                              {isCreating && <>
                                <Spinner size="sm me-1" color="light" />
                              </>}
                              {!isCreating && <>
                                Send
                              </>}
                            </Button>

                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AddContactsDialog
        currentProjectId={currentProjectId}
        isOpen={isAddContactsDialogOpen}
        closeDialog={hideAddContactsDialog}
        onAddContacts={addContactsToSendTo}
      />
      <AddContactsGroupsDialog
        currentProjectId={currentProjectId}
        isOpen={isAddContactsGroupsDialogOpen}
        closeDialog={hideAddContactsGroupsDialog}
        onAddContactsGroups={addContactsGroupsToSendTo}
      />
    </React.Fragment>
  )
}

Compose.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(Compose)));