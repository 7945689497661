import React, { useState } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  //DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import Avatar from 'avatar-initials';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const userEmail = useSelector((state) => state.AuthUser.user?.email);
  const gravatarUrlFromEmail = Avatar.gravatarUrl({
    email: userEmail
  });

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={gravatarUrlFromEmail}
            alt={userEmail}
          />
          {/* . // don't need username (email) to show for now
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{userEmail}</span>{" "}
          */}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <Link to="/profile" className="dropdown-item text-dark">
            {" "}
            <i className="uil uil-user-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("View Profile")}
            {" "}
          </Link>
          {/*<DropdownItem tag="a" href="https://textspaces.com/docs/" target="_blank" rel="noopener noreferrer" className="text-dark">
            {" "}
            <i className="uil uil-question-circle font-size-18 align-middle text-muted me-1"></i>
            {props.t("Documentation")}{" "}
        </DropdownItem>*/}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item text-dark">
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
