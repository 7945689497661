import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Form, Label, Input, InputGroup, FormFeedback } from "reactstrap";
import CreatableSelect from 'react-select/creatable';

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { emptyFunc, createOption } from '../../helpers/utils';

import './tcr.scss';

const keywordsComponents = {
  DropdownIndicator: null,
};

const AddCampaignStep3 = (props) => {

  let onCampaignDescriptionChange = props?.onCampaignDescriptionChange ?? emptyFunc;
  let onMessageFlowChange = props?.onMessageFlowChange ?? emptyFunc;
  let onSubscriberOptinChange = props?.onSubscriberOptinChange ?? emptyFunc;
  let onOptinMessageChange = props?.onOptinMessageChange ?? emptyFunc;
  let onOptinKeywordsChange = props?.onOptinKeywordsChange ?? emptyFunc;
  let onSubscriberOptoutChange = props?.onSubscriberOptoutChange ?? emptyFunc;
  let onOptoutMessageChange = props?.onOptoutMessageChange ?? emptyFunc;
  let onOptoutKeywordsChange = props?.onOptoutKeywordsChange ?? emptyFunc;
  let onSubscriberHelpChange = props?.onSubscriberHelpChange ?? emptyFunc;
  let onHelpMessageChange = props?.onHelpMessageChange ?? emptyFunc;
  let onHelpKeywordsChange = props?.onHelpKeywordsChange ?? emptyFunc;
  let onNumberPoolChange = props?.onNumberPoolChange ?? emptyFunc;
  let onDirectLendingChange = props?.onDirectLendingChange ?? emptyFunc;
  let onEmbeddedLinkChange = props?.onEmbeddedLinkChange ?? emptyFunc;
  let onEmbeddedPhoneChange = props?.onEmbeddedPhoneChange ?? emptyFunc;
  let onAgeGatedChange = props?.onAgeGatedChange ?? emptyFunc;

  const { validation } = props;
  const validationRef = useRef(validation);

  const [errorMessage] = useState(''); // to show a error message
  const [isLoading] = useState(false);

  // opt-in keywords
  const [optinKeywordsInputValue, setOptinKeywordsInputValue] = React.useState('');
  const [optinKeywordsValue, setOptinKeywordsValue] = React.useState([]);

  // opt-out keywords
  const [optoutKeywordsInputValue, setOptoutKeywordsInputValue] = React.useState('');
  const [optoutKeywordsValue, setOptoutKeywordsValue] = React.useState([]);

  // help keywords
  const [helpKeywordsInputValue, setHelpKeywordsInputValue] = React.useState('');
  const [helpKeywordsValue, setHelpKeywordsValue] = React.useState([]);

  const handleKeywordsKeyDown = (event, inputValue, setInputValueFunc, setValueFunc) => {
    if (!inputValue) {
      if (event.key === 'Enter') { // this prevent hte form submission in case of hitting Enter without any value typed
        event.preventDefault();
      }
      return;
    }
    switch (event.key) {
      case 'Enter':
      case ',':
      case 'Tab':
        setValueFunc((prev) => [...prev, createOption(inputValue.toUpperCase())]);
        setInputValueFunc('');
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    onCampaignDescriptionChange(validation.values.campaignDescription);
  }, [onCampaignDescriptionChange, validation.values.campaignDescription]);

  useEffect(() => {
    onMessageFlowChange(validation.values.messageFlow);
  }, [onMessageFlowChange, validation.values.messageFlow]);

  useEffect(() => {
    onSubscriberOptinChange(validation.values.subscriberOptin);
  }, [onSubscriberOptinChange, validation.values.subscriberOptin]);

  useEffect(() => {
    onOptinMessageChange(validation.values.optinMessage);
  }, [onOptinMessageChange, validation.values.optinMessage]);

  useEffect(() => {
    onOptinKeywordsChange(optinKeywordsValue.map((v) => v.value).join(","));
    validationRef.current.setFieldValue('optinKeywords', optinKeywordsValue.map((v) => v.value).join(","));
  }, [onOptinKeywordsChange, optinKeywordsValue]);

  useEffect(() => {
    onSubscriberOptoutChange(validation.values.subscriberOptout);
  }, [onSubscriberOptoutChange, validation.values.subscriberOptout]);

  useEffect(() => {
    onOptoutMessageChange(validation.values.optoutMessage);
  }, [onOptoutMessageChange, validation.values.optoutMessage]);

  useEffect(() => {
    onOptoutKeywordsChange(optoutKeywordsValue.map((v) => v.value).join(","));
    validationRef.current.setFieldValue('optoutKeywords', optoutKeywordsValue.map((v) => v.value).join(","));
  }, [onOptoutKeywordsChange, optoutKeywordsValue]);

  useEffect(() => {
    onSubscriberHelpChange(validation.values.subscriberHelp);
  }, [onSubscriberHelpChange, validation.values.subscriberHelp]);

  useEffect(() => {
    onHelpMessageChange(validation.values.helpMessage);
  }, [onHelpMessageChange, validation.values.helpMessage]);

  useEffect(() => {
    onHelpKeywordsChange(helpKeywordsValue.map((v) => v.value).join(","));
    validationRef.current.setFieldValue('helpKeywords', helpKeywordsValue.map((v) => v.value).join(","));
  }, [onHelpKeywordsChange, helpKeywordsValue]);

  useEffect(() => {
    onNumberPoolChange(validation.values.numberPool);
  }, [onNumberPoolChange, validation.values.numberPool]);

  useEffect(() => {
    onDirectLendingChange(validation.values.directLending);
  }, [onDirectLendingChange, validation.values.directLending]);

  useEffect(() => {
    onEmbeddedLinkChange(validation.values.embeddedLink);
  }, [onEmbeddedLinkChange, validation.values.embeddedLink]);

  useEffect(() => {
    onEmbeddedPhoneChange(validation.values.embeddedPhone);
  }, [onEmbeddedPhoneChange, validation.values.embeddedPhone]);

  useEffect(() => {
    onAgeGatedChange(validation.values.ageGated);
  }, [onAgeGatedChange, validation.values.ageGated]);

  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={isLoading} />
      {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
      {!isLoading &&
        <>
          <Form
            className="form-group add-campaign-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="mb-3">
              <Col className="col-6">
                <div>
                  <Label>Campaign description</Label>
                  <Input
                    type="textarea"
                    name="campaignDescription"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.campaignDescription || ""}
                    maxLength="4096"
                    rows="5"
                    placeholder="Tell us about your campaign"
                  />
                  {validation.touched.campaignDescription && validation.errors.campaignDescription ? (
                    <>
                      <span className="is-invalid"></span>
                      <FormFeedback type="invalid">{validation.errors.campaignDescription}</FormFeedback>
                    </>
                  ) : null}
                </div>
              </Col>
              <Col className="col-6">
                <div>
                  <Label>Message flow / Call-to-Action</Label>
                  <Input
                    type="textarea"
                    name="messageFlow"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.messageFlow || ""}
                    maxLength="4096"
                    rows="5"
                    placeholder="How a consumer opts-in to this campaign?"
                  />
                  {validation.touched.messageFlow && validation.errors.messageFlow ? (
                    <>
                      <span className="is-invalid"></span>
                      <FormFeedback type="invalid">{validation.errors.messageFlow}</FormFeedback>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-6">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subscriberOptin"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.subscriberOptin}
                    />
                    {' '} Subscriber opt-in

                  </label>
                </InputGroup>
              </Col>
            </Row>
            <Row className={validation.values.subscriberOptin ? "mb-3" : "mb-3 d-none"}>
              <Col className="col-6">
                {/* see https://react-select.com/creatable */}
                <CreatableSelect
                  name="optinKeywords"
                  components={keywordsComponents}
                  inputValue={optinKeywordsInputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setOptinKeywordsValue(newValue)}
                  onInputChange={(newValue) => setOptinKeywordsInputValue(newValue)}
                  onKeyDown={(e) => handleKeywordsKeyDown(e, optinKeywordsInputValue, setOptinKeywordsInputValue, setOptinKeywordsValue)}
                  placeholder="Opt-in keywords (e.g. keyword: START, SUBSCRIBE). Press <Enter> to add."
                  value={optinKeywordsValue}
                />
                {validation.touched.optinKeywords && validation.errors.optinKeywords ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.optinKeywords}</FormFeedback>
                  </>
                ) : null}
              </Col>
              <Col className="col-6">
                <Input
                  type="textarea"
                  name="optinMessage"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optinMessage || ""}
                  maxLength={320}
                  minLength={validation.values.subscriberOptin ? 20 : 0}
                  rows="1"
                  placeholder="Your Opt-in message"
                />
                {validation.touched.optinMessage && validation.errors.optinMessage ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.optinMessage}</FormFeedback>
                  </>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subscriberOptout"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.subscriberOptout}
                    />
                    {' '} Subscriber opt-out

                  </label>
                </InputGroup>
              </Col>
            </Row>
            <Row className={validation.values.subscriberOptout ? "mb-3" : "mb-3 d-none"}>
              <Col className="col-6">
                {/* see https://react-select.com/creatable */}
                <CreatableSelect
                  name="optoutKeywords"
                  components={keywordsComponents}
                  inputValue={optoutKeywordsInputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setOptoutKeywordsValue(newValue)}
                  onInputChange={(newValue) => setOptoutKeywordsInputValue(newValue)}
                  onKeyDown={(e) => handleKeywordsKeyDown(e, optoutKeywordsInputValue, setOptoutKeywordsInputValue, setOptoutKeywordsValue)}
                  placeholder="Opt-out keywords (e.g. keyword: STOP, QUIT). Press <Enter> to add."
                  value={optoutKeywordsValue}
                />
                {validation.touched.optoutKeywords && validation.errors.optoutKeywords ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.optoutKeywords}</FormFeedback>
                  </>
                ) : null}
              </Col>
              <Col className="col-6">
                <Input
                  type="textarea"
                  name="optoutMessage"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optoutMessage || ""}
                  maxLength={320}
                  minLength={validation.values.subscriberOptout ? 20 : 0}
                  rows="1"
                  placeholder="Your Opt-out message"
                />
                {validation.touched.optoutMessage && validation.errors.optoutMessage ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.optoutMessage}</FormFeedback>
                  </>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="subscriberHelp"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.subscriberHelp}
                    />
                    {' '} Subscriber help

                  </label>
                </InputGroup>
              </Col>
            </Row>
            <Row className={validation.values.subscriberHelp ? "mb-3" : "mb-3 d-none"}>
              <Col className="col-6">
                {/* see https://react-select.com/creatable */}
                <CreatableSelect
                  name="helpKeywords"
                  components={keywordsComponents}
                  inputValue={helpKeywordsInputValue}
                  isClearable
                  isMulti
                  menuIsOpen={false}
                  onChange={(newValue) => setHelpKeywordsValue(newValue)}
                  onInputChange={(newValue) => setHelpKeywordsInputValue(newValue)}
                  onKeyDown={(e) => handleKeywordsKeyDown(e, helpKeywordsInputValue, setHelpKeywordsInputValue, setHelpKeywordsValue)}
                  placeholder="Help keywords (e.g. keyword: HELP, INFO). Press <Enter> to add."
                  value={helpKeywordsValue}
                />
                {validation.touched.helpKeywords && validation.errors.helpKeywords ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.helpKeywords}</FormFeedback>
                  </>
                ) : null}
              </Col>
              <Col className="col-6">
                <Input
                  type="textarea"
                  name="helpMessage"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.helpMessage || ""}
                  maxLength={320}
                  minLength={validation.values.subscriberHelp ? 20 : 0}
                  rows="1"
                  placeholder="Your Help message"
                />
                {validation.touched.helpMessage && validation.errors.helpMessage ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.helpMessage}</FormFeedback>
                  </>
                ) : null}
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="numberPool"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.numberPool}
                    />
                    {' '} Number pooling

                  </label>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="directLending"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.directLending}
                    />
                    {' '} Direct lending or loan arrangement

                  </label>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="embeddedLink"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.embeddedLink}
                    />
                    {' '} Embedded link

                  </label>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="embeddedPhone"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.embeddedPhone}
                    />
                    {' '} Embedded phone number

                  </label>
                </InputGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col className="col-12">
                <InputGroup>
                  <label className="cursor-pointer">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="ageGated"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation.values.ageGated}
                    />
                    {' '} Age-gated content

                  </label>
                </InputGroup>
              </Col>
            </Row>
          </Form>
        </>
      }

    </React.Fragment>
  )
}

AddCampaignStep3.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddCampaignStep3)));