import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Row, Col, Table } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import './tcr.scss';

import { tcrService } from "../../services";

import { constructErrorMessage, emptyFunc, boolToYesNo } from "../../helpers/utils";

const AddCampaignStep2 = (props) => {

  let { selectedBrandId, selectedBrandUseCase, isComponentVisible } = props;
  let onExcludedMnosListChange = props?.onExcludedMnosListChange ?? emptyFunc;
  let onAllMnosListChange = props?.onAllMnosListChange ?? emptyFunc;

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [useCaseQualification, setUseCaseQualification] = useState({});
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message
  const [isLoading, setIsLoading] = useState(false);
  const [excludedMnos, setExcludedMnos] = useState(new Map()); // we maintain map to represent a Set (and easy manipulate)


  const qualifyBrandForUseCase = useCallback(async () => {
    if (!isComponentVisible) { // executing only when this tab is visible
      return;
    }
    if (!currentProjectId || !selectedBrandId || !selectedBrandUseCase) {
      return;
    }
    try {
      setIsLoading(true);
      setErrorMessage('');
      let result = await tcrService.qualifyBrandForUseCase(currentProjectId, selectedBrandId, selectedBrandUseCase);

      if (!result?.useCase) {
        throw new Error("no use case available for the selected brand");
      }

      setUseCaseQualification(result?.useCase);
      
      if (result?.useCase?.mnoMetadata) {
        let m = new Map();
        // disabling checkboxes for non-qualify
        Object.keys(result?.useCase?.mnoMetadata).forEach((mnoId) => {
          if (!result.useCase.mnoMetadata[mnoId].qualify) {
            m.set(mnoId);
          }
        });
        
        setExcludedMnos(m);
        // setting all MNOs and exluded MNOs for the parent component
        onAllMnosListChange(Object.keys(result?.useCase?.mnoMetadata));
        onExcludedMnosListChange(Array.from(m.keys()));
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, [isComponentVisible, currentProjectId, selectedBrandId, selectedBrandUseCase, setExcludedMnos, onExcludedMnosListChange, onAllMnosListChange]);

  // loading use cases and qualify brand
  useEffect(() => {
    qualifyBrandForUseCase();
  }, [qualifyBrandForUseCase]);

  useEffect(() => {
    onExcludedMnosListChange(Array.from(excludedMnos.keys()));
  }, [onExcludedMnosListChange, excludedMnos]);


  // sub use cases checkbox handlers
  const mnoCheckboxIsChecked = (mnoId) => {
    return !excludedMnos.has(mnoId);
  }

  const handleSelectedMnosChange = (e) => {
    let mnoId = e?.target?.value;

    if (!mnoId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (excludedMnos.has(mnoId)) {
      let m = excludedMnos;
      m.delete(mnoId);
      setExcludedMnos(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    } else {

      let m = excludedMnos;
      m.set(mnoId, true);
      setExcludedMnos(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }
  }
  // end sub use cases checkbox handlers

  const generateMnoLimits = (mnoMetadata) => {
    return <>
      {mnoMetadata.brandDailyCap &&
        <div>Brand daily cap: {mnoMetadata.brandDailyCap}</div>
      }
      {mnoMetadata.tpm &&
        <div>SMS per minute: {mnoMetadata.tpm}</div>
      }
      {mnoMetadata.mmsTpm &&
        <div>MMS per minute: {mnoMetadata.mmsTpm}</div>
      }
      {!mnoMetadata.brandDailyCap && !mnoMetadata.tpm && !mnoMetadata.mmsTpm &&
        <div>-</div>
      }
    </>
  }

  return (
    <React.Fragment>
      <GlobalProgressBar isLoading={isLoading} />
      {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
      {!isLoading &&
        <>
          <Row>
            <Col className="col-12">
              <Table className="table table-striped table-responsive table-hover">
                <thead>
                  <tr>
                    <th />
                    <th>Mobile network operator (MNO)</th>
                    <th>Qualify</th>
                    {/*<th>Needs MNO review</th>*/}
                    <th>Limits</th>
                  </tr>
                </thead>
                <tbody>
                  {useCaseQualification && useCaseQualification.mnoMetadata && Object.keys(useCaseQualification?.mnoMetadata).map(key => <tr key={key}>
                    <td style={{ width: "2rem" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={key}
                        onChange={handleSelectedMnosChange}
                        id={`chk-mno-${key}`}
                        defaultChecked={mnoCheckboxIsChecked(key)}
                        disabled={!useCaseQualification.mnoMetadata[key].qualify}
                      />
                    </td>
                    <td>{useCaseQualification.mnoMetadata[key].mno}</td>
                    <td>{boolToYesNo(useCaseQualification.mnoMetadata[key].qualify)}</td>
                    {/*<td>{boolToYesNo(useCaseQualification.mnoMetadata[key].mnoReview)}</td>*/}
                    <td><small>{generateMnoLimits(useCaseQualification.mnoMetadata[key])}</small></td>
                  </tr>)
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      }
    </React.Fragment>
  )
}

AddCampaignStep2.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddCampaignStep2)));