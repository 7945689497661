import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { Table, Container, Row, Col, Card, CardBody } from 'reactstrap';
import { withRouter, Link, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructCampaignStatus } from './utils';

import {
  getCampaigns
} from "../../store/actions";

import '../datatables.scss';
import { constructErrorMessage } from '../../helpers/utils';


const Campaigns = () => {

  const location = useLocation();
  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const campaignsList = useSelector((state) => state.Tcr.campaignsList);
  const loading = useSelector((state) => state.Tcr.loading);
  const apiError = useSelector((state) => state.Tcr.error);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      dispatch(getCampaigns(currentProjectId));
    }
  }, [dispatch, currentProjectId]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  const dataRefresh = useCallback(() => { // this func is for naming consistency with other Refresh buttons
    initialDataLoad();
  }, [initialDataLoad]);

  useEffect(() => {

    if (redirectStatus === 'delete-campaign-succeeded') {
      setSuccessMessage("Campaign was successfully deregistered");
    }

    if (redirectStatus === 'update-campaign-succeeded') {
      setSuccessMessage("Campaign was successfully updated");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }
  }, [redirectStatus]);

  /*
  const truncateCampaignDescription = (desc) => {
    const maxDescLength = 30;
    if (!desc) {
      return "";
    }

    if (desc.length > maxDescLength) {
      return desc.substring(0, maxDescLength - 1) + '...';
    }

    return desc;
  }
  */

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (loading === undefined || loading === true) {
      return undefined;
    }

    return Array.isArray(campaignsList) && campaignsList.length > 0;
  }, [loading, campaignsList]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Registered Campaigns" />
          <Card>
            <CardBody>
              {!loading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
              {!loading && apiError ? <CustomAlert color="danger" role="alert">{constructErrorMessage(apiError)}</CustomAlert> : null}
              <div className="actions clearfix">
                <ul>
                  {isDataAvailable() && <li
                  >
                    <Link
                      to={`/projects/${currentProjectId}/tcr/campaigns/add`}
                      className="btn btn-success"
                    >
                      + Register campaign
                    </Link>
                  </li>}
                  <li>
                    <button
                      className="btn refresh without-outline"
                      type="button"
                      title="Refresh"
                      onClick={dataRefresh}
                    >
                      <i className='mdi mdi-reload h3' />
                    </button>
                  </li>
                </ul>
              </div>
              <GlobalProgressBar isLoading={loading} />
              <Row>
                <Col className="col-12">
                  {isDataAvailable() === false && <EmptyBlock>
                    <div className="text-center">
                      <p className="h3">No Campaigns</p>
                      <p>You haven't register your 10DLC campaign yet.</p>
                      <p>
                        <Link
                          to={`/projects/${currentProjectId}/tcr/campaigns/add`}
                          className="btn btn-success"
                        >
                          + Register campaign
                        </Link>
                      </p>
                    </div>
                  </EmptyBlock>}
                  {isDataAvailable() && <Table className="table table-striped table-responsive">
                    <thead>
                      <tr>
                        <th>Campaign ID</th>
                        <th>Use case</th>
                        <th>Status</th>
                        <th>Brand</th>
                        <th>Submitted on</th>
                      </tr>
                    </thead>
                    <tbody>
                      {campaignsList.map((c, i) => <tr key={i} className="align-middle">
                        <td>
                          <Link to={`/projects/${currentProjectId}/tcr/brands/${c.brandId}/campaigns/${c.campaignId}`} title="Go to campaign details">
                            {c.campaignId}
                          </Link>
                        </td>
                        <td>{c.useCase}</td>
                        <td><span className='text-uppercase'>{constructCampaignStatus(c?.campaignStatus)}</span></td>
                        <td>
                          <Link to={`/projects/${currentProjectId}/tcr/brands/${c.brandId}`} title="Go to brand details">
                            {c.brandName}
                          </Link>
                        </td>
                        <td>{!c.createdTimestampMs ? '' : new Date(c.createdTimestampMs).toLocaleString()}</td>
                      </tr>)
                      }
                    </tbody>
                  </Table>
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment >
  )
}

Campaigns.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  Tcr: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    tcr: state.Tcr,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Campaigns)));