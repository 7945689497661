import {
  GET_PROJECT_DETAILS_REQUEST,
  GET_PROJECT_DETAILS_SUCCESS,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST,
  UPDATE_INBOUND_MESSAGE_WEBHOOK_SUCCESS,
  UPDATE_AUTO_CHARGE_REQUEST,
  UPDATE_AUTO_CHARGE_SUCCESS,
  PROJECT_API_ERROR
} from "./actionTypes";

export const getProjectDetails = (projectId) => {
  return {
    type: GET_PROJECT_DETAILS_REQUEST,
    payload: projectId
  }
};

export const getProjectDetailsSuccess = projectDetails => {
  return {
    type: GET_PROJECT_DETAILS_SUCCESS,
    payload: projectDetails
  }
};

export const updateProject = (projectId, name, callback) => {
  return {
    type: UPDATE_PROJECT_REQUEST,
    payload: { projectId, name, callback }
  }
};

export const updateProjectSuccess = () => {
  return {
    type: UPDATE_PROJECT_SUCCESS,
  }
};

export const updateInboundMessageWebhook = (projectId, url, headers, callback) => {
  return {
    type: UPDATE_INBOUND_MESSAGE_WEBHOOK_REQUEST,
    payload: { projectId, url, headers, callback }
  }
};

export const updateInboundMessageWebhookSuccess = () => {
  return {
    type: UPDATE_INBOUND_MESSAGE_WEBHOOK_SUCCESS,
  }
};

export const updateAutoCharge = (projectId, thresholdAmount, topUpAmount, paymentMethodId, callback) => {
  return {
    type: UPDATE_AUTO_CHARGE_REQUEST,
    payload: { projectId, thresholdAmount, topUpAmount, paymentMethodId, callback }
  }
};

export const updateAutoChargeSuccess = () => {
  return {
    type: UPDATE_AUTO_CHARGE_SUCCESS,
  }
};

export const projectApiError = error => {
  return {
    type: PROJECT_API_ERROR,
    payload: error,
  }
}