import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Table } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { getMapKeyByValue, constructErrorMessage, emptyFunc } from '../../helpers/utils';

import './contacts.scss';

import { contactService } from "../../services";

const AttributeMapping = (props) => {
  const attrs = props?.contactAttributes;
  const csvIndex = props?.csvIndex;
  const csvColumnsMap = props?.csvColumnsMap;
  const onSelectedAttributeChange = props?.onSelectedAttributeChange ?? emptyFunc;

  const isAttributeUsedByAnotherSelect = (a) => { // check whether this attr is already used by another <select>
    let mapKey = getMapKeyByValue(csvColumnsMap, a.attributeName);
    if (mapKey === undefined) { // this attribute was not set at all
      return false;
    } else if (csvIndex !== mapKey) { // this attribute was set but for another csvIndex (= csv column)
      return true;
    };

    return false;
  }

  return (
    <div className="form-floating mb-3 width-min-content">
      <select
        className="form-select csv-column-mapping"
        onChange={(e) => onSelectedAttributeChange(e?.target?.value)}
        value={csvColumnsMap.get(csvIndex)}
      >
        <option value=""></option>
        {Array.isArray && attrs.filter(a => !isAttributeUsedByAnotherSelect(a)).map((a, i) =>
          <option
            key={`contactAttr${i}`}
            value={a.attributeName}
          >
            {a.displayName ?? a.attributeName}
          </option>)
        }
      </select>
      <label className="text-nowrap">
        Map to contact attribute
      </label>
    </div>
  )
}

const AddContactImportTaskStep2 = (props) => {
  const currentProjectId = props?.currentProjectId;
  const fileId = props?.fileId;
  const fileDelimiter = props?.fileDelimiter;
  const csvColumnsMap = props?.csvColumnsMap;

  const onCsvColumnMappingChange = props?.onCsvColumnMappingChange ?? emptyFunc;

  const [csvRecords, setCsvRecords] = useState(false);
  const [contactAttributes, setContactAttributes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const getFilePreview = useCallback(async (projectId, fileId, delimiter) => {
    if (!fileId || !projectId) {
      return;
    }
    try {
      setIsLoading(true);
      setErrorMessage('');

      // loading fetchContactImportTaskFilePreview() and fetchAllContactAttributes() in parallel. see https://stackoverflow.com/questions/35612428/call-async-await-functions-in-parallel
      let [csvRecordsResult, allAttributesResult] = await Promise.all([
        contactService.fetchContactImportTaskFilePreview(projectId, fileId, delimiter),
        contactService.fetchAllContactAttributes(projectId),
      ]);

      if (!csvRecordsResult?.csvRecords) {
        throw new Error("no CSV records to read");
      }

      if (!allAttributesResult?.contactAttributes) {
        throw new Error("cannot load contact attributes");
      }

      setCsvRecords(csvRecordsResult?.csvRecords);
      setContactAttributes(allAttributesResult?.contactAttributes);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading]);

  useEffect(() => {
    if (currentProjectId && fileId) {
      getFilePreview(currentProjectId, fileId, fileDelimiter);
    }
  }, [getFilePreview, currentProjectId, fileId, fileDelimiter]);

  return (
    <React.Fragment>
      <p className="card-title-desc">
        Let's map CSV columns to the  contact attributes. Please specify at least the column for the phone number.<br />
      </p>
      <div>
        {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage ?? "Something went wrong"}</CustomAlert> : null}
        <GlobalProgressBar isLoading={isLoading} />
        {!isLoading && csvRecords && Array.isArray(csvRecords) && csvRecords.length > 0 &&
          <Table className="table table-striped table-responsive">
            <thead>
              <tr>
                {/* we care only for a number of columns, so the first row should be fine */
                  csvRecords[0].map((c, i) => <th key={`hc_${i}`}>
                    Column {i + 1}
                  </th>)
                }
              </tr>
            </thead>
            <tbody>
              {csvRecords.map((r, i) => <tr key={`br_${i}`} className="align-middle">
                {r.map((c, i) => <td key={`bc_${i}`}>
                  {c}
                </td>)
                }
              </tr>)
              }
            </tbody>
            <tfoot>
              <tr>
                {/* we care only for a number of columns, so the first row should be fine */
                  csvRecords[0].map((c, i) => <th key={`fc_${i}`}>
                    <AttributeMapping
                      contactAttributes={contactAttributes}
                      csvColumnsMap={csvColumnsMap}
                      csvIndex={i + 1}
                      onSelectedAttributeChange={(attrName) => onCsvColumnMappingChange(i + 1, attrName)}
                    />
                  </th>)
                }
              </tr>
            </tfoot>
          </Table>
        }
      </div>
    </React.Fragment>
  )
}

AddContactImportTaskStep2.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddContactImportTaskStep2)));