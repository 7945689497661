import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Row, Card, CardTitle, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getProfileDetails,
  changePassword
} from "../../store/actions";

const ChangePasswordCard = () => {

  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.AuthUser.user);
  const profileDetails = useSelector((state) => state.Profile.profileDetails);
  const isLoading = useSelector((state) => state.AuthChangePassword.loading);
  const isProfileLoading = useSelector((state) => state.Profile.loading);
  const passwordAPIError = useSelector((state) => state.AuthChangePassword.error);

  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message


  const isAuthThirdPartyManaged = () => {
    if (!authUser || !authUser.providerData || !Array.isArray(authUser.providerData)) {
      return false;
    }

    let result = true;
    for(var i = 0; i < authUser.providerData.length; i++) {
      if (authUser.providerData[i].providerId === "password") {
        result = false;
        break;
      }
    }

    return result;
  }

  useEffect(() => {
    dispatch(getProfileDetails());
  }, [dispatch]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
    },
    validationSchema: Yup.object({
      currentPassword: Yup.string().required("Please enter your current password"),
      newPassword: Yup.string().min(6, "Password should be at least 6 characters long"),
      newPasswordRepeat: Yup.string()
        .max(3000, "Password is too long")
        .test(
          "passwordsAreEqual",
          "Passwords are not equal",
          (value, context) => value === context.parent.newPassword,
        ),
    }),
    onSubmit: async (values) => {
      setIsChangingPassword(true);
      dispatch(changePassword(profileDetails?.email, values.currentPassword, values.newPassword, () => {
        validation.resetForm();
        setIsChangingPassword(false);
        setSuccessMessage("Password was successfully updated");
      }));
    }
  });

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Change Password</CardTitle>
          <GlobalProgressBar isLoading={(isLoading || isProfileLoading)} />
          {!isLoading && !isProfileLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
          {!isLoading && !isProfileLoading && passwordAPIError ? <CustomAlert color="danger" role="alert">{passwordAPIError.includes("wrong-password") ? "Incorrect user or password" : "Something went wrong"}</CustomAlert> : null}
          {!isLoading && !isProfileLoading && isAuthThirdPartyManaged() && 
            <div>
              Your account is managed via external identity provider
            </div>
          }
          {!isLoading && !isProfileLoading && !isAuthThirdPartyManaged() &&
            <>
              <Form
                className="form-group top-up-balance"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row className="mb-3">
                  <Label className="form-label">Current password</Label>
                  <InputGroup>
                    <Input
                      name="currentPassword"
                      type="password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.currentPassword || ""}
                      invalid={
                        validation.touched.currentPassword && validation.errors.currentPassword ? true : false
                      }
                    />
                    {validation.touched.currentPassword && validation.errors.currentPassword ? (
                      <FormFeedback type="invalid">{validation.errors.currentPassword}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">New password</Label>
                  <InputGroup>
                    <Input
                      name="newPassword"
                      type="password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.newPassword || ""}
                      invalid={
                        validation.touched.newPassword && validation.errors.newPassword ? true : false
                      }
                    />
                    {validation.touched.newPassword && validation.errors.newPassword ? (
                      <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <Label className="form-label">New password confirm</Label>
                  <InputGroup>
                    <Input
                      name="newPasswordRepeat"
                      placeholder="Enter your new password one more time"
                      type="password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.newPasswordRepeat || ""}
                      invalid={
                        validation.touched.newPasswordRepeat && validation.errors.newPasswordRepeat ? true : false
                      }
                    />
                    {validation.touched.newPasswordRepeat && validation.errors.newPasswordRepeat ? (
                      <FormFeedback type="invalid">{validation.errors.newPasswordRepeat}</FormFeedback>
                    ) : null}
                  </InputGroup>
                </Row>
                <Row className="mb-3">
                  <div className="d-flex flex-wrap gap-3 mt-3">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-md"
                    >
                      {isChangingPassword && !passwordAPIError && <>
                        <Spinner size="sm me-1" color="light" />
                      </>}
                      {(!isChangingPassword || passwordAPIError) && <>
                        Change
                      </>}
                    </Button>
                  </div>
                </Row>
              </Form>
            </>
          }
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

ChangePasswordCard.propTypes = {
  t: PropTypes.any,
  profile: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    profile: state.Profile,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ChangePasswordCard)));