import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Label, Button, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, clearHistoryState } from "../../helpers/utils";

import { keywordService, contactService } from "../../services";

import './keywords.scss';

const KeywordDetails = (props) => {

  const location = useLocation();
  const history = useHistory();

  // getting keywordId fromURL
  const { keywordId: currentKeywordId } = useParams();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [keywordDetails, setKeywordDetails] = useState(null);
  const [addToContactGroups, setAddToContactGroups] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText, setConfirmDialogText] = useState('');
  const [onConfirmFunc, setOnConfirmFunc] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react

  const getKeywordDetails = useCallback(async (projectId, keywordId) => {
    try {
      setIsLoading(true);
      let k = await keywordService.fetchKeywordDetails(projectId, keywordId);

      setKeywordDetails(k);

      if (Array.isArray(k?.addToContactGroupIds)) {
        k?.addToContactGroupIds.forEach(async (groupId) => {
          let g = await contactService.fetchContactGroupDetails(projectId, groupId);
          if (g?.title) {
            let arr = addToContactGroups;
            arr.push(g);
            setAddToContactGroups(arr);
          }
        });
      } else {
        setAddToContactGroups([]);
      }

    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, [addToContactGroups]);

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  useEffect(() => {

    if (redirectStatus === 'create-keyword-succeeded') {
      setSuccessMessage("Keyword was successfully registered");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  useEffect(() => {
    if (currentProjectId && currentKeywordId) {
      getKeywordDetails(currentProjectId, currentKeywordId);
    }
  }, [getKeywordDetails, currentProjectId, currentKeywordId]);


  const onDeleteKeywordClick = () => {
    setConfirmDialogText(`Are you sure you want to delete this keyword?`);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      keywordService.deleteKeyword(currentProjectId, currentKeywordId).then(() => {
        history.push(`/projects/${currentProjectId}/keywords`, { redirectStatus: `delete-keyword-succeeded` });
      }).catch((err) => {
        setErrorMessage(constructErrorMessage(err));
      })
    });

    setIsConfirmDialogOpen(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Keywords"
            pageTitle = "Keyword Details"
            titleUrl={`/projects/${currentProjectId}/keywords`}
            breadcrumbItem={keywordDetails?.keyword} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading && keywordDetails &&
                    <>
                      <Row className="keyword-details-card mb-3">
                        <Col className="col-8">
                          <Label className="form-label">Keyword</Label>
                          <p>{keywordDetails?.keyword}</p>
                        </Col>
                        <Col className="col-4">
                          <Label className="form-label">Created Date</Label>
                          <p>{!keywordDetails?.createdTimestampMs ? '-' : new Date(keywordDetails?.createdTimestampMs).toLocaleString()}</p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={12}>
                          <Label className="form-label">Auto response message</Label>
                          <p className={keywordDetails?.autoResponseMessage ? "" : "text-muted small"}>{keywordDetails?.autoResponseMessage || 'no auto response message'}</p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={12}>
                          <Label className="form-label">Add to contacts groups</Label>
                          <p>
                            {addToContactGroups && Array.isArray(addToContactGroups) && addToContactGroups.length > 0 && addToContactGroups.map((g, i) => <span key={i} className='d-block'>
                              <Link to={`/projects/${currentProjectId}/directory/contacts/groups/${g?.contactGroupId}`} target="_blank">{g?.title}</Link>
                            </span>
                            )}
                            {!(addToContactGroups && Array.isArray(addToContactGroups) && addToContactGroups.length > 0) && <span className="text-muted">
                              -
                            </span>}
                          </p>
                        </Col>
                      </Row>
                      {keywordDetails?.keywordId && <Row className="mb-3">
                        <div className="d-flex flex-wrap gap-3 mt-3">
                          <Button
                            type="reset"
                            color="danger"
                            outline
                            className="w-md"
                            onClick={onDeleteKeywordClick}
                          >{isDeleting && <>
                            <Spinner size="sm me-1" color="danger" />
                          </>}
                            {!isDeleting && <>
                              Delete
                            </>}
                          </Button>
                        </div>
                      </Row>}
                      <Row className="mb-3">
                        <p><small>{props.t("Updating a keyword is limited. You can delete and create a new one.")}</small></p>
                      </Row>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

KeywordDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  tokens: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(KeywordDetails)));