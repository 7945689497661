import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { withRouter, Link, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Table, Badge } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyBlock from '../../components/Common/EmptyBlock';
import CustomConfirmDialog from '../../components/Common/CustomConfirmDialog';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import {
  getNumbersForProject,
  setNumbersLimit,
  getNumbersForProjectNoLoading,
  //deleteNumber
} from "../../store/actions";

import { clearHistoryState } from '../../helpers/utils';
import { formatTNLocation, formatTNType, getTelephoneNumberBadgeCssClassName } from './utils';

import { TELEPHONE_NUMBERS_REFRESH_SEC } from "../../config";

import './numbers.scss';

const Numbers = () => {

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const timerRef = useRef(null);
  const isTelephoneNumbersUpdatingRef = useRef(false);

  const telephoneNumbersList = useSelector((state) => state.Numbers.telephoneNumbersList);
  const telephoneNumbersLimit = useSelector((state) => state.Numbers.telephoneNumbersLimit);
  const telephoneNumbersOffset = useSelector((state) => state.Numbers.telephoneNumbersOffset);
  const telephoneNumbersTotalCount = useSelector((state) => state.Numbers.telephoneNumbersTotalCount);
  const telephoneNumbersLoading = useSelector((state) => state.Numbers.loading);

  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const pageSize = telephoneNumbersLimit || 20;

  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogText/*, setConfirmDialogText*/] = useState('');
  const [onConfirmFunc/*, setOnConfirmFunc*/] = useState(() => () => { }); // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react


  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectTelephoneNumber = (location.state && location.state.tn !== undefined) ? location.state.tn : new URLSearchParams(window?.location?.search).get(
    'tn'
  );

  useEffect(() => {

    if (redirectStatus === 'delete-tn-succeeded') {
      setSuccessMessage(`Telephone number ${decodeURIComponent(redirectTelephoneNumber)} was successfully released`);
    }

    if (redirectStatus === 'purchase-tn-succeeded') {
      setSuccessMessage(`Telephone number ${decodeURIComponent(redirectTelephoneNumber)} was successfully purchased`);
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, redirectTelephoneNumber, history]);

  const loadTelephoneNumbers = useCallback((projectId, limit, offset, isSilent) => {
    if (isSilent) {
      dispatch(getNumbersForProjectNoLoading(projectId, limit, offset));
    } else {
      dispatch(getNumbersForProject(projectId, limit, offset));
    }
  }, [dispatch]);

  const initialDataLoad = useCallback(() => {
    if (currentProjectId) {
      loadTelephoneNumbers(currentProjectId, pageSize, 0, false);
    }
  }, [loadTelephoneNumbers, currentProjectId, pageSize]);

  const dataRefresh = useCallback(() => {
    if (currentProjectId) {
      loadTelephoneNumbers(currentProjectId, telephoneNumbersLimit, telephoneNumbersOffset, false)
    }
  }, [loadTelephoneNumbers, currentProjectId, telephoneNumbersLimit, telephoneNumbersOffset]);

  const silentDataRefresh = useCallback(() => {
    if (currentProjectId) {
      loadTelephoneNumbers(currentProjectId, telephoneNumbersLimit, telephoneNumbersOffset, true)
    }
  }, [loadTelephoneNumbers, currentProjectId, telephoneNumbersLimit, telephoneNumbersOffset]);

  useEffect(() => {
    if (currentProjectId) {
      initialDataLoad();
    }
  }, [initialDataLoad, currentProjectId]);

  const onNextPageClick = () => {
    if (telephoneNumbersOffset + pageSize < telephoneNumbersTotalCount) {
      // Clear the interval when we go too the next page
      isTelephoneNumbersUpdatingRef.current = false;
      clearTimeout(timerRef.current);
      dispatch(getNumbersForProject(currentProjectId, pageSize, telephoneNumbersOffset + pageSize));
    }
  }

  const onPreviousPageClick = () => {
    if (telephoneNumbersOffset !== 0) {
      // Clear the interval when we go too the previous page
      isTelephoneNumbersUpdatingRef.current = false;
      clearTimeout(timerRef.current);
      dispatch(getNumbersForProject(currentProjectId, pageSize, telephoneNumbersOffset - pageSize));
    }
  }

  useEffect(() => {
    if (!currentProjectId || !Array.isArray(telephoneNumbersList)) {
      return;
    }

    if (isTelephoneNumbersUpdatingRef.current) {
      return; // we already launched the update loop
    }

    // if at least one pending_purchase?
    if (telephoneNumbersList.find(tn => tn.status === 'pending_purchase')) {
      isTelephoneNumbersUpdatingRef.current = true;
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        silentDataRefresh();
        isTelephoneNumbersUpdatingRef.current = false;
      }, (TELEPHONE_NUMBERS_REFRESH_SEC ?? 3) * 1000);
    }
  }, [currentProjectId, silentDataRefresh, telephoneNumbersList]);


  // this useEffect should be without any dependencies to work as "unload" func
  useEffect(() => {
    return () => {
      // Clear the interval when the component unmounts
      isTelephoneNumbersUpdatingRef.current = false;
      clearTimeout(timerRef.current);
    };
  }, []);

  /*
  const onDeleteNumberClick = (telephoneNumberId, telephoneNumber) => {

    setConfirmDialogText(<>Are you sure you want to release ${telephoneNumber}? <br/>You will not be able to undo this action.</>);

    setOnConfirmFunc(() => () => { // see https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      dispatch(deleteNumber(currentProjectId, telephoneNumberId, () => {
        dispatch(getNumbersForProject(currentProjectId, pageSize, 0));
        history.push(`/projects/${currentProjectId}/numbers`, { redirectStatus: `delete-tn-succeeded`, tn: `${telephoneNumber}` });
      }))
    });

    setIsConfirmDialogOpen(true);
  }
  */

  // isDataAvailable returns whether we have data to show. If we are loading or it's our first show (isLoading === undefined) - we return undefined to prevent "blinking"
  const isDataAvailable = useCallback(() => {
    if (telephoneNumbersLoading === undefined || telephoneNumbersLoading === true) {
      return undefined;
    }

    return Array.isArray(telephoneNumbersList) && telephoneNumbersList.length > 0;
  }, [telephoneNumbersLoading, telephoneNumbersList]);

  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Purchased Phone Numbers" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col className="col-12">
                      {!telephoneNumbersLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                      <div className="actions clearfix">
                        <ul>
                          {isDataAvailable() && <li>
                            <Link
                              to={`/projects/${currentProjectId}/numbers/search`}
                              className="btn btn-success"
                            >
                              + Purchase number
                            </Link>
                          </li>}
                          <li>
                            <button
                              className="btn refresh without-outline"
                              type="button"
                              title="Refresh"
                              onClick={dataRefresh}
                            >
                              <i className='mdi mdi-reload h3' />
                            </button>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      <GlobalProgressBar isLoading={telephoneNumbersLoading} />
                      {isDataAvailable() === false && <EmptyBlock>
                        <div className="text-center">
                          <p className="h3">No Phone Numbers</p>
                          <p>You haven't chosen your phone number yet.</p>
                          <p>
                            <Link
                              to={`/projects/${currentProjectId}/numbers/search`}
                              className="btn btn-success"
                            >
                              + Purchase number
                            </Link>
                          </p>
                        </div>
                      </EmptyBlock>}
                      {isDataAvailable() && <Table className="table table-striped table-responsive">
                        <thead>
                          <tr>
                            <th>Telephone number</th>
                            <th>Type</th>
                            <th>Capabilities</th>
                            <th>Status</th>
                            <th>Purchase date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {telephoneNumbersList.map((tn, i) => <tr key={i} className="align-middle">
                            {/*<td style={{ width: "2rem" }}>
                                  <i className="uil-trash-alt" title="Release this phone number" role="button" onClick={() => onDeleteNumberClick(tn.telephoneNumberId, tn.telephoneNumber)}></i>
                               </td>*/}
                            <td>
                              {tn?.status === 'active' &&
                                <Link to={`/projects/${currentProjectId}/numbers/${tn.telephoneNumberId}`} title="Go to phone number details">
                                  {tn.telephoneNumber}
                                </Link>}
                              {tn?.status !== 'active' &&
                                <span>
                                  {tn.telephoneNumber}
                                </span>}
                              <br />
                              <div><strong>{formatTNLocation(tn)}</strong></div>
                            </td>
                            <td>
                              {formatTNType(tn)}
                            </td>
                            <td>
                              {tn.capabilities['voice'] &&
                                <i className="uil-phone-alt" title="Voice"></i>
                              }
                              &nbsp;
                              {tn.capabilities['sms'] &&
                                <i className="uil-comment-alt-message" title="SMS"></i>
                              }
                              &nbsp;
                              {tn.capabilities['mms'] &&
                                <i className="uil-comment-alt-image" title="MMS"></i>
                              }
                            </td>
                            <td>
                              <span className='text-uppercase'><Badge className={getTelephoneNumberBadgeCssClassName(tn) + " rounded-pill"}>
                                {tn?.status ? tn?.status.replaceAll("_", " ") : ""}
                              </Badge></span>
                            </td>
                            <td>{!tn.purchasedTimestampMs ? '' : new Date(tn.purchasedTimestampMs).toLocaleString()}</td>
                          </tr>)
                          }
                        </tbody>
                      </Table>
                      }
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12">
                      {(isDataAvailable() || telephoneNumbersTotalCount > 0) &&
                        <div className="actions clearfix">
                          <ul className='d-flex align-items-center justify-content-end'>
                            <li>
                              {/* we need Number() because without it it uses "String" */}
                              <select
                                className="form-control cursor-pointer"
                                onChange={(e) => dispatch(setNumbersLimit(Number(e?.target?.value)))}
                                value={pageSize}
                              >
                                <option value="10">10 rows per page</option>
                                <option value="20">20 rows per page</option>
                                <option value="50">50 rows per page</option>
                                <option value="100">100 rows per page</option>
                                <option value="250">250 rows per page</option>
                              </select>
                            </li>
                            <li
                              className={
                                telephoneNumbersOffset === 0 ? "previous disabled" : "previous"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onPreviousPageClick}
                              >
                                <i className="fa fa-chevron-left" />
                              </Link>
                            </li>
                            <li
                              className={
                                telephoneNumbersOffset + pageSize >= telephoneNumbersTotalCount ? "next disabled" : "next"
                              }
                            >
                              <Link
                                to="#"
                                className="btn btn-primary"
                                onClick={onNextPageClick}
                              >
                                <i className="fa fa-chevron-right" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CustomConfirmDialog
        isOpen={isConfirmDialogOpen}
        closeDialog={() => setIsConfirmDialogOpen(false)}
        confirmationText={confirmDialogText}
        confirmationStyle="warning"
        onConfirm={onConfirmFunc}
      />
    </React.Fragment>
  )
}

Numbers.propTypes = {
  t: PropTypes.any,
  numbers: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    numbers: state.Numbers,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(Numbers)));