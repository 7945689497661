import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Form, Label, Input, FormFeedback, InputGroup, Alert } from "reactstrap";

import { emptyFunc } from "../../helpers/utils";

//i18n
import { withTranslation } from 'react-i18next';

const AddTollFreeVerificationStep1 = (props) => {

  let onBusinessNameChange = props?.onBusinessNameChange ?? emptyFunc;
  let onCorporateWebsiteChange = props?.onCorporateWebsiteChange ?? emptyFunc;
  let onFirstNameChange = props?.onFirstNameChange ?? emptyFunc;
  let onLastNameChange = props?.onLastNameChange ?? emptyFunc;
  let onEmailChange = props?.onEmailChange ?? emptyFunc;
  let onPhoneNumberChange = props?.onPhoneNumberChange ?? emptyFunc;
  let onAddress1Change = props?.onAddress1Change ?? emptyFunc;
  let onAddress2Change = props?.onAddress2Change ?? emptyFunc;
  let onStateNameChange = props?.onStateNameChange ?? emptyFunc;
  let onZipCodeChange = props?.onZipCodeChange ?? emptyFunc;
  let onCityChange = props?.onCityChange ?? emptyFunc;
  let onCountryChange = props?.onCountryChange ?? emptyFunc;

  const { validation } = props;

  useEffect(() => {
    onBusinessNameChange(validation.values.businessName);
  }, [onBusinessNameChange, validation.values.businessName]);

  useEffect(() => {
    onCorporateWebsiteChange(validation.values.corporateWebsite);
  }, [onCorporateWebsiteChange, validation.values.corporateWebsite]);

  useEffect(() => {
    onFirstNameChange(validation.values.firstName);
  }, [onFirstNameChange, validation.values.firstName]);

  useEffect(() => {
    onLastNameChange(validation.values.lastName);
  }, [onLastNameChange, validation.values.lastName]);

  useEffect(() => {
    onEmailChange(validation.values.email);
  }, [onEmailChange, validation.values.email]);

  useEffect(() => {
    onPhoneNumberChange(validation.values.phoneNumber);
  }, [onPhoneNumberChange, validation.values.phoneNumber]);

  useEffect(() => {
    onAddress1Change(validation.values.address1);
  }, [onAddress1Change, validation.values.address1]);

  useEffect(() => {
    onAddress2Change(validation.values.address2);
  }, [onAddress2Change, validation.values.address2]);

  useEffect(() => {
    onStateNameChange(validation.values.stateName);
  }, [onStateNameChange, validation.values.stateName]);

  useEffect(() => {
    onZipCodeChange(validation.values.zipCode);
  }, [onZipCodeChange, validation.values.zipCode]);

  useEffect(() => {
    onCityChange(validation.values.city);
  }, [onCityChange, validation.values.city]);

  useEffect(() => {
    onCountryChange(validation.values.country);
  }, [onCountryChange, validation.values.country]);

  return (
    <React.Fragment>
      <>
        <Form
          className="form-group add-toll-free-verification-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="mb-5">
            <Alert color="info">
              Fill in all details according to business details, not personal details
            </Alert>
          </Row>
          <Row className="mb-5">
            <Col className="col-6">
              <Label className="form-label">Business name</Label>
              <InputGroup>
                <Input
                  name="businessName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.businessName || ""}
                  invalid={
                    validation.touched.businessName && validation.errors.businessName ? true : false
                  }
                />
                {validation.touched.businessName && validation.errors.businessName ? (
                  <FormFeedback type="invalid">{validation.errors.businessName}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
            <Col className="col-6">
              <Label className="form-label">Corporate website</Label>
              <InputGroup>
                <Input
                  name="corporateWebsite"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.corporateWebsite || ""}
                  invalid={
                    validation.touched.corporateWebsite && validation.errors.corporateWebsite ? true : false
                  }
                />
                {validation.touched.corporateWebsite && validation.errors.corporateWebsite ? (
                  <FormFeedback type="invalid">{validation.errors.corporateWebsite}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3 mt-5">
            <Col className="col-6">
              <Label className="form-label">First name</Label>
              <InputGroup>
                <Input
                  name="firstName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.firstName || ""}
                  invalid={
                    validation.touched.firstName && validation.errors.firstName ? true : false
                  }
                />
                {validation.touched.firstName && validation.errors.firstName ? (
                  <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
            <Col className="col-6">
              <Label className="form-label">Last name</Label>
              <InputGroup>
                <Input
                  name="lastName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.lastName || ""}
                  invalid={
                    validation.touched.lastName && validation.errors.lastName ? true : false
                  }
                />
                {validation.touched.lastName && validation.errors.lastName ? (
                  <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col className="col-6">
              <Label className="form-label">Email</Label>
              <InputGroup>
                <Input
                  name="email"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email ? true : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
            <Col className="col-6">
              <Label className="form-label">Phone number</Label>
              <InputGroup>
                <Input
                  name="phoneNumber"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phoneNumber || ""}
                  invalid={
                    validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                  }
                />
                {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                  <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3 mt-5">
            <Col className="col-6">
              <Label className="form-label">Address 1</Label>
              <InputGroup>
                <Input
                  name="address1"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address1 || ""}
                  invalid={
                    validation.touched.address1 && validation.errors.address1 ? true : false
                  }
                />
                {validation.touched.address1 && validation.errors.address1 ? (
                  <FormFeedback type="invalid">{validation.errors.address1}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
            <Col className="col-6">
              <Label className="form-label">Address 2</Label>
              <InputGroup>
                <Input
                  name="address2"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.address2 || ""}
                  invalid={
                    validation.touched.address2 && validation.errors.address2 ? true : false
                  }
                />
                {validation.touched.address2 && validation.errors.address2 ? (
                  <FormFeedback type="invalid">{validation.errors.address2}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-6">
              <Label className="form-label">State</Label>
              <InputGroup>
                <Input
                  name="stateName"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.stateName || ""}
                  invalid={
                    validation.touched.stateName && validation.errors.stateName ? true : false
                  }
                />
                {validation.touched.stateName && validation.errors.stateName ? (
                  <FormFeedback type="invalid">{validation.errors.stateName}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
            <Col className="col-6">
              <Label className="form-label">Postal code</Label>
              <InputGroup>
                <Input
                  name="zipCode"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.zipCode || ""}
                  invalid={
                    validation.touched.zipCode && validation.errors.zipCode ? true : false
                  }
                />
                {validation.touched.zipCode && validation.errors.zipCode ? (
                  <FormFeedback type="invalid">{validation.errors.zipCode}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-6">
              <Label className="form-label">City</Label>
              <InputGroup>
                <Input
                  name="city"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.city || ""}
                  invalid={
                    validation.touched.city && validation.errors.city ? true : false
                  }
                />
                {validation.touched.city && validation.errors.city ? (
                  <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
            <Col className="col-6">
              <Label className="form-label">Country</Label>
              <InputGroup>
                <Input
                  name="country"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.country || ""}
                  invalid={
                    validation.touched.country && validation.errors.country ? true : false
                  }
                />
                {validation.touched.country && validation.errors.country ? (
                  <FormFeedback type="invalid">{validation.errors.country}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </>
    </React.Fragment>
  )
}

AddTollFreeVerificationStep1.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddTollFreeVerificationStep1)));