import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  Spinner,
  Input,
} from "reactstrap";
import mime from "mime-types";

//SimpleBar
import SimpleBar from "simplebar-react";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import CustomAlert from '../../components/Common/CustomAlert';
import EmptyCard from '../../components/Common/EmptyCard';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import ContactPanel from '../Directory/ContactPanel';

import { messageService } from "../../services";

import { constructErrorMessage, formatBytes, constructContactName } from '../../helpers/utils';
import { preventDefault } from '@fullcalendar/core';


const Chat = props => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const timerRef = useRef(null);
  const isChatUpdatingRef = useRef(false);
  const chatUpdatesSinceTimestampRef = useRef(0);
  //const [isChatUpdating, setIsChatUpdating] = useState(false);

  const [messageBox, setMessageBox] = useState(null);
  const [curMessage, setCurMessage] = useState("");


  const [inboxMessages, setInboxMessages] = useState(undefined);
  const [chatMessages, setChatMessages] = useState(undefined);
  const [chatUpdates, setChatUpdates] = useState([]);
  const [selectedFromTo, setSelectedFromTo] = useState([]); // holds the from-to pair
  const [isLoading, setIsLoading] = useState(undefined); // we need undefined to correctly handle the "no messages in the inbox" message. otherwise (if initiated by false) it "blinks" for a moment before first load
  const [isChatMessagesLoading, setIsChatMessagesLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [Chat_Box_Username, setChat_Box_Username] = useState("");
  const [Chat_Box_SendingFrom, setChat_Box_SendingFrom] = useState("");
  const [chat_Box_ContactId, setChat_Box_ContactId] = useState("");
  const [contactDetailsContactId, setContactDetailsContactId] = useState("");
  const [attachedFilesMap, setAttachedFilesMap] = React.useState(new Map());
  const [isContactPanelOpen, setIsContactPanelOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  const isMessageStatusFailure = (m) => {
    if (!m || !m.status) {
      return false;
    }

    return (m.status.indexOf('failed') > -1 || m.status.indexOf('undelivered') > -1);
  }

  const appendChatMessage = useCallback((chatMessage) => {
    if (isChatMessagesLoading) { // don't do anything during initial loading
      return;
    }

    if (!Array.isArray(chatMessages)) {
      return;
    }

    // checking if message is valid
    if (!chatMessage || !chatMessage.from || !chatMessage.to || !Array.isArray(chatMessage.to) || chatMessage.to.length === 0) {
      return;
    }

    if (!Array.isArray(selectedFromTo) || selectedFromTo.length < 2) { // we did not select any chat. no need to append message
      return;
    }

    let mFrom = chatMessage.from;
    let mTo = chatMessage.to[0];
    let [chatFrom, chatTo] = selectedFromTo;

    // append to chat
    if ((chatFrom === mFrom && chatTo === mTo) || (chatFrom === mTo && chatTo === mFrom)) {
      let messageIndex = chatMessages.findIndex(m => chatMessage.messageId === m.messageId);
      if (messageIndex === -1) { // appending only if we did not do that before for this messageId
        chatMessages.push(chatMessage);
      } else { // if we are already displaying the message with this messageId then let's update it. This is useful when a message get's updated (delivered/failed etc.)
        chatMessages[messageIndex] = chatMessage;
      }
    }

  }, [chatMessages, isChatMessagesLoading, selectedFromTo]);

  const appendInboxMessage = useCallback((inboxMessage) => {
    if (isLoading || isLoading === undefined) { // don't do anything during initial loading
      return;
    }

    if (!Array.isArray(inboxMessages)) {
      return;
    }

    // checking if message is valid
    if (!inboxMessage || !inboxMessage.from || !inboxMessage.to || !Array.isArray(inboxMessage.to) || inboxMessage.to.length === 0) {
      return;
    }

    let mFrom = inboxMessage.from;
    let mTo = inboxMessage.to[0];

    // checking if we already have an inbox for this from-to pair
    let inboxIdx = inboxMessages.findIndex(m => Array.isArray(m.to) && m.to.length > 0 && ((m.from === mFrom && m.to[0] === mTo) || (m.from === mTo && m.to[0] === mFrom)));
    if (inboxIdx > -1) { // if we found it, let's replace it with keeping inboxFrom/inboxTo from the old message

      if (inboxMessages[inboxIdx].messageId === inboxMessage.messageId) {
        return; // we already added this message. this will break infinite update because of inboxMessages dependency
      }

      let oldMessage = Object.assign({}, inboxMessages[inboxIdx]); // making a copy
      let newMessage = Object.assign({}, inboxMessage); // making a copy
      // copying fields that should stay the same (we always show the same "from")
      newMessage.inboxContact = oldMessage.inboxContact;
      newMessage.inboxFrom = oldMessage.from;
      newMessage.inboxTo = oldMessage.to;
      // finally replacing a message in the array
      inboxMessages[inboxIdx] = newMessage;
      setInboxMessages([...inboxMessages]); // to trigger re-rendering
    } else if (inboxMessage.direction === 'inbound') { // maybe it's a new inbound message for a TN we had no conversations before?
      // then simply prepend it (with setting inboxFrom/inboxTo)
      let newMessage = Object.assign({}, inboxMessage); // making a copy
      // just copying fields from exact struct to be in the required format
      newMessage.inboxContact = newMessage.fromContact;
      newMessage.inboxFrom = newMessage.from;
      newMessage.inboxTo = newMessage.to;
      inboxMessages.unshift(newMessage);
      setInboxMessages([...inboxMessages]); // to trigger re-rendering
    }

  }, [inboxMessages, isLoading]);


  const loadChatUpdates = useCallback(async (projectId) => {
    try {
      if (!isChatUpdatingRef.current) {
        return;
      }

      let r = await messageService.fetchChatUpdates(projectId, chatUpdatesSinceTimestampRef.current);

      if (r.timeout && r.timestamp) {
        chatUpdatesSinceTimestampRef.current = r.timestamp; // getting timestamp from the timeout response
      } else if (Array.isArray(r.events) && r.events.length > 0) {
        chatUpdatesSinceTimestampRef.current = r.events[r.events.length - 1].timestamp; // getting timestamp of the newest event
      } else {
        chatUpdatesSinceTimestampRef.current = "";
      }

      setChatUpdates(r.events);


      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        loadChatUpdates(projectId);
      }, 10);

    } catch (err) {
      console.log(err);
      chatUpdatesSinceTimestampRef.current = "";
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(() => {
        loadChatUpdates(projectId);
      }, 5000 /* 5 sec timeout in case of an error) */);

    }
  }, []);

  const loadInboxMessages = useCallback(async (projectId, limit, offset) => {
    try {
      setIsLoading(true);
      let r = await messageService.fetchInboxMessages(projectId, limit, offset)

      setInboxMessages(r.messages);
      isChatUpdatingRef.current = true;
      setTimeout(() => {
        loadChatUpdates(projectId);
      }, 10);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, [loadChatUpdates]);

  const loadChatMessages = useCallback(async (projectId, from, to, limit, offset) => {
    try {
      setIsChatMessagesLoading(true);
      let r = await messageService.fetchChatMessages(projectId, from, to, limit, offset)

      setChatMessages(r.messages);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsChatMessagesLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentProjectId && !isChatMessagesLoading && !isLoading && inboxMessages === undefined) {
      loadInboxMessages(currentProjectId, 1000 /* temporary */, 0 /* temporary */);
    }
  }, [loadInboxMessages, currentProjectId, isChatMessagesLoading, isLoading, inboxMessages]);

  useEffect(() => {
    if (currentProjectId && selectedFromTo && selectedFromTo.length > 1) {
      loadChatMessages(currentProjectId, selectedFromTo[0], selectedFromTo[1], 1000 /* temporary */, 0 /* temporary */);
    }
  }, [loadChatMessages, currentProjectId, selectedFromTo]);

  // this useEffect should be without any dependencies to work as "unload" func
  useEffect(() => {
    return () => {
      // Clear the interval when the component unmounts
      isChatUpdatingRef.current = false;
      clearTimeout(timerRef.current);
    };
  }, []);

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => { // see https://stackoverflow.com/questions/66077709/usestate-with-callback-not-updating-usecallback-dependencies
    if (Array.isArray(chatUpdates) && chatUpdates.length > 0) {
      for (var eIdx = 0; eIdx < chatUpdates.length; eIdx++) {
        appendChatMessage(chatUpdates[eIdx].data);
        appendInboxMessage(chatUpdates[eIdx].data);
      }
      scrollToBottom();
    }
  }, [chatUpdates, appendChatMessage, appendInboxMessage, inboxMessages, isLoading, chatMessages, isChatMessagesLoading, selectedFromTo, scrollToBottom]);

  useEffect(() => {
    if (inboxMessages && inboxMessages.length > 0 && (!selectedFromTo || selectedFromTo.length === 0)) { // this is the first load. we did not selected any chat.
      openChat(inboxMessages[0].inboxFrom, inboxMessages[0].inboxTo, constructContactName(inboxMessages[0].inboxContact, inboxMessages[0].inboxFrom), inboxMessages[0]?.inboxContact?.contactId); // opening the top (= the newest) chat.
    }
  }, [inboxMessages, selectedFromTo]);

  useEffect(() => {
    if (!isEmpty(chatMessages)) scrollToBottom();
  }, [scrollToBottom, chatMessages]);

  const openChat = (from, to, chatName, chatContactId) => {
    setSelectedFromTo([from, to]);
    setChat_Box_Username(chatName);
    setChat_Box_SendingFrom(to);
    setChat_Box_ContactId(chatContactId);
  }

  const addMessage = async () => {

    setIsSendingMessage(true);
    setErrorMessage('');
    try {

      if (!Array.isArray(selectedFromTo) || selectedFromTo.length === 0) {
        throw new Error("no chat was selected");
      }
      let response = await messageService.createMessageGroupFromTelephoneNumber(
        currentProjectId,
        curMessage,
        selectedFromTo[1], // this is a TN we are sending our message from. It the chat it is considered as "to" because all communication is initiated by customer (via an INBOUND message)
        [selectedFromTo[0]], // this is a TN we are sending our message to. It the chat it is considered as "from" because all communication is initiated by customer (via an INBOUND message)
        null,
        null,
        [...attachedFilesMap.values()], // attachedFiles
      );


      if (!response?.createdMessageGroup?.messageGroupId) {
        throw new Error("message was not created");
      }

      setCurMessage("");
      setAttachedFilesMap(new Map());
      //onAddMessage(message);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsSendingMessage(false);
    }

  };

  function handleAttachedFiles(files) {

    let mapCopy = new Map(attachedFilesMap); // this will create a new map

    Array.from(files).forEach(file => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })

      mapCopy.set(file.name, file); // Adding file to the map or override if the file with the same name exists. This will prevent duplicates.
    });

    setAttachedFilesMap(mapCopy); // setting changed map to the state variable
  }

  function removeAttachedFile(fileName) {
    if (attachedFilesMap.has(fileName)) {
      let m = new Map(attachedFilesMap);
      m.delete(fileName);
      setAttachedFilesMap(m);
    }
  }

  function areInboxMessagesAvailable() {
    return isLoading === false && inboxMessages && Array.isArray(inboxMessages) && inboxMessages.length > 0;
  }

  const onContactUpdated = useCallback(() => {
    setIsContactPanelOpen(false);
    setSuccessMessage("Contact was successfully updated");
    setTimeout(() => { // clearing the message to avoid showing it multiple times
      setSuccessMessage('');
    }, 1000);
  }, []);

  const onContactDeleted = useCallback(() => {
    setIsContactPanelOpen(false);
    setSuccessMessage("Contact was successfully deleted");
    setTimeout(() => { // clearing the message to avoid showing it multiple times
      setSuccessMessage('');
    }, 1000);
  }, []);

  const openContactDetailsPanel = (id) => { // this func opens the contact details panel for contactId (if supplied) of for a current chat contact Id as a default
    if (id) {
      setContactDetailsContactId(id);
    } else {
      setContactDetailsContactId(chat_Box_ContactId);
    }
    setIsContactPanelOpen(true);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs breadcrumbItem="Inbox" />
          {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
          {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
          <GlobalProgressBar isLoading={isLoading || isChatMessagesLoading} />
          {isLoading === false && !areInboxMessagesAvailable() && <>
            <Row>
              <Col className="col-12">
                <EmptyCard>
                  <div className="text-center">
                    <p className="h3">No Inbox Messages</p>
                    <p>You inbox messages will appear here</p>
                    <p>
                      <Link
                        to={`/projects/${currentProjectId}/compose`}
                        className="btn btn-success"
                        role="button"
                      >
                        Compose message
                      </Link>
                    </p>
                  </div>
                </EmptyCard>
              </Col>
            </Row>
          </>}
          {isLoading === false && areInboxMessagesAvailable() &&
            <div className="d-lg-flex mb-4">
              <Card className="chat-leftsidebar">
                {/*<div className="p-3">
                                <div className="search-box chat-search-box">
                                    <div className="position-relative">
                                        <Input
                                            type="text"
                                            className="form-control bg-light border-light rounded"
                                            placeholder="Search..."
                                        />
                                        <i className="uil uil-search search-icon"></i>
                                    </div>
                                </div>
                            </div>*/}
                <div className="pb-3">
                  <SimpleBar
                    className="chat-message-list"
                    data-simplebar
                  >
                    <div className="p-4 border-top">
                      <div>
                        {/*<div className="float-end">
                                                <Link to="#" className="text-primary"><i className="mdi mdi-plus"></i> New contact</Link>
                                            </div>*/}
                        <h5 className="font-size-16 mb-3"><i className="uil uil-comments-alt me-1"></i> Conversations</h5>
                        <ul className="list-unstyled chat-list">
                          {isLoading === false && (!inboxMessages || !Array.isArray(inboxMessages) || inboxMessages.length === 0) && <>
                            <span className='text-secondary'>no messages in the inbox</span>
                          </>}
                          {map(inboxMessages, inboxMessage => (
                            <li
                              key={inboxMessage.messageId}
                              className={
                                selectedFromTo && selectedFromTo[0] === inboxMessage.from && selectedFromTo[1] === inboxMessage.to
                                  ? "active"
                                  : ""
                              }
                            >
                              <Link
                                to="#"
                                onClick={() => {
                                  openChat(inboxMessage.inboxFrom, inboxMessage.inboxTo, constructContactName(inboxMessage.inboxContact, inboxMessage.inboxFrom), inboxMessage?.inboxContact?.contactId);
                                }}
                              >
                                <div className="d-flex align-items-start">
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate font-size-12 mb-1">
                                      {constructContactName(inboxMessage.inboxContact, inboxMessage.inboxFrom)}
                                    </h5>
                                    <p className="text-truncate mb-0">
                                      {inboxMessage.body || '<media>'}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <div className="font-size-11">
                                      {!inboxMessage.sentTimestampMs ? '' : new Date(inboxMessage.sentTimestampMs).toLocaleString()}
                                    </div>
                                    <React.Fragment>
                                      {false /* is chat handled? */ ?
                                        (
                                          <span className="badge bg-danger rounded-pill">01</span>
                                        )
                                        :
                                        null}
                                    </React.Fragment>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </SimpleBar>
                </div>
              </Card>

              <div className="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
                <Card>
                  <div className="p-3 px-lg-4 border-bottom">
                    <Row>
                      <Col md="8" className="col-10">
                        {Chat_Box_Username &&
                          <h5 className="font-size-16 mb-1 text-truncate">
                            {chat_Box_ContactId && /* showing link if we know this contact */
                              <Link to="#" onClick={(e) => { openContactDetailsPanel(); preventDefault(e); }} className="text-dark">Conversation with {Chat_Box_Username}</Link>
                            }
                            {!chat_Box_ContactId &&
                              <span className="text-dark">Conversation with {Chat_Box_Username}</span>
                            }
                          </h5>
                        }
                        {Chat_Box_SendingFrom &&
                          <p className="text-muted text-truncate mb-0"><i className="mdi mdi-cellphone-play me-1"></i> sending from {Chat_Box_SendingFrom}</p>
                        }
                      </Col>
                      {/*<Col md="8" className="col-6">
                                            <ul className="list-inline user-chat-nav text-end mb-0">
                                                <li className="list-inline-item">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle className="btn nav-btn" tag="button">
                                                            <i className="uil uil-search"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end dropdown-menu-md">
                                                            <Form className="p-2">
                                                                <div>
                                                                    <Input type="text" className="form-control rounded" placeholder="Search..." />
                                                                </div>
                                                            </Form>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                                <li className="list-inline-item">
                                                    <UncontrolledDropdown>
                                                        <DropdownToggle className="btn nav-btn" tag="button">
                                                            <i className="uil uil-ellipsis-h"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <DropdownItem href="#">Profile</DropdownItem>
                                                            <DropdownItem href="#">Archive</DropdownItem>
                                                            <DropdownItem href="#">Muted</DropdownItem>
                                                            <DropdownItem href="#">Delete</DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                </li>
                                            </ul>
                                        </Col>*/}
                    </Row>
                  </div>

                  <div className="px-lg-2">
                    <div className="chat-conversation py-3">
                      <PerfectScrollbar className="list-unstyled chat-conversation-message mb-0 px-3"
                        containerRef={ref => setMessageBox(ref)}
                      >
                        {isChatMessagesLoading && <>
                          <Spinner size="me-1" color="light" />
                        </>}
                        {!isChatMessagesLoading && chatMessages &&
                          map(chatMessages, message => (
                            <li
                              key={"chat_message_" + message.messageId}
                              className={
                                message.direction !== "inbound"
                                  ? "right"
                                  : ""
                              }
                            >
                              <div className="conversation-list ">
                                <div className="ctext-wrap">
                                  <div className={isMessageStatusFailure(message) ? "ctext-wrap-content bg-soft-danger failure" : "ctext-wrap-content"}>
                                    <h5 className="font-size-12 conversation-name">
                                      {message?.fromContact?.contactId && /* showing link if we know this contact */
                                        <Link to="#" onClick={(e) => { openContactDetailsPanel(message?.fromContact?.contactId); preventDefault(e); }} className="text-dark">{constructContactName(message.fromContact, message.from)}</Link>
                                      }
                                      {!message?.fromContact?.contactId &&
                                        <span className="text-dark">{constructContactName(message.fromContact, message.from)}</span>
                                      }
                                      <span className="d-inline-block font-size-12 text-muted ms-2">{!message.sentTimestampMs ? '' : new Date(message.sentTimestampMs).toLocaleString()}</span>

                                    </h5>
                                    <p className="mb-0">{message.body}</p>
                                    {message?.media && Array.isArray(message?.media) && message?.media.length > 0 && message?.media.map((md, i) => <p key={i} className="mb-0">
                                      <a href={md?.url} target="_blank" rel="noreferrer">
                                        {md?.name ? md?.name : md?.url}{md?.contentType ? "." + mime.extension(md?.contentType) : ""}
                                      </a>
                                    </p>
                                    )}
                                    {isMessageStatusFailure(message) && <div className='text-danger'>
                                      <small><i>{message?.status ? message?.status.replaceAll("_", " ") : ""}</i></small>
                                    </div>}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                      </PerfectScrollbar>
                    </div>
                  </div>
                  <div className="p-3 chat-input-section">
                    <Row>
                      <div className="col">
                        <div className="position-relative">
                          {/*<input type="text" className="form-control chat-input rounded" value={curMessage} onChange={e => setCurMessage(e.target.value)} placeholder="Enter your message..." />*/}
                          <Input
                            type="textarea"
                            name="messageBody"
                            className="form-control chat-input rounded"
                            onChange={(e) => setCurMessage(e.target.value)}
                            value={curMessage || ""}
                            placeholder="Enter your message..."
                            rows="3"
                          />
                        </div>
                      </div>
                      <div className="col-auto">
                        <Row className="mb-3">
                          <Button type="submit" color="primary" className="chat-send w-md waves-effect waves-light"
                            onClick={() =>
                              addMessage()
                            }
                          >
                            {isSendingMessage && <>
                              <Spinner size="sm me-1" color="light" />
                            </>}
                            {!isSendingMessage && <>
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span> <i className="mdi mdi-send float-end"></i>
                            </>}
                          </Button>
                        </Row>
                        <Row className="mb=3">
                          <label htmlFor="formFileSm" className="form-label text-primary attach-files-label">
                            <i className="mdi mdi-attachment me-1" />
                            Attach files</label>
                          <input
                            className="form-control form-control-sm d-none"
                            id="formFileSm"
                            type="file"
                            multiple
                            onChange={(e) => handleAttachedFiles(e.target.files)}
                          />
                        </Row>
                      </div>
                    </Row>
                    {attachedFilesMap && attachedFilesMap.size > 0 &&
                      <Row className="mb-3">
                        <Col className="col-12">

                          <div className="dropzone-previews mt-3" id="file-previews">
                            {[...attachedFilesMap.values()].map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <Row>
                                    <Col className="align-items-center">
                                      <span
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </span>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                    <Col className="d-flex flex-row-reverse align-items-center">
                                      <div>
                                        <i className="uil uil-trash-alt h5 cursor-pointer" title='Remove this attachment' onClick={() => removeAttachedFile(f.name)}></i>
                                      </div>
                                    </Col>
                                  </Row>
                                </Card>
                              )
                            })}
                          </div>
                        </Col>
                      </Row>
                    }
                  </div>
                </Card>
              </div>

            </div>
          }
        </Container>
      </div>
      <ContactPanel
        isOpen={isContactPanelOpen}
        toggle={() => setIsContactPanelOpen(!isContactPanelOpen)}
        currentProjectId={currentProjectId}
        currentContactId={contactDetailsContactId}
        onUpdateCallback={onContactUpdated}
        onDeleteCallback={onContactDeleted}
      />
    </React.Fragment>
  );
};

Chat.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    //contact: state.Contact,
  };
};

export default connect(mapStateToProps, {})(Chat);