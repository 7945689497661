import {
  GET_MESSAGES_FOR_PROJECT_REQUEST,
  GET_MESSAGES_FOR_PROJECT_SUCCESS,
  GET_MESSAGE_DETAILS_REQUEST,
  GET_MESSAGE_DETAILS_SUCCESS,
  GET_MESSAGE_GROUPS_FOR_PROJECT_REQUEST,
  GET_MESSAGE_GROUPS_FOR_PROJECT_SUCCESS,
  SET_MESSAGE_GROUPS_LIMIT,
  GET_MESSAGE_GROUP_DETAILS_REQUEST,
  GET_MESSAGE_GROUP_DETAILS_NO_LOADING_REQUEST,
  GET_MESSAGE_GROUP_DETAILS_SUCCESS,
  MESSAGE_API_ERROR
} from "./actionTypes";

export const getMessagesForProject = (projectId, direction, limit, offset) => {
  return {
    type: GET_MESSAGES_FOR_PROJECT_REQUEST,
    payload: { projectId, direction, limit, offset }
  }
};

export const getMessagesForProjectSuccess = ({ messages: messagesList, offset: messagesOffset, limit: messagesLimit }) => {
  return {
    type: GET_MESSAGES_FOR_PROJECT_SUCCESS,
    payload: { messagesList, messagesOffset, messagesLimit }
  }
};

export const getMessageDetails = (projectId, messageId) => {
  return {
    type: GET_MESSAGE_DETAILS_REQUEST,
    payload: { projectId, messageId }
  }
};

export const getMessageDetailsSuccess = message => {
  return {
    type: GET_MESSAGE_DETAILS_SUCCESS,
    payload: message
  }
};

export const getMessageGroupsForProject = (projectId, direction, limit, offset) => {
  return {
    type: GET_MESSAGE_GROUPS_FOR_PROJECT_REQUEST,
    payload: { projectId, direction, limit, offset }
  }
};

export const getMessageGroupsForProjectSuccess = ({ messageGroups: messageGroupsList, offset: messageGroupsOffset, limit: messageGroupsLimit }) => {
  return {
    type: GET_MESSAGE_GROUPS_FOR_PROJECT_SUCCESS,
    payload: { messageGroupsList, messageGroupsOffset, messageGroupsLimit }
  }
};

export const setMessageGroupsLimit = limit => {
  return {
    type: SET_MESSAGE_GROUPS_LIMIT,
    payload: limit,
  }
}

export const getMessageGroupDetails = (projectId, messageGroupId) => {
  return {
    type: GET_MESSAGE_GROUP_DETAILS_REQUEST,
    payload: { projectId, messageGroupId }
  }
};

export const getMessageGroupDetailsNoLoading = (projectId, messageGroupId) => {
  return {
    type: GET_MESSAGE_GROUP_DETAILS_NO_LOADING_REQUEST,
    payload: { projectId, messageGroupId }
  }
};

export const getMessageGroupDetailsSuccess = messageGroup => {
  return {
    type: GET_MESSAGE_GROUP_DETAILS_SUCCESS,
    payload: messageGroup
  }
};

export const messageApiError = error => {
  return {
    type: MESSAGE_API_ERROR,
    payload: error,
  }
}