import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import ContactsGroupInfo from './ContactsGroupInfo';
import GroupContactsCard from './GroupContactsCard';

import classnames from 'classnames';

const ContactsGroupDetails = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const contactGroupDetails = useSelector((state) => state.Contact.contactGroupDetails);

  // getting contactGroupId fromURL
  const { contactGroupId: currentContactGroupId } = useParams();

  const [activeTab, setActiveTab] = useState("1");

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
      <Container fluid className="width-min-content">
          <Breadcrumbs
            title="Contacts Groups"
            pageTitle = "Contact Group Details"
            titleUrl={`/projects/${currentProjectId}/directory/contacts/groups`}
            breadcrumbItem={contactGroupDetails?.title} />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Nav tabs className="nav-tabs-custom">
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-information-variant"></i>
                        </span>
                        <span className="d-none d-sm-block"><i className="mdi mdi-information-variant"></i>Group Info</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        <span className="d-block d-sm-none">
                          <i className="mdi mdi-account-multiple"></i>
                        </span>
                        <span className="d-none d-sm-block"><i className="mdi mdi-account-multiple"></i>Contacts in Group</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab} className="p-3 text-muted">
                    <TabPane tabId="1">
                      <ContactsGroupInfo />
                    </TabPane>
                    <TabPane tabId="2">
                      <GroupContactsCard
                        currentProjectId={currentProjectId}
                        currentContactGroupId={currentContactGroupId}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ContactsGroupDetails.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(ContactsGroupDetails)));