import PropTypes from 'prop-types';
import React, { useState, useCallback } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, NavItem, NavLink, TabPane, TabContent } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import classnames from 'classnames';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { contactService } from "../../services";

import { constructErrorMessage } from "../../helpers/utils";

import AddContactImportTaskStep1 from './AddContactImportTaskStep1';
import AddContactImportTaskStep2 from './AddContactImportTaskStep2';
import AddContactImportTaskStep3 from './AddContactImportTaskStep3';

const AddContactImportTask = () => {

  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message
  const [activeTab, setActiveTab] = useState(1);
  const [csvColumnsMap, setCsvColumnsMap] = useState(new Map());

  const [fileId, setFileId] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [fileDelimiter, setFileDelimiter] = useState(';');
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [firstRowColumnNames, setFirstRowColumnNames] = useState(false);
  const [updateIfRecordExists, setUpdateIfRecordExists] = useState(false);
  const [consentObtained, setConsentObtained] = useState(false);

  const onSelectedFilesChange = useCallback((files) => setSelectedFiles(files), []);
  const onFirstRowColumnNamesChange = useCallback((b) => setFirstRowColumnNames(b), []);
  const onUpdateIfRecordExistsChange = useCallback((b) => setUpdateIfRecordExists(b), []);
  const onSelectedGroupIdsChange = useCallback((list) => setSelectedGroupIds(list), []);
  const onFileDelimiterChange = useCallback((d) => setFileDelimiter(d), []);
  const onCsvColumnMappingChange = (idx, attr) => {
    let m = csvColumnsMap;
    if (attr) {
      m.set(idx, attr);
    } else {

      m.delete(idx); // sets map value if the value is not empty. deletes the map key otherwise.

    }
    setCsvColumnsMap(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
  }

  const isStep1Completed = () => {

    if (!consentObtained) {
      return false;
    }

    if (!Array.isArray(selectedFiles) || selectedFiles.length === 0) {
      return false;
    }

    return true;
  }


  const isStep2Completed = () => {

    return [...csvColumnsMap.values()].filter(v => v === 'phoneNumber').length > 0;

  };

  const uploadFile = async (callback) => {
    if (!Array.isArray(selectedFiles) || selectedFiles.length === 0) {
      return false;
    }

    try {
      setIsLoading(true);
      setErrorMessage('');
      let result = await contactService.uploadContactImportTaskFile(currentProjectId, selectedFiles);

      if (!result?.createdFile) {
        throw new Error('no file was uploaded');
      }

      setFileId(result?.createdFile?.fileId);

      if (callback) {
        callback();
      }

    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }

  }

  const submitContactItemTask = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      let response = await contactService.createContactImportTask(
        currentProjectId,
        fileId,
        selectedFiles[0].name,
        firstRowColumnNames,
        updateIfRecordExists,
        selectedGroupIds,
        fileDelimiter,
        csvColumnsMap
      );
      if (!response?.createdContactImportTask?.taskId) {
        throw new Error("import task was not created");
      }

      history.push(`/projects/${currentProjectId}/directory/contacts/imports`, { redirectStatus: `create-import-task-succeeded` });
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }


  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Breadcrumbs
            title="Import Contacts"
            titleUrl={`/projects/${currentProjectId}/directory/contacts/imports`}
            breadcrumbItem="New Import" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading &&
                    <>
                      <div className="wizard clearfix">
                        <div className="steps clearfix">
                          <ul>
                            <NavItem
                              className={classnames({ current: activeTab === 1 })}>
                              <NavLink
                                className={classnames({ current: activeTab === 1 })}
                              >
                                <span className="number">1</span>{" "}
                                Upload File
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 2 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 2 })}
                              >
                                <span className="number me-2">2</span>{" "}
                                Attributes Mappings
                              </NavLink>
                            </NavItem>
                            <NavItem className={classnames({ current: activeTab === 3 })}>
                              <NavLink
                                className={classnames({ active: activeTab === 3 })}
                              >
                                <span className="number">3</span>{" "}
                                Options
                              </NavLink>
                            </NavItem>
                          </ul>
                        </div>
                        <div className="content clearfix">
                          <div className="body">
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={1}>
                                <AddContactImportTaskStep1
                                  selectedFiles={selectedFiles}
                                  onSelectedFilesChange={onSelectedFilesChange}
                                  fileDelimiter={fileDelimiter}
                                  consentObtained={consentObtained}
                                  onFileDelimiterChange={onFileDelimiterChange}
                                  onConsentObtainedChange={() => setConsentObtained(!consentObtained)}
                                />
                                {isStep1Completed() && <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={() => isStep1Completed() && uploadFile(() => setActiveTab(2))}
                                    >
                                      Next step
                                    </Link>
                                  </div>
                                </Row>
                                }
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={2}>
                                <AddContactImportTaskStep2
                                  currentProjectId={currentProjectId}
                                  fileId={fileId}
                                  fileDelimiter={fileDelimiter}
                                  csvColumnsMap={csvColumnsMap}
                                  onCsvColumnMappingChange={onCsvColumnMappingChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(1)}
                                    >
                                      Previous step
                                    </Link>

                                    {isStep2Completed() && <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={() => setActiveTab(3)}
                                    >
                                      Next step
                                    </Link>
                                    }
                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                            <TabContent
                              activeTab={activeTab}
                            >
                              <TabPane tabId={3}>
                                <AddContactImportTaskStep3
                                  currentProjectId={currentProjectId}
                                  firstRowColumnNames={firstRowColumnNames}
                                  updateIfRecordExists={updateIfRecordExists}

                                  onFirstRowColumnNamesChange={onFirstRowColumnNamesChange}
                                  onUpdateIfRecordExistsChange={onUpdateIfRecordExistsChange}
                                  onSelectedGroupIdsChange={onSelectedGroupIdsChange}
                                />
                                <Row className="mb-3">
                                  <div className="d-flex flex-wrap gap-3 mt-3">
                                    <Link
                                      to="#"
                                      className="btn btn-secondary w-md"
                                      onClick={() => setActiveTab(2)}
                                    >
                                      Previous step
                                    </Link>

                                    <Link
                                      to="#"
                                      className="btn btn-primary w-md"
                                      onClick={() => submitContactItemTask()}
                                    >
                                      Start import
                                    </Link>

                                  </div>
                                </Row>
                              </TabPane>
                            </TabContent>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddContactImportTask.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddContactImportTask)));