import React, { useState, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { Offcanvas, OffcanvasHeader, OffcanvasBody } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import ContactEditComponent from './ContactEditComponent';

import { constructContactName } from '../../helpers/utils';

import './contacts.scss';


const ContactPanel = (props) => {

  const {
    currentProjectId,
    currentContactId,
    onUpdateCallback,
    onDeleteCallback,
  } = props;

  const [contactDetails, setContactDetails] = useState({});

  const [isDataLoading, setIsDataLoading] = useState(false); // to show the title

  const onIsLoadingChange = useCallback((val) => {
    setIsDataLoading(val);
  },[]);

  return (
    <React.Fragment>
      <Offcanvas
        isOpen={props?.isOpen}
        direction="end"
        toggle={props?.toggle}
        className="contact-panel"
      >
        <OffcanvasHeader toggle={props?.toggle}>
          {!isDataLoading && constructContactName(contactDetails, contactDetails?.phoneNumber)}
        </OffcanvasHeader>
        <OffcanvasBody>
          <ContactEditComponent
            currentProjectId={currentProjectId}
            currentContactId={currentContactId}
            onIsLoadingChangeCallback={onIsLoadingChange}
            onContactDetailsLoadedCallback={useCallback((c) => setContactDetails(c), [])}
            onUpdateCallback={onUpdateCallback}
            onDeleteCallback={onDeleteCallback}
          />
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}


export default withRouter(withTranslation()(ContactPanel));