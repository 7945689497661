import PropTypes from 'prop-types';
import React, { useEffect, useState, useCallback } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Card, CardBody, Form, Label, Input, Button, FormFeedback, InputGroup, Spinner } from "reactstrap";
import Select from "react-select";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { mapToOptions, getMapKeyByValue, constructErrorMessage, clearHistoryState } from '../../helpers/utils';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { constantsService, contactService } from "../../services";

import {
  getContactGroupsForProject
} from "../../store/actions";


const AddContact = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const contactGroupsList = useSelector((state) => state.Contact.contactGroupsList);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [attributesList, setAttributesList] = useState([]);
  const [countriesMap, setCountriesMap] = useState(new Map());
  const [languagesMap, setLanguagesMap] = useState(new Map());
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show a error message

  // getting redirectStatus from state
  // see https://stackoverflow.com/questions/69143619/react-js-show-notification-after-redirecting-to-the-new-route
  // with a fallback to the URL param
  const redirectStatus = (location.state && location.state.redirectStatus !== undefined) ? location.state.redirectStatus : new URLSearchParams(window?.location?.search).get(
    'redirect_status'
  );

  const getConstantEnums = useCallback(async () => {
    try {
      setIsLoading(true);
      let [countriesResult, languagesResult] = await Promise.all([
        constantsService.fetchCountriesMap(),
        constantsService.fetchLanguagesMap(),
      ]);

      setCountriesMap(countriesResult);
      setLanguagesMap(languagesResult);

    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);

  const getAdditionalContactAttributesForProject = useCallback(async (projectId) => {
    if (!projectId) {
      return;
    }
    try {
      setIsLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      let result = await contactService.fetchAdditionalContactAttributes(projectId);

      if (!result?.additionalContactAttributes) {
        throw new Error("no attributes available");
      }

      setAttributesList(result?.additionalContactAttributes);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentProjectId) {
      dispatch(getContactGroupsForProject(currentProjectId, null, null));
      getAdditionalContactAttributesForProject(currentProjectId);
      getConstantEnums();
    }
  }, [dispatch, getAdditionalContactAttributesForProject, getConstantEnums, currentProjectId]);

  useEffect(() => {

    if (redirectStatus === 'create-contact-succeeded') {
      setSuccessMessage("Contact was successfully created");
    }

    if (!redirectStatus) {
      setSuccessMessage("");
    }

    // clearing state to avoid showing the same messages after a page refresh
    clearHistoryState(history);

  }, [redirectStatus, history]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      phoneNumber: "",
      email: "",
      firstName: "",
      lastName: "",
      fullName: "",
      company: "",
      jobTitle: "",
      countryCode: "",
      languageCode: "",
      groups: [],
      addMore: false,
      consentToSendMessages: false,
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string().trim().matches(/^\++[1-9]\d{1,14}$/, "Incorrect phone number format. Please use E.164 format.").required("Please enter the phone number."),
      email: Yup.string().nullable().email("Incorrect email format."),
      consentToSendMessages: Yup.boolean().required("Please confirm the written consent.")
        .oneOf([true], "Please confirm the written consent."),
    }),
    onSubmit: async (values) => {
      try {
        setSuccessMessage('');
        setErrorMessage('');

        let attrObj = {};
        attributesList.filter((v) => values[v.attributeName] /* filter non-empty. equal to if (values[v.attributeName]){..}  */).forEach(v => attrObj[v.attributeName] = values[v.attributeName]);

        setIsCreating(true);
        let response = await contactService.createContact(
          currentProjectId,
          values.phoneNumber,
          values.email,
          values.fullName,
          values.firstName,
          values.lastName,
          values.company,
          values.jobTitle,
          values.countryCode,
          values.languageCode,
          values.groups?.map(g => g.value),
          attrObj
        );
        if (!response?.createdContact?.contactId) {
          throw new Error("contact was not created");
        }
        validation.resetForm();
        if (addMore) {
          history.push(`/projects/${currentProjectId}/directory/contacts/add`, { redirectStatus: `create-contact-succeeded` });
        } else {
          history.push(`/projects/${currentProjectId}/directory/contacts/${response?.createdContact?.contactId}`, { redirectStatus: `create-contact-succeeded` });
        }
      } catch (err) {
        console.log(err);
        setErrorMessage(constructErrorMessage(err));
      } finally {
        setIsCreating(false);
      }
    }
  });

  const languagesOptionGroup = [
    {
      // label: group label
      options: mapToOptions(languagesMap)
    }
  ];

  const countriesOptionGroup = [
    {
      // label: group label
      options: mapToOptions(countriesMap)
    }
  ];

  const getGroupsOptionGroup = () => {

    if (!contactGroupsList || !Array.isArray(contactGroupsList) || contactGroupsList.length === 0) {
      return [
        {
          options: []
        }
      ];
    }

    let arr = [];

    contactGroupsList.forEach((e) => {
      arr.push({ label: <><i className="uil uil-users-alt" /> {e.title}</>, value: e.contactGroupId });
    });

    return [
      {
        options: arr
      }
    ];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Contacts"
            titleUrl={`/projects/${currentProjectId}/directory/contacts`}
            breadcrumbItem="New Contact" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={isLoading} />
                  {!isLoading && successMessage ? <CustomAlert color="success" role="alert">{successMessage}</CustomAlert> : null}
                  {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
                  {!isLoading &&
                    <>
                      <Form
                        className="form-group add-contact-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row className="mb-3">
                          <Col className="col-12">
                            <Label className="form-label">Phone number</Label>
                            <InputGroup>
                              <Input
                                name="phoneNumber"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phoneNumber || ""}
                                invalid={
                                  validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                                }
                              />
                              {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                                <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <div>
                            <Label className="form-label">Add to groups</Label>
                            <Select
                              name="groupIds"
                              isMulti={true}
                              isClearable
                              onChange={options => { validation.setFieldValue("groups", options); }}
                              options={getGroupsOptionGroup()}
                              maxMenuHeight={300}
                            />
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col"><hr className="hr hr-blurry" /></div>
                          <div className="col-auto text-muted">other contact attributes</div>
                          <div className="col"><hr className="hr hr-blurry" /></div>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">First name</Label>
                            <InputGroup>
                              <Input
                                name="firstName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.firstName || ""}
                                invalid={
                                  validation.touched.firstName && validation.errors.firstName ? true : false
                                }
                              />
                              {validation.touched.firstName && validation.errors.firstName ? (
                                <FormFeedback type="invalid">{validation.errors.firstName}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Last name</Label>
                            <InputGroup>
                              <Input
                                name="lastName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.lastName || ""}
                                invalid={
                                  validation.touched.lastName && validation.errors.lastName ? true : false
                                }
                              />
                              {validation.touched.lastName && validation.errors.lastName ? (
                                <FormFeedback type="invalid">{validation.errors.lastName}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">Full name</Label>
                            <InputGroup>
                              <Input
                                name="fullName"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.fullName || ""}
                                invalid={
                                  validation.touched.fullName && validation.errors.fullName ? true : false
                                }
                              />
                              {validation.touched.fullName && validation.errors.fullName ? (
                                <FormFeedback type="invalid">{validation.errors.fullName}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Email</Label>
                            <InputGroup>
                              <Input
                                name="email"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email && validation.errors.email ? true : false
                                }
                              />
                              {validation.touched.email && validation.errors.email ? (
                                <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <Label className="form-label">Company</Label>
                            <InputGroup>
                              <Input
                                name="company"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.company || ""}
                                invalid={
                                  validation.touched.company && validation.errors.company ? true : false
                                }
                              />
                              {validation.touched.company && validation.errors.company ? (
                                <FormFeedback type="invalid">{validation.errors.company}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                          <Col className="col-6">
                            <Label className="form-label">Job title</Label>
                            <InputGroup>
                              <Input
                                name="jobTitle"
                                type="text"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.jobTitle || ""}
                                invalid={
                                  validation.touched.jobTitle && validation.errors.jobTitle ? true : false
                                }
                              />
                              {validation.touched.jobTitle && validation.errors.jobTitle ? (
                                <FormFeedback type="invalid">{validation.errors.jobTitle}</FormFeedback>
                              ) : null}
                            </InputGroup>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Preferred language</Label>
                              <Select
                                name="languageCode"
                                isClearable
                                onChange={option => validation.setFieldValue("languageCode", option?.value)}
                                defaultValue={validation.values.languageCode ? { label: getMapKeyByValue(languagesMap, validation.values.languageCode), value: validation.values.languageCode } : ""}
                                options={languagesOptionGroup}
                                maxMenuHeight={170}
                              />
                            </div>
                          </Col>
                          <Col className="col-6">
                            <div>
                              <Label className="form-label">Country</Label>
                              <Select
                                name="countryCode"
                                isClearable
                                onChange={option => validation.setFieldValue("countryCode", option?.value)}
                                defaultValue={validation.values.countryCode ? { label: getMapKeyByValue(countriesMap, validation.values.countryCode), value: validation.values.countryCode } : ""}
                                options={countriesOptionGroup}
                                maxMenuHeight={170}
                              />
                            </div>
                          </Col>
                        </Row>
                        {/* adding custom attributes */}
                        {
                          attributesList && Array.isArray(attributesList) && attributesList.length > 0 && attributesList.map((a, i) => <Row key={i} className="mb-3">
                            <Col className="col-12">
                              <Label className="form-label">{a?.displayName ?? a?.attributeName}</Label>
                              <InputGroup>
                                <Input
                                  name={a?.attributeName}
                                  type="text"
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values[a?.attributeName] || ""}
                                />
                              </InputGroup>
                            </Col>
                          </Row>)
                        }
                        {/* done adding custom attributes */}
                        <Row className="mb-3">
                          <InputGroup>
                            <label>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="consentToSendMessages"
                                onChange={validation.handleChange}
                                defaultChecked={validation.values.consentToSendMessages}
                              />
                              <span className="m-1 text-info cursor-pointer"><i>In compliance with the TCPA legislation I confirm that I obtained a written consent from this contact to send promotional text messages</i></span>
                            </label>
                            {validation.touched.consentToSendMessages && validation.errors.consentToSendMessages ? (
                              <>
                                <span className="is-invalid"></span>
                                <FormFeedback type="invalid">{validation.errors.consentToSendMessages}</FormFeedback>
                              </>
                            ) : null}
                          </InputGroup>
                        </Row>
                        <Row className="mb-3">
                          <div className="d-flex flex-wrap gap-3 mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-md text-nowrap"
                              onClick={() => setAddMore(false)}
                            >
                              {isCreating && !addMore && <>
                                <Spinner size="sm me-1" color="light" />
                              </>}
                              {(!isCreating || addMore) && <>
                                Create
                              </>}
                            </Button>
                            <Button
                              type="submit"
                              color="primary"
                              className="w-xxl text-nowrap"
                              onClick={() => setAddMore(true)}
                            >
                              {isCreating && addMore && <>
                                <Spinner size="sm me-1" color="light" />
                              </>}
                              {(!isCreating || !addMore) && <>
                                Create and enter more
                              </>}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddContact.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AddContact)));