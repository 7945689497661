import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Label } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

import { boolToYesNo } from '../../helpers/utils';

const CampaignDetailsTab2 = (props) => {

  let {
    campaignDetails,
  } = props;

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col md={2}>
          <Label className="form-label">Use case</Label>
          <p>{campaignDetails?.useCase}</p>
        </Col>
        <Col md={10}>
          <Label className="form-label">Sub use cases</Label>
          <p>{campaignDetails?.subUseCases && campaignDetails?.subUseCases.length > 0 ? campaignDetails?.subUseCases.join(", ") : "-"}</p>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Label className="form-label">Subscriber opt-in</Label>
          <p>{boolToYesNo(campaignDetails?.subscriberOptin)}</p>
        </Col>
        <Col md={3}>
          <Label className="form-label">Opt-in keywords</Label>
          <p>
            {campaignDetails?.optinKeywords ? campaignDetails?.optinKeywords.replaceAll(",", ", ") : "-"}
          </p>
        </Col>
        <Col md={7}>
          <Label className="form-label">Opt-in message</Label>
          <p>
            {campaignDetails?.optinMessage || "-"}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Label className="form-label">Subscriber opt-out</Label>
          <p>{boolToYesNo(campaignDetails?.subscriberOptout)}</p>
        </Col>
        <Col md={3}>
          <Label className="form-label">Opt-out keywords</Label>
          <p>
            {campaignDetails?.optoutKeywords ? campaignDetails?.optoutKeywords.replaceAll(",", ", ") : "-"}
          </p>
        </Col>
        <Col md={7}>
          <Label className="form-label">Opt-out message</Label>
          <p>
            {campaignDetails?.optoutMessage || "-"}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Label className="form-label">Subscriber help</Label>
          <p>{boolToYesNo(campaignDetails?.subscriberHelp)}</p>
        </Col>
        <Col md={3}>
          <Label className="form-label">Help keywords</Label>
          <p>
            {campaignDetails?.helpKeywords ? campaignDetails?.helpKeywords.replaceAll(",", ", ") : "-"}
          </p>
        </Col>
        <Col md={7}>
          <Label className="form-label">Help message</Label>
          <p>
            {campaignDetails?.helpMessage || "-"}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label className="form-label">Number pooling</Label>
          <p>{boolToYesNo(campaignDetails?.numberPool)}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label className="form-label">Direct lending or loan arrangement</Label>
          <p>{boolToYesNo(campaignDetails?.directLending)}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label className="form-label">Embedded link</Label>
          <p>{boolToYesNo(campaignDetails?.embeddedLink)}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label className="form-label">Embedded phone number</Label>
          <p>{boolToYesNo(campaignDetails?.embeddedPhone)}</p>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Label className="form-label">Age-gated content</Label>
          <p>{boolToYesNo(campaignDetails?.ageGated)}</p>
        </Col>
      </Row>
    </React.Fragment>
  )
}

CampaignDetailsTab2.propTypes = {
  t: PropTypes.any,
}

const mapStateToProps = state => {
  return {
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(CampaignDetailsTab2)));