import React, { useState, useEffect, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Modal, Table, Button } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import { constructErrorMessage, emptyFunc } from '../../helpers/utils';

import { contactService } from "../../services";

const AddContactsGroupsDialog = (props) => {

  var dialogIsOpen = props.isOpen ?? false;
  var onAddContactsGroups = props.onAddContactsGroups ?? emptyFunc;
  const closeDialog = (needsRefresh) => {
    setIsLoading(false);
    setSuccessMessage('');
    setErrorMessage('');
    return props.closeDialog(needsRefresh);
  }

  const currentProjectId = props?.currentProjectId;

  const pageSize = 10;

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // to show a success message
  const [errorMessage, setErrorMessage] = useState(''); // to show an error message
  const [contactGroupsSelectedIds, setContactGroupsSelectedIds] = useState(new Map());

  const [contactsGroupsList, setContactsGroupsList] = useState([]);
  const [contactsGroupsLimit, setContactsGroupsLimit] = useState(pageSize);
  const [contactsGroupsOffset, setContactsGroupsOffset] = useState(0);
  const [contactsGroupsTotalCount, setContactsGroupsTotalCount] = useState(0);

  const onAddContactsGroupsClick = async () => {
    let selectedIds = [...contactGroupsSelectedIds.keys()];
    let selectedGroups = [...contactGroupsSelectedIds.values()];
    closeDialog(true);
    onAddContactsGroups(selectedIds, selectedGroups);
  };

  const loadContactsGroups = useCallback(async (projectId, limit, offset) => {
    try {
      setIsLoading(true);
      setSuccessMessage('');
      setErrorMessage('');
      //setContactsGroupsList([]); commenting this will makes the table stop "blinking"
      let l = await contactService.fetchContactGroups(projectId, limit, offset);

      if (!l?.contactGroups) {
        throw new Error('empty contacts groups');
      }
      setContactsGroupsList(l?.contactGroups);
      setContactsGroupsLimit(l?.limit ?? pageSize);
      setContactsGroupsOffset(l?.offset ?? 0);
      setContactsGroupsTotalCount(l?.totalCount ?? 0);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentProjectId && dialogIsOpen) {
      setContactGroupsSelectedIds(new Map());
      loadContactsGroups(currentProjectId, pageSize, 0);
    }
  }, [dialogIsOpen, loadContactsGroups, currentProjectId, pageSize]);

  const onNextPageClick = () => {
    if (contactsGroupsOffset + contactsGroupsLimit < contactsGroupsTotalCount) {
      loadContactsGroups(currentProjectId, contactsGroupsLimit, contactsGroupsOffset + contactsGroupsLimit);
    }
  }

  const onPreviousPageClick = () => {
    if (contactsGroupsOffset !== 0) {
      loadContactsGroups(currentProjectId, contactsGroupsLimit, contactsGroupsOffset - contactsGroupsLimit);
    }
  }

  /*
  const handleContactGroupSelectedIdsChange = (e) => {
    let contactsGroupId = e?.target?.value;

    if (!contactsGroupId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (contactGroupsSelectedIds.has(contactsGroupId)) {
      let m = contactGroupsSelectedIds;
      m.delete(contactsGroupId);
      setContactGroupsSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update

    } else {
      let m = contactGroupsSelectedIds;
      // finding contact to add to the map (by contactsGroupId)
      let groupToAdd = contactsGroupsList.find(g => g.contactGroupId === contactsGroupId);
      m.set(contactsGroupId, groupToAdd);
      setContactGroupsSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }

  }

  */

  const handleContactGroupSelectedIdsChangeByGroupId = (contactsGroupId) => {

    if (!contactsGroupId) {
      return;
    }

    // toggle by adding/removing keys from map
    if (contactGroupsSelectedIds.has(contactsGroupId)) {
      let m = contactGroupsSelectedIds;
      m.delete(contactsGroupId);
      setContactGroupsSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update

    } else {
      let m = contactGroupsSelectedIds;
      // finding contact to add to the map (by contactsGroupId)
      let groupToAdd = contactsGroupsList.find(g => g.contactGroupId === contactsGroupId);
      m.set(contactsGroupId, groupToAdd);
      setContactGroupsSelectedIds(new Map(m)); // we need a new Map otherwise React considers it the same and does not update
    }

  }

  const rowCheckboxIsChecked = (contactsGroupId) => {
    return contactGroupsSelectedIds.has(contactsGroupId);
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={dialogIsOpen}
        centered={true}
        contentClassName="width-min-content"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="AddContactsToContactGroupModalLabel">
            Add Contacts Groups
          </h5>
          <button
            type="button"
            onClick={() => {
              closeDialog(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
            <div className="actions clearfix actions-and-search">
              <ul>
                <li
                  className={
                    contactsGroupsOffset === 0 ? "previous disabled" : "previous"
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={onPreviousPageClick}
                  >
                    <i className="fa fa-chevron-left" />
                  </Link>
                </li>
                <li
                  className={
                    (contactsGroupsOffset + contactsGroupsLimit >= contactsGroupsTotalCount) ? "next disabled" : "next"
                  }
                >
                  <Link
                    to="#"
                    className="btn btn-primary"
                    onClick={onNextPageClick}
                  >
                    <i className="fa fa-chevron-right" />
                  </Link>
                </li>
              </ul>
            </div>
            <GlobalProgressBar isLoading={isLoading} />
            <Table className="table-striped table-responsive">
              <thead>
                <tr>
                  <th></th>
                  <th>Group title</th>
                </tr>
              </thead>
              <tbody>
                {(!contactsGroupsList || !Array.isArray(contactsGroupsList) || contactsGroupsList.length === 0) && <tr><td colSpan={2} className="text-center">No contact groups yet</td></tr>}
                {
                  contactsGroupsList && Array.isArray(contactsGroupsList) && contactsGroupsList.length > 0 && contactsGroupsList.map((cg, i) => <tr key={i} className={rowCheckboxIsChecked(cg.contactGroupId) ? "align-middle cursor-pointer table-info" : "align-middle cursor-pointer"} onClick={() => handleContactGroupSelectedIdsChangeByGroupId(cg.contactGroupId)}>
                    <td style={{ width: "2rem" }}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={cg.contactGroupId}
                        id={`chk-contact-group-${i}`}
                        readOnly={true}
                        checked={rowCheckboxIsChecked(cg.contactGroupId)}
                      />
                    </td>
                    <td>
                      {cg.title}
                    </td>
                  </tr>)
                }
              </tbody>
            </Table>
          </div>
        </div>
        <div className="modal-footer">
          <div className='d-flex flex-wrap gap-3 mt-3'>
            <Button
              type="button"
              color="primary"
              className="w-md"
              onClick={onAddContactsGroupsClick}
              disabled={!contactGroupsSelectedIds || [...contactGroupsSelectedIds.keys()].length === 0}
            >
              Add
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              className="w-md"
              onClick={() => {
                if (!isLoading && !successMessage) {
                  closeDialog(false);
                }
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(AddContactsGroupsDialog));