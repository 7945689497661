import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect, useSelector, useDispatch } from "react-redux";
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Container, Row, Col, Card, CardBody, Button, Spinner } from "reactstrap";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import CustomAlert from '../../components/Common/CustomAlert';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';

import {
  getSetupIntentForPaymentMethod,
  stripeApiError
} from "../../store/actions";

import './billing.scss';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe("pk_test_51LtPmHFsX1VIZKmfl13JVU7NsjFI8CV8I9nVEWNQEcV4eWtoufuwbGXhm5mFaR8oiFHmFNUftTSwSkk0iTZQdRI400uRZO3D5p"); // TODO: make an env param

// Stripe component should be wrapped in a separate component. That's why we have two components here. Please see https://stackoverflow.com/questions/64109065/stripe-reactjs-could-not-find-elements-context for details.
const AddPaymentMethodForm = () => {
  const dispatch = useDispatch();
  const stripeError = useSelector((state) => state.Billing.stripeError);
  const stripe = useStripe();
  const elements = useElements();

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);

  const [isCreating, setIsCreating] = useState(false);
  const [showForm, setShowForm] = useState(false); // a flag to show buttons/titles (we set it true when PaymentElement is ready)
  const [redirectToPaymentsTable, setRedirectToPaymentsTable] = useState(false); // a flag to redirect back

  const onCancelPaymentMethodClick = () => {
    setRedirectToPaymentsTable(true);
  }

  const handleSetupIntentSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      console.log("(!stripe || !elements)");
      return;
    }

    setIsCreating(true);

    dispatch(stripeApiError('')); // clear the error message first

    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port;

    const { error } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${protocol}//${domain}:${port ? port : ""}/projects/${currentProjectId}/billing/payment-methods`,
      }
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      console.log(error.message);
      dispatch(stripeApiError(error.message)); // this error message is cleaned in PaymentMethodsTable.onAddPaymentMethodClick().
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setIsCreating(false);
  };

  return (
    <div className="setup-payment-method">
      {redirectToPaymentsTable &&
        <Redirect push to={`/projects/${currentProjectId}/billing/payment-methods`} />
      }
      <form onSubmit={handleSetupIntentSubmit}>
        {stripeError ? <CustomAlert color="danger">{stripeError}</CustomAlert> : null}
        <PaymentElement onReady={() => { setShowForm(true); }} />
        {showForm &&
          <div className="d-flex flex-wrap gap-3 mt-3">
            <Button
              type="submit"
              color="primary"
              className="w-md"
              onClick={handleSetupIntentSubmit}
            >
              {isCreating && <>
                <Spinner size="sm me-1" color="light" />
              </>}
              {!isCreating && <>
                Submit
              </>}
            </Button>
            <Button
              type="reset"
              color="secondary"
              outline
              className="w-md"
              onClick={onCancelPaymentMethodClick}
            >
              Cancel
            </Button>
          </div>
        }
      </form>
    </div>
  );
};



// Stripe Elements Wrapper Form. Please see https://stackoverflow.com/questions/64109065/stripe-reactjs-could-not-find-elements-context for details.

const AddPaymentMethod = () => {

  const dispatch = useDispatch();
  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const setupIntentForPaymentMethodSecret = useSelector((state) => state.Billing.setupIntentForPaymentMethodSecret);
  const setupIntentLoading = useSelector((state) => state.Billing.loading);


  useEffect(() => {
    if (currentProjectId) {
      dispatch(getSetupIntentForPaymentMethod(currentProjectId));
    }
  }, [dispatch, currentProjectId]);


  const stripeOptions = {
    // passing the client secret obtained in step 2
    clientSecret: setupIntentForPaymentMethodSecret,
    // Fully customizable with appearance API.
    appearance: {/*...*/ },
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Payment Methods"
            titleUrl={`/projects/${currentProjectId}/billing/payment-methods`}
            breadcrumbItem="Add Payment Method" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <GlobalProgressBar isLoading={setupIntentLoading} />
                  {!setupIntentLoading &&
                    <>
                      <Elements stripe={stripePromise} options={stripeOptions}>
                        <AddPaymentMethodForm />
                      </Elements>
                    </>
                  }
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

AddPaymentMethod.propTypes = {
  t: PropTypes.any,
  authUser: PropTypes.any,
  billing: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    authUser: state.AuthUser,
    billing: state.Billing,
  };
};

export default connect(
  mapStateToProps,
  {}
)(withRouter(withTranslation()(AddPaymentMethod)));