import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, CardTitle, Label, InputGroup } from "reactstrap";
import ReactEcharts from "echarts-for-react";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

//i18n
import { withTranslation } from 'react-i18next';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import GlobalProgressBar from '../../components/Common/GlobalProgressBar';
import CustomAlert from '../../components/Common/CustomAlert';

import { extractDate, constructErrorMessage } from "../../helpers/utils";

import { projectService } from '../../services';

const baseMessagesOptions = {
  grid: {
    zlevel: 0,
    x: 50,
    x2: 50,
    y: 30,
    y2: 30,
    borderWidth: 0,
    backgroundColor: "rgba(0,0,0,0)",
    borderColor: "rgba(0,0,0,0)",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      crossStyle: {
        color: "#999",
      },
    },
  },
  //toolbox: {
  // feature: {
  //   dataView: { show: true, readOnly: false },
  //   magicType: { show: true, type: ["line", "bar"] },
  //   restore: { show: true },
  //   saveAsImage: { show: true },
  // },
  //},
  color: ["#3c4ccf", "#02a499"],
  legend: {
    data: ["SMS", "MMS"],
    textStyle: {
      color: ["#74788d"],
    },
  },
  xAxis: [], // overridden
  yAxis: [
    {
      type: "value",
      name: "Amount",
      position: "right",
      ///min: 0,
      //max: 250,
      //interval: 50,
      axisLabel: {
        // formatter: "{value} ml",
        formatter: "{value}",
      },
      axisLine: {
        lineStyle: {
          color: "#74788d",
        },
      },
    }
  ],
  series: [], // overridden
  textStyle: {
    color: ["#74788d"],
  },
}

const MessagingReport = () => {

  const currentProjectId = useSelector((state) => state.AuthUser.currentProjectId);
  const [isLoading, setIsLoading] = useState(false);
  //const [statsMessaging, setStatsMessaging] = useState({});
  const [dateRange, setDateRange] = useState([ undefined, undefined]);
  const [outboundChartOptions, setOutboundChartOptions] = useState({});
  const [inboundChartOptions, setInboundChartOptions] = useState({});
  const [deliveredChartOptions, setDeliveredChartOptions] = useState({});
  const [undeliveredChartOptions, setUndeliveredChartOptions] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  const getStatsMessaging = useCallback(async (projectId, extractedStartDate, extractedEndDate) => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      let sm = await projectService.fetchProjectStatsMessaging(projectId, Intl.DateTimeFormat().resolvedOptions().timeZone, extractedStartDate, extractedEndDate);

      //setStatsMessaging(sm);

      let dateLabels = [];
      let outboundSMSCounts = [];
      let outboundMMSCounts = []
      let inboundSMSCounts = [];
      let inboundMMSCounts = [];
      let deliveredSMSCounts = [];
      let deliveredMMSCounts = [];
      let undeliveredSMSCounts = [];
      let undeliveredMMSCounts = [];
      if (sm?.statsMessages) {
        dateLabels = sm?.statsMessages.map(s => s.dwDate.replaceAll("T00:00:00Z", ""));

        outboundSMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.smsSentCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart
        outboundMMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.mmsSentCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart

        deliveredSMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.smsDeliveredCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart
        deliveredMMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.mmsDeliveredCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart

        undeliveredSMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.smsUndeliveredCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart
        undeliveredMMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.mmsUndeliveredCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart

        inboundSMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.smsReceivedCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart
        inboundMMSCounts = sm?.statsMessages.map(s => [s.dwDate.replaceAll("T00:00:00Z", ""), s.mmsReceivedCount]); // see https://stackoverflow.com/questions/60763662/custom-start-point-for-axis-labels-in-echart
      }

      let outboundOptions = {
        ...baseMessagesOptions,
        xAxis: [
          {
            type: "time",
            data: dateLabels,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#74788d",
              },
            },
            splitLine: {
              show: false, // this controls vertical grid lines
            }
          }
        ],
        series: [
          {
            name: "SMS",
            type: "line",
            data: outboundSMSCounts,
            smooth: true,
          },
          {
            name: "MMS",
            type: "line",
            data: outboundMMSCounts,
            smooth: true,
          }
        ],
      };

      let inboundOptions = {
        ...baseMessagesOptions,
        xAxis: [
          {
            type: "time",
            data: dateLabels,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#74788d",
              },
            },
            splitLine: {
              show: false, // this controls vertical grid lines
            }
          }
        ],
        series: [
          {
            name: "SMS",
            type: "line",
            data: inboundSMSCounts,
            smooth: true,
          },
          {
            name: "MMS",
            type: "line",
            data: inboundMMSCounts,
            smooth: true,
          },
        ],
      };

      let deliveredOptions = {
        ...baseMessagesOptions,
        xAxis: [
          {
            type: "time",
            data: dateLabels,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#74788d",
              },
            },
            splitLine: {
              show: false, // this controls vertical grid lines
            }
          }
        ],
        series: [
          {
            name: "SMS",
            type: "line",
            data: deliveredSMSCounts,
            smooth: true,
          },
          {
            name: "MMS",
            type: "line",
            data: deliveredMMSCounts,
            smooth: true,
          }
        ],
      };

      let undeliveredOptions = {
        ...baseMessagesOptions,
        color: ["#ff0000", "#aa00cc"],
        xAxis: [
          {
            type: "time",
            data: dateLabels,
            axisPointer: {
              type: "shadow",
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#74788d",
              },
            },
            splitLine: {
              show: false, // this controls vertical grid lines
            }
          }
        ],
        series: [
          {
            name: "SMS",
            type: "line",
            data: undeliveredSMSCounts,
            smooth: true,
          },
          {
            name: "MMS",
            type: "line",
            data: undeliveredMMSCounts,
            smooth: true,
          }
        ],
      };

      setOutboundChartOptions(outboundOptions);
      setInboundChartOptions(inboundOptions);
      setDeliveredChartOptions(deliveredOptions);
      setUndeliveredChartOptions(undeliveredOptions);
    } catch (err) {
      console.log(err);
      setErrorMessage(constructErrorMessage(err));

    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (currentProjectId) {

      var startDate = new Date();
      startDate.setDate(startDate.getDate() - 7); // -7 days for now
      let extractedStartDate = extractDate(startDate);
      let extractedEndDate = extractDate(new Date());
      setDateRange([extractedStartDate, extractedEndDate]);
      // getStatsMessaging(currentProjectId, extractedStartDate, extractedEndDate); // this will be triggered by useEffect on dateRange
    }
  }, [currentProjectId]);

  useEffect(() => {
    if (currentProjectId && Array.isArray(dateRange) && dateRange.length === 2) {
      if (dateRange[0] && dateRange[1]) {
        getStatsMessaging(currentProjectId, dateRange[0], dateRange[1]);
      }
    }
  }, [dateRange, getStatsMessaging, currentProjectId]);


  return (
    <React.Fragment>
      <div className="page-content width-min-content">
        <Container fluid>
          <Row>
            <Col className="col-form-label d-none d-sm-block">
              <Breadcrumbs breadcrumbItem="Messaging Report"/>
            </Col>
            <Col className="col-12 col-sm-6">
              <div className="mb-3 row">
                <Label className="col col-form-label text-end d-none d-lg-block">Date range</Label>
                <InputGroup className="col">
                  <Flatpickr
                    className="form-control d-block"
                    placeholder="select date range"
                    value={dateRange}
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      maxDate: "today",
                    }}
                    onChange={([startDate, endDate]) => {
                      if (startDate && endDate) {
                        setDateRange([extractDate(startDate), extractDate(endDate)]);
                      }
                    }}
                  />
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row>

            <GlobalProgressBar isLoading={isLoading} />
            {!isLoading && errorMessage ? <CustomAlert color="danger" role="alert">{errorMessage}</CustomAlert> : null}
            {!isLoading &&
              <>
                <Col lg={12} xxl={12 /*6*/}>
                  <Card>
                    <CardBody>
                      <CardTitle>Sent</CardTitle>
                      <Row>
                        <Col>
                          <ReactEcharts style={{ height: "300px" }} option={outboundChartOptions} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12} xxl={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>Received</CardTitle>
                      <Row>
                        <Col>
                          <ReactEcharts style={{ height: "300px" }} option={inboundChartOptions} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12} xxl={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>Delivered</CardTitle>
                      <Row>
                        <Col>
                          <ReactEcharts style={{ height: "300px" }} option={deliveredChartOptions} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12} xxl={12}>
                  <Card>
                    <CardBody>
                      <CardTitle>Undelivered</CardTitle>
                      <Row>
                        <Col>
                          <ReactEcharts style={{ height: "300px" }} option={undeliveredChartOptions} />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </>
            }
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(MessagingReport));