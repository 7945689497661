import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { Row, Col, Form, Label, Input, FormFeedback, InputGroup } from "reactstrap";

import { emptyFunc } from "../../helpers/utils";

//i18n
import { withTranslation } from 'react-i18next';


const AddTollFreeVerificationStep3 = (props) => {

  let onOptInWorkflowChange = props?.onOptInWorkflowChange ?? emptyFunc;
  let onOptInWorkflowImageUrlChange = props?.onOptInWorkflowImageUrlChange ?? emptyFunc;
  let onAdditionalUseCaseDetailsChange = props?.onAdditionalUseCaseDetailsChange ?? emptyFunc;

  const { validation } = props;

  useEffect(() => {
    onOptInWorkflowChange(validation.values.optInWorkflow);
  }, [onOptInWorkflowChange, validation.values.optInWorkflow]);

  useEffect(() => {
    onOptInWorkflowImageUrlChange(validation.values.optInWorkflowImageUrl);
  }, [onOptInWorkflowImageUrlChange, validation.values.optInWorkflowImageUrl]);

  useEffect(() => {
    onAdditionalUseCaseDetailsChange(validation.values.additionalUseCaseDetails);
  }, [onAdditionalUseCaseDetailsChange, validation.values.additionalUseCaseDetails]);

  return (
    <React.Fragment>
      <>
        <Form
          className="form-group add-toll-free-verification-form"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="mb-3">
            <Col className="col-12">
              <div>
                <Label className="form-label">Opt-in workflow</Label>
                <Input
                  type="textarea"
                  name="optInWorkflow"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optInWorkflow || ""}
                  maxLength="672"
                  rows="3"
                />
                {validation.touched.optInWorkflow && validation.errors.optInWorkflow ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.optInWorkflow}</FormFeedback>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <Label className="form-label">Opt-in workflow image URL</Label>
              <InputGroup>
                <Input
                  name="optInWorkflowImageUrl"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.optInWorkflowImageUrl || ""}
                  invalid={
                    validation.touched.optInWorkflowImageUrl && validation.errors.optInWorkflowImageUrl ? true : false
                  }
                />
                {validation.touched.optInWorkflowImageUrl && validation.errors.optInWorkflowImageUrl ? (
                  <FormFeedback type="invalid">{validation.errors.optInWorkflowImageUrl}</FormFeedback>
                ) : null}
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-12">
              <div>
                <Label className="form-label">Additional use case details</Label>
                <Input
                  type="textarea"
                  name="additionalUseCaseDetails"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.additionalUseCaseDetails || ""}
                  maxLength="672"
                  rows="3"
                />
                {validation.touched.additionalUseCaseDetails && validation.errors.additionalUseCaseDetails ? (
                  <>
                    <span className="is-invalid"></span>
                    <FormFeedback type="invalid">{validation.errors.additionalUseCaseDetails}</FormFeedback>
                  </>
                ) : null}
              </div>
            </Col>
          </Row>
        </Form>
      </>
    </React.Fragment>
  )
}

AddTollFreeVerificationStep3.propTypes = {
  t: PropTypes.any,
}

export default connect(
  null,
  {}
)(withRouter(withTranslation()(AddTollFreeVerificationStep3)));