import React from "react";
import { useSpinDelay } from 'spin-delay';
import { BarLoader } from "react-spinners";
import config from "../../config";


const GlobalProgressBar = ({ isLoading, className, color, delay, minDuration }) => {
  const showProgressBar = useSpinDelay(isLoading, { delay: delay ?? config.DEFAULT_GLOBAL_PROGRESS_BAR_DELAY, minDuration: minDuration ?? config.DEFAULT_GLOBAL_PROGRESS_BAR_MIN_DURATION });

  return (
    <BarLoader loading={showProgressBar} color={color ?? "#5b73e8"} className={className} cssOverride={{
      position: "fixed",
      top: 0,
      right: 0,
      zIndex: 100000,
      width: "100%",
    }} />
  );
}

export default GlobalProgressBar;
