import { get, post } from "../helpers/api_helper";
const _ = require('lodash');

class MessageService {

  fetchMessages(projectId, direction, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }
    const directionParam = direction ? direction : null

    let url = `/projects/${projectId}/messages?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    if (directionParam) {
      url = url + `&direction=${directionParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchMessageDetails(projectId, messageId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/messages/${messageId}`)
        .then(response => {
          const message = _.get(response, 'message', {});
          resolve(message);
        }).catch(error => {
          reject(error);
        })
    });

  }

  createMessageGroupFromTelephoneNumberId(projectId, messageBody, fromTelephoneNumberId, toTelephoneNumbers, toContactIds, toContactGroupIds, attachedFiles) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/messages/groups`,
        {
          messageBody,
          fromTelephoneNumberId,
          toTelephoneNumbers,
          toContactIds,
          toContactGroupIds,
          attachedFiles
        },
        {
          'headers': {
            'Content-Type': 'multipart/form-data',
          }
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  createMessageGroupFromTelephoneNumber(projectId, messageBody, fromTelephoneNumber, toTelephoneNumbers, toContactIds, toContactGroupIds, attachedFiles) {
    return new Promise((resolve, reject) => {
      post(
        `/projects/${projectId}/messages/groups`,
        {
          messageBody,
          fromTelephoneNumber,
          toTelephoneNumbers,
          toContactIds,
          toContactGroupIds,
          attachedFiles
        },
        {
          'headers': {
            'Content-Type': 'multipart/form-data',
          }
        }
      ).then((response) => {
        resolve(response);
      }).catch(error => {
        reject(error);
      })
    });

  }

  fetchMessageGroups(projectId, direction, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }
    const directionParam = direction ? direction : null

    let url = `/projects/${projectId}/messages/groups?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    if (directionParam) {
      url = url + `&direction=${directionParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchMessageGroupDetails(projectId, messageGroupId) {
    return new Promise((resolve, reject) => {
      get(`/projects/${projectId}/messages/groups/${messageGroupId}`)
        .then(response => {
          const mg = _.get(response, 'messageGroup', {});
          resolve(mg);
        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchInboxMessages(projectId, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/messages/inbox?offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchChatMessages(projectId, from, to, limit, offset) {
    const limitParam = limit ? limit : null;
    let offsetParam = offset ? offset : 0;
    if (offsetParam < 0) {
      offsetParam = 0; // preventing a negative offset
    }

    let url = `/projects/${projectId}/messages/inbox/chats/messages?from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}&offset=${offsetParam}`;
    if (limitParam) {
      url = url + `&limit=${limitParam}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response (including limit and offset)

        }).catch(error => {
          reject(error);
        })
    });

  }

  fetchChatUpdates(projectId, sinceTimestampMs) {

    let url = `/projects/${projectId}/messages/inbox/chats/events`;
    if (sinceTimestampMs) {
      url = url + `?sinceTimestampMs=${sinceTimestampMs}`;
    }

    return new Promise((resolve, reject) => {
      get(url)
        .then(response => {
          resolve(response); // we need all values from response

        }).catch(error => {
          reject(error);
        })
    });

  }

}

export const messageService = new MessageService();